import BigNumber from 'bignumber.js';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import { core, http } from 'novapay-ui';

import isMobileDevice from '@services/is-mobile-device';
import handleError from '@services/handle-api-error';
import router from '@/router';

import MobilePos from '../../mobile-pos';

import { enum as instruments } from '@repo/enums/operation-instruments';
import { enum as posMerchantBanks } from '@repo/enums/pos-merchant-banks';
import { enum as posMerchantTypes } from '@repo/enums/pos-merchant-types';
import { enum as productParticipantTypes } from '@repo/enums/product-participant-types';
import { enum as awisPaymentTypes } from '@repo/enums/awis-payment-types';

const createActions = (types, rootTypes) => {
	const actions = {
		getSelectedOperations: (context, payload) => {
			let { paymentType } = router.currentRoute.params;
			let { operations, fetchedCommission } = context.state.props;
			let selectedOperations;

			switch (paymentType) {
				case 'instant':
				case 'instant-payout':
				case 'instant-topup':
					selectedOperations = [
						{
							recipient_type: productParticipantTypes.individual,
							amount: payload.payments[0].recipients[0].amount,
							payer_commission: context.state.props.checkedPayments?.[0]?.payment?.commission?.amount ?? null
						}
					];
					break;
				case 'western-union-pay':
					selectedOperations = [
						{
							recipient_type: productParticipantTypes.individual,
							amount: payload.payments[0].recipients[0].amount,
							payer_commission: fetchedCommission?.amount ?? null,
							is_legal_contragent_payment: true
						}
					];
					break;
				case 'family':
				case 'loan-repayment':
					selectedOperations = [
						{
							recipient_type: productParticipantTypes.legal,
							amount: payload.payments[0].recipients[0].amount,
							payer_commission: fetchedCommission?.amount ?? null,
							is_legal_contragent_payment: true
						}
					];
					break;
				case 'awis':
					selectedOperations = operations.filter((o) => payload.ids.includes(o.id));
					break;
				default:
					throw new Error('undefined behavior');
			}

			return selectedOperations;
		},

		autoselectPos: (context, payload) => {
			let { posTerminals } = context.rootState.root.props;
			const isPosDaemonOutdated = context.rootGetters['root/isPosDaemonOutdated'];
			let selectedOperations = actions.getSelectedOperations(context, payload);

			let resPos = null;
			let novapayPoses =
				(!isPosDaemonOutdated && posTerminals.filter((pos) => pos.merchant_bank === posMerchantBanks.tas)) || [];
			let avalPoses = posTerminals.filter(
				(pos) => !pos.code.startsWith('000') && pos.merchant_bank === posMerchantBanks.aval
			);
			let privatPoses = posTerminals.filter((pos) => pos.merchant_bank === posMerchantBanks.privatbank);
			let oschadPoses = posTerminals.filter((pos) => pos.merchant_bank === posMerchantBanks.oschadbank);

			let individualOps = selectedOperations.filter((op) => op.recipient_type === productParticipantTypes.individual);
			let individualOpsSum = individualOps.reduce((acc, op) => new BigNumber(op.amount).plus(acc).toNumber(), 0);

			if (individualOps.length && new BigNumber(individualOpsSum).lt(30000)) {
				[resPos] = oschadPoses || [];
			} else {
				[resPos] = privatPoses || [];
			}
			if (avalPoses.length) {
				[resPos] = avalPoses;
			}
			if (novapayPoses.length) {
				[resPos] = novapayPoses;
			}
			return resPos;
		},

		processPosSelection: (context, payload) => {
			let { selectedPos, rrnCompletionData, forceManualPosSelectionAfterError } = context.state.props;
			let isRRNCompletionWithoutData = payload.is_rrn_completion && !payload.pos_data && !rrnCompletionData;

			if (payload.instrument === instruments.pos) {
				let { posTerminals } = context.rootState.root.props;
				const isPosDaemonOutdated = context.rootGetters['root/isPosDaemonOutdated'];
				posTerminals = (posTerminals || []).filter(
					(p) =>
						(!isPosDaemonOutdated || p.merchant_bank !== 'tas') &&
						(p.merchant_bank !== 'aval' || !p.code.startsWith('000'))
				);
				if (forceManualPosSelectionAfterError) {
					return { selectPosManually: true };
				}

				if (
					isMobileDevice &&
					MobilePos.connectedPosDevices.length > 1 &&
					isRRNCompletionWithoutData &&
					!selectedPos.selected_for_rrn_completion
				) {
					return { selectPosManually: true };
				}

				if (selectedPos) {
					// important bit: mobile pos logic revolves around operation instrument
					// because we cant know tapxphone terminal code before paying
					// so here we set instrument too, not just pos code. same logic goes in submit handler
					if (isMobileDevice) {
						payload.instrument = selectedPos.instrument;
						if (payload.instrument === instruments.tapxphone) {
							payload.np_mobile_device_id = selectedPos.np_mobile_device_id;
						}
					}
					payload.pos_code = selectedPos.code || undefined;
					return { selectPosManually: false };
				}

				// autoselect pos if not mobile
				if (!isMobileDevice) {
					let autoselectedPos = posTerminals.length === 1 ? posTerminals[0] : actions.autoselectPos(context, payload);
					if (autoselectedPos && !isRRNCompletionWithoutData) {
						payload.pos_code = autoselectedPos.code;
						return { selectPosManually: false };
					}
				}

				return { selectPosManually: true };
			}
		},

		processMerchantSelection: (context, payload) => {
			if (payload.instrument === instruments.pos) {
				let { posTerminals } = context.rootState.root.props;
				let { pos_code } = payload;
				let { selectedPosMerchant, actionToResume } = context.state.props;
				let selectedPos = find(posTerminals, { code: pos_code });
				let p2pOschadMerchantInPOS = find(selectedPos.merchants, (m) => m.type === posMerchantTypes.oschadP2P) || {};
				let p2pOschadMerchant = p2pOschadMerchantInPOS.type || selectedPosMerchant || 'visaOrMasterCard';
				let selectedOperations = actions.getSelectedOperations(context, payload);
				let isRRNCompletionWithData = payload.is_rrn_completion && payload.pos_data;

				// eslint-disable-next-line no-inner-declarations
				function findOperationsBySelectedMerchantForRRNCompletion(selectedMerchants, opsGroupedByMerchant) {
					let isOschad = selectedPos.merchant_bank === posMerchantBanks.oschadbank;
					let userSelectedVisaMaster = [posMerchantTypes.oschadVisa, posMerchantTypes.oschadMasterCard].includes(
						payload.pos_data.merchant_type
					);
					let userSelectedMerchant = find(selectedMerchants, (m) => {
						return (
							m === payload.pos_data.merchant_type || (isOschad && m === 'visaOrMasterCard' && userSelectedVisaMaster)
						);
					});
					if (!userSelectedMerchant) {
						let text = 'Обрано некоректний мерчант для допроведення. Будь ласка, спробуйте знову з коректним мерчантом';
						context.commit(rootTypes.ERROR_ALERT, { text }, { root: true });
						context.commit(types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR);
						throw new Error('stopExecution');
					}
					// select operations that match user selected merchant
					if (payload.ids) {
						payload.ids = payload.ids.filter((id) =>
							opsGroupedByMerchant[userSelectedMerchant].map((op) => op.id).includes(id)
						);
					}
					payload.merchant_type = payload.pos_data.merchant_type;
					// update actionToResume so that it contains up to date payload (with ids)
					// will be useful when success event arrives
					context.commit(types.SET_ACTION_TO_RESUME, { ...actionToResume, payload });
				}

				// eslint-disable-next-line no-inner-declarations
				function detectMerchantForOschad() {
					let totalAmountP2P = 0;
					let opsGroupedByMerchant = groupBy(selectedOperations, (op) => {
						if (
							[
								awisPaymentTypes.PaymentForServices,
								awisPaymentTypes.PaymentForRedirecting,
								awisPaymentTypes.PaymentForReturn,
								awisPaymentTypes.PaymentForStorage,
								awisPaymentTypes.PaymentForInternationalDelivery,
								awisPaymentTypes.PaymentForCustomsPayments,
								awisPaymentTypes.PaymentForCommissionBroker,
								awisPaymentTypes.PaymentForInternationalDeliveryNPU,
								awisPaymentTypes.PaymentForLinehaul,
								awisPaymentTypes.PaymentForParcel,
								awisPaymentTypes.PaymentForAdditionalServices
							].includes(op?.metadata?.type)
						) {
							return posMerchantTypes.oschadNovaPoshta;
						} else if (
							[awisPaymentTypes.PaymentForPackingAgent, awisPaymentTypes.PaymentForAfterPayment].includes(
								op?.metadata?.type
							) ||
							op.is_legal_contragent_payment
						) {
							return posMerchantTypes.oschadContragents;
						} else {
							totalAmountP2P = new BigNumber(totalAmountP2P)
								.plus(op.amount)
								.plus(op.payer_commission || 0)
								.toNumber();
							return p2pOschadMerchant;
						}
					});
					if (new BigNumber(totalAmountP2P).gte(30000)) {
						opsGroupedByMerchant = omit(
							{
								...opsGroupedByMerchant,
								[posMerchantTypes.oschadContragents]: (
									opsGroupedByMerchant[posMerchantTypes.oschadContragents] || []
								).concat(cloneDeep(opsGroupedByMerchant[p2pOschadMerchant]))
							},
							[p2pOschadMerchant]
						);
					}

					let selectedMerchants = orderBy(Object.keys(opsGroupedByMerchant), (merchant) => {
						return merchant !== p2pOschadMerchant;
					});
					let posMerchantForAllOperations = selectedMerchants.length === 1 ? selectedMerchants[0] : null;
					return { opsGroupedByMerchant, selectedMerchants, posMerchantForAllOperations };
				}

				// eslint-disable-next-line no-inner-declarations
				function detectMerchantForAval() {
					let totalAmountP2P = 0;
					let opsGroupedByMerchant = groupBy(selectedOperations, (op) => {
						if (new BigNumber(op.amount).plus(op.payer_commission || 0).gte(30000)) {
							return posMerchantTypes.avalPayment;
						}
						if (
							[
								awisPaymentTypes.PaymentForServices,
								awisPaymentTypes.PaymentForRedirecting,
								awisPaymentTypes.PaymentForReturn,
								awisPaymentTypes.PaymentForStorage,
								awisPaymentTypes.PaymentForInternationalDelivery,
								awisPaymentTypes.PaymentForPackingAgent,
								awisPaymentTypes.PaymentForAfterPayment,
								awisPaymentTypes.PaymentForCustomsPayments,
								awisPaymentTypes.PaymentForCommissionBroker,
								awisPaymentTypes.PaymentForInternationalDeliveryNPU,
								awisPaymentTypes.PaymentForLinehaul,
								awisPaymentTypes.PaymentForParcel,
								awisPaymentTypes.PaymentForAdditionalServices
							].includes(op?.metadata?.type) ||
							op.is_legal_contragent_payment
						) {
							return posMerchantTypes.avalPayment;
						} else {
							totalAmountP2P = new BigNumber(totalAmountP2P)
								.plus(op.amount)
								.plus(op.payer_commission || 0)
								.toNumber();
							return posMerchantTypes.avalP2P;
						}
					});

					let selectedMerchants = orderBy(Object.keys(opsGroupedByMerchant));
					let posMerchantForAllOperations = selectedMerchants.length === 1 ? selectedMerchants[0] : null;
					if (new BigNumber(totalAmountP2P).gte(30000)) {
						posMerchantForAllOperations = posMerchantTypes.avalPayment;
					}
					return { opsGroupedByMerchant, selectedMerchants, posMerchantForAllOperations };
				}

				// eslint-disable-next-line no-inner-declarations
				function detectMerchantForNovapay() {
					let totalAmountP2P = 0;
					let opsGroupedByMerchant = groupBy(selectedOperations, (op) => {
						if (new BigNumber(op.amount).plus(op.payer_commission || 0).gte(30000)) {
							return posMerchantTypes.novapayDefault;
						}
						if (
							[
								awisPaymentTypes.PaymentForServices,
								awisPaymentTypes.PaymentForRedirecting,
								awisPaymentTypes.PaymentForReturn,
								awisPaymentTypes.PaymentForStorage,
								awisPaymentTypes.PaymentForInternationalDelivery,
								awisPaymentTypes.PaymentForPackingAgent,
								awisPaymentTypes.PaymentForAfterPayment,
								awisPaymentTypes.PaymentForCustomsPayments,
								awisPaymentTypes.PaymentForCommissionBroker,
								awisPaymentTypes.PaymentForInternationalDeliveryNPU,
								awisPaymentTypes.PaymentForLinehaul,
								awisPaymentTypes.PaymentForParcel,
								awisPaymentTypes.PaymentForAdditionalServices
							].includes(op?.metadata?.type) ||
							op.is_legal_contragent_payment
						) {
							return posMerchantTypes.novapayDefault;
						} else {
							totalAmountP2P = new BigNumber(totalAmountP2P)
								.plus(op.amount)
								.plus(op.payer_commission || 0)
								.toNumber();
							return posMerchantTypes.novapayP2P;
						}
					});

					let selectedMerchants = orderBy(Object.keys(opsGroupedByMerchant));
					let posMerchantForAllOperations = selectedMerchants.length === 1 ? selectedMerchants[0] : null;
					if (new BigNumber(totalAmountP2P).gte(30000)) {
						posMerchantForAllOperations = posMerchantTypes.novapayDefault;
					}
					return { opsGroupedByMerchant, selectedMerchants, posMerchantForAllOperations };
				}

				if (selectedPos.merchant_bank === posMerchantBanks.privatbank) {
					payload.merchant_type = posMerchantTypes.privatDefault;
					return { selectMerchantManually: false };
				}

				if (
					[posMerchantBanks.aval, posMerchantBanks.oschadbank, posMerchantBanks.tas].includes(selectedPos.merchant_bank)
				) {
					let posOptions = null;
					switch (selectedPos.merchant_bank) {
						case posMerchantBanks.oschadbank:
							posOptions = detectMerchantForOschad();
							break;
						case posMerchantBanks.aval:
							posOptions = detectMerchantForAval();
							break;
						case posMerchantBanks.tas:
							posOptions = detectMerchantForNovapay();
							break;
					}
					let { opsGroupedByMerchant, selectedMerchants, posMerchantForAllOperations } = posOptions;
					if (isRRNCompletionWithData) {
						try {
							findOperationsBySelectedMerchantForRRNCompletion(selectedMerchants, opsGroupedByMerchant);
						} catch (e) {
							if (e.message === 'stopExecution') {
								return { stopExecution: true };
							} else {
								throw e;
							}
						}
						return { selectMerchantManually: false };
					} else if (selectedMerchants.includes('visaOrMasterCard')) {
						return { selectMerchantManually: true };
					} else if (posMerchantForAllOperations) {
						payload.merchant_type = posMerchantForAllOperations;
						return { selectMerchantManually: false };
					} else {
						let merchantQueueItems = getQueueItemsByMerchant(payload, selectedMerchants, opsGroupedByMerchant);
						return { selectMerchantManually: false, merchantQueueItems };
					}
				}
			}
		},

		getPosTransactions: async (context, query) => {
			let { selectedPos } = context.state.props;
			if (isMobileDevice) {
				if (selectedPos.instrument === instruments.portablePos) {
					let privatTransactions = [];
					context.commit('SET_LOADING_ACTION', { action: 'getPosTransactions' });
					try {
						privatTransactions = await MobilePos.getPrivatTransactions();
					} catch (e) {
						context.commit(
							rootTypes.namespaced.ERROR_ALERT,
							{ title: 'Помилка отримання списку транзакцiй', text: e.message },
							{ root: true }
						);
					}
					context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
					return context.commit(types.MOBILE_POS_RRN_TRANSACTIONS, privatTransactions);
				}
				if (selectedPos.instrument === instruments.tapxphone) {
					let { cashdesk } = context.rootState.root.props;
					context.commit('SET_LOADING_ACTION', { action: 'getPosTransactions' });
					let posRes = await http({
						url: `/v3/tapxphone/reserve-pos`,
						method: 'POST',
						data: { np_mobile_device_id: selectedPos.np_mobile_device_id, cashdesk_id: cashdesk.id }
					});
					if (!handleError()(posRes, context)) {
						return;
					}
					if (!posRes.data || !posRes.data.code) {
						context.commit(types.MOBILE_POS_RRN_TRANSACTIONS, []);
						return context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
					}
					context.commit(types.CLOSE_POS_SELECT, { pos: { ...selectedPos, ...posRes.data } });
					let trxRes = await http({
						url: `/v3/tapxphone/transactions`,
						query: { code: posRes.data.code }
					});
					context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
					if (!handleError()(trxRes, context)) {
						return;
					}
					return context.commit(types.MOBILE_POS_RRN_TRANSACTIONS, trxRes.data);
				}
			} else {
				let { offset = 0, limit = 5 } = query || {};
				let { posTerminals } = context.rootState.root.props;
				let updatedTerminals = (posTerminals || []).map((p) => {
					return p.code === selectedPos.code ? { ...p, transactions: null, transactions_total: 0 } : p;
				});
				context.commit(rootTypes.UPDATE_POS_TERMINALS, updatedTerminals, { root: true });

				context.commit('SET_LOADING_ACTION', { action: 'getPosTransactions' });
				setTimeout(() => {
					context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
				}, 90000);

				let res = await http({
					url: `/v3/pos-terminals/transactions`,
					query: { code: selectedPos.code, offset, limit }
				});
				handleError()(res, context);
			}
		},

		resetPosTransactionsLoading: (context) => context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' }),

		checkOperationExistsByRrn: async (context, { rrn, pos_code, merchant_id } = {}) => {
			let res = await http({ url: `/v3/operations/check-exists-by-rrn`, query: { rrn, merchant_id } });
			if (!handleError()(res, context)) {
				return;
			}
			if (isMobileDevice) {
				let existing = context.state.props.mobilePosTransactions || [];
				return context.commit(
					types.MOBILE_POS_RRN_TRANSACTIONS,
					existing.map((t) => (t.rrn === rrn ? { ...t, rrn_exists: res.data.exists } : t))
				);
			}
			let { posTerminals } = context.rootState.root.props;
			let updatedTerminals = (posTerminals || []).map((p) => {
				return p.code === pos_code
					? {
							...p,
							transactions: (p.transactions || []).map((t) =>
								t.rrn === rrn && t.merchant_id === merchant_id ? { ...t, rrn_exists: res.data.exists } : t
							)
					  }
					: p;
			});
			context.commit(rootTypes.UPDATE_POS_TERMINALS, updatedTerminals, { root: true });
		},

		payWithMobilePos: async (context, payload) => {
			let posData = {};
			let txpTokenData = {};
			try {
				txpTokenData = await getTxpPaymentToken(payload);
				posData = await MobilePos.pay(payload, txpTokenData, (title, severity = 'info') => {
					context.commit(
						rootTypes.ADD_ALERT,
						{
							severity,
							title
						},
						{ root: true }
					);
				});
			} catch (e) {
				posData = { ...posData, is_successful: false, reason: e.message };
			}

			if (txpTokenData) {
				posData = { ...posData, tapxphone_payment_token: txpTokenData.token };
			}

			let res = await http(`/v3/operations/portable-pos-complete-pay`, {
				method: 'POST',
				data: {
					ids: payload.ids,
					...posData
				}
			});

			handleError()(res, context);
		},
		selectPos: (context, pos) => context.commit(types.CLOSE_POS_SELECT, { pos }),

		requestPosOtpInput: async (context, payload) => {
			const pos_code = context.rootGetters['root/activePosTerminal']?.code;
			if (!pos_code) {
				return context.commit(
					rootTypes.ADD_ALERT,
					{
						severity: 'error',
						title: 'Жоден ПОС-термінал не підключено'
					},
					{ root: true }
				);
			}
			const data = { pos_code };
			const res = await http(`/v3/pos-terminals/request-otp-input`, { method: 'POST', data });
			if (!handleError()(res, context)) {
				return;
			}

			const activePosTerminalMerchantBank = context.rootGetters['root/activePosTerminalMerchantBank'];
			context.commit(
				rootTypes.ADD_ALERT,
				{
					severity: 'info',
					title: `Ініційоване ввдедення OTP на терміналі ${activePosTerminalMerchantBank.text}`
				},
				{ root: true }
			);
			const timeout = setTimeout(() => {
				context.commit(types.TIMEOUT_POS);
			}, 30_000);
			context.commit(types.ACTIVATE_POS, { timeout });
		},

		receiveOtpInputEvent: async (context, payload) => {
			const { is_successful, otp, reason } = payload;
			if (!is_successful) {
				const { render } = context.rootState.payment.state_key;

				const isPosActivated =
					render?.credit?.scoring?.pos_otp === 'pos_activated' ||
					render?.credit?.otp?.pos_otp === 'pos_activated' ||
					render?.pos_otp === 'pos_activated';

				if (isPosActivated) {
					context.commit(
						rootTypes.ADD_ALERT,
						{
							severity: 'error',
							title: 'Помилка введення OTP',
							text: reason
						},
						{ root: true }
					);
					context.dispatch('togglePosOtp');
				}
			}
			if (otp) {
				context.commit(types.SET_ACTION_TO_RESUME, {
					...context.state.props.actionToResume,
					payload: {
						...context.state.props.actionToResume?.payload,
						otp
					}
				});
				context.dispatch('togglePosOtp', { otp });
			}
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.CLOSE_POS_SELECT]: (state, { pos } = {}) => {
		if (pos && isMobileDevice && state.props.actionToResume?.payload?.is_rrn_completion) {
			pos.selected_for_rrn_completion = true;
		}
		state.props = { ...state.props, selectedPos: pos, forceManualPosSelectionAfterError: false };
	},
	[types.CLOSE_POS_MERCHANT_SELECT]: (state, { merchant } = {}) => {
		state.props = { ...state.props, selectedPosMerchant: merchant };
	},
	[types.CLOSE_RRN_COMPLETION]: (state, { rrnCompletionData } = {}) => {
		state.props = { ...state.props, rrnCompletionData, mobilePosTransactions: null };
	},
	[types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR]: (state) => {
		state.props = {
			...state.props,
			selectedPos: null,
			selectedPosMerchant: null,
			rrnCompletionData: null,
			forceManualPosSelectionAfterError: true,
			mobilePosTransactions: null,
			splitPayment: null
		};
	},
	[types.MOBILE_POS_RRN_TRANSACTIONS]: (state, mobilePosTransactions) => {
		state.props = {
			...state.props,
			mobilePosTransactions
		};
	},
	[types.ACTIVATE_POS]: (state, { timeout } = {}) => {
		state.props = {
			...state.props,
			activePosTimeout: timeout
		};
	},
	[types.TIMEOUT_POS]: (state) => {
		state.props = {
			...state.props,
			activePosTimeout: null
		};
	},
	[types.CLOSE_POS_OTP]: (state) => {
		clearInterval(state.props.activePosTimeout);
		state.props = {
			...state.props,
			activePosTimeout: null
		};
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

function getQueueItemsByMerchant(payload, selectedMerchants, opsGroupedByMerchant) {
	return selectedMerchants.map((merchant_type) => {
		return {
			payload: {
				...payload,
				ids: opsGroupedByMerchant[merchant_type].map((o) => o.id),
				merchant_type
			},
			actionName: 'submitPayment',
			loadingAction: { id: instruments.pos }
		};
	});
}

async function getTxpPaymentToken(payload) {
	if (payload.instrument === instruments.tapxphone) {
		let [op] = payload?.initializeRes || [];
		if (!op?.transaction?.pos_terminal_id) {
			throw new Error('Помилка визначення терміналу для Tapxphone');
		}
		let tapxphoneTokenRes = await http(`/v3/tapxphone/payment-token`, {
			method: 'POST',
			data: {
				amount: payload.mobile_pos_amount,
				pos_terminal_id: op.transaction.pos_terminal_id
			}
		});
		if (tapxphoneTokenRes.status !== 200 || !tapxphoneTokenRes?.data?.token) {
			throw new Error('Помилка отримання токену Tapxphone');
		}
		return tapxphoneTokenRes.data;
	}
}

export default createComponentStore;
