import Promise from 'bluebird';
import debounce from 'lodash/debounce';
import { core, http } from 'novapay-ui';
import isMobileDevice from '@services/is-mobile-device';

const createActions = (types, rootTypes) => {
	const actions = {
		checkMitAvailability: async (context, { phone } = {}) => {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				if (!phone || !phone.length) {
					context.commit(types.MIT_CARD_DATA, null);
					return resolve(null);
				}
				phone = phone.replace(/[()\s]/g, '');
				if (phone.length < 13) {
					context.commit(types.MIT_CARD_DATA, null);
					return resolve(null);
				}
				try {
					let res = await http({
						url: '/v3/operations/check-mit-availability',
						method: 'POST',
						data: { phone: phone.replace(/[()\s]/g, '') },
						timeout: 3 * 1000
					});
					if (!res || res.status !== 200) {
						context.commit(types.MIT_CARD_DATA, null);
						return resolve(null);
					}
					if (isMobileDevice) {
						res.data.use_by_default = false;
					}
					context.commit(types.MIT_CARD_DATA, res.data);
					return resolve(res.data);
				} catch (e) {
					context.commit(types.MIT_CARD_DATA, null);
					return resolve(null);
				}
			});
		},
		// not doing this in xstate because we want this modal state to be "parallel" to others
		toggleMitByDefault: (context) => {
			context.commit(types.TOGGLE_MIT_BY_DEFAULT);
		}
	};
	actions.checkMitAvailabilityDebounced = debounce(actions.checkMitAvailability, 500, { leading: true });
	return actions;
};

const createMutations = (types) => ({
	[types.MIT_CARD_DATA]: (state, mitCardData) => {
		state.props = { ...state.props, mitCardData };
	},
	[types.TOGGLE_MIT_BY_DEFAULT]: (state) => {
		state.props = { ...state.props, showMitByDefault: !state.props.showMitByDefault };
	},
	[types.HIDE_MIT_BY_DEFAULT_ON_ERROR]: (state) => {
		state.props = {
			...state.props,
			showMitByDefault: false,
			actionToResume: state.props.mitCardData?.use_by_default ? null : state.props.actionToResume
		};
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
