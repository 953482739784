const types = {
	incoming: 'incoming',
	outgoing: 'outgoing'
};

const locale = {
	[types.incoming]: 'ПКО',
	[types.outgoing]: 'ВКО'
};

module.exports = {
	enum: types,
	locale: locale
};
