import qs from 'qs';
import Vue from 'vue';
import Router from 'vue-router';
import { core } from 'novapay-ui';

import Root from '@/modules/root';
import store from '@/store';

const { NavigationFailureType, isNavigationFailure } = Router;
const rootTypes = core.getTypes('root').namespaced;
const errorRoutes = ['/404', '/500', '/403'];

Vue.use(Router);
let baseTitle = document.title;
const routes = [
	{
		path: '/',
		component: Root,
		children: [
			{
				path: '/',
				component: () => import(/* webpackChunkName: "control-center" */ '@/modules/control-center'),
				meta: { title: 'Сторiнка касира' }
			},
			{
				path: '/acts',
				component: () => import(/* webpackChunkName: "acts" */ '@/modules/acts'),
				meta: { title: 'Акти' }
			},
			{
				path: '/acts/:cashbook_id',
				component: () => import(/* webpackChunkName: "acts" */ '@/modules/acts'),
				meta: { title: 'Акти' }
			},
			{
				path: '/orders',
				component: () => import(/* webpackChunkName: "orders" */ '@/modules/orders'),
				meta: { title: 'Ордери' }
			},
			{
				path: '/orders/:cashbook_id',
				component: () => import(/* webpackChunkName: "orders" */ '@/modules/orders'),
				meta: { title: 'Касова книга' }
			},
			{
				path: '/cash-management/:cashbook_id',
				component: () => import(/* webpackChunkName: "cash-management" */ '@/modules/cash-management'),
				meta: { title: 'Графік перевезення коштів' }
			},
			{
				path: '/printed-documents/:cashbook_id',
				component: () => import(/* webpackChunkName: "printed-documents" */ '@/modules/printed-documents'),
				meta: { title: 'Друкованi форми' }
			},
			{
				path: '/cashbooks',
				component: () => import(/* webpackChunkName: "cashbooks" */ '@/modules/cashbooks'),
				meta: { title: 'Касовi книги' }
			},
			{
				path: '/operations',
				component: () => import(/* webpackChunkName: "operations" */ '@/modules/operations'),
				meta: { title: 'ЖО' }
			},
			{
				path: '/payment/:paymentType',
				component: () => import(/* webpackChunkName: "payment" */ '@/modules/payment'),
				props: (route) => ({ paymentType: route.params.paymentType }),
				meta: { title: 'Операцiя' }
			},
			{
				path: '/refreshments',
				component: () => import(/* webpackChunkName: "refreshments" */ '@/modules/refreshments'),
				meta: { title: 'Пiдкрiплення' }
			},
			{
				path: '/archive-documents',
				component: () => import(/* webpackChunkName: "archive-documents" */ '@/modules/archive-documents'),
				meta: { title: 'Архiв' }
			},
			{
				path: '/archive-packages',
				component: () => import(/* webpackChunkName: "archive-packages" */ '@/modules/archive-packages'),
				meta: { title: 'Архiв' }
			},
			{
				path: '/naftogaz-applications',
				component: () => import(/* webpackChunkName: "naftogaz-applications" */ '@/modules/naftogaz-applications'),
				meta: { title: 'Журнал заявок Нафтогазу' }
			},
			{
				path: '/inventory-record',
				component: () => import(/* webpackChunkName: "inventory-record" */ '@/modules/inventory-record'),
				meta: { title: 'Інвентаризація' }
			},
			{
				path: '/operation-recipient-claims',
				component: () =>
					import(/* webpackChunkName: "operation-recipient-claims" */ '@/modules/operation-recipient-claims'),
				meta: { title: 'Заявки на зміну ПІБ' }
			},
			{
				path: '/workplaces',
				component: () => import(/* webpackChunkName: "workplaces" */ '@/modules/workplaces'),
				meta: { title: 'Робочі мiсця касирiв' }
			},
			{
				path: '/profile',
				component: () => import(/*webpackChunkName: "profile"*/ '@/modules/profile'),
				meta: { title: 'Особистий кабінет' },
				children: [
					{
						path: '/profile',
						component: () => import(/*webpackChunkName: "dashboard"*/ '@/modules/profile/components/dashboard'),
						meta: { title: 'Особистий кабінет' }
					},
					{
						path: '/profile/documents',
						component: () => import(/*webpackChunkName: "documents"*/ '@/modules/profile/components/documents'),
						meta: { title: 'Мої заяви' }
					},
					{
						path: '/profile/vacation-application',
						component: () =>
							import(/*webpackChunkName: "vacation-application"*/ '@/modules/profile/components/vacation-application'),
						meta: { title: 'Заява на відпустку' }
					}
				]
			},
			{
				path: '*',
				component: () => import(/* webpackChunkName: "errors" */ '@/components/errors'),
				props: (route) => ({
					code: errorRoutes.includes(route.path) ? route.path.replace('/', '') : '404',
					uuid: route.query && route.query.uuid
				}),
				meta: { title: 'Сторiнку не знайдено' }
			}
		]
	}
];

const router = new Router({
	parseQuery(query) {
		return qs.parse(query, { arrayLimit: Infinity, parameterLimit: Infinity });
	},
	stringifyQuery(query) {
		let result = qs.stringify(query);
		return result ? '?' + result : '';
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeResolve(function (to, from, next) {
	document.title = `${baseTitle} - ${to.meta.title}`;
	store.commit(rootTypes.PAGE_CHANGE, null, { root: true });
	next();
});

export default router;
export { NavigationFailureType, isNavigationFailure };
