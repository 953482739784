const types = {
	body: 'body',
	payerCommission: 'payer_commission',
	recipientCommission: 'recipient_commission'
};

const locale = {
	[types.body]: 'тiло',
	[types.payerCommission]: 'комісія з платника',
	[types.recipientCommission]: 'комісія з отримувача'
};

module.exports = {
	enum: types,
	locale: locale
};
