const types = {
	recipient: 'recipient',
	payer: 'payer',
	recipientAgent: 'recipient_agent'
};

const locale = {
		[types.recipient]: 'отримувач',
		[types.payer]: 'платник',
		[types.recipientAgent]: 'довiрена особа отримувача'
};

module.exports = {
	enum: types,
	locale: locale
};
