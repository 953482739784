const types = {
	cashbook: 'cashbook',
	order: 'order'
};

const locale = {
	[types.cashbook]: 'КК',
	[types.order]: 'ордер'
};

module.exports = {
	enum: types,
	locale: locale
};
