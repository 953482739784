const types = {
	order: 'order',
	instruction: 'instruction'
};

const locale = {
	[types.order]: 'наказ',
	[types.instruction]: 'розпорядження'
};

module.exports = {
	enum: types,
	locale: locale
};
