import { core, http } from 'novapay-ui';
import Promise from 'bluebird';
import { DateTime } from 'luxon';
import print from '@services/print';
import qs from 'qs';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import router from '@/router';

import { enum as cashbookOrderSubTypes } from '@repo/enums/cashbook-order-sub-types';
import { enum as printedDocumentTypes } from '@repo/enums/cashbook-printed-document-types';
import { enum as verificationStatuses } from '@repo/enums/cashbook-printed-document-verification-statuses';

const { actions: commonStoreActions } = commonStore('printed-documents');
let types = core.getTypes('printed_documents');
let rootTypes = core.getTypes('root');

const getPrintedDocuments = async (context) => {
	let { query, cashbook } = context.state.props;
	let id = router.currentRoute.params?.cashbook_id;
	if (!id || isNaN(parseInt(id))) {
		return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
	}
	if (!cashbook) {
		let cashbookRes = await http(`/v3/cashbooks`, { query: { filters: { id: [id] } } });
		if (!handleError()(cashbookRes, context)) {
			return;
		}
		if (!cashbookRes.data?.length) {
			return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
		}
		cashbook = cashbookRes.data[0];
	}
	let [documentsRes, totalsRes] = await Promise.all([
		http(`/v3/salepoint/${id}/printed-documents`, { query }),
		http(`/v3/salepoint/totals/${DateTime.fromISO(cashbook.created_at).toISODate()}`)
	]);

	if (!handleError()(documentsRes, context) || !handleError()(totalsRes, context)) {
		return;
	}
	context.commit(types.DOCUMENTS_SUCCESS, {
		documents: documentsRes.data,
		cashbook,
		totals: totalsRes.data
	});
	return commonStoreActions.hideRootSplashScreen(context);
};

const verifyDocument = async (context, id) => {
	context.commit(types.VERIFY);
	let data = { id, cashbook_id: router.currentRoute.params?.cashbook_id };
	let res = await http('/v3/salepoint/printed-documents/verify', { data, method: 'POST' });
	const handler = (context, res) => {
		if (!res || [403, 500].includes(res.status)) {
			return context.commit(rootTypes.namespaced.ERROR, { code: 500 }, { root: true });
		}
		if (res.status === 400) {
			context.commit(
				rootTypes.namespaced.ADD_ALERT,
				{
					title: res.data.error ? res.data.error : 'Документ не знайдено',
					severity: 'error'
				},
				{ root: true }
			);
			context.commit(types.VERIFY_ERROR);
			return false;
		}
		return true;
	};
	if (!handleError(handler)(res, context)) {
		return;
	}
	if (res.data.salepoint_verified !== verificationStatuses.confirmed && !res.data.error) {
		context.commit(
			rootTypes.namespaced.ADD_ALERT,
			{ title: 'Даний документ не є актуальним', severity: 'info' },
			{ root: true }
		);
	} else {
		context.commit(rootTypes.namespaced.ADD_SNACKBAR, { variant: 'success', title: 'Успішно' }, { root: true });
	}
	return context.commit(types.VERIFY_SUCCESS);
};

const finalizeCashbook = async (context) => {
	context.commit(types.FINALIZE);
	let cashbook_id = router.currentRoute.params?.cashbook_id;
	if (!cashbook_id) {
		return context.commit(types.FINALIZE_ERROR);
	}
	let res = await http('/v3/salepoint/cashbook/finalize', { data: { cashbook_id }, method: 'POST' });
	if (!handleError()(res, context, 200, types.FINALIZE_ERROR, types.FINALIZE_ERROR)) {
		return;
	}
	return context.commit(types.FINALIZE_SUCCESS, (res.data || [])[0]);
};

const printCashbook = async (context, { id, printed_document_type }) => {
	let res = await http(`/v3/cashbooks/print/${id}/${printed_document_type}`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printOrder = async (context, order) => {
	let method = null;
	let { id, sub_type, printed_document_type } = order;
	if (sub_type === cashbookOrderSubTypes.cashToBank && printed_document_type !== printedDocumentTypes.main) {
		method = () => http(`/v3/orders/print-cover-sheet/${id}?${qs.stringify({ last_page: true })}`);
	} else {
		method = () => http(`/v3/orders/print/${id}`);
	}
	let res = await method();
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

export default {
	...commonStoreActions,
	printOrder,
	printCashbook,
	getPrintedDocuments,
	verifyDocument,
	finalizeCashbook
};
