import { core, http } from 'novapay-ui';
import Promise from 'bluebird';
import cloneDeep from 'lodash/cloneDeep';
import print from '@services/print';
import { DateTime } from 'luxon';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';

const { actions: commonStoreActions } = commonStore('archive-packages');
let types = core.getTypes('archive_packages');
let rootTypes = core.getTypes('root');

const getPackagesAndScheduleData = async (context) => {
	let { query } = cloneDeep(context.state.props);
	let [month, year] = DateTime.local().toFormat('M-yyyy').split('-');
	if (!query.filters?.month?.length || !query.filters?.year?.length) {
		Object.assign(query.filters, {
			month: query.filters?.month || [month],
			year: query.filters?.year || [year]
		});
	}
	let [packagesRes, scheduleRes] = await Promise.all([
		http('/v3/archive/packages', { query }),
		http('/v3/archive/schedule')
	]);
	if (!handleError()(packagesRes, context, 200)) {
		return;
	}
	context.commit(types.PACKAGES_SUCCESS, {
		packages: packagesRes.data,
		schedule: scheduleRes.data,
		total: (packagesRes.data || [])[0]?.total_count || 0
	});
	return commonStoreActions.hideRootSplashScreen(context);
};

const togglePackageModal = (context, data) => {
	if (context.state.state_key?.render?.package_modal === 'modal') {
		return context.commit(types.CLOSE_PACKAGE_MODAL);
	}
	return context.commit(types.OPEN_PACKAGE_MODAL, data);
};

const createPackage = async (context, data) => {
	let res = await http(`/v3/archive/package`, { data, method: 'PUT' });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data?.id) {
		printPackage(context, { id: res.data?.id });
	}
	return context.commit(types.CREATE_PACKAGE_SUCCESS);
};

const removePackage = async (context, { id }) => {
	let res = await http(`/v3/archive/package/${id}`, { method: 'DELETE' });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.REMOVE_PACKAGE_SUCCESS);
};

const editPackage = async (context, data) => {
	let res = await http(`/v3/archive/package/${data.id}/edit`, { method: 'PUT', data });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.EDIT_PACKAGE_SUCCESS);
};

const printPackage = async (context, { id }) => {
	let res = await http(`/v3/archive/package/${id}/print`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const showOnlySameMonthDocumentsError = (context) => {
	return context.commit(
		rootTypes.namespaced.ERROR_ALERT,
		{
			title: 'Помилка',
			text: 'Дозволено додавати зшиви тількі у розрізі одного місяця'
		},
		{ root: true }
	);
};

export default {
	...commonStoreActions,
	getPackagesAndScheduleData,
	togglePackageModal,
	createPackage,
	removePackage,
	editPackage,
	showOnlySameMonthDocumentsError,
	printPackage
};
