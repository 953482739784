import { core } from 'novapay-ui';
import { v4 as uuid } from 'uuid';

const createActions = (types, rootTypes) => ({
	// addNoCashOperatorSnackbar: (context, snackbar) => {
	// 	context.commit(types.ADD_NO_CASH_OPERATOR_SNACKBAR, snackbar);
	// },
	// removeNoCashOperatorSnackbar: (context, id) => {
	// 	context.commit(types.REMOVE_NO_CASH_OPERATOR_SNACKBAR, id);
	// }
});

const createMutations = (types) => ({
	[types.ADD_NO_CASH_OPERATOR_SNACKBAR]: (state, snackbar) => {
		state.props = {
			...state.props,
			noCashOperatorSnackbar: (state.props.noCashOperatorSnackbar || []).concat([{ id: uuid(), ...snackbar }])
		};
	},
	[types.REMOVE_NO_CASH_OPERATOR_SNACKBAR]: (state, id) => {
		state.props = {
			...state.props,
			noCashOperatorSnackbar: state.props.noCashOperatorSnackbar?.filter((s) => s.id !== id)
		};
	},
	[types.SET_NEED_CASH_OPERATOR]: (state, needCashOperator) => {
		state.props = { ...state.props, needCashOperator };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
