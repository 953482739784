import 'normalize.css';
import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import VueHotkey from 'v-hotkey';
import { components, formatters } from 'novapay-ui';
import log from '@services/log';

import App from './App.vue';
import router from './router';
import store from './store';
import Components from './components';

Vue.config.productionTip = false;

Vue.use(components);
Vue.use(Components);
Vue.use(VueHotkey);
Object.keys(formatters).forEach((name) => Vue.filter(name, formatters[name]));
Vue.directive('click-outside', ClickOutside);

window.onerror = function (message, source, lineno, colno, error) {
	log('frontend-error', { error: message, stack: error?.stack, source, lineno, colno, url: window.location.href });
};
window.onunhandledrejection = function (ev) {
	log('frontend-error', { error: ev?.reason?.message, stack: ev?.reason?.stack, url: window.location.href });
};

Vue.config.errorHandler = (err, vm, info) => {
	//eslint-disable-next-line
	console.error(err, info);
	log('frontend-error', {
		error: err.message,
		stack: err.stack,
		data: JSON.stringify({ component: vm?.$vnode?.tag, info }),
		url: window.location.href
	});
};

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
