<template>
	<NP-SplashScreen>
		<div class="splash-container">
			<div class="nav-block">
				<div class="lines" />
				<div class="lines" />
				<div class="lines" />
				<div class="lines" />
				<div class="lines" />
				<div class="lines" />
			</div>
			<div class="splash-inner-container">
				<div class="upper-blocks">
					<div class="block-1" />
					<div class="block-1" />
				</div>
				<div class="block-2">
					<div class="lines" />
					<div class="btns" />
				</div>
				<div v-if="isChiefCashier" class="middle-block">
					<div class="line" />
					<div class="btns" />
					<div class="row" />
					<div class="row" />
					<div class="row" />
					<div class="row" />
				</div>
				<div class="middle-block">
					<div class="line" />
					<div class="btns" />
					<div class="row" />
					<div class="row" />
				</div>
				<div class="block-3">
					<div class="lines" />
				</div>
			</div>
		</div>
	</NP-SplashScreen>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ControlCenterSplashScreen',
	computed: {
		...mapGetters({ isChiefCashier: 'root/isChiefCashier' })
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

::v-deep .controls-placeholder {
	display: none;
}
.splash-container {
	padding: 24px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	.nav-block {
		width: 300px;
		background: $gray-10;
		border-radius: $border-radius;
		margin-right: 24px;
		height: 400px;
	}

	.lines,
	.line {
		position: relative;
		width: 200px;
		height: 48px;
		margin-right: 52%;
		&:not(:first-child) {
			margin-top: 10px;
		}
		&:before,
		&:after {
			display: block;
			position: absolute;
			content: '';
			background: $gray-20;
			border-radius: 8px;
			height: 12px;
			left: 24px;
		}
		&:before {
			top: 24px;
			width: 240px;
		}
		&:after {
			top: 52px;
			width: 200px;
		}
	}
	.line {
		&:after {
			display: none;
		}
	}
	.splash-inner-container {
		width: 1110px;
		.upper-blocks {
			display: flex;
			.block-1 {
				position: relative;
				background: $gray-10;
				height: 88px;
				width: 100%;
				border-radius: $border-radius;
				&:first-child {
					margin-right: 24px;
				}
				&:before,
				&:after {
					display: block;
					position: absolute;
					content: '';
					height: 12px;
					background: $gray-20;
					border-radius: 8px;
				}
				&:before {
					width: 40%;
					top: 24px;
					left: 24px;
				}
				&:after {
					width: 30%;
					bottom: 24px;
					left: 24px;
				}
			}
		}
		.middle-block {
			position: relative;
			display: flex;
			flex-direction: column;
			height: fit-content;
			width: 100%;
			background: $gray-10;
			margin-top: 24px;
			padding: 24px;
			border-radius: $border-radius;
			.btns {
				&:after {
					display: none;
				}
				&:before {
					top: 24px;
					width: 500px;
					height: 35px;
				}
			}
			.line {
				position: static;
				&:before {
					margin-top: 10px;
				}
			}
		}
		.block-2 {
			position: relative;
			display: flex;
			width: 100%;
			height: 160px;
			background: $gray-10;
			border-radius: $border-radius;
			margin-top: 24px;
			&:before,
			&:after {
				display: block;
				position: absolute;
				content: '';
				width: 500px;
				height: 40px;
				background: $gray-20;
				border-radius: $border-radius;
				right: 24px;
				top: 24px;
			}
			&:after {
				width: 160px;
				left: 370px;
			}
		}
		.block-3 {
			width: 100%;
			padding: 20px;
			height: 90px;
			background: $gray-10;
			border-radius: $border-radius;
			margin-top: 24px;
			.lines {
				&:before {
					bottom: 0;
					width: 270px;
				}
				&:after {
					top: 0;
				}
			}
		}

		.btns {
			&:before,
			&:after {
				position: absolute;
				content: '';
				width: 240px;
				height: 40px;
				background: $gray-20;
				border-radius: $border-radius;
				bottom: 44px;
				right: 24px;
			}
			&:after {
				right: 284px;
			}
		}

		.row {
			background: $gray-20;
			border-radius: $border-radius;
			width: 100%;
			height: 32px;
			margin-top: 10px;
		}
		@media (max-width: 1500px) {
			width: 850px;
			& .upper-blocks {
				flex-direction: column;
				.block-1:first-child {
					display: none;
				}
			}
			.block-2:after {
				display: none;
			}
		}
	}
	@media (max-width: $max-navbar-visibility-width) {
		.nav-block {
			display: none;
		}
	}
}
</style>
