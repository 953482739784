import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('refreshments');
const { mutations } = commonStore('refreshments');

export default {
	...mutations,
	[types.PAGE_DATA_SUCCESS]: (state, { refreshments, total, query }) => {
		state.props = { refreshments, total, query };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { ...state.props, query };
	},
	[types.SET_REASONS]: (state, reasons) => {
		state.props = {
			...state.props,
			reasons
		};
	},
	[types.OPEN_REJECT_MODAL]: (state, id) => {
		state.props = {
			...state.props,
			selectedRefreshmentId: id
		};
	},
	[types.CLOSE_REJECT_MODAL]: (state, id) => {
		state.props = {
			...state.props,
			selectedRefreshmentId: null
		};
	},
	[types.OPEN_ACCEPT_MODAL]: (state, id) => {
		state.props = {
			...state.props,
			selectedRefreshmentId: id
		};
	},
	[types.OPEN_CONFIRM_ACCEPT_MODAL]: (state, refreshment) => {
		state.props = {
			...state.props,
			refreshment
		};
	},
	[types.CLOSE_ACCEPT_MODAL]: (state) => {
		state.props = {
			...state.props,
			selectedRefreshmentId: null
		};
	},
	[types.OPEN_REFRESHMENT_FORM]: (state, edit_entity) => {
		state.props = { ...state.props, edit_entity };
	},
	[types.CLOSE_REFRESHMENT_FORM]: (state) => {
		state.props = {
			...state.props,
			edit_entity: null,
			salepoint: null,
			validation_errors: null
		};
	},
	[types.GET_SALEPOINT_SUCCESS]: (state, salepoint) => {
		state.props = { ...state.props, salepoint };
	},
	[types.VALIDATION_ERRORS]: (state, errors) => {
		state.props = { ...state.props, validation_errors: errors };
	},
	[types.OPEN_ALERT_MODAL]: (state, alertMessage) => {
		state.props = {
			...state.props,
			alertMessage
		};
	},
	[types.CLOSE_ALERT_MODAL]: (state) => {
		state.props = {
			...state.props,
			alertMessage: null
		};
	}
};
