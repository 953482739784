const denominations = {
	'0.1': '0.1',
	'0.5': '0.5',
	'1': '1',
	'2': '2',
	'5': '5',
	'10': '10'
};

const locale = {
	[denominations['0.1']]: '10 коп.',
	[denominations['0.5']]: '50 коп.',
	[denominations['1']]: '1 грн.',
	[denominations['2']]: '2 грн.',
	[denominations['5']]: '5 грн.',
	[denominations['10']]: '10 грн.'
};

module.exports = {
	enum: denominations,
	locale: locale
};
