import { core } from 'novapay-ui';

const types = core.createTypes('control_center');

export default {
	[types.OPEN_INVENTORY_ACT_MODAL]: (state, modalData) => {
		state.props = { ...state.props, ...modalData };
	},
	[types.CLOSE_INVENTORY_ACT_MODAL]: (state) => {
		state.props = {
			...state.props,
			validation_errors: null,
			cashdeskActionModalData: null,
			cashbookClosalInitialized: false,
			actUserFromName: null,
			actUserFromPosition: null,
			actUserToName: null,
			actUserToPosition: null,
			actActionOnSubmit: null,
			actRecordedAmount: null,
			actType: null,
			actCash: null,
			actScenario: null
		};
	},
	[types.INIT_CASHBOOK_CLOSAL]: (state) => {
		state.props = { ...state.props, cashbookClosalInitialized: true };
	},
	[types.CANCEL_CASHBOOK_CLOSAL]: (state) => {
		state.props = { ...state.props, cashbookClosalInitialized: false };
	},
	[types.SET_ISSUE_AMOUNT]: (state, issueAmount) => {
		state.props = {
			...state.props,
			issueAmount
		};
	}
};
