import { core, http } from 'novapay-ui';
import Promise from 'bluebird';

import handleError from '@services/handle-api-error';

//handle muted errors from np-ui http as error
function posResponseHandler(res) {
	if (!res.status) {
		throw new Error('bad response');
	}
	return res;
}

const createActions = (types) => ({
	pollPOSDaemonStart: async (context) => {
		let posDaemonRes = await Promise.any([
			http({ url: `http://localhost:8002/GetPOSInfo`, method: 'GET', timeout: 10000 }).then(posResponseHandler),
			http({ url: `https://localhost:8004/GetPOSInfo`, method: 'GET', timeout: 10000 }).then(posResponseHandler)
		]).catch(() => null);
		// posDaemonRes = {
		// 	status: 200,
		// 	data: {
		// 		devices: ['210837303221233120170109'],
		// 		version: ['1.7.12']
		// 	}
		// };

		if (posDaemonRes?.status === 200 && posDaemonRes?.data) {
			const [posDaemonVersion] = posDaemonRes.data.version;
			context.commit(types.UPDATE_POS_DAEMON_VERSION, posDaemonVersion);
			let connectedPoses = posDaemonRes?.data?.devices ?? [];
			let savedPoses = context.state.props.posTerminals || [];
			let savedPosesCodes = savedPoses.map((p) => p.code);

			let newlyConnectedPoses = connectedPoses.filter((code) => !savedPosesCodes.includes(code));
			let savedConnectedPoses = savedPoses.filter((p) => connectedPoses.includes(p.code));

			let updateData = savedConnectedPoses;
			if (newlyConnectedPoses.length) {
				let infoRes = await http({
					url: `/v3/pos-terminals/info`,
					method: 'GET',
					query: {
						codes: newlyConnectedPoses
					}
				});
				if (!handleError()(infoRes, context)) {
					return;
				}
				updateData = [...updateData, ...infoRes.data];
			}
			context.commit(types.UPDATE_POS_TERMINALS, updateData);
		} else if (context.state.props?.posTerminals?.length) {
			context.commit(types.UPDATE_POS_TERMINALS, []);
		}

		window.posPollTimeoutId = setTimeout(() => context.dispatch('pollPOSDaemonStart'), 10000);
	},
	pollPOSDaemonStop: (context) => {
		clearTimeout(window?.posPollTimeoutId);
	}
});

const createMutations = (types) => ({
	[types.UPDATE_POS_TERMINALS]: (state, posTerminals) => {
		state.props = { ...state.props, posTerminals };
	},
	[types.UPDATE_POS_DAEMON_VERSION]: (state, posDaemonVersion) => {
		state.props = { ...state.props, posDaemonVersion };
	}
});

const createComponentStore = () => {
	const mutations = createMutations(core.createTypes('root'));
	const actions = createActions(core.getTypes('root'));
	return { mutations, actions };
};

export default createComponentStore;
