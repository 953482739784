import { core, http } from 'novapay-ui';

import cashbookActions from '../components/cashbook/store/actions';
import cashdeskActions from '../components/cashdesk/store/actions';
import openedCashdesksActions from '../components/opened-cashdesks/store/actions';
import posTerminalActions from '../components/pos-terminals/store/actions';
import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';

import { enum as centrifugoEventTypes } from '@repo/enums/centrifugo-event-types';

const { actions: commonStoreActions } = commonStore('control-center');

const types = core.getTypes('control_center');

const resetState = (context) => context.commit(types.RESET_STATE);

const getControlCenterData = async (context) => {
	let collapsed = localStorage.getItem('collapsedComponents');
	context.commit(types.CONTROL_CENTER_DATA_SUCCESS, { collapsed: JSON.parse(collapsed) });
	commonStoreActions.hideRootSplashScreen(context);
};

const toggleCollapsibleComponent = (context, componentName) => {
	let { collapsedComponents } = context.state.props;
	let collapsedData;

	if (collapsedComponents && collapsedComponents.includes(componentName)) {
		collapsedData = collapsedComponents.filter((name) => name !== componentName);
	} else {
		collapsedData = [].concat(collapsedComponents, componentName);
	}

	context.commit(types.SET_COLLAPSED_COMPONENTS, collapsedData);
	localStorage.setItem('collapsedComponents', JSON.stringify(collapsedData));
};

const toggleTransferWorkplaceModal = (context) => {
	if (context.state.state_key.render === 'transfer_workplace_modal') {
		return context.commit(types.CLOSE_TRANSFER_WORKPLACE_MODAL);
	}
	context.commit(types.OPEN_TRANSFER_WORKPLACE_MODAL);
};

const findWorkplace = async (context, { code }) => {
	let res = await http(`/v3/workplaces/${code}`);
	if (!handleError()(res, context, 200)) {
		return;
	}
	context.commit(types.TRANSFER_WORKPLACE_DATA, res.data);
};

const eventListener = (context, e) => {
	switch (e.type) {
		case centrifugoEventTypes.posMessage: {
			let { posTerminals } = context.rootState.root.props;
			const canReceiveEvent = (posTerminals || []).map((p) => p.code).includes(e.payload?.pos_code);
			if (canReceiveEvent) {
				let { type } = e.payload;
				if (type === 'last-receipt') {
					context.commit(types.UPDATE_LAST_RECEIPT, e.payload);
				} else if (type === 'transactions') {
					context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
					context.commit(types.UPDATE_POS_TRANSACTIONS, e.payload);
				}
			}
			return;
		}
		default:
			return;
	}
};

export default {
	resetState,
	getControlCenterData,
	toggleCollapsibleComponent,
	toggleTransferWorkplaceModal,
	findWorkplace,
	eventListener,
	...cashbookActions,
	...cashdeskActions,
	...openedCashdesksActions,
	...posTerminalActions
};
