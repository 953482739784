import { core, http, lookup } from 'novapay-ui';
import handleError from '@services/handle-api-error';

const createActions = (types) => {
	const actions = {
		lookupWesternUnionCountries: async (context) => {
			let res = await http('/v3/lists/western-union/countries');
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.WU_COUNTRIES_LOOKUP, res.data);
		},
		lookupWesternUnionCountryCurrency: async (context, { country_code }) => {
			context.commit(types.WU_SET_DELIVERY_SERVICE_DATA, null);
			let res = await http('/v3/lists/western-union/country-currencies', { query: { country_code } });
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.WU_COUNTRY_CURRENCIES_LOOKUP, res.data);
		},
		lookupMexicoStateCitiesOptions: async (context, { state_code }) => {
			let res = await http('/v3/lists/western-union/mexico-state-cities', { query: { state_code } });
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.WU_MEXICO_STATE_CITIES_LOOKUP, res.data);
		},
		westernUnionFeeInquiry: async (context, payload) => {
			context.commit(types.SET_DETECT_COMMISSION_LOADER, true);
			let res = await lookup('/v3/operations/western-union/fee-inquiry', { query: payload });
			if (!handleError()(res, context)) {
				context.commit(types.SET_DETECT_COMMISSION_LOADER, res.status === 499);
				return;
			}
			context.commit(types.SET_DETECT_COMMISSION_LOADER, false);
			return context.commit(types.WU_FEE_INQUIRY, res.data);
		},
		checkDeliveryServiceAvaliable: async (context, { query, cb }) => {
			context.commit(types.WU_CHECK_DELIVERY_SERVICE_LOADER, true);
			let res = await lookup('/v3/operations/western-union/check-delivery-service', { query });
			if (!handleError()(res, context)) {
				context.commit(types.WU_CHECK_DELIVERY_SERVICE_LOADER, res.status === 499);
				return;
			}
			context.commit(types.WU_SET_DELIVERY_SERVICE_DATA, res.data);
			context.commit(types.WU_CHECK_DELIVERY_SERVICE_LOADER, false);
			return cb();
		},
		resetCommision: (context) => context.commit(types.RESET_COMMISION)
	};
	return actions;
};

const createMutations = (types) => ({
	[types.WU_COUNTRIES_LOOKUP]: (state, westernUnionCountriesLookupResult) => {
		state.props = { ...state.props, westernUnionCountriesLookupResult };
	},
	[types.WU_COUNTRY_CURRENCIES_LOOKUP]: (state, westernUnionCountryCurrenciesLookupResult) => {
		state.props = { ...state.props, westernUnionCountryCurrenciesLookupResult };
	},
	[types.WU_MEXICO_STATE_CITIES_LOOKUP]: (state, westernUnionMexicoStateCitiesLookupResult) => {
		state.props = { ...state.props, westernUnionMexicoStateCitiesLookupResult };
	},
	[types.WU_FEE_INQUIRY]: (state, fetchedCommission) => {
		state.props = { ...state.props, fetchedCommission };
	},
	[types.RESET_COMMISION]: (state) => {
		state.props = { ...state.props, fetchedCommission: null };
	},
	[types.WU_SET_DELIVERY_SERVICE_DATA]: (state, wuDeliveryServiceData) => {
		state.props = { ...state.props, wuDeliveryServiceData };
	},
	[types.WU_REPRINT_TEMPLATE_ON_COMMISSION_DISCREPANCY]: (state, wuShouldReprintTemplate) => {
		state.props = { ...state.props, wuShouldReprintTemplate };
	},
	[types.WU_CHECK_DELIVERY_SERVICE_LOADER]: (state, checkDeliveryServiceLoading) => {
		state.props = { ...state.props, checkDeliveryServiceLoading };
	},
	[types.OPEN_WU_POS_ERROR_MODAL]: (state, { error: wuPOSError }) => {
		state.props = { ...state.props, wuPOSError };
	},
	[types.CLOSE_WU_POS_ERROR_MODAL]: (state) => {
		state.props = { ...state.props, wuPOSError: null };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
