const denominations = {
	'1': '1',
	'2': '2',
	'5': '5',
	'10': '10',
	'20': '20',
	'50': '50',
	'100': '100',
	'200': '200',
	'500': '500',
	'1000': '1000'
};

const locale = {
	[denominations['1']]: '1 грн.',
	[denominations['2']]: '2 грн.',
	[denominations['5']]: '5 грн.',
	[denominations['10']]: '10 грн.',
	[denominations['20']]: '20 грн.',
	[denominations['50']]: '50 грн.',
	[denominations['100']]: '100 грн.',
	[denominations['200']]: '200 грн.',
	[denominations['500']]: '500 грн.',
	[denominations['1000']]: '1000 грн.'
};

module.exports = {
	enum: denominations,
	locale: locale
};
