<template>
	<NP-Modal :label="header" :toggle="toggle">
		<div class="application-details">
			<table>
				<section class="section">
					<h5>Загальна інформація</h5>
					<tr>
						<td>
							<span class="header">Дата створення</span>
							<br />
							<span class="body">
								{{ application.created_at.date }} <span class="gray">{{ application.created_at.time }}</span>
							</span>
						</td>
						<td>
							<span class="header">Створив</span><br />
							<span class="body">{{ application.created_by }}</span>
						</td>
						<td>
							<span class="header">Тип</span><br />
							<span class="body">{{ application.type }}</span>
						</td>
					</tr>
					<tr>
						<td>
							<span class="header">EIC-код</span>
							<br />
							<span class="body">{{ application.eic_code }}</span>
						</td>
						<td>
							<span class="header">Особовий рахунок</span><br />
							<span class="body semibold">{{ application.account }}</span>
						</td>
						<td>
							<span class="header">Статус</span><br />
							<span class="body">{{ application.status }}</span>
						</td>
					</tr>
				</section>
				<section class="section">
					<h5>Персональна інформація</h5>
					<tr>
						<td>
							<span class="header">ПІБ</span>
							<br />
							<span class="body">{{ application.name }}</span>
						</td>
						<template v-if="!isRequestDocumentApp">
							<td>
								<span class="header">Дата народження</span>
								<br />
								<span class="body">{{ application.birth_date }}</span>
							</td>
							<td>
								<span class="header">Документ</span>
								<br />
								<span class="body">{{ application.document }}</span>
							</td>
						</template>
					</tr>
					<tr v-if="!isRequestDocumentApp">
						<td>
							<span class="header">ІПН</span>
							<br />
							<span class="body">{{ application.taxpayer_id }}</span>
						</td>
						<td>
							<span class="header">Телефон</span>
							<br />
							<span class="body">{{ application.phone }}</span>
						</td>
						<td>
							<span class="header">Ел. пошта</span>
							<br />
							<span class="body">{{ application.email }}</span>
						</td>
					</tr>
				</section>
				<template v-if="!isRequestDocumentApp">
					<section class="section">
						<h5>Адреса об'єкта газопостачання</h5>
						<tr>
							<td>
								<span class="header">Область</span>
								<br />
								<span class="body">{{ application.address_region }}</span>
							</td>
							<td>
								<span class="header">Район</span>
								<br />
								<span class="body">{{ application.address_district }}</span>
							</td>
							<td>
								<span class="header">Місто</span>
								<br />
								<span class="body">{{ application.address_city }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span class="header">Поштовий індекс</span>
								<br />
								<span class="body">{{ application.address_index }}</span>
							</td>
							<td>
								<span class="header">Вулиця</span>
								<br />
								<span class="body">{{ application.address_street_name_manual }}</span>
							</td>
							<td>
								<span class="header">Тип вулиці</span>
								<br />
								<span class="body">{{ application.address_street_type_id }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span class="header">Номер будинку</span>
								<br />
								<span class="body">{{ application.address_build_number }}</span>
							</td>
							<td>
								<span class="header">Номер квартири</span>
								<br />
								<span class="body">{{ application.address_apartment_number }}</span>
							</td>
						</tr>
					</section>
					<section class="section">
						<h5>Дані об'єкта газопостачання</h5>
						<tr>
							<td>
								<span class="header">Вид об'єкта</span>
								<br />
								<span class="body">{{ application.building_type }}</span>
							</td>
							<td>
								<span class="header">Тип споживання</span>
								<br />
								<span class="body">{{ application.using_type }}</span>
							</td>
							<td>
								<span class="header">Оператор ГРМ</span>
								<br />
								<span class="body">{{ application.operator_grm }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span class="header">Тариф</span>
								<br />
								<span class="body">{{ application.tariff }}</span>
							</td>
							<td>
								<span class="header">Наявність пільг</span>
								<br />
								<span class="body">{{ application.has_privilege }}</span>
							</td>
							<td>
								<span class="header">Наявність лічильника</span>
								<br />
								<span class="body">{{ application.has_counter }}</span>
							</td>
						</tr>
						<tr>
							<td>
								<span class="header">Кількість зареєстрованих осіб</span>
								<br />
								<span class="body">{{ application.registered_persons }}</span>
							</td>
						</tr>
					</section>
					<section class="section">
						<h5>Додаткова інформація</h5>
						<tr>
							<td>
								<span class="header">Звідки дізналися про компанію/пропозицію</span>
								<br />
								<span class="body">{{ application.source_info_id }}</span>
							</td>
							<td>
								<span class="header">Інше</span>
								<br />
								<span class="body">{{ application.source_info_other }}</span>
							</td>
						</tr>
					</section>
					<section class="section" v-if="uploadApplicationImages">
						<span>
							Роздрукуйте заяву, заповніть відповідні поля, надайте клієнту на підпис та загрузіть ії скан-копію.
						</span>
						<NP-Input
							type="file"
							key="application-template"
							class="file-input"
							accept="image/*,.pdf,.zip"
							label="Заява на зміну постачальника з підписом"
							:selected-file-title="`Заява на зміну постачальника з підписом`"
							:selected-file-name="`${files.map((f) => f.name).join(', ')}`"
							@input="handleOnFileInput($event.target.files[0])"
						>
							<template #icons>
								<div class="icon-view" @click.prevent.stop="toggleLightbox" />
								<div class="icon-clear" @click.prevent.stop="files = []" />
							</template>
						</NP-Input>
					</section>
				</template>
			</table>
			<div></div>
			<div class="buttons">
				<NP-Button
					v-if="uploadApplicationImages"
					priority="high"
					class="upload-btn"
					@click="
						() =>
							uploadApplicationImages({
								external_id: data.external_id,
								files,
								loadingAction: true
							})
					"
					:disabled="!files.length"
					:loading="loadingActions.includes('uploadApplicationImages')"
				>
					Завантажити заяву
				</NP-Button>
				<NP-Button
					priority="medium"
					@click="() => printApplication({ ...infoForPrintPfd, loadingAction: true })"
					:loading="loadingActions.includes('printApplication')"
				>
					<div class="icon-print" slot="icon" />
					Друкувати
				</NP-Button>
			</div>
		</div>
		<NP-LightBox :lightbox-scan="lightboxScan" :toggle-lightbox="toggleLightbox" />
	</NP-Modal>
</template>

<script>
import { DateTime } from 'luxon';
import { locale as applicationTypesLocale } from '@repo/enums/naftogaz-application-types';
import { locale as applicationStatusesLocale } from '@repo/enums/naftogaz-application-statuses';
const { requestDocumentTypes } = require('../../../../enums/naftogaz-application-types');
import _ from 'lodash';

export default {
	name: 'NaftogazApplicationPreviewModal',
	props: {
		data: { type: Object, default: () => ({}) },
		loadingActions: { type: Array, default: () => [] },
		toggle: { type: Function, default: null },
		showErrorSnackbar: { type: Function, default: null },
		uploadApplicationImages: { type: Function, default: null },
		printApplication: { type: Function, default: null }
	},
	data() {
		return {
			files: [],
			lightboxScan: null
		};
	},
	computed: {
		application() {
			if (this.isRequestDocumentApp) {
				return { ...this.requestDocumentApp };
			}
			return { ...this.createNewConnectionApp };
		},
		header() {
			const { external_id } = this.data;
			return external_id ? `Заявка №${this.data.external_id}` : 'Заявка';
		},
		isRequestDocumentApp() {
			return this.data.type in requestDocumentTypes;
		},
		fullName() {
			if (this.isRequestDocumentApp) {
				return '—';
			}
			return `${this.data.last_name} ${this.data.first_name} ${this.data.patronymic}`;
		},
		createNewConnectionApp() {
			let { metadata = {}, account_number, created_by_login, type } = this.data;
			return {
				...this.data,
				...metadata,
				created_by: created_by_login,
				address_city: metadata.address_city_id.text,
				address_district: metadata.address_district_id.text,
				address_region: metadata.address_region_id.text,
				address_street_type_id: metadata.address_street_type_id.text,
				building_type: metadata.building_type.text,
				using_type: metadata.using_type.text,
				tariff: metadata.tariff_id.text,
				source_info_id: metadata.source_info_id?.text ?? '',
				created_at: this.created,
				account: account_number || 'Не присвоєно',
				type: applicationTypesLocale[type],
				name: this.fullName,
				document: `${metadata.document_series || ''} ${metadata.document_number}`,
				email: metadata.contact_email || '-',
				birth_date: DateTime.fromFormat(metadata.birth_date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy'),
				registered_persons: metadata.registered_persons || '-',
				has_counter: metadata.has_counter ? 'Так' : 'Ні',
				has_privilege: metadata.has_privilege ? 'Так' : 'Ні',
				status: applicationStatusesLocale[this.data.status],
				address_apartment_number: metadata.address_apartment_number || '-'
			};
		},
		requestDocumentApp() {
			const { created_by_login, type, status } = this.data;
			return {
				...this.data,
				...this.data.metadata,
				created_at: this.created,
				name: this.fullName,
				type: applicationTypesLocale[type],
				status: applicationStatusesLocale[status],
				created_by: created_by_login,
				account: this.data.account_number || 'Не присвоєно'
			};
		},
		created() {
			const created = DateTime.fromISO(this.data.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
			return {
				date: created.split(' ')[0],
				time: created.split(' ')[1]
			};
		},
		infoForPrintPfd() {
			return _.pick(this.data, ['id', 'external_id', 'type']);
		}
	},
	methods: {
		toggleLightbox() {
			this.lightboxScan = this.lightboxScan ? null : this.files[0];
		},
		handleOnFileInput(file, arrayname) {
			let maxSize = 10;
			let errorMessage;
			if (!file) {
				errorMessage = `Виникла помилка під час завантаження`;
			} else if (!file.size) {
				errorMessage = `Неможливо завантажити пустий файл.`;
			} else if (file.size / 1024 / 1024 > maxSize) {
				errorMessage = `Максимальний розмір файлу ${maxSize} МБ.`;
			}

			if (errorMessage) {
				this.showErrorSnackbar(errorMessage);
			} else {
				this.files = [file];
				if (!file.url && (file.type.includes('image') || file.type === 'application/pdf')) {
					let reader = new FileReader();
					reader.onload = (e) => (this.files[0].url = e.target.result);
					reader.readAsDataURL(file);
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/mixins';
@import '@assets/scss/variables';

$icon-print: '~@assets/images/icon-print.svg';
$icon-view: '~@assets/images/icon-eye.svg';
$icon-clear: '~@assets/images/icon-remove.svg';

::v-deep .modal-container {
	max-height: 90vh;
	overflow-y: scroll;
}

::v-deep .lightbox .modal-container {
	max-height: unset;
}

div.application-details {
	width: 900px;
	table {
		width: 100%;
		.section {
			background: $gray-0;
			border-radius: $border-radius;
			padding: 15px;
			margin-bottom: 8px;
			h5 {
				font-family: Proxima Nova Semibold;
				color: $gray-50;
			}
			tr {
				td {
					width: 300px;
					padding-top: 10px;
					.header {
						line-height: 14px;
						color: $gray-40;
						font-size: 12px;
						font-family: Proxima Nova Semibold;
					}
					.body {
						.gray {
							color: $gray-40;
						}
						&.semibold {
							font-family: Proxima Nova Semibold;
						}
					}
				}
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		margin-top: 16px;
		.upload-btn {
			margin-right: 10px;
		}
		::v-deep .button {
			.icon-print {
				@include make-icon($icon-print, $white, 20px, 20px);
			}
		}
	}
	::v-deep .input.file {
		width: 100%;
		label.file-input {
			margin-top: 14px;
			align-items: flex-start;
			.wrap {
				flex-direction: row;
				justify-content: flex-end;
				i {
					margin: 0 20px;
				}
				.title {
					display: none;
				}
				.text {
					font-size: 14px;
					color: $red;
					padding: 0;
				}
			}
			&.file-selected {
				height: 52px;
				width: 100%;
				background: $gray-10;
				.tooltip-container {
					width: 100%;
					.element-container {
						.file-name {
							margin-left: 16px;
							flex-direction: column;
							align-items: flex-start;
							.icon-file {
								display: none;
							}
							.title {
								font-size: 12px;
								color: $gray-40;
							}
							.name {
								max-width: 800px;
							}
							.icon-clear {
								position: absolute;
								top: 5px;
								right: 10px;
								@include make-icon($icon-clear, $gray-50, 20px, 20px);
								&:hover {
									@include make-icon($icon-clear, $red, 20px, 20px);
								}
							}
							.icon-view {
								position: absolute;
								top: 5px;
								right: 35px;
								@include make-icon($icon-view, $gray-50, 20px, 20px);
								&:hover {
									@include make-icon($icon-view, $red, 20px, 20px);
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
