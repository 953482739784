import { http } from 'novapay-ui';

let domain = 'localhost'; //'raspberry.local';

class RroPrinter {
	constructor() {
		this.connected = false;
		this.testNumber = null;

		this.printQueue = [];
		this.isPrinting = false;

		// only print in currently open tab
		this.windowActive = true;
		window.onfocus = () => (this.windowActive = true);
		window.onblur = () => (this.windowActive = false);
	}

	async checkConnection() {
		// ToDo: implement check connection logic. Maybe.
		this.connected = true;
	}

	async print(rootTypes, context, printLines, fromQueue = false) {
		if (!this.connected || (!this.windowActive && !fromQueue)) {
			return;
		}

		if (this.isPrinting) {
			this.printQueue.push(printLines);
			return;
		}
		this.isPrinting = true;
		let res = await http(`http://${domain}:8001/print`, {
			method: 'POST',
			data: JSON.stringify({ print_lines: printLines })
		});
		if (res.status !== 200) {
			context.commit(rootTypes.ADD_SNACKBAR, { variant: 'error', title: 'Помилка друку на термопринтерi' });
		}
		this.isPrinting = false;
		if (this.printQueue.length) {
			let data = this.printQueue.shift();
			this.print(rootTypes, context, data, true);
		}
	}
}

export default new RroPrinter();
