import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('archive_packages');
const { mutations } = commonStore('archive-packages');

export default {
	...mutations,
	[types.PACKAGES_SUCCESS]: (state, { packages, schedule, total }) => {
		state.props = {
			...state.props,
			packages,
			schedule,
			total
		};
	},
	[types.OPEN_PACKAGE_MODAL]: (state, data) => {
		state.props = {
			...state.props,
			package_data: data
		};
	},
	[types.VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = { ...state.props, validationErrors };
	},
	[types.CLOSE_PACKAGE_MODAL]: (state) => {
		state.props = { ...state.props, validationErrors: null };
	}
};
