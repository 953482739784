import { core } from 'novapay-ui';
import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('naftogaz_applications');

export default core.createStore(actions, {}, mutations, {
	key: 'naftogaz_applications',
	initial: 'idle',
	states: {
		idle: {
			entry: 'getNaftogazApplications',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.APPLICATIONS_SUCCESS]: 'render'
			}
		},
		render: {
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.OPEN_APPLICATION_PREVIEW_MODAL]: 'application_preview_modal'
			}
		},
		application_preview_modal: {
			on: {
				[types.CLOSE_APPLICATION_PREVIEW_MODAL]: 'render',
				[types.APPLY_QUERY]: 'idle'
			}
		}
	}
});
