const statuses = {
	created: 'created',
	verified: 'verified',
	active: 'active',
	canceled: 'canceled',
	expired: 'expired',
	confirmed: 'confirmed'
};

const locale = {
	[statuses.created]: 'створено',
	[statuses.verified]: 'підтверджено клієнтом',
	[statuses.active]: 'активний',
	[statuses.canceled]: 'скасований',
	[statuses.expired]: 'видалено',
	[statuses.confirmed]: 'закритий'
};

module.exports = {
	enum: statuses,
	locale: locale
};
