const types = {
	individual: 'individual',
	legal: 'legal'
};

const locale = {
		[types.individual]: 'фізична особа',
		[types.legal]: 'юридична особа'
};

module.exports = {
	enum: types,
	locale: locale
};
