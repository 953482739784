const types = {
	registration: 'registration',

	sideOne: 'side_one',
	sideTwo: 'side_two',

	pageOne: 'page_one',
	pageTwoThree: 'page_two_three',
	pageFourFive: 'page_four_five',
	pageSixSeven: 'page_six_seven',

	otherPage: 'other_page',
	taxpayerId: 'taxpayer_id',

	digital: 'digital'
};

const locale = {
	[types.registration]: 'місце проживання',
	[types.sideOne]: 'сторона один',
	[types.sideTwo]: 'сторона два',
	[types.pageOne]: 'сторінка один',
	[types.pageTwoThree]: 'сторінки два і три',
	[types.pageFourFive]: `сторінки чотири і п'ять`,
	[types.pageSixSeven]: 'сторінки шість і сім',
	[types.otherPage]: 'інша сторінка',
	[types.taxpayerId]: 'ідентифікаційний номер фізичної особи',
	[types.digital]: 'електронний документ'
};

module.exports = {
	enum: types,
	locale: locale
};
