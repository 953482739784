<template>
	<div class="error-container">
		<div class="error">
			<h1 v-if="code == '500'">Помилка системи</h1>
			<h1 v-if="code == '403'">У вас немає прав для перегляду даної сторінки</h1>
			<h1 v-if="code == '404'">Сторінку не знайдено (404)</h1>
			<div class="uuid" v-if="uuid">
				Код помилки:&nbsp; <span id="uuid"> {{ uuid }} </span>
				<NP-Button class="copy" type="button" priority="low" @click="copy">
					<span v-if="copied"> Скопiйовано </span>
					<span v-else> Копiювати </span>
				</NP-Button>
			</div>
			<a v-if="code !== '403'" href="/"> На головну </a>
		</div>
	</div>
</template>

<script>
import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

export default core.createComponent({
	name: 'errors',
	props: {
		code: { type: [String, Number], default: '500' },
		uuid: { type: String, default: null }
	},
	data() {
		return {
			copied: false
		};
	},
	beforeMount() {
		let { actions } = commonStore('errors');
		actions.hideRootSplashScreen(this.$store);
	},
	methods: {
		copy() {
			const el = document.createElement('textarea');
			el.value = this.uuid;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			this.copied = true;
			setTimeout(() => {
				this.copied = false;
			}, 2000);
		}
	}
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins';

.error-container {
	position: absolute;
	left: 0;
	top: 0;
	z-index: $error-screen-z-index;

	display: flex;
	height: 100vh;
	width: 100vw;
	background: $white;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		display: block;
		color: $gray-50;
		margin-bottom: 48px;
		text-align: center;
	}

	.uuid {
		position: relative;
		color: $gray-50;
		margin-bottom: 64px;
		text-align: center;
		label {
			font-family: Proxima Nova Semibold;
			color: $gray-50;
			padding-left: 3px;
		}
		input {
			display: none;
		}
		#uuid {
			font-family: Proxima Nova Semibold;
		}
		.button {
			display: block;
			margin: 16px auto;
		}
	}

	a {
		display: block;
		text-align: center;
		color: $gray-50;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
