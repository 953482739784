import actions from './actions';
import mutations from './mutations';
import { core } from 'novapay-ui';

const types = core.createTypes('archive_packages');

export default core.createStore(actions, {}, mutations, {
	key: 'archive_packages',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			onEntry: 'getPackagesAndScheduleData',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.PACKAGES_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.CREATE_PACKAGE_SUCCESS]: 'idle',
				[types.EDIT_PACKAGE_SUCCESS]: 'idle',
				[types.REMOVE_PACKAGE_SUCCESS]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_PACKAGE_MODAL]: 'package_modal'
					}
				},
				package_modal: {
					initial: 'modal',
					on: {
						[types.CLOSE_PACKAGE_MODAL]: 'main',
						[types.CORRECT_PACKAGE_SUCCESS]: 'main'
					},
					states: {
						modal: {
							on: {
								[types.CORRECT_PACKAGE]: 'correcting'
							}
						},
						correcting: {
							on: {
								[types.CORRECT_PACKAGE_ERROR]: 'modal'
							}
						}
					}
				}
			}
		}
	}
});
