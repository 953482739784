const countries = require('i18n-iso-countries');
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/uk.json"));

module.exports = {
	enum:	Object.entries(countries.getNames('en', { select: 'official' })).reduce(
		(res, [key, val]) => ({
			...res,
			[key]: key
		}), {}),
	locale: countries.getNames('uk', { select: 'official' })
};
