const banks = {
	tas: 'tas',
	privatbank: 'privatbank',
	oschadbank: 'oschadbank',
	aval: 'aval'
};

const locale = {
	[banks.tas]: 'НоваПей',
	[banks.privatbank]: 'ПриватБанк',
	[banks.oschadbank]: 'ОщадБанк',
	[banks.aval]: 'Райффайзен Банк'
};

module.exports = {
	enum: banks,
	locale: locale
};
