const types = {
	new: 'new',
	sentOff: 'sent_off',
	confirmed: 'confirmed',
	on_hold: 'on_hold',
	completed: 'completed',
	needs_revision: 'needs_revision',
	canceled: 'canceled',
	billing_transfer: 'billing_transfer'
};

const locale = {
	[types.new]: 'нова',
	[types.sentOff]: 'в роботі',
	[types.confirmed]: 'погоджено',
	[types.on_hold]: 'на паузі',
	[types.completed]: 'завершено',
	[types.needs_revision]: 'на доопрацюванні',
	[types.canceled]: 'анульовано',
	[types.billing_transfer]: 'передавання в білінг'
};

module.exports = {
	enum: types,
	locale: locale
};
