const statuses = {
	created: 'created',
	processing: 'processing',
	hold: 'technical_hold',
	needRefinement: 'need_refinement',
	refinementDone: 'refinement_done',
	completed: 'completed',
	completedWithoutNameChange: 'completed_without_name_change',
	rejected: 'rejected'
};

const locale = {
	[statuses.created]: 'очікує виконання',
	[statuses.processing]: 'в роботі',
	[statuses.hold]: 'відкладена технічно',
	[statuses.needRefinement]: 'необхідне доопрацювання',
	[statuses.refinementDone]: 'доопрацювано',
	[statuses.completed]: 'виконана',
	[statuses.completedWithoutNameChange]: 'видача без зміни ПІБ',
	[statuses.rejected]: 'відхилена'
};

module.exports = {
	enum: statuses,
	locale: locale
};
