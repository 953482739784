const types = {
	salaryAccountTransitionApplication: 'salary-account-transition-application',
	vacationApplication: 'vacation-application',
	familiarisation: 'familiarisation'
};

const locale = {
	[types.salaryAccountTransitionApplication]: 'Заява щодо перерахування коштів на картковий рахунок',
	[types.vacationApplication]: 'Заява на відпустку',
	[types.familiarisation]: 'Ознайомлення з наказом'
};

module.exports = {
	enum: types,
	locale: locale
};
