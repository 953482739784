import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('inventory_record');

export default core.createStore(actions, {}, mutations, {
	key: 'inventory_record',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			entry: 'getPageData',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.PAGE_DATA_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_ASSET_MODAL]: 'asset_modal'
					}
				},
				asset_modal: {
					on: {
						[types.CLOSE_ASSET_MODAL]: 'main'
					}
				}
			}
		}
	}
});
