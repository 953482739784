import { core } from 'novapay-ui';

const types = core.createTypes('control_center');

export default {
	[types.SHOW_CASH_MACHINE_OTP]: (state) => {
		state.props = {
			...state.props,
			showCashMachineOtpInput: true
		};
	},
	[types.HIDE_CASH_MACHINE_OTP]: (state) => {
		state.props = {
			...state.props,
			showCashMachineOtpInput: false
		};
	},
	[types.CLOSE_SELECT_CASH_MACHINE_MODAL]: (state) => {
		state.props = {
			...state.props,
			showCashMachineOtpInput: false
		};
	},
	[types.OPEN_GIVE_ACCEPT_CASH_MODAL]: (
		state,
		{ tab: giveAcceptCashModalDefaulTab, cashdesk_closal: giveCashOnCashdeskClosal } = {}
	) => {
		state.props = {
			...state.props,
			giveAcceptCashModalDefaulTab,
			giveCashOnCashdeskClosal
		};
	},
	[types.CLOSE_GIVE_ACCEPT_CASH_MODAL]: (state) => {
		state.props = {
			...state.props,
			giveAcceptCashModalDefaulTab: null,
			giveCashOnCashdeskClosal: false,
			validation_errors: null
		};
	}
};
