import { core } from 'novapay-ui';
import actions from '../store/actions';
import mutations from '../store/mutations';

const types = core.createTypes('operation_recipient_claims');

export default core.createStore(actions, {}, mutations, {
	key: 'operation_recipient_claims',
	initial: 'idle',
	states: {
		idle: {
			entry: 'getClaims',
			on: {
				[types.GET_CLAIMS_SUCCESS]: 'render',
				[types.APPLY_QUERY]: 'idle'
			}
		},
		render: {
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.OPEN_CLAIM_MODAL]: 'claim_modal'
			}
		},
		claim_modal: {
			on: {
				[types.CLOSE_CLAIM_MODAL]: 'render',
				[types.APPLY_QUERY]: 'idle'
			}
		}
	}
});
