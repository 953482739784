const types = {
	residencePermanentPermit: 'permanent_residence_permit',
	temporaryCitizenCertificate: 'temporary_citizen_certificate',
	passport: 'passport',
	biometricPassport: 'biometric_passport',
	temporaryResidencePermit: 'temporary_residence_permit',
	internationalPassport: 'international_passport',
	refugeeCertificate: 'refugee_certificate',
	biometricTemporaryResidencePermit: 'biometric_temporary_residence_permit',
	biometricPermanentResidencePermit: 'biometric_permanent_residence_permit',
	protectedPersonCertificate: 'protected_person_certificate',
	powerOfAttorney: 'power_of_attorney',
	foreignersPassport: 'foreigners_passport',
	birthCertificate: 'birth_certificate',
	certificateOfInheritance: 'certificate_of_inheritance'
};

const locale = {
	[types.residencePermanentPermit]: 'посвідка на постійне проживання',
	[types.temporaryCitizenCertificate]: 'тимчасове посвідчення громадянина',
	[types.passport]: 'паспорт громадянина України',
	[types.biometricPassport]: 'біометричний паспорт громадянина України',
	[types.temporaryResidencePermit]: 'посвідка на тимчасове проживання',
	[types.internationalPassport]: 'паспорт громадянина України для виїзду за кордон',
	[types.refugeeCertificate]: 'посвідчення біженця, що видане в Україні',
	[types.biometricTemporaryResidencePermit]: 'біометрична посвідка на тимчасове проживання',
	[types.biometricPermanentResidencePermit]: 'біометрична посвідка на постійне проживання',
	[types.protectedPersonCertificate]: 'посвідчення особи, яка потребує додаткового захисту',
	[types.powerOfAttorney]: 'довіреність',
	[types.foreignersPassport]: 'паспорт громадянина іноземної держави',
	[types.birthCertificate]: 'свідоцтво про народження',
	[types.certificateOfInheritance]: 'свідоцтво про право на спадщину'
};

module.exports = {
	enum: types,
	locale: locale
};
