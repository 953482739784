const statuses = {
	opened: 'opened',
	closed: 'closed',
	closing: 'closing'
};

const locale = {
	[statuses.opened]: 'вiдкрито',
	[statuses.closed]: 'закрито',
	[statuses.closing]: 'закривається'
};

module.exports = {
	enum: statuses,
	locale: locale
};
