const types = {
	alfa: 'alfa',
	oschad: 'oschad',
	ibox: 'ibox',
	tas: 'tas',
	unison: 'unison',
	pumb: 'pumb'
};

const locale = {
	[types.alfa]: 'АТ "АЛЬФА-БАНК"',
	[types.oschad]: 'АТ "Ощадбанк"',
	[types.ibox]: 'АТ "АЙБОКС БАНК"',
	[types.tas]: 'ТАСКОМБАНК',
	[types.unison]: 'ПАТ "БАНК ЮНИСОН"',
	[types.pumb]: 'АТ "ПУМБ"'
};

module.exports = {
	enum: types,
	locale: locale
};
