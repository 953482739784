<template>
	<div
		:class="{
			'cashbook-totals': true,
			'with-discrepancy': hasDiscrepancy
		}"
	>
		<div class="amounts">
			<div class="sum">
				<div>
					<span class="bold gray-50"> <span class="uah" />{{ factualAmount | formatMoney }} </span>
				</div>
				<span class="gray-40">Сума фактична</span>
			</div>
			<i class="icon" />
			<div class="sum">
				<div>
					<span class="bold gray-50"><span class="uah" />{{ recordedAmount | formatMoney }}</span>
				</div>
				<span class="gray-40">{{ recordedAmountTitle }}</span>
			</div>
		</div>
		<div class="discrepancy">
			<div class="sum">
				<div>
					<span class="bold"><span class="uah" />{{ discrepancy | formatMoney }}</span>
				</div>
				<span>Розбіжність</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		factualAmount: { type: [Number, String], default: null },
		recordedAmount: { type: [Number, String], default: null },
		discrepancy: { type: [Number, String], default: null },
		recordedAmountTitle: { type: String, default: 'Сума за КК' }
	},
	computed: {
		hasDiscrepancy() {
			return Number(this.discrepancy) !== 0;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

$icon-compare: '~@assets/images/icon-compare.svg';
$icon-error: '~@assets/images/icon-error.svg';

.cashbook-totals {
	display: flex;
	text-align: center;
	.sum div:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.amounts {
		flex: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 14px 0 11px 0;
		margin-right: 16px;
		border: 1px solid $gray-10;
		box-sizing: border-box;
		border-radius: $border-radius;
		.icon {
			@include make-icon($icon-compare, $blue, 20px, 20px);
			margin: 0 20px;
		}
		.bold {
			font-size: 16px;
		}
	}
	.uah {
		color: $gray-40;
		margin-right: 4px;
	}
	.discrepancy {
		flex: 1;
		padding: 14px 0 11px 0;
		border-radius: $border-radius;
		background-color: $gray-5;
		color: $gray-40;
		.bold {
			font-size: 16px;
		}
	}
}
.with-discrepancy {
	.amounts {
		border: 1px solid $red;
		.icon {
			@include make-icon($icon-error, $red, 20px, 20px);
			margin: 0 20px;
		}
	}
	.discrepancy {
		background: rgba($red, 0.1);
		color: $red;
		.uah {
			color: $red;
		}
	}
}
</style>
