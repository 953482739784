const statuses = {
	created: 'created',
	revision: 'revision'
};

const locale = {
	[statuses.created]: 'створено',
	[statuses.revision]: 'на доопрацюванні'
};

module.exports = {
	enum: statuses,
	locale: locale
};
