import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('cash_management');

export default core.createStore(actions, {}, mutations, {
	key: 'cash_management',
	initial: 'idle',
	states: {
		idle: {
			onEntry: 'getData',
			on: {
				[types.DATA_SUCCESS]: 'render',
				[types.RESET_STATE]: 'idle'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.RESET_STATE]: 'idle'
			},
			states: {
				main: {
					on: {}
				}
			}
		}
	}
});
