<template>
	<div class="root-splash">
		<NP-SplashScreen v-if="this.isMobileDevice" class="mobile-splash" />
		<ControlCenterSplashScreen v-else-if="$route.path === '/'" />
		<CashbookDocumentsSplashScreen v-else-if="/(\/orders|\/acts)\/\d/.test($route.path)" />
		<NP-SplashScreen v-else-if="this.familySearch" variant="family-search" />
		<NP-SplashScreen v-else-if="this.paymentList" variant="payment-list" />
		<NP-SplashScreen v-else-if="this.$route.path.startsWith('/payment/')" variant="payment-cards" />
		<NP-SplashScreen v-else class="default-splash" />
	</div>
</template>

<script>
import ControlCenterSplashScreen from '../../control-center/components/control-center-splash-screen';
import CashbookDocumentsSplashScreen from '../../../components/cashbook-documents-splash-screen';

import isMobileDevice from '@services/is-mobile-device';

export default {
	name: 'RootSplashScreen',
	components: {
		ControlCenterSplashScreen,
		CashbookDocumentsSplashScreen
	},
	computed: {
		familySearch() {
			return (
				this.$route.path === '/payment/family' &&
				(!this.$route.query || !this.$route.query.family_bank_selected_service)
			);
		},
		paymentList() {
			return ['/payment/payout', '/payment/refund', '/payment/awis'].some((path) => this.$route.path.startsWith(path));
		},
		isMobileDevice() {
			return isMobileDevice;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';

.root-splash {
	.default-splash {
		::v-deep div.default-screen {
			max-width: $max-content-width;
			margin: 0 auto;
			.menu-placeholder {
				position: absolute;
				left: 0;
			}
			.progress {
				position: absolute;
				left: 0;
				top: 56px;
			}
			&.table .controls-placeholder {
				margin-top: 80px;
			}
		}
	}
	.mobile-splash {
		::v-deep div.default-screen {
			max-width: $max-content-width;
			margin: 0 auto;
			.menu-placeholder {
				height: 64px;
				position: absolute;
				left: 0;
			}
			.progress {
				position: absolute;
				left: 0;
				top: 64px;
			}
			.controls-placeholder,
			.body-placeholder {
				display: none;
			}
		}
	}
}
</style>
