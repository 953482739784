import { core } from 'novapay-ui';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';

import commonStore from '@services/common-store';
import orderSidebarMutations from '../components/order-sidebar/store/mutations';
import { enum as orderStatuses } from '@repo/enums/cashbook-order-statuses';
import { enum as cashbookStatuses } from '@repo/enums/cashbook-statuses';

const types = core.createTypes('orders');
const { mutations: commonMutations } = commonStore('orders');

const handleOrderAddOrUpdate = (state, order) => {
	let cashbook_orders = cloneDeep(state.props.orders);
	if (!find(cashbook_orders, { id: order.id }) && order.status !== orderStatuses.rejected) {
		cashbook_orders = [order, ...cashbook_orders];
	} else if (find(cashbook_orders, { id: order.id })) {
		cashbook_orders = cashbook_orders.map((o) => (o.id === order.id ? order : o));
	} else if (order.status === orderStatuses.rejected) {
		cashbook_orders = (cashbook_orders || []).filter((o) => o.id !== order.id);
	}
	state.props = { ...state.props, orders: cashbook_orders };
};

export default {
	...commonMutations,
	...orderSidebarMutations,
	[types.GET_PAGE_DATA_SUCCESS]: (state, { orders = null, cashbook = null, totals, total = null } = {}) => {
		orders = orders.map((o) => (o.status === orderStatuses.rejected ? { ...o, confirmed_at: null } : o));
		cashbook = cashbook?.status === cashbookStatuses.opened ? { ...cashbook, closed_at: null } : cashbook;
		state.props = { ...state.props, cashbook, orders, totals, total };
	},
	[types.SET_CASHBOOK_ID]: (state, cashbook_id) => {
		state.props = { cashbook_id };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { cashbook: state.props.cashbook, cashbook_id: state.props.cashbook_id, query };
	},
	[types.OPEN_ORDER_DETAILS_MODAL]: (state, order) => {
		state.props = { ...state.props, order };
	},
	[types.CLOSE_ORDER_DETAILS_MODAL]: (state) => {
		state.props = { ...state.props, order: null };
	},
	[types.OPEN_OUTGOING_PRINT_ACTION_MODAL]: (state, order) => {
		state.props = { ...state.props, order };
	},
	[types.CLOSE_OUTGOING_PRINT_ACTION_MODAL]: (state) => {
		state.props = { ...state.props, order: null };
	},
	[types.OPEN_CONFIRM_CASH_FROM_CASHDESK_MODAL]: (state, order) => {
		state.props = { ...state.props, order };
	},
	[types.CLOSE_CONFIRM_CASH_FROM_CASHDESK_MODAL]: (state) => {
		state.props = { ...state.props, order: null };
	},
	[types.OPEN_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL]: (state, order) => {
		state.props = { ...state.props, order };
	},
	[types.CLOSE_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL]: (state) => {
		state.props = { ...state.props, order: null };
	},
	[types.UPDATE_ORDER]: (state, params) => {
		state.props = { ...state.props, order: { ...state.props.order, ...params } };
	},
	[types.OPEN_INVENTORY_ACT_MODAL]: (state, actData) => {
		state.props = {
			...state.props,
			actType: actData.type,
			actTypeData: actData.type_data,
			actCash: actData.cash,
			actUserFromId: actData.user_from_id,
			actUserFromName: actData.user_from_name,
			actUserFromPosition: actData.user_from_position,
			actUserToId: actData.user_to_id,
			actUserToName: actData.user_to_name,
			actUserToPosition: actData.user_to_position
		};
	},
	[types.CLOSE_INVENTORY_ACT_MODAL]: (state) => {
		state.props = {
			...state.props,
			actCash: null,
			actUserFromId: null,
			actUserFromName: null,
			actUserFromPosition: null,
			validationErrors: null
		};
	},
	[types.VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = { ...state.props, validationErrors };
	},
	[types.CASHBOOK_EVENT]: (state, cashbook) => {
		state.props = { ...state.props, cashbook };
	},
	[types.CASHBOOK_ORDER_EVENT]: (state, order = {}) => {
		handleOrderAddOrUpdate(state, order);
	},
	[types.TOTALS_UPDATE]: (state, totals) => {
		state.props = { ...state.props, totals };
	}
};
