import { core, lookup } from 'novapay-ui';

import handleError from '@services/handle-api-error';

const createActions = (types) => {
	const actions = {
		lookupProductTemplates: async (context, { query = '', category = {} }) => {
			let res = await lookup('/v3/lookups/product-templates', { query: { query, category: category?.id } });
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.PRODUCT_TEMPLATES_LOOKUP, res.data);
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.PRODUCT_TEMPLATES_LOOKUP]: (state, productTemplatesLookupResult) => {
		state.props = { ...state.props, productTemplatesLookupResult, familyBankServiceLookupResult: null };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
