<template>
	<span class="countdown" v-if="countdown">{{ countdown }}</span>
</template>
<script>
const REFRESH_INTERVAL = 1000 / 30;

const timeUnits = [
	['Y', 1000 * 60 * 60 * 24 * 365], // years
	['M', 1000 * 60 * 60 * 24 * 30], // months
	['D', 1000 * 60 * 60 * 24], // days
	['H', 1000 * 60 * 60], // hours
	['m', 1000 * 60], // minutes
	['s', 1000], // seconds
	['S', 1] // million seconds
];

export function formatTimeStr(duration, format) {
	let leftDuration = duration;

	const escapeRegex = /\[[^\]]*]/g;
	const keepList = (format.match(escapeRegex) || []).map((str) => str.slice(1, -1));
	const templateText = format.replace(escapeRegex, '[]');

	const replacedText = timeUnits.reduce((current, [name, unit]) => {
		if (current.includes(name)) {
			const value = Math.floor(leftDuration / unit);
			leftDuration -= value * unit;
			return current.replace(new RegExp(`${name}+`, 'g'), (match) => {
				const len = match.length;
				return value.toString().padStart(len, '0');
			});
		}
		return current;
	}, templateText);

	let index = 0;
	return replacedText.replace(escapeRegex, () => {
		const match = keepList[index];
		index += 1;
		return match;
	});
}

export default {
	name: 'Countdown',
	props: {
		value: { type: Number, default: null },
		format: { type: String, default: () => 'HH:mm:ss' }
	},
	data() {
		return {
			intervalId: null,
			countdown: null
		};
	},
	methods: {
		getTime(value) {
			return new Date(value).getTime();
		},
		stopTimer() {
			clearInterval(this.intervalId);
			this.intervalId = null;
			this.countdown = null;
		},
		syncTimer() {
			const timestamp = this.getTime(this.value);
			if (timestamp >= Date.now()) {
				this.intervalId = setInterval(() => {
					if (this.value) {
						const target = this.value;
						const current = Date.now();
						const diff = Math.max(target - current, 0);

						this.countdown = formatTimeStr(diff, this.format);
					}
					if (timestamp < Date.now()) {
						this.$emit('finish');
						this.stopTimer();
					}
				}, REFRESH_INTERVAL);
			}
		}
	},
	mounted() {
		this.syncTimer();
	},
	beforeDestroy() {
		this.stopTimer();
	},
	watch: {
		value() {
			this.syncTimer();
		}
	}
};
</script>

<style scoped lang="scss">
.countdown {
	font-family: Montserrat;
}
</style>
