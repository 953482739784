<template>
	<NP-Modal :class="{ 'verification-blocked': true, mobile: isMobileDevice }" priority="error" :toggle="toggle">
		<img :src="$options.modalError" alt="error icon" />
		<h4 class="header">Ти не можеш провести цю операцію.</h4>
		<div class="description">
			Поклич на допомогу колегу або допоможи клієнту сплатити онлайн, якщо це можливо.
			<br />
			Заявку в ServiceDesk створювати не потрібно.
			<br />
			Касири з громадянством рф/рб не можуть проводити верифікацію клієнтів.
		</div>
		<div class="btns">
			<NP-Button type="submit" priority="high" @click.stop="toggle">Зрозуміло</NP-Button>
		</div>
	</NP-Modal>
</template>

<script>
import isMobileDevice from '@services/is-mobile-device';
import modalError from '@assets/images/modal-error.svg';

export default {
	name: 'VerificationBlocked',
	props: {
		toggle: { type: Function, default: null }
	},
	modalError,
	computed: {
		isMobileDevice() {
			return isMobileDevice;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-close: '~@assets/images/icon-close.svg';

.verification-blocked {
	::v-deep .modal-container {
		width: 500px;
		text-align: center;
		img {
			display: block;
			margin: -40px auto 16px auto;
		}
		.header {
			font-size: 18px;
			font-family: Proxima Nova Semibold;
			color: $red;
			margin-bottom: 8px;
		}
		.description {
			font-size: 14px;
			line-height: 19px;
			color: $gray-40;
			margin-bottom: 24px;
			.gray-50 {
				font-family: Proxima Nova Semibold;
			}
		}
		.btns {
			display: flex;
			justify-content: center;
		}
	}
	&.mobile {
		::v-deep .modal-container {
			width: 100vw !important;
			img {
				margin-top: -20px;
			}
		}
	}
}
</style>
