import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('archive_documents');
const { mutations } = commonStore('archive-documents');

export default {
	...mutations,
	[types.DOCUMENTS_SUCCESS]: (state, { documents, schedule, total }) => {
		state.props = { ...state.props, documents, schedule, total };
	},
	[types.VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = { ...state.props, validationErrors };
	}
};
