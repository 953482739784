const Promise = require('bluebird');

let scriptsLoaded = false;

function loadScript(src) {
	return new Promise((resolve, reject) => {
		let el = document.createElement('script');
		el.src = src;
		el.onload = resolve;
		el.onerror = reject;
		document.body.appendChild(el);
	});
}

export async function loadPdfScripts() {
	if (!scriptsLoaded) {
		await Promise.all([loadScript('/pdf.min.js'), loadScript('/pdf.worker.min.js')]);
		scriptsLoaded = true;
	}
}

export async function convertToImages(pdfBase64) {
	// eslint-disable-next-line no-undef
	let doc = await pdfjsLib.getDocument({ data: atob(pdfBase64) });
	let canvas = document.createElement('canvas');
	canvas.id = 'np3-pdfjs-canvas';
	canvas.style = 'display: none';
	document.body.appendChild(canvas);

	return Promise.mapSeries(Array.from(Array(doc.numPages)), async (emptyVal, i) => {
		let page = await doc.getPage(i + 1);
		let context = canvas.getContext('2d');
		let viewport = page.getViewport({ scale: 2 }); // affects image quality
		canvas.height = viewport.height;
		canvas.width = viewport.width;
		const renderTask = page.render({ canvasContext: context, viewport });
		await renderTask.promise;
		return canvas.toDataURL();
	});
}
