export default function b64toBlob(b64Data, contentType) {
	var byteCharacters = atob(b64Data);

	var byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		var slice = byteCharacters.slice(offset, offset + 512);
		var byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}
