<template>
	<NP-Modal class="service-desk-success" priority="success" :toggle="toggle">
		<img :src="$options.modalSuccess" />
		<h4 class="success-text">Заявку створено успішно</h4>
		<p class="text">
			Заявку в Сервіс Деску створено з номером <span class="bold">{{ number }}</span>
		</p>
	</NP-Modal>
</template>

<script>
import modalSuccess from '@assets/images/modal-success.svg';
export default {
	name: 'ServiceDeskSuccess',
	props: {
		toggle: { type: Function, default: null },
		number: { type: Number, default: null }
	},
	modalSuccess
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';

.service-desk-success {
	::v-deep .modal-header {
		height: 0;
	}
	::v-deep .modal-body {
		width: 400px;
		img {
			display: block;
			margin: -16px auto 16px auto;
		}
		.success-text {
			text-align: center;
			color: $green;
			font-size: 18px;
			font-family: Proxima Nova Semibold;
			margin-bottom: 15px;
		}
		.text {
			color: $gray-40;
			font-size: 14px;
			.bold {
				color: $gray-50;
			}
		}
	}
}
</style>
