<template>
	<NP-Modal class="confirm-modal" :toggle="toggle" :label="title">
		<p>{{ text }}</p>
		<p v-html="textWithHTML" />

		<div class="buttons">
			<NP-Button v-if="toggle" type="button" priority="low" @click.stop="toggle">
				<i slot="icon" class="icon-close" />
				{{ secondaryBtnText }}
			</NP-Button>

			<a v-if="linkData" :href="linkData.href" class="btn btn-primary" @click="toggle" :target="linkData.target">
				{{ primaryBtnText }}
			</a>

			<NP-Button v-else type="submit" :loading="loading" priority="high" @click.stop="confirm">
				{{ primaryBtnText }}
			</NP-Button>
		</div>
	</NP-Modal>
</template>

<script>
export default {
	name: 'Confirm',
	props: {
		title: { type: String, default: null },
		text: { type: String, default: null },
		textWithHTML: { type: String, default: null },
		primaryBtnText: { type: String, default: 'Продовжити' },
		secondaryBtnText: { type: String, default: 'Скасувати' },
		loading: { type: Boolean, default: false },
		toggle: { type: Function, default: () => {} },
		confirm: { type: Function, default: () => {} },
		linkData: { type: Object, default: null }
	}
};
</script>

<style lang="scss" scoped>
@import './assets/scss/variables';
@import './assets/scss/mixins';

$icon-close: '~@assets/images/icon-close.svg';

.confirm-modal {
	z-index: $confirm-z-index;
	::v-deep .modal-container {
		width: 500px;
		display: block;
		p {
			text-align: center;
		}
		.buttons {
			margin-top: 24px;
			display: flex;
			justify-content: space-between;
			.icon-close {
				@include make-icon($icon-close, $gray-40, 20px, 20px);
			}
		}
	}
}
</style>
