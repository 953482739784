const statuses = {
	opened: 'opened',
	closed: 'closed'
};

const locale = {
	[statuses.opened]: 'вiдкрито',
	[statuses.closed]: 'закрито'
};

module.exports = {
	enum: statuses,
	locale: locale
};
