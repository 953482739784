import { core, http } from 'novapay-ui';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import Promise from 'bluebird';
import { DateTime } from 'luxon';
import print from '@services/print';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';

const { actions: commonStoreActions } = commonStore('operations');
let types = core.getTypes('operations');
let rootTypes = core.getTypes('root');

const getOperations = async (context) => {
	let query = cloneDeep(context.state.props.query);
	let [res, totalsRes] = await Promise.all([
		http('/v3/operations/list-transactions', { query }),
		http('/v3/operations/totals', { query })
	]);
	if (!handleError()(res, context, 200) || !handleError()(totalsRes, context, 200)) {
		return;
	}
	context.commit(types.OPERATIONS_SUCCESS, { operations: res.data, total: 0, totals: totalsRes.data });
	return commonStoreActions.hideRootSplashScreen(context);
};

const togglePreviewModal = async (context, row) => {
	if (context.state.state_key?.render === 'preview_modal') {
		return context.commit(types.CLOSE_PREVIEW_MODAL);
	}
	if (!row) {
		return;
	}
	context.commit(types.LOADING_PREVIEW);
	let res = await http(`/v3/operations/details/${row.id}`);
	if (!handleError()(res, context)) {
		return;
	}
	let { transactions, ...other } = res.data || {};
	let tr = find(transactions, { id: row.transaction_id });
	if (tr) {
		return context.commit(types.OPEN_PREVIEW_MODAL, { ...other, ...tr });
	}
	return;
};

const printCheque = async (context, payload) => {
	let { processing_group, status } = payload;
	let { cashdesk } = cloneDeep(context.rootState.root.props);
	let res = await http('/v3/operations/print-cheque', {
		method: 'POST',
		data: {
			processing_group,
			status,
			cash_machine_code: cashdesk?.use_thermal_printer
				? `${cashdesk.salepoint_id}-${cashdesk.user_id}-thermal_printer`
				: cashdesk?.cash_machine_code
		}
	});
	if (!handleError()(res, context)) {
		return;
	}
	if (res.data?.cheque_url) {
		Object.assign(document.createElement('a'), { target: '_blank', href: res.data.cheque_url }).click();
	}
};

const printFiscalCheque = async (context, payload) => {
	let { processing_group } = payload;
	let res = await http('/v3/operations/print-fiscal-cheque', {
		method: 'POST',
		data: { processing_group }
	});
	if (!handleError()(res, context)) {
		return;
	}
	Object.assign(document.createElement('a'), { target: '_blank', href: res.data.cheque_url }).click();
};

const printOperationsRegistry = async (context) => {
	let isChiefCashier = context.rootGetters['root/isChiefCashier'];
	let { user } = context.rootState.root.props;
	let {
		dateRanges: { created_at },
		filters
	} = context.state.props.query || {};

	let start = DateTime.fromFormat(created_at.start, 'yyyy-MM-dd HH:mm:ss.SSS');
	let end = DateTime.fromFormat(created_at.end, 'yyyy-MM-dd HH:mm:ss.SSS');

	let oneDay = start.ordinal === end.ordinal;
	let badCountOfUsers = isChiefCashier && (!filters.user_id?.length || filters.user_id?.length > 1);

	if (!oneDay || badCountOfUsers) {
		let warn = `${!oneDay ? ' 1 день' : ''} ${badCountOfUsers ? '1 касира' : ''}`;
		return context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ variant: 'error', title: 'Оберіть:', subtitle: warn },
			{ root: true }
		);
	}
	let query = {
		user_id: isChiefCashier ? filters.user_id[0] : user.id,
		date_range: { start: start.toISO(), end: end.toISO() },
		instruments: filters.instrument
	};
	let res = await http(`/v3/operations/print-registry`, { query });
	if (handleError()(res, context)) {
		await print(res.data);
	}
};

const getOperationsRecordsGroups = async (context, { forExport = false } = {}) => {
	let { query } = context.state.props;
	let start = DateTime.fromFormat(query.dateRanges?.created_at?.start, 'yyyy-MM-dd HH:mm:ss.SSS');
	let end = DateTime.fromFormat(query.dateRanges?.created_at?.end, 'yyyy-MM-dd HH:mm:ss.SSS');
	let oneDay = start.ordinal === end.ordinal;
	if (!oneDay || !query.filters?.status || query.filters?.status?.length > 1) {
		return context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ variant: 'error', title: 'Оберіть:', subtitle: `1 день та 1 тип операцій` },
			{ root: true }
		);
	}
	let res = await http('/v3/operations/record-groups', { query });
	if (!handleError()(res, context)) {
		return;
	}
	if (res.data.length === 1) {
		let timePeriod = { start: start.toFormat('HH:mm'), end: end.toFormat('HH.mm') };
		if (forExport) {
			return context.commit(types.OPEN_EMAIL_CONFIRM_FOR_RECORDS_MODAL, timePeriod);
		}
		return operationsRecordsAction(context, { timePeriod });
	}
	return context.commit(types.OPEN_MASS_REGISTRY_PERIOD_SELECT_MODAL, { periods: res.data, forExport });
};

const toggleMassRegistryPeriodSelectModal = (context) => {
	context.commit(types.CLOSE_MASS_REGISTRY_PERIOD_SELECT_MODAL);
};

const operationsRecordsAction = async (context, { email, timePeriod, forExport = false }) => {
	let {
		query: { ...query }
	} = context.state.props;
	let format = 'yyyy-MM-dd HH:mm:ss.SSS';
	let [startHour, startMinute] = (timePeriod || context.state.props.timePeriod).start.split(':');
	let [endHour, endMinute] = (timePeriod || context.state.props.timePeriod).end.split(':');
	query.dateRanges = {
		created_at: {
			start: DateTime.fromFormat(query.dateRanges.created_at.start, format)
				.set({ hour: startHour, minute: startMinute })
				.toFormat(format),
			end: DateTime.fromFormat(query.dateRanges.created_at.end, format)
				.set({ hour: endHour, minute: endMinute })
				.toFormat(format)
		}
	};
	let res = forExport
		? await http(`/v3/operations/export/records`, { query, data: { email }, method: 'POST' })
		: await http(`/v3/operations/print-records`, { query });
	if (!handleError()(res, context)) {
		return;
	}
	if (forExport) {
		context.commit(types.CLOSE_EMAIL_CONFIRM_FOR_RECORDS_MODAL);
	} else {
		await print(res.data);
		context.commit(types.CLOSE_MASS_REGISTRY_PERIOD_SELECT_MODAL);
	}
};

const toggleEmailConfirmModal = (context, { timePeriod } = {}) => {
	let type =
		context.state.state_key?.render === 'email_confirm_for_records'
			? types.CLOSE_EMAIL_CONFIRM_FOR_RECORDS_MODAL
			: types.OPEN_EMAIL_CONFIRM_FOR_RECORDS_MODAL;
	context.commit(type, timePeriod);
};
const toggleEmailConfirmForOperations = (context) => {
	let type =
		context.state.state_key?.render === 'email_confirm_for_operations'
			? types.CLOSE_EMAIL_CONFIRM_FOR_OPERATIONS_MODAL
			: types.OPEN_EMAIL_CONFIRM_FOR_OPERATIONS_MODAL;
	context.commit(type);
};

const exportOperations = async (context, data) => {
	let res = await http('/v3/operations/export/operations', { query: context.state.props.query, data, method: 'POST' });
	if (handleError()(res, context)) {
		context.commit(types.CLOSE_EMAIL_CONFIRM_FOR_OPERATIONS_MODAL);
	}
};

const getCashdesks = async (context) => {
	let { query } = context.state.props;
	let isChiefCashier = context.rootGetters['root/isChiefCashier'];
	let { user } = context.rootState.root.props;
	if (isChiefCashier && (!query.filters?.user_id || query.filters.user_id?.length > 1)) {
		return context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ variant: 'error', title: 'Оберіть 1 користувача' },
			{ root: true }
		);
	}
	let user_id = isChiefCashier ? query.filters?.user_id[0] : user.id;
	let res = await http(`/v3/salepoint/cashdesks`, { query: { created_at: query.dateRanges.created_at, user_id } });
	if (!handleError()(res, context)) {
		return;
	}
	if (res.data?.length === 0) {
		context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ variant: 'error', title: 'Немає змін РРО за даний період' },
			{ root: true }
		);
	} else if (res.data?.length === 1) {
		getDailyReportData(context, { cashdesk_id: res.data[0].id });
	} else {
		context.commit(types.OPEN_CASHDESKS_MODAL, res.data);
	}
};

const toggleSelectCashdesksModal = (context, data) => {
	let type =
		context.state.state_key?.render === 'select_cashdesks_modal'
			? types.CLOSE_CASHDESKS_MODAL
			: types.OPEN_CASHDESKS_MODAL;
	context.commit(type, data);
};

const getDailyReportData = async (context, { cashdesk_id }) => {
	let res = await http(`/v3/operations/daily-report`, { query: { cashdesk_id } });
	if (!handleError()(res, context)) {
		return;
	}
	return context.commit(types.OPEN_DAILY_REPORT_PREVIEW_MODAL, res.data);
};

const toggleDailyReportPreview = (context) => {
	let type =
		context.state.state_key?.render === 'daily_report_preview_modal'
			? types.CLOSE_DAILY_REPORT_PREVIEW_MODAL
			: types.OPEN_DAILY_REPORT_PREVIEW_MODAL;
	context.commit(type);
};

const printDailyReport = async (context, { cash_machine_code }) => {
	let { cashdesk_id } = context.state.props.dailyReportData;
	let res = await http('/v3/salepoint/print-cashdesk-report', {
		data: { cashdesk_id, cash_machine_code },
		method: 'POST'
	});
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.CLOSE_DAILY_REPORT_PREVIEW_MODAL);
};

const wuEditRecipient = async (context, data) => {
	let res = await http('v3/operations/western-union/recipient/edit', { method: 'PUT', data });
	const processingHandler = (context, res) => {
		if (res.data.code === 'OperationNotPermittedError') {
			toggleOperationNotPermitted(context);
			return false;
		}
		let errorAlert = { text: res.data.error, code: res.data.code };
		context.commit(rootTypes.namespaced.ERROR_ALERT, errorAlert, { root: true });
		return false;
	};
	if (!handleError({ processing: processingHandler })(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.CLOSE_EDIT_RECIPIENT_MODAL);
};

const toggleEditRecipientModal = (context) => {
	let type =
		context.state.state_key?.render === 'edit_recipient_modal'
			? types.CLOSE_EDIT_RECIPIENT_MODAL
			: types.OPEN_EDIT_RECIPIENT_MODAL;
	context.commit(type);
};

const toggleOperationNotPermitted = (context) => {
	let type =
		context.state.state_key?.render === 'operation_not_permitted'
			? types.CLOSE_OPERATION_NOT_PERMITTED_MODAL
			: types.OPEN_OPERATION_NOT_PERMITTED_MODAL;
	context.commit(type);
};

const upadteWesternUnionStatus = async (context, { mtcn }) => {
	let res = await http('v3/operations/western-union/payment-status', { method: 'PUT', data: { mtcn } });
	if (!handleError()(res, context, 200)) {
		return;
	}
	return context.commit(types.UPDATE_WU_STATUS, res.data.status);
};

export default {
	...commonStoreActions,
	getCashdesks,
	printDailyReport,
	toggleDailyReportPreview,
	getDailyReportData,
	toggleEmailConfirmForOperations,
	getOperations,
	toggleSelectCashdesksModal,
	exportOperations,
	toggleEmailConfirmModal,
	toggleMassRegistryPeriodSelectModal,
	operationsRecordsAction,
	printOperationsRegistry,
	togglePreviewModal,
	printCheque,
	printFiscalCheque,
	getOperationsRecordsGroups,
	wuEditRecipient,
	toggleEditRecipientModal,
	upadteWesternUnionStatus,
	toggleOperationNotPermitted
};
