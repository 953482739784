import BigNumber from 'bignumber.js';

export default function calculateCash(cash) {
	let res = 0;
	if (!cash) {
		return res;
	}
	if (cash.banknotes) {
		res = cash.banknotes.reduce((acc, banknote) => {
			let amount = new BigNumber(banknote.denomination).times(banknote.count);
			return new BigNumber(acc).plus(amount).toFixed(2);
		}, res);
	}
	if (cash.coins) {
		res = cash.coins.reduce((acc, coin) => {
			let amount = new BigNumber(coin.denomination).times(coin.count);
			return new BigNumber(acc).plus(amount).toFixed(2);
		}, res);
	}
	return res;
}
