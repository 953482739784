const statuses = {
	lead: 'lead',
	verifying: 'verifying',
	active: 'active',
	blocked: 'blocked'
};

const locale = {
	[statuses.lead]: 'лід',
	[statuses.verifying]: 'перевірка',
	[statuses.active]: 'активний',
	[statuses.blocked]: 'заблокований'
};

module.exports = {
	enum: statuses,
	locale: locale
};
