import { core } from 'novapay-ui';

const types = core.createTypes('control-center');

export default {
	[types.OPEN_POS_DETAILS_MODAL]: (state, posDetails) => {
		state.props = { ...state.props, posDetails };
	},
	[types.CLOSE_POS_DETAILS_MODAL]: (state) => {
		state.props = { ...state.props, posDetails: null };
	}
};
