<template>
	<div class="archive-info-wrap">
		<div class="container">
			<div class="row">
				<h3>Архів</h3>
				<div v-if="scheduleText" class="schedule">
					{{ scheduleText }}
				</div>
			</div>
			<NP-Tabs :tabs="tabs" />
		</div>
	</div>
</template>

<script>
import find from 'lodash/find';
export default {
	name: 'ArchiveInfo',
	props: {
		salepoint: { type: Object, default: null },
		lists: { type: Object, default: null },
		schedule: { type: Array, default: () => [] }
	},
	computed: {
		scheduleText() {
			let branch = (find(this.lists['salepoint-branches'], { text: this.salepoint.branch }) || {}).id;
			let d = find(this.schedule, { branch }) || {};
			let due_date =
				d && d.till && d.from ? `Вiдправка касет з ${d.from}-го до ${d.till}-го числа поточного мiсяця` : '';
			return due_date;
		},
		tabs() {
			return [
				{
					url: `/archive-documents`,
					title: 'Зшиви',
					active: /archive-documents/.test(this.$route.path)
				},
				{
					url: `/archive-packages`,
					title: 'Описи',
					active: /archive-packages/.test(this.$route.path)
				}
			];
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
.archive-info-wrap {
	position: absolute;
	width: 100%;
	height: 107px;
	top: 56px;
	left: 0px;
	background-color: $white;
	.container {
		height: 100%;
		margin: 0 auto;
		max-width: $max-content-width;
		min-width: 1000px;
		padding: 0 20px;
		.row {
			display: flex;
			justify-content: space-between;
			h3 {
				margin-top: 25px;
			}
			.schedule {
				margin-top: 20px;
				padding: 0 14px;
				height: 40px;
				line-height: 40px;
				color: $red;
				background: #fef5e1;
				border-radius: $border-radius;
				box-sizing: border-box;
				font-size: 14px;
			}
		}
		.tabs {
			margin-top: 13px;
		}
	}
}
</style>
