<template>
	<div :class="{ 'create-signature-screen': true, 'show-mobile-form': showMobileForm }">
		<div class="left-part">
			<img :src="$options.logo" class="logo" />
			<template v-if="!user.has_gryada_key">
				<div class="header">
					<span class="greeting">Привіт!</span><br />
					Для продовження роботи необхідно створити КЕП <br />
					(кваліфікований електронний підпис).
				</div>
				<div class="requirements">
					<span class="title">Як створити КЕП</span>
					<div class="requirement">
						<div class="number">1</div>
						<span class="text">Створи пароль до ключа КЕП, який ти будеш використовувати для підпису документів.</span>
					</div>
					<div class="requirement">
						<div class="number">2</div>
						<span class="text">
							Щоб завершити створення заявки на КЕП, переходь в розділ створення заявок та обери тип "Заява на КЕП".
							Слідкуй за статусом заяви у цьому розділі
						</span>
					</div>
					<img :src="$options.eds" class="image-show-load-eds-docs" />
				</div>
			</template>
			<template v-else>
				<div class="header">
					<span class="greeting">Привіт!</span><br />
					Для продовження роботи необхідно поновити КЕП <br />
					(кваліфікований електронний підпис).
				</div>
				<div class="requirements">
					<span class="title">Як поновити КЕП</span>
					<div class="requirement">
						<div class="number">1</div>
						<span class="text">Натиснути кнопку "Створити" для створення заявки.</span>
					</div>
					<div class="requirement">
						<div class="number">2</div>
						<span class="text">
							Щоб завершити створення заявки на КЕП, переходь в розділ створення заявок та обери тип "Заява на КЕП".
							Слідкуй за статусом заяви у цьому розділі
						</span>
					</div>
					<img :src="$options.eds" class="image-show-load-eds-docs" />
				</div>
			</template>
			<NP-Button @click.stop="showMobileForm = true" class="show-form-btn" priority="high"> Створити КЕП </NP-Button>
		</div>
		<form class="right-part" @submit.prevent="submitSignatureCreation">
			<div class="create-text" v-if="!user.has_gryada_key">Створіть пароль <br />до особистого ключа КЕП</div>
			<div
				v-if="user.eds_request && user.eds_request.status === edsRequestStatuses.rejected"
				class="eds-request-status rejected"
			>
				Твоя попередня заявка відхилена: {{ user.eds_request.reason }}.<br />
				Необхідно повторно створити <span v-if="!user.has_gryada_key">пароль та</span> заяву на КЕП
			</div>
			<div class="password-info" v-if="!user.has_gryada_key">
				Пароль може містити літери латиницею,<br />
				цифри, спецсимволи (!#$%&‘*+—/=?^_`{ | }~)
			</div>
			<NP-Input
				v-if="!user.has_gryada_key"
				v-model="createGryadaKeyForm.password"
				label="Пароль"
				type="password"
				:error="formErrors.password"
			/>
			<NP-Input
				v-if="!user.has_gryada_key"
				v-model="createGryadaKeyForm.repeatPassword"
				label="Повторіть пароль"
				type="password"
				:error="formErrors.repeatPassword"
			/>
			<div class="back-btn-container">
				<NP-Button @click.stop="showMobileForm = false" class="back-to-info-btn" priority="low-borderless">
					Назад
				</NP-Button>
			</div>
			<NP-Button type="submit" priority="high" :loading="loadingActions.includes('createGryadaKey')">
				Створити
			</NP-Button>
		</form>
	</div>
</template>

<script>
import { validation } from 'novapay-ui';
import logo from '@assets/images/logo.svg';
import eds from '@assets/images/image-show-load-eds-docs.png';

import { enum as edsRequestStatuses } from '@repo/enums/electronic-digital-signature-requests-statuses';

export default {
	name: 'GryadaKey',
	logo,
	eds,
	props: {
		user: { type: Object, default: null },
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] },
		createGryadaKey: { type: Function, default: null }
	},
	data() {
		return {
			showMobileForm: false,
			createGryadaKeyForm: {
				password: null,
				repeatPassword: null
			},
			edsRequestStatuses
		};
	},
	computed: {
		formErrors() {
			return validation.computeValidationMessages(this.errors, ['password', 'repeatPassword']);
		}
	},
	methods: {
		submitSignatureCreation() {
			return this.createGryadaKey({ ...this.createGryadaKeyForm, loadingAction: true });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/mixins';
@import '@assets/scss/variables.scss';

.create-signature-screen {
	height: 100%;
	width: 100%;
	display: flex;
	.logo {
		position: absolute;
		top: 40px;
		width: 109px;
		height: 32px;
	}
	.left-part {
		flex: 1;
		display: flex;
		justify-content: center;
		flex-direction: column;
		background: $white;
		padding: 40px;
		.header {
			.greeting {
				font-size: 24px;
				font-family: Proxima Nova Semibold;
				line-height: 40px;
			}
			margin-bottom: 16px;
			font-family: Proxima Nova Semibold;
			font-size: 18px;
		}
		.requirements {
			.title {
				display: block;
				line-height: 30px;
				font-size: 14px;
				font-family: Proxima Nova;
				margin-bottom: 16px;
			}
			.requirement {
				display: flex;
				align-items: center;
				margin-bottom: 16px;
				.number {
					display: flex;
					font-size: 14px;
					font-family: Proxima Nova;
					color: $gray-40;
					justify-content: center;
					align-items: center;
					border-radius: 4px;
					padding: 10px;
					width: 24px;
					height: 24px;
					background: $gray-10;
					margin-right: 16px;
				}
				.text {
					font-size: 14px;
					line-height: 18px;
					font-family: Proxima Nova;
					color: $gray-50;
				}
			}
			.image-show-load-eds-docs {
				margin-left: 40px;
				height: 124px;
				width: 292px;
			}
		}
		.show-form-btn {
			display: none;
		}
	}
	.right-part {
		flex: 1;
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: $gray-0;
		.back-btn-container {
			display: none;
		}
		.create-text {
			width: 301px;
			font-size: 24px;
			line-height: 113.1%;
			text-align: center;
			font-family: Proxima Nova Semibold;
			margin-bottom: 25px;
		}
		.eds-request-status {
			margin-bottom: 24px;
			padding: 12px 16px;
			border-radius: 4px;
			font-size: 12px;
			line-height: 16px;
			&.rejected {
				background: $light-red;
				color: $red;
			}
		}
		.password-info {
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			color: $gray-40;
			margin-bottom: 16px;
		}
		.input {
			width: 260px;
			margin: 0 auto 16px auto;
		}
		.button {
			display: block;
			width: 260px;
			margin: 0 auto;
		}
	}
	@media (max-width: 800px) {
		flex-direction: column;
		.logo {
			left: 20px;
			top: 20px;
		}
		.left-part {
			padding: 70px 15px 15px 15px;
			.header {
				text-align: center;
			}
			.requirements {
				.image-show-load-eds-docs {
					width: calc(100% - 40px);
					max-width: 292px;
					height: auto;
				}
				margin-bottom: 20px;
			}
			.show-form-btn {
				display: block;
				width: 100%;
				align-self: center;
				margin-top: auto;
			}
		}
		.right-part {
			display: none;
		}
		&.show-mobile-form {
			.left-part {
				display: none;
			}
			.right-part {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px 15px;
				margin-bottom: 20%;
				.back-btn-container {
					display: block;
					.back-to-info-btn {
						background: transparent;
						margin-bottom: 16px;
					}
				}
				.create-text {
					width: 100%;
					font-size: 20px;
				}
				.input,
				.button {
					width: 100%;
					max-width: 260px;
				}
			}
		}
	}
}
</style>
