const types = {
	cashbookShift: 'cashbook_shift',
	cashbook: 'cashbook',
	cashbookOrder: 'cashbook_order',
	cashdesk: 'cashdesk',
	posInfo: 'pos_info',//pos device updates (status, balances etc)
	posMessage: 'pos_message',//last transaction data, transactions list, last receipt data
	posAlert: 'pos_alert',//payment related notification messages
	operations: 'operations',
	paymentFailed: 'payment_failed',
	signatureRequest: 'signature_request',
	successfulSign: 'successful_sign',
	digitalDocument: 'digital_document',
	thermalPrinter: 'thermal_printer',
	rroPrinter: 'rro_printer',
	otpInput: 'otp_input',
};

const locale = {
	[types.cashbookShift]: 'змiна старшого касира',
	[types.cashbook]: 'касова книга',
	[types.cashbookOrder]: 'касовий ордер',
	[types.cashdesk]: 'каса',
	[types.posInfo]: 'информацiя про POS',
	[types.posMessage]: 'данi вiд POS-у',
	[types.posAlert]: 'алерт POS-у',
	[types.operations]: 'операцiї',
	[types.paymentFailed]: 'невдала оплата',
	[types.signatureRequest]: 'необхідний підпис',
	[types.successfulSign]: 'успішно підписано',
	[types.digitalDocument]: 'цифровий документ',
	[types.thermalPrinter]: 'команда на термопринтер',
	[types.otpInput]: 'введення OTP',
};

module.exports = {
	enum: types,
	locale: locale
};
