import { core, http } from 'novapay-ui';
import print from '@services/print';
import forEach from 'lodash/forEach';
import Promise from 'bluebird';
import handleError from '@services/handle-api-error';
import b64toBlob from '@services/transform-to-blob';
import { enum as applicationTypes } from '@repo/enums/naftogaz-application-types';

const createActions = (types, rootTypes) => ({
	verifyEicCodeAndGetClientInfo: async (context, { eic_code, account_number, type } = {}) => {
		const processed = await processNoDeptAndActPdf(context, { eic_code, account_number, type, types });
		if (processed) {
			return;
		}

		const isAlreadyExist = await http('/v3/naftogaz/check-if-application-already-exists-by-eic-code', {
			query: { eic_code }
		});
		if (!handleError()(isAlreadyExist, context, 200, types.VALIDATION_ERRORS)) {
			return;
		}

		let gasClientInfo = await http('/v3/naftogaz/get-info-by-eic-code', { query: { eic_code } });
		if (!handleError()(gasClientInfo, context, 200, types.VALIDATION_ERRORS)) {
			return;
		}

		let [applicationEnums, addressRegions, tariffs] = await Promise.all([
			http('/v3/naftogaz/get-application-enums'),
			http('/v3/naftogaz/get-address-regions'),
			http('/v3/naftogaz/get-tariffs')
		]);
		if (
			!handleError()(applicationEnums, context) ||
			!handleError()(addressRegions, context) ||
			!handleError(tariffs, context)
		) {
			return;
		}
		context.commit(types.VALIDATION_ERRORS, []);
		context.commit(types.SET_APPLICATION_INFO, {
			gasClientInfo: gasClientInfo.data,
			applicationEnums: applicationEnums.data,
			regionList: addressRegions.data,
			tariffList: tariffs.data
		});
		context.commit(types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL);
	},
	getAddressDistricts: async (context, { region_id }) => {
		let res = await http('/v3/naftogaz/get-address-districts', { query: { region_id } });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.SET_APPLICATION_INFO, { districtList: res.data });
	},
	getAddressCities: async (context, { region_id, district_id }) => {
		let res = await http('/v3/naftogaz/get-address-cities', { query: { region_id, district_id } });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.SET_APPLICATION_INFO, { cityList: res.data });
	},
	getAddressStreets: async (context, { region_id, district_id, city_id }) => {
		let res = await http('/v3/naftogaz/get-address-streets', { query: { region_id, district_id, city_id } });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.SET_APPLICATION_INFO, { streetList: res.data });
	},
	closeCheckClientDocumentModalWithPassportInfo: async (context, { gasClientInfo, isConfirmed, type }) => {
		if (!isConfirmed) {
			return context.commit(types.IDLE);
		}

		if (!gasClientInfo) {
			context.commit(types.CLOSE_CHECK_CLIENT_DOCUMENTS_MODAL);
			return;
		}
		context.commit(types.CLOSE_CHECK_CLIENT_DOCUMENTS_MODAL);
		switch (type.id) {
			case applicationTypes.certificate:
			case applicationTypes.act:
				setTimeout(() => context.commit(types.OPEN_VERIFY_CLIENT_PHONE_MODAL), 0);
				break;
		}
	},
	sendNaftogazOtpCode: async (context, { phone }) => {
		const res = await http('/v3/naftogaz/otp', { method: 'POST', data: { phone } });
		if (!handleError()(res, context)) {
			return false;
		}
		return res.data;
	},
	toggleVerifyClientPhoneModal: (context) => {
		const isClosed = context.state?.state_key?.render !== 'verify_client_phone_modal';
		const action = isClosed ? types.OPEN_VERIFY_CLIENT_PHONE_MODAL : types.CLOSE_VERIFY_CLIENT_PHONE_MODAL;
		context.commit(action);
	},
	prepareApplication: async (context, { gasClientInfo, type, phone, otp }) => {
		if (!gasClientInfo) {
			context.commit(types.CLOSE_VERIFY_CLIENT_PHONE_MODAL);
			return;
		}
		const data = { ...gasClientInfo, type: type.id, phone, otp };
		const url = {
			[applicationTypes.certificate]: '/v3/naftogaz/prepare-no-debt-certificate',
			[applicationTypes.act]: '/v3/naftogaz/prepare-act'
		}[type.id];
		const res = await http(url, { method: 'POST', data });
		if (
			!handleError({
				processing: (context, res) => {
					if (res.data.code === 'OTPCheckFailedError') {
						context.commit(types.OTP_CHECK_FAILED, { phone, otp });
						return false;
					}
					context.commit(
						rootTypes.ERROR_ALERT,
						{
							text: res.data.params?.error ?? res.data.error,
							code: res.data.code
						},
						{ root: true }
					);
					return false;
				}
			})(res, context)
		) {
			return;
		}
		context.commit(types.OTP_CHECK_SUCCESS);
		context.commit(types.SET_APPLICATION_INFO, {
			gasClientInfo: {
				phone,
				...gasClientInfo,
				eic_code: gasClientInfo.eic_code || gasClientInfo.eic,
				id: res.data.id,
				ticketType: res.data.type
			}
		});
		context.commit(types.CLOSE_VERIFY_CLIENT_PHONE_MODAL);
	},
	closeCheckClientDocumentModal: async (context, { shouldPrintPartOwnership, isConfirmed } = {}) => {
		if (!isConfirmed) {
			return context.commit(types.IDLE);
		}
		let res = await http('/v3/naftogaz/print-agreement-and-ownership-templates', {
			query: { shouldPrintPartOwnership }
		});
		if (!handleError()(res, context)) {
			return;
		}
		print(res.data);
		context.commit(types.CLOSE_CHECK_CLIENT_DOCUMENTS_MODAL);
	},
	printApplication: async (context, data) => {
		let res = await http('/v3/naftogaz/print-application-template', { method: 'POST', data });
		if (!handleError()(res, context)) {
			return;
		}
		return window.open(URL.createObjectURL(b64toBlob(res.data, 'application/pdf')));
	},
	createApplication: async (context, data) => {
		if (!data.patronymic && !confirm('Я впевнений, що у клієнта немає по батькові')) {
			return;
		}
		if (!data.taxpayer_id && !confirm('Я впевнений, що у клієнта немає ІПН')) {
			return;
		}
		let formData = new FormData();
		data = {
			...data,
			patronymic: data.patronymic || '_',
			taxpayer_id: data.taxpayer_id || '1111111111'
		};
		forEach(data, (value, key) => {
			if (!value && typeof value !== 'boolean') {
				return;
			}
			if (Array.isArray(value)) {
				value.forEach((file) => {
					formData.append(key, file);
				});
			} else {
				formData.append(key, JSON.stringify(value));
			}
		});
		let res = await http(`/v3/naftogaz/create-application`, {
			method: 'POST',
			data: formData,
			headers: { 'content-type': 'multipart/form-data' }
		});
		let handler = () => {
			context.commit(types.VALIDATION_ERRORS, []);
			context.commit(
				rootTypes.ERROR_ALERT,
				{
					text: res.data.error,
					code: res.data.code
				},
				{ root: true }
			);
			return false;
		};
		if (!handleError({ processing: handler })(res, context, 200, types.VALIDATION_ERRORS)) {
			return;
		}
		context.commit(types.VALIDATION_ERRORS, []);
		context.commit(types.OPEN_UPLOAD_APPLICATION_MODAL, res.data);
		context.commit(rootTypes.ADD_SNACKBAR, { variant: 'success', title: 'Заявка успішно створена' }, { root: true });
	},
	uploadApplicationImages: async (context, data) => {
		let formData = new FormData();
		formData.append('external_id', data.external_id);
		data.files.forEach((file) => {
			formData.append('application_for_change_images', file);
		});
		let res = await http('/v3/naftogaz/upload-application-images', { method: 'POST', data: formData });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.CLOSE_UPLOAD_APPLICATION_MODAL);
		context.commit(types.IDLE);
		context.commit(rootTypes.ADD_SNACKBAR, { variant: 'success', title: 'Заявка успішно відправлена' }, { root: true });
	},
	showErrorSnackbar: async (context, message) => {
		return context.commit(rootTypes.ADD_SNACKBAR, { variant: 'error', title: message }, { root: true });
	}
});

const createMutations = (types) => ({
	[types.SET_APPLICATION_INFO]: (
		state,
		{ gasClientInfo, applicationEnums, regionList, districtList, cityList, streetList, tariffList }
	) => {
		state.props = {
			...state.props,
			gasClientInfo: gasClientInfo ? gasClientInfo : state.props.gasClientInfo,
			applicationEnums: {
				...((applicationEnums ? applicationEnums : state.props?.applicationEnums) || {}),
				regionList: regionList ? regionList : state.props?.applicationEnums?.regionList || [],
				districtList: districtList ? districtList : state.props?.applicationEnums?.districtList || [],
				cityList: cityList ? cityList : state.props?.applicationEnums?.cityList || [],
				streetList: streetList ? streetList : state.props?.applicationEnums?.streetList || [],
				tariffList: tariffList ? tariffList : state.props?.applicationEnums?.tariffList || []
			}
		};
	},
	[types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL]: (state) => {
		state.props = { ...state.props, eicCodeVerified: true };
	},
	[types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL_WITH_PASSPORT_INFO]: (state, gasClientInfo) => {
		state.props = { ...state.props, gasClientInfo, eicCodeVerified: true };
	},
	[types.OPEN_UPLOAD_APPLICATION_MODAL]: (state, application) => {
		state.props = { ...state.props, application };
	},
	[types.CLOSE_UPLOAD_APPLICATION_MODAL]: (state) => {
		state.props = { ...state.props, application: null };
	},
	[types.OTP_CHECK_FAILED]: (state, { phone, otp } = {}) => {
		state.props = { ...state.props, otpErrors: { [phone]: otp } };
	},
	[types.OTP_CHECK_SUCCESS]: (state) => {
		state.props = { ...state.props, otpErrors: null };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};
async function processNoDeptAndActPdf(context, { eic_code, account_number, type, types }) {
	let gasClientInfo;
	if ([applicationTypes.certificate, applicationTypes.act].indexOf(type.id) === -1) {
		return false;
	}
	gasClientInfo = await getAccountInfo(eic_code, account_number);

	if (!handleError()(gasClientInfo, context, 200, types.VALIDATION_ERRORS)) {
		return true;
	}

	gasClientInfo = {
		...gasClientInfo.data,
		account_number,
		type
	};

	context.commit(types.OPEN_CHECK_CLIENT_DOCUMENTS_MODAL_WITH_PASSPORT_INFO, gasClientInfo);
	return true;
}

async function getAccountInfo(eic_code, account_number) {
	if (account_number && account_number.length === 9) {
		return http('/v3/naftogaz/get-info-by-account-number', { query: { account_number } });
	} else {
		const gasClient = await http('/v3/naftogaz/get-info-by-eic-code', { query: { eic_code } });
		return { ...gasClient, data: { ...gasClient.data, eic_code } };
	}
}

export default createComponentStore;
