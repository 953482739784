<template>
	<div class="login-screen">
		<div class="login-container">
			<img :src="$options.logo" class="logo" />
			<form @submit.prevent="submitLogin" class="login-form">
				<div class="login-block">
					<div class="welcome-text">
						<div class="title">Привіт!</div>
						<div class="text">Ласкаво просимо до системи NovaPay</div>
					</div>
					<NP-Input v-model="loginForm.login" ref="login" label="Логін" type="text" :error="formErrors.login" />
					<NP-Input v-model="loginForm.password" label="Пароль" type="password" :error="formErrors.password" />
					<NP-Button type="submit" priority="medium" :loading="loadingActions.includes('login')"> Увійти </NP-Button>
				</div>
				<div class="spacer" />
			</form>
		</div>
		<div :class="{ 'addition-content': true }">
			<div class="bg-image" />
		</div>
	</div>
</template>

<script>
import { validation } from 'novapay-ui';
import logo from '@assets/images/novapay-violet-large.svg';

export default {
	name: 'Login',
	props: {
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] },
		login: { type: Function, default: null }
	},
	logo,
	mounted() {
		this.$refs.login.$refs.input.focus();
	},
	data() {
		return {
			loginForm: {
				login: null,
				password: null
			}
		};
	},
	computed: {
		formErrors() {
			return validation.computeValidationMessages(this.errors, ['login', 'password']);
		}
	},
	methods: {
		submitLogin() {
			return this.login({ ...this.loginForm, loadingAction: true });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$screen: '~@assets/images/login-bg.png';

.login-screen {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	.login-container {
		width: 440px;
		background: $gray-0;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		padding: 32px 40px 20px 40px;
		position: relative;
		overflow-y: auto;
		@include pretty-scrollbar();
		.logo {
			width: 262px;
			height: 48px;
		}
		.login-form {
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 20px 35px;
			.login-block {
				display: flex;
				flex-direction: column;
				flex-shrink: 0;
				flex-grow: 3;
				justify-content: flex-end;
				.title {
					color: $gray-50;
					font-family: Proxima Nova Semibold;
					font-size: 24px;
					margin-bottom: 5px;
				}
				.text {
					color: $gray-40;
					font-size: 16px;
					font-family: Proxima Nova;
					margin-bottom: 32px;
				}
				.input {
					width: 100%;
					margin-bottom: 20px;
				}
				.button {
					border: 0;
					background: $violet;
					box-shadow: 0 2px 6px rgba(235, 189, 191, 0.8);
					width: 100%;
				}
			}
			.spacer {
				flex-shrink: 1;
				flex-grow: 6;
			}
		}
	}
	.addition-content {
		flex: 1;
		display: flex;
		position: relative;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background: #eae8f6;
		.bg-image {
			width: 100%;
			height: 100%;
			background-image: url($screen);
			background-repeat: no-repeat;
			background-position: 0;
			background-size: cover;
			image-rendering: high-quality;
			image-rendering: -webkit-optimize-contrast;
		}
	}
	@media screen and (max-width: 1150px) {
		height: 100%;
		flex-direction: column;
		.login-container {
			flex: 1;
			padding: 10px 10px 20px 10px;
			overflow-y: unset;
			text-align: center;
			width: 100%;
			.input,
			.button {
				max-width: 300px;
			}
		}
		.addition-content {
			min-height: 350px;
			max-height: 800px;
		}
	}
	@include mobile() {
		overflow-y: auto;
		@include pretty-scrollbar();
		.login-container {
			.login-form {
				padding: 10px;
			}
			.logo {
				margin-bottom: 20px;
			}
			.welcome-text {
				margin-bottom: -10px;
			}
		}
		.addition-content {
			display: none;
		}
	}
}
</style>
