<template>
	<div class="wrap">
		<div class="cashbook-info">
			<div class="info-wrap">
				<div class="info">
					<div class="cashbook-dates">
						<h3>Касова Книга №{{ cashbook.number }}</h3>
						<span>
							<div class="mid-circle" />
							{{ formatDate(cashbook.created_at) }}
						</span>
						<div v-if="cashbook.closed_at" class="dots" />
						<span v-if="cashbook.closed_at">
							<div class="mid-dot" />
							{{ formatDate(cashbook.closed_at) }}
						</span>
					</div>
					<div>
						<NP-Chip v-if="showVerificationStatus" class="status-chip" :can-close="false" :variant="chipVariant">
							{{ cashbookVerificationStatus | capitalize }}
						</NP-Chip>
						<NP-Chip v-else class="status-chip" :can-close="false" :variant="chipVariant">
							{{ cashbookStatus | capitalize }}
						</NP-Chip>
						<p
							v-if="cashbook.uses_electronic_digital_signatures && !showVerificationStatus"
							class="electronic-digital-signature-status"
						>
							КЕП
						</p>
					</div>
					<span title="Початковий баланс">
						{{ cashbook.starting_balance | formatMoney }}
						<span class="uah" />
					</span>
					<span title="Залишок на кінець дня">
						{{ cashbook.current_balance | formatMoney }}
						<span class="uah" />
					</span>
					<span title="Розбіжність по ЖО" :class="{ red: operations_discrepancy != 0 }">
						{{ operations_discrepancy | formatMoney }}
						<span class="uah" />
					</span>
					<span title="Розбіжність факт." :class="{ red: factual_discrepancy != 0 }">
						{{ factual_discrepancy | formatMoney }}
						<span class="uah" />
					</span>
				</div>
				<div v-click-outside="() => showBankRequisites && toggleRequisites()" class="requisites-wrap">
					<NP-Button :class="{ 'bank-requisites-btn': true, expanded: showBankRequisites }" @click="toggleRequisites">
						<div>Реквізити банку</div>
					</NP-Button>
					<NP-Button
						v-if="cashbook.status === 'closed' && printCashbook"
						variant="square"
						priority="medium"
						@click.stop="() => printCashbook({ loadingAction: true })"
						:loading="loadingActions.includes('printCashbook')"
					>
						<div class="icon-printer" slot="icon" />
					</NP-Button>
					<div v-if="showBankRequisites" class="bank-requisites">
						<table>
							<tr>
								<td><b>Банк інкасації</b></td>
								<td>{{ encashmentBankName }}</td>
							</tr>
							<tr>
								<td>Код банку</td>
								<td>{{ salepoint.encashment_bank_code }}</td>
							</tr>
							<tr>
								<td>Рахунок № (Дебет)</td>
								<td>{{ salepoint.encashment_debit_account }}</td>
							</tr>
							<tr>
								<td>Рахунок № (Кредит)</td>
								<td>{{ salepoint.encashment_credit_account }}</td>
							</tr>
							<tr v-if="salepoint.transit_bank_name">
								<td><b>Транзитний банк</b></td>
								<td>{{ transitBankName }}</td>
							</tr>
							<tr v-if="salepoint.transit_bank_code">
								<td>Код банку</td>
								<td>{{ salepoint.transit_bank_code }}</td>
							</tr>
							<tr v-if="salepoint.transit_credit_account">
								<td>Рахунок № (Кредит)</td>
								<td>{{ salepoint.transit_credit_account }}</td>
							</tr>
							<tr v-if="salepoint.refreshment_bank_name">
								<td><b>Банк підкріплення</b></td>
								<td>
									{{ salepoint.refreshment_bank_name | getListTextValue(lists['territorial-refreshment-banks']) }}
								</td>
							</tr>
							<tr v-if="salepoint.cash_management_limits">
								<td><b>Ліміт</b></td>
								<td>{{ limitAmount | formatMoney }} грн ( {{ limitActivationDate }} р. )</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<NP-Tabs :tabs="tabs" />
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon';
import { formatters } from 'novapay-ui';
import BigNumber from 'bignumber.js';

import { enum as cashbookStatuses } from '@repo/enums/cashbook-statuses';
import { locale as banks } from '@repo/enums/banks';

export default {
	name: 'CashbookInfo',
	props: {
		cashbook: { type: Object, default: () => ({}) },
		totals: { type: Object, default: null },
		salepoint: { type: Object, default: null },
		lists: { type: Object, default: null },
		loadingActions: { type: Array, default: () => [] },
		printCashbook: { type: Function, default: null },
		showVerificationStatus: { type: Boolean, default: false }
	},
	data: () => ({
		showBankRequisites: false
	}),
	computed: {
		operations_discrepancy() {
			return (
				new BigNumber(this.totals?.recorded_orders_incoming)
					.minus(this.totals?.recorded_orders_outgoing)
					.plus(this.totals?.recorded_orders_without_303_outgoing)
					.minus(this.totals?.recorded_orders_without_303_incoming)
					.plus(this.totals?.operations_cash_paid_off_amount)
					.plus(this.totals?.operations_paid_off_rounding)
					.minus(this.totals?.operations_cash_paid_amount)
					.minus(this.totals?.operations_paid_rounding)
					.toNumber() || 0
			);
		},
		factual_discrepancy() {
			return (
				new BigNumber(this.totals?.factual_orders_incoming)
					.minus(this.totals?.factual_orders_outgoing)
					.minus(this.totals?.recorded_orders_incoming)
					.plus(this.totals?.recorded_orders_outgoing)
					.toNumber() || 0
			);
		},
		encashmentBankName() {
			return banks[this.salepoint.encashment_bank_name] || '';
		},
		transitBankName() {
			return banks[this.salepoint.transit_bank_name] || '';
		},
		cashbookStatus() {
			return formatters.getListTextValue(this.cashbook.status, this.lists['cashbook-statuses']);
		},
		cashbookVerificationStatus() {
			if (this.cashbook.uses_electronic_digital_signatures && !this.cashbook?.has_unsigned_printed_documents) {
				return 'КЕП';
			}
			return this.cashbook?.salepoint_verified ? 'Повна' : 'Неповна';
		},
		chipVariant() {
			if (this.showVerificationStatus) {
				if (this.cashbook.uses_electronic_digital_signatures && !this.cashbook.has_unsigned_printed_documents) {
					return 'progress';
				}
				return this.cashbook?.salepoint_verified ? 'success' : 'failure';
			}
			return this.cashbook.status === cashbookStatuses.opened ? 'success' : 'regular';
		},
		tabs() {
			let tabs = [
				{
					url: `/orders/${this.cashbook.id}`,
					title: 'Журнал ордерів',
					active: /orders\/\d/.test(this.$route.path)
				},
				{
					url: `/acts/${this.cashbook.id}`,
					title: 'Журнал актів',
					active: /acts\/\d/.test(this.$route.path)
				},
				{
					url: '/cashbooks',
					title: 'Журнал Касових Книг',
					active: /cashbooks\/\d/.test(this.$route.path)
				},
				{
					url: `/printed-documents/${this.cashbook.id}`,
					title: 'Друкованi форми',
					active: /printed-documents\/\d/.test(this.$route.path)
				}
			];

			if (this.salepoint.branch === 'Філія № 4 (Львівська обл.)') {
				tabs.push({
					url: `/cash-management/${this.cashbook.id}`,
					title: 'Графік перевезення коштів',
					active: /cash-management\/\d/.test(this.$route.path)
				});
			}

			return tabs;
		},
		limitAmount() {
			return this.salepoint?.cash_management_limits?.max_amount?.max_amount;
		},
		limitActivationDate() {
			let date = this.salepoint?.cash_management_limits?.max_amount?.activation_date;
			return date ? DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd.MM.yyyy') : null;
		}
	},
	methods: {
		toggleRequisites() {
			this.showBankRequisites = !this.showBankRequisites;
		},
		formatDate(date) {
			return DateTime.fromISO(date).toFormat('dd.MM.yyyy / HH:mm:ss');
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/mixins';
@import '@assets/scss/variables';

$mid-dot: '~@assets/images/mid-dot.svg';
$mid-circle: '~@assets/images/mid-circle.svg';
$icon-print: '~@assets/images/icon-print.svg';
$icon-confirm-green: '~@assets/images/icon-confirm-green.svg';

.wrap {
	position: absolute;
	left: 0;
	width: 100%;
	background: $white;
	box-shadow: 0 1px 1px rgba(226, 231, 240, 0.6);
	height: 138px;
	.cashbook-info {
		max-width: $max-content-width;
		background: $white;
		margin: 0 auto;
		padding: 0 20px;
		.info-wrap {
			padding: 20px 0 24px 0;
			display: flex;
			justify-content: space-between;
			.info {
				display: flex;
				.cashbook-dates {
					position: relative;
					height: 58px;
					white-space: nowrap;
					span {
						display: block;
						color: $gray-40;
						margin-bottom: 4px;
						.mid-circle {
							margin: 0 6px 2px 0;
							@include make-icon($mid-circle, $gray-40, 6px, 6px);
						}
						.mid-dot {
							margin: 0 6px 2px 0;
							@include make-icon($mid-dot, $gray-40, 6px, 6px);
						}
					}
					.dots::after {
						content: '...';
						transform: rotate(90deg);
						color: $gray-40;
						font-size: 16px;
						position: absolute;
						top: 32px;
						left: 1px;
					}
				}
				.electronic-digital-signature-status {
					background: $gray-5;
					border-radius: 50px;
					color: $gray-40;
					font-size: 12px;
					padding: 5px 10px 4px 25px;
					margin-top: 12px;
					position: relative;
					width: fit-content;
					&:before {
						background: url($icon-confirm-green) 0 / contain no-repeat;
						border-radius: 50%;
						content: '';
						position: absolute;
						left: 5px;
						top: calc(50% - 8px);
						width: 16px;
						height: 16px;
					}
				}
				div {
					margin-right: 30px;
				}
				.status-chip {
					margin-right: 55px;
					margin-top: -5px;
				}
				span[title] {
					display: block;
					position: relative;
					font-family: Proxima Nova Semibold;
					font-size: 20px;
					margin-right: 30px;
					width: 160px;
					white-space: nowrap;
					.uah {
						font-size: 19px;
					}
					&::after {
						content: attr(title);
						color: $gray-40;
						position: absolute;
						left: 0;
						top: 25px;
						white-space: nowrap;
						font-size: 12px;
						font-family: Proxima Nova;
					}
				}
			}
			.requisites-wrap {
				position: relative;
				flex-wrap: nowrap;
				flex-shrink: 0;
				.button:not(:first-child) {
					margin-left: 8px;
				}
				.bank-requisites-btn {
					div {
						position: relative;
						width: 120px;
						text-align: left;
						&::after {
							content: '';
							position: absolute;
							right: 2px;
							top: 8px;
							border: 4px solid $gray-50;
							border-color: $gray-50 transparent transparent transparent;
						}
					}
					&.expanded {
						div::after {
							transition: all 0.1s;
							transform: rotate(180deg);
							top: 4px;
						}
					}
				}
				.icon-printer {
					@include make-icon($icon-print, $white, 20px, 20px);
				}
				.bank-requisites {
					position: absolute;
					top: 50px;
					right: 0;
					background: $gray-50;
					color: $white;
					padding: 10px;
					border-radius: 4px;
					box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);
					z-index: 100;
					table tr td {
						padding: 3px 10px;
						white-space: nowrap;
					}
				}
			}
		}
	}
	@include small-desktop {
		.cashbook-info {
			.info-wrap .info span[title] {
				width: 138px;
				margin-right: 8px;
			}
		}
	}
}
</style>
