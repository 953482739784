import { core, http } from 'novapay-ui';
import { DateTime } from 'luxon';

import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';

const types = core.getTypes('refreshments');
const rootTypes = core.getTypes('root');

const { actions: commonActions } = commonStore('refreshments');

const getRefreshments = async (context) => {
	let refreshments = await http('/v3/refreshments', { query: context.state.props.query });
	if (!handleError()(refreshments, context)) {
		return;
	}
	let total = refreshments.data?.length && refreshments.data[0].total_count;
	context.commit(types.PAGE_DATA_SUCCESS, { refreshments: refreshments.data, total, query: context.state.props.query });
	commonActions.hideRootSplashScreen(context);
};

const toggleRefreshmentForm = (context, edit_entity) => {
	if (context.state.state_key.render === 'refreshment_form') {
		return context.commit(types.CLOSE_REFRESHMENT_FORM);
	}
	return context.commit(types.OPEN_REFRESHMENT_FORM, edit_entity);
};

const submitRefreshmentForm = (context, data) => {
	if (context.state.props.edit_entity) {
		return editRefreshment(context, data);
	}
	return createRefreshment(context, data);
};

const createRefreshment = async (context, data) => {
	let res = await http({ url: `/v3/refreshments`, method: 'POST', data });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}

	if (!res.data.is_created_on_time) {
		let alertMessage =
			'Заявку створено невчасно та в роботу не прийнято! ' +
			'При виникненні питань зверніться до Головного касира Філії';
		return context.commit(types.OPEN_ALERT_MODAL, alertMessage);
	}

	return context.commit(types.APPLY_QUERY, context.state.props.query);
};

const editRefreshment = async (context, data) => {
	let res = await http({ url: `/v3/refreshments/${data.id}`, method: 'PUT', data });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.APPLY_QUERY, context.state.props.query);
};

const toggleRejectModal = (context, id) => {
	if (context.state.state_key.render === 'reject_modal') {
		return context.commit(types.CLOSE_REJECT_MODAL);
	}

	return context.commit(types.OPEN_REJECT_MODAL, id);
};

const rejectRefreshment = async (context) => {
	const id = context.state.props.selectedRefreshmentId;

	let res = await http({
		url: `/v3/refreshments/${id}`,
		method: 'DELETE'
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}

	return context.commit(types.APPLY_QUERY, context.state.props.query);
};

const notAcceptRefreshment = async (context, { comment, reason }) => {
	const id = context.state.props.selectedRefreshmentId;

	let res = await http({
		url: `/v3/refreshments/${id}/not-accept`,
		method: 'POST',
		data: { comment, reason }
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}

	return context.commit(types.APPLY_QUERY, context.state.props.query);
};

const toggleAcceptModal = (context, row) => {
	if (context.state.state_key.render === 'accept_modal') {
		return context.commit(types.CLOSE_ACCEPT_MODAL);
	}

	return context.commit(types.OPEN_ACCEPT_MODAL, row);
};

const toggleConfirmAcceptModal = (context, row) => {
	if (context.state.state_key.render === 'confirm_accept_modal') {
		return context.commit(types.CLOSE_CONFIRM_ACCEPT_MODAL);
	}

	if (row.refreshment_date !== DateTime.local().toFormat('yyyy-MM-dd')) {
		let alertMessage = 'Дата заявки не збігається з поточною. Зверніться до Головного касира Філії';
		return context.commit(types.OPEN_ALERT_MODAL, alertMessage);
	}

	return context.commit(types.OPEN_CONFIRM_ACCEPT_MODAL, row);
};

const acceptRefreshment = async (context, data) => {
	const { id } = context.state.props.refreshment;

	let res = await http({
		url: `/v3/refreshments/${id}/accept`,
		method: 'POST',
		data
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}

	return context.commit(types.APPLY_QUERY, context.state.props.query);
};

const getSalepoint = async (context, salepoint_id) => {
	let res = await http('/v3/salepoints', { query: { filters: { id: [salepoint_id] } } });
	let [salepointRes] = res.data;
	if (!salepointRes) {
		return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
	}

	context.commit(types.GET_SALEPOINT_SUCCESS, salepointRes);
};

const toggleAlertModal = (context) => {
	if (context.state.state_key.render === 'alert_modal') {
		return context.commit(types.APPLY_QUERY, context.state.props.query);
	}

	return context.commit(types.OPEN_ALERT_MODAL);
};

export default {
	getRefreshments,
	toggleRefreshmentForm,
	toggleRejectModal,
	rejectRefreshment,
	notAcceptRefreshment,
	toggleConfirmAcceptModal,
	toggleAcceptModal,
	acceptRefreshment,
	getSalepoint,
	submitRefreshmentForm,
	toggleAlertModal,
	...commonActions
};
