import { core, http } from 'novapay-ui';
import handleError from '@services/handle-api-error';

const types = core.getTypes('control-center');

const togglePosDetailsModal = (context, pos) => {
	if (context.state.state_key?.render === 'pos_details_modal') {
		return context.commit(types.CLOSE_POS_DETAILS_MODAL);
	}
	return context.commit(types.OPEN_POS_DETAILS_MODAL, pos);
};

const getLastReceipt = async (context) => {
	let { code } = context.state.props.posDetails;
	return handleError()(await http({ url: `/v3/pos-terminals/last-receipt`, query: { code } }), context);
};

const getPosTransactions = async (context, query) => {
	let { code } = context.state.props.posDetails;
	context.commit('SET_LOADING_ACTION', { action: 'getPosTransactions' });
	setTimeout(() => {
		context.commit('RESET_LOADING_ACTION', { action: 'getPosTransactions' });
	}, 90000);
	return handleError()(await http({ url: `/v3/pos-terminals/transactions`, query: { code, ...query } }), context);
};

const refreshPosInfo = async (context, { code }) => {
	return handleError()(await http({ url: `/v3/pos-terminals/refresh-info`, query: { code } }), context);
};

export default {
	refreshPosInfo,
	togglePosDetailsModal,
	getLastReceipt,
	getPosTransactions
};
