<template>
	<NP-Modal class="awis-barcode" label="Штрихкод для оплати ЕН" :toggle="toggle">
		<form @submit.prevent="submitBarcode">
			<div class="content">
				<div v-if="awisStatus" :class="['awis-status', awisStatus.level]">
					<span class="icon" />
					<span class="message">{{ awisStatus.message }}</span>
				</div>
				<div class="title">Відскануй штрихкод, що надав клієнт</div>
				<div class="awis-barcode-input">
					<div class="barcode-icon" />
					<NP-Input
						ref="barcode"
						label="Номер штрих-коду"
						v-model="barcode"
						class="awis-barcode-input"
						:mask="{ mask: /^[0-9\-]+$/ }"
						autofocus
					/>
				</div>
			</div>
			<div class="buttons">
				<NP-Button type="button" class="cancel-btn" priority="low-borderless" @click.stop="toggle">
					<i slot="icon" class="icon-close" />
					Скасувати
				</NP-Button>
				<NP-Button
					type="submit"
					priority="high"
					:disabled="!barcode"
					:loading="loadingActions.includes('getOperationByAwisBarcode')"
				>
					Продовжити
				</NP-Button>
			</div>
		</form>
	</NP-Modal>
</template>

<script>
export default {
	name: 'AwisBarcodeModal',
	props: {
		toggle: { type: Function, default: null },
		data: { type: Object, default: null },
		getOperation: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] }
	},
	data() {
		return {
			barcode: null
		};
	},
	mounted() {
		if (this.$refs.barcode) {
			this.$refs.barcode.$refs.input.focus();
		}
	},
	computed: {
		awisStatus() {
			if (this.data && this.data.error) {
				return {
					level: 'error',
					message: 'Штрих-код недійсний. Спробуй ще раз'
				};
			}
			return null;
		}
	},
	methods: {
		async submitBarcode() {
			await this.getOperation({
				barcode: this.barcode.replace(/\s+/g, ''),
				loadingAction: true
			});
			this.barcode = null;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-barcode: '~@assets/images/icon-barcode.svg';
$icon-close: '~@assets/images/icon-close.svg';
$icon-error: '~@assets/images/icon-error.svg';
$icon-confirm: '~@assets/images/icon-confirm.svg';
$icon-info: '~@assets/images/icon-info.svg';

.awis-barcode {
	::v-deep .modal-body {
		width: 414px;
		pointer-events: auto;
		.content {
			margin-bottom: 32px;

			.awis-status {
				padding: 12px 10px;
				border-radius: 4px;
				margin-bottom: 24px;
				display: flex;
				align-items: center;

				.icon {
					margin-right: 12px;
				}
				.message {
					font-size: 12px;
				}

				&.error {
					background-color: $light-red;
					color: $red;
					.icon {
						@include make-icon($icon-error, $red, 20px, 20px);
					}
				}
			}
			.title {
				color: $gray-40;
				font-size: 14px;
				margin-bottom: 24px;
			}
			.awis-barcode-input {
				position: relative;
				.barcode-icon {
					@include make-icon($icon-barcode, $gray-30, 20px, 20px);
					position: absolute;
					top: 2px;
					left: 2px;
					z-index: 1;
					margin: 8px;
				}
				.awis-barcode-input {
					min-width: 366px;
					input {
						padding-left: 40px;
					}
				}
			}
		}
		.buttons {
			display: flex;
			justify-content: space-between;
			.cancel-btn {
				margin-right: 24px;
				.icon-close {
					@include make-icon($icon-close, $gray-40, 20px, 20px);
				}
			}
		}
	}
}
</style>
