const types = {
	operation: 'operation',
	credit: 'credit',
	mobileApp: 'mobile_app'
};

const locale = {
	[types.operation]: 'операція',
	[types.credit]: 'кредит',
	[types.mobileApp]: 'mobile_app'
};

module.exports = {
	enum: types,
	locale: locale
};
