<template>
	<NP-Modal label="Введіть пароль до ключа" class="gryada-login" :toggle="toggle">
		<form @submit.prevent="() => gryadaLogin({ password, loadingAction: true })" @keydown.stop>
			<div class="info">
				Введіть пароль до особистого ключа<br />
				та спробуйте знову
			</div>
			<NP-Input v-model="password" label="Пароль" type="password" :error="formErrors.password" :mask="mask" />
			<NP-Button type="submit" priority="high" :loading="loadingActions.includes('gryadaLogin')"> Зберегти </NP-Button>
		</form>
	</NP-Modal>
</template>

<script>
import { validation } from 'novapay-ui';

export default {
	name: 'GryadaLogin',
	props: {
		toggle: { type: Function, default: null },
		gryadaLogin: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] }
	},
	data() {
		return {
			password: '',
			mask: { mask: /^[^ʼ]+$/ }
		};
	},
	computed: {
		formErrors() {
			return validation.computeValidationMessages(this.errors, ['password']);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';

.gryada-login {
	::v-deep .modal-container {
		width: 400px;
		.info {
			color: $gray-50;
			text-align: center;
			margin-bottom: 16px;
			font-size: 14px;
			line-height: 17px;
		}
		.input {
			width: 250px;
			margin: 0 auto 16px auto;
		}
		.button {
			display: block;
			margin: 0 auto;
		}
	}
}
</style>
