<template>
	<form action="" @submit.prevent="submitForm">
		<p class="info-block">
			Щоб тобі було зручно користуватися сервісами NovaPay необхідно актуалізувати твій номер телефону. Раз на квартал
			ми будемо просити тебе оновлювати інформацію.
		</p>
		<NP-Input label="Телефон" v-model="phone_number" :mask="inputMasks.phone" :error="fieldErrors.phone_number" />
		<div v-if="!otpSent" class="buttons">
			<NP-Button type="submit" priority="medium" :loading="isSendOtpCodeBtnLoading"> Підтвердити номер </NP-Button>
		</div>
		<div v-else>
			<div class="container">
				<h3>ОТП пароль</h3>
				<NP-Input v-model="otp" key="otp-input" placeholder="****" :mask="{ mask: '0000', lazy: true }" />
			</div>
			<div class="buttons">
				<NP-Button type="submit" priority="medium" :loading="isApproveBtnLoading" :disabled="!otp">
					Підтвердити
				</NP-Button>
			</div>
			<button @click.prevent="sendOtp" class="resend-otp-btn" :disabled="countdown">
				<span class="icon-repeat" />
				Відправити повторно
				<span v-if="countdown" class="timer">({{ countdown }})</span>
			</button>
		</div>
	</form>
</template>

<script>
import inputMasks from '@services/input-masks';
import { DateTime, Duration } from 'luxon';
import { validation } from 'novapay-ui';
import { mapActions } from 'vuex';

export default {
	name: 'ApprovePhoneNumber',
	props: {
		root: { type: Object, default: null }
	},
	data() {
		return {
			inputMasks,
			localErrors: [],
			phone_number: this.root.phoneToApprove || this.root.user.approved_phone || this.root.user.phone_number,
			otp: '',
			end: null,
			now: null,
			timer: null
		};
	},
	computed: {
		otpSent() {
			return !!this.root.approveOTPSentAt;
		},
		isApproveBtnLoading() {
			return (this.root.loadingActions || []).includes('approveUserPhoneNumber');
		},
		isSendOtpCodeBtnLoading() {
			return (this.root.loadingActions || []).includes('sendApprovePhoneOtpCode');
		},
		fieldErrors() {
			return validation.computeValidationMessages([...this.localErrors], ['phone_number']);
		},
		countdown() {
			if (!this.end) {
				return;
			}
			return Duration.fromObject(this.end.diff(this.now).toObject()).toFormat('hh:mm:ss');
		},
		finished() {
			if (!this.end) {
				return true;
			}
			return this.now >= this.end;
		}
	},
	methods: {
		...mapActions({
			sendApprovePhoneOtpCode: 'root/sendApprovePhoneOtpCode',
			approveUserPhoneNumber: 'root/approveUserPhoneNumber'
		}),
		submitForm() {
			return this.otpSent ? this.approve() : this.sendOtp();
		},
		async sendOtp() {
			let phoneToApprove = this.phone_number.replace(/[()\s_]/g, '');
			if (phoneToApprove && phoneToApprove.length !== 13) {
				return this.localErrors.push({ dataPath: 'phone_number', message: 'Невалідний номер' });
			}
			this.localErrors = [];

			await this.sendApprovePhoneOtpCode({
				phoneToApprove,
				loadingAction: true
			});
			this.startTimer();
		},
		approve() {
			let phone = this.phone_number.replace(/[()\s_]/g, '');
			if (phone && phone.length !== 13) {
				return this.localErrors.push({ dataPath: 'phone_number', message: 'Невалідний номер' });
			}
			this.localErrors = [];
			return this.approveUserPhoneNumber({ otp: this.otp, phone, loadingAction: true });
		},
		startTimer() {
			let date = this.root.approveOTPSentAt;
			if (!date) {
				return;
			}
			this.now = DateTime.local().set({ milliseconds: 0 });
			this.end = DateTime.fromISO(date).plus({ seconds: 60 });
			this.timer = setInterval(() => {
				this.now = DateTime.local().set({ milliseconds: 0 });
				if (this.now >= this.end) {
					this.now = null;
					this.end = null;
					clearInterval(this.timer);
				}
			}, 1000);
		}
	},
	mounted() {
		this.startTimer();
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-repeat: '~@assets/images/icon-refresh.svg';

h3 {
	margin-bottom: 16px;
}

form {
	margin-top: 16px;
	.info-block {
		margin-bottom: 24px;
		font-size: 14px;
		line-height: 18px;
	}
}

::v-deep .input {
	margin-bottom: 16px;
	label {
		color: $gray-50;
	}
	&.disabled {
		input,
		label {
			color: $gray-50;
		}
	}
}

.resend-otp-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: none;
	background: none;
	color: $red;
	white-space: nowrap;
	font-size: 14px;
	line-height: 17px;
	margin-top: 24px;
	margin-left: auto;
	.icon-repeat {
		margin-right: 10px;
		@include make-icon($icon-repeat, $red, 14px, 14px);
	}
	.timer {
		font-size: 14px;
		line-height: 17px;
		margin-left: 4px;
	}
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		cursor: default;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 16px;
}
</style>
