const statuses = {
	precreated: 'precreated',
	new: 'new',
	waitingForCertificates: 'waiting_for_certificates',
	processed: 'processed',
	rejected: 'rejected',
	needsCorrection: 'needs_correction',
	expiring: 'expiring'
};

const locale = {
	[statuses.precreated]: 'попередньо створений',
	[statuses.new]: 'новий',
	[statuses.waitingForCertificates]: 'очікування сертифікатів',
	[statuses.processed]: 'оброблений',
	[statuses.rejected]: 'вiдмiнений',
	[statuses.needsCorrection]: 'необхідне виправлення',
	[statuses.expiring]: 'закінчується'
};

module.exports = {
	enum: statuses,
	locale: locale
};
