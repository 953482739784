import { core, http } from 'novapay-ui';
import b64toBlob from '@services/transform-to-blob';
import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';

const { actions: commonStoreActions } = commonStore('naftogaz_applications');
const rootTypes = core.getTypes('root');
const types = core.getTypes('naftogaz_applications');

const getNaftogazApplications = async (context) => {
	let res = await http('/v3/naftogaz/', { query: context.state.props.query });
	if (!handleError()(res, context)) {
		return;
	}
	let total = res.data?.[0]?.total_count;
	commonStoreActions.hideRootSplashScreen(context);
	return context.commit(types.APPLICATIONS_SUCCESS, { applications: res.data, total });
};

const toggleApplicationModal = (context, data) => {
	let type =
		context.state.state_key === 'application_preview_modal'
			? types.CLOSE_APPLICATION_PREVIEW_MODAL
			: types.OPEN_APPLICATION_PREVIEW_MODAL;
	context.commit(type, data);
};

const printApplication = async (context, data) => {
	let res = await http('/v3/naftogaz/print-application-template', { method: 'POST', data });
	if (!handleError()(res, context)) {
		return;
	}
	return window.open(URL.createObjectURL(b64toBlob(res.data, 'application/pdf')));
};

const uploadApplicationImages = async (context, data) => {
	let formData = new FormData();
	formData.append('external_id', data.external_id);
	data.files.forEach((file) => {
		formData.append('application_for_change_images', file);
	});
	let res = await http('/v3/naftogaz/upload-application-images', { method: 'POST', data: formData });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.APPLY_QUERY, context.state.props.query);
	context.commit(
		rootTypes.namespaced.ADD_SNACKBAR,
		{ variant: 'success', title: 'Заявка успішно відправлена' },
		{ root: true }
	);
};

export default {
	...commonStoreActions,
	getNaftogazApplications,
	toggleApplicationModal,
	uploadApplicationImages,
	printApplication
};
