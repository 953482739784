import { core } from 'novapay-ui';

const types = core.createTypes('orders');

export default {
	[types.OPEN_ORDER_SIDEBAR]: (state, edit_entity) => {
		state.props = { ...state.props, edit_entity };
	},
	[types.CLOSE_ORDER_SIDEBAR]: (state) => {
		state.props = { ...state.props, orderFormErrors: null };
	},
	[types.ORDER_FORM_ERRORS]: (state, orderFormErrors) => {
		state.props = { ...state.props, orderFormErrors };
	},
	[types.RESET_ERRORS]: (state) => {
		state.props = { ...state.props, orderFormErrors: null };
	}
};
