import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const { mutations } = commonStore('naftogaz_applications');
const types = core.createTypes('naftogaz_applications');

export default {
	...mutations,
	[types.APPLICATIONS_SUCCESS]: (state, { applications, total }) => {
		state.props = { ...state.props, applications, total };
	},
	[types.OPEN_APPLICATION_PREVIEW_MODAL]: (state, selectedApplication) => {
		state.props = { ...state.props, selectedApplication };
	},
	[types.CLOSE_APPLICATION_PREVIEW_MODAL]: (state) => {
		state.props = { ...state.props, selectedApplication: null };
	}
};
