import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('documents');

export default core.createStore(actions, {}, mutations, {
	key: 'printed_documents',
	strict: true,
	initial: 'render',
	states: {
		idle: {
			entry: 'getPrintedDocuments',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.DOCUMENTS_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.VERIFY]: 'verifying',
						[types.FINALIZE]: 'finalizing'
					}
				},
				verifying: {
					on: {
						[types.VERIFY_SUCCESS]: 'main',
						[types.VERIFY_ERROR]: 'main'
					}
				},
				finalizing: {
					on: {
						[types.FINALIZE_ERROR]: 'main',
						[types.FINALIZE_SUCCESS]: 'main'
					}
				}
			}
		}
	}
});
