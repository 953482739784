import { core, http, lookup } from 'novapay-ui';
import print from '@services/print';

import handleError from '@services/handle-api-error';

import { enum as orderSubTypes } from '@repo/enums/cashbook-order-sub-types';

const types = core.createTypes('cashbook');

const createOrderSidebar = async (context, payload) => {
	let { type, ...data } = payload;
	if (!type) {
		return;
	}
	let method;
	switch (type) {
		case orderSubTypes.cashToCashdesk:
			method = () => http('/v3/orders/cash-to-cashdesk', { data, method: 'PUT' });
			break;
		case orderSubTypes.cashToBank:
			method = () => http('/v3/orders/cash-to-bank', { data, method: 'PUT' });
			break;
		case orderSubTypes.cashToAnotherSalepoint:
		case orderSubTypes.cashToTransitionalSalepoint:
			method = () =>
				http('/v3/orders/cash-to-another-salepoint', { data: { ...data, order_sub_type: type }, method: 'PUT' });
			break;
		case orderSubTypes.cashFromAnotherSalepoint:
		case orderSubTypes.cashFromTransitionalSalepoint:
			method = () =>
				http('/v3/orders/cash-from-another-salepoint', { data: { ...data, order_sub_type: type }, method: 'PUT' });
			break;
		case orderSubTypes.cashToCentral:
			method = () => http('/v3/orders/cash-to-central', { data, method: 'PUT' });
			break;
		case orderSubTypes.cashFromCentral:
			method = () => http('/v3/orders/cash-from-central', { data, method: 'PUT' });
			break;
		case orderSubTypes.cashFromSelfServiceTerminal:
			method = () => http('/v3/orders/cash-from-self-service-terminal', { data, method: 'PUT' });
			break;
		case orderSubTypes.encashmentByCashier:
			method = () => http('/v3/orders/encashment-by-cashier', { data, method: 'PUT' });
			break;
		default:
			return;
	}
	context.commit(types.RESET_ERRORS);
	let res = await method();
	if (!handleError()(res, context, 200, types.ORDER_FORM_ERRORS)) {
		return;
	}
	let { order } = res.data;
	if (
		[
			orderSubTypes.encashmentByCashier,
			orderSubTypes.cashToBank,
			orderSubTypes.cashToAnotherSalepoint,
			orderSubTypes.cashToTransitionalSalepoint,
			orderSubTypes.cashToCentral
		].includes(type)
	) {
		let route = null;
		switch (type) {
			case orderSubTypes.encashmentByCashier:
				route = 'print-encashment-by-cashier-cover-sheet';
				break;
			case orderSubTypes.cashToAnotherSalepoint:
			case orderSubTypes.cashToTransitionalSalepoint:
				route = 'print-cash-to-another-salepoint-cover-sheet';
				break;
			case orderSubTypes.cashToCentral:
				route = 'print-cash-to-central-cover-sheet';
				break;
			default:
				route = 'print-cover-sheet';
		}
		let res = await http(`/v3/orders/${route}/${order.id}`);
		if (handleError()(res, context)) {
			await print(res.data);
		}
	}
	context.commit(types.CLOSE_ORDER_SIDEBAR);
};

const editOrderSidebar = async (context, payload) => {
	let { type, id, ...data } = payload;
	if (!type || !id) {
		throw new Error('cashbook type or order id is missing in edit entity');
	}
	let method;
	switch (type) {
		case orderSubTypes.cashToBank:
			method = () => http(`/v3/orders/edit/cash-to-bank/${id}`, { data, method: 'POST' });
			break;
		default:
			return;
	}
	context.commit(types.RESET_ERRORS);
	let res = await method();
	if (!handleError()(res, context, 200, types.ORDER_FORM_ERRORS)) {
		return;
	}
	context.commit(types.CLOSE_ORDER_SIDEBAR);
};

const lookupSalepoints = (context, { query, cb }) => {
	return lookup('/v3/lookups/salepoints/code', { query: { query } }).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const lookupUsers = (context, { query, cb }) => {
	return lookup(`/v3/lookups/users/name?query=${query || ''}`).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const lookupSalepontsUsers = (context, { query, cb }) => {
	return lookup(`/v3/lookups/salepoints/users/name?query=${query || ''}`).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const listPendingCashFromAnotherSalepoint = (context, { cb }) => {
	return http(`/v3/orders/pending-cash-from-another-salepoint`).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const listPendingCashFromTransitionalSalepoint = (context, { cb }) => {
	return http(`/v3/orders/pending-cash-from-transitional-salepoint`).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const lookupSelfServiceTerminals = (context, { query, cb }) => {
	return lookup(`/v3/lookups/self-service-terminals?query=${query || ''}`).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		cb(res);
	});
};

const cleanOrderErrors = (context) => {
	context.commit(types.RESET_ERRORS);
};

export default {
	createOrderSidebar,
	cleanOrderErrors,
	editOrderSidebar,
	lookupSalepoints,
	lookupUsers,
	lookupSalepontsUsers,
	lookupSelfServiceTerminals,
	listPendingCashFromAnotherSalepoint,
	listPendingCashFromTransitionalSalepoint
};
