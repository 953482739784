import { core } from 'novapay-ui';
import actions from '../store/actions';
import mutations from '../store/mutations';

const types = core.createTypes('workplaces');

export default core.createStore(actions, {}, mutations, {
	key: 'workplaces',
	initial: 'idle',
	states: {
		idle: {
			entry: 'getWorkplaces',
			on: {
				[types.GET_WORKPLACES_SUCCESS]: 'render',
				[types.APPLY_QUERY]: 'idle'
			}
		},
		render: {
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.OPEN_WORKPLACE_MODAL]: 'workplace_modal'
			}
		},
		workplace_modal: {
			on: {
				[types.CLOSE_WORKPLACE_MODAL]: 'render',
				[types.APPLY_QUERY]: 'idle'
			}
		}
	}
});
