import { Duration } from 'luxon';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import { core, http } from 'novapay-ui';
import handleError from '@services/handle-api-error';

const createActions = (types, rootTypes) => ({
	sendOtpCode: async (context, { phone }) => {
		if (context.state.props.otp_interval_id) {
			return;
		}
		let res = await http(`/v3/otp/send`, { method: 'POST', data: { phone } });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.OTP_SEND_SUCCESS);
		let otp_interval_id = setInterval(() => {
			let { isOtpVerified, otp_countdown, otp_interval_id } = context.state.props;
			if (isOtpVerified || otp_countdown?.seconds === 1) {
				clearInterval(otp_interval_id);
				context.commit(types.SET_OTP_INTERVAL, null);
			}
			return context.commit(types.REDUCE_OTP_TIMER);
		}, 1000);
		context.commit(types.SET_OTP_INTERVAL, otp_interval_id);
	},
	verifyOtpCode: async (context, { otp, phone }) => {
		let res = await http('/v3/otp/check', { method: 'POST', data: { otp, phone } });
		if (!handleError()(res, context)) {
			return;
		}
		context.commit(types.VERIFY_OTP_SUCCESS);
	},
	checkClientInBlackList: async (context, client) => {
		let res = await http({
			url: '/v3/operations/check-client-in-payment-system',
			method: 'POST',
			data: client
		});
		context.commit(types.SET_CLIENT_IN_BLACK_LIST, res.status === 400);
	},
	createClaim: async (context, data) => {
		if (!data.scans?.length) {
			return;
		}
		let newlySelectedScans = data.scans.filter((f) => f instanceof File);
		let alreadyExistingScansNames = data.scans
			.filter((f) => f.id)
			.map((f) => ({ object_name: f.storage_object_name, bucket_name: f.storage_bucket_name }));

		let payload = {
			...data,
			scans: alreadyExistingScansNames,
			files: newlySelectedScans
		};
		let formData = new FormData();
		forEach(payload, (value, key) => {
			if (!value) {
				return;
			}
			if (key === 'files') {
				value.forEach((file) => {
					formData.append(key, file);
				});
			} else {
				formData.append(key, JSON.stringify(value));
			}
		});
		let res = await http('/v3/claims/create-claim', { method: 'POST', data: formData });
		if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
			let fileError = find(res.data.errors || [], (e) => e.dataPath.includes('files'));
			if (fileError) {
				context.commit(
					rootTypes.ERROR_ALERT,
					{
						text: 'Помилка завантаження файлiв: ' + fileError.message
					},
					{ root: true }
				);
			}
			return;
		}
		context.commit(types.IDLE);
		context.commit(
			rootTypes.ADD_SNACKBAR,
			{
				title: 'Заявка успішно створена',
				variant: 'success'
			},
			{ root: true }
		);
	}
});

const createMutations = (types) => ({
	[types.OTP_SEND_SUCCESS]: (state) => {
		state.props = {
			...state.props,
			otp_countdown: Duration.fromObject({ seconds: 59 })
		};
	},
	[types.REDUCE_OTP_TIMER]: (state) => {
		state.props = {
			...state.props,
			otp_countdown: state.props.otp_countdown?.minus({ seconds: 1 })
		};
	},
	[types.VERIFY_OTP_SUCCESS]: (state) => {
		state.props = {
			...state.props,
			otp_countdown: null,
			isOtpVerified: true
		};
	},
	[types.SET_OTP_INTERVAL]: (state, otp_interval_id) => {
		state.props = { ...state.props, otp_interval_id };
	},
	[types.SET_CLIENT_IN_BLACK_LIST]: (state, clientInBlackList) => {
		state.props = { ...state.props, clientInBlackList };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
