const types = {
	physical: 'physical',
	posAval: 'pos_aval',
	posOschad: 'pos_oschad',
	posNovapay: 'pos_novapay',
	diia: 'diia',
	mobileCashdesk: 'mobile_cashdesk'
};

const locale = {
	[types.physical]: 'оригінал',
	[types.posAval]: 'пос термінал Аваль',
	[types.posOschad]: 'пос термінал Ощад',
	[types.posNovapay]: 'пос термінал Новапей',
	[types.diia]: 'дія',
	[types.mobileCashdesk]: 'мобiльний'
};

module.exports = {
	enum: types,
	locale: locale
};
