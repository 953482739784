import { core } from 'novapay-ui';

import cashbookControlsMutations from '../components/cashbook/store/mutations';
import openedCashdeskMutations from '../components/opened-cashdesks/store/mutations';
import cashdeskMutations from '../components/cashdesk/store/mutations';
import posTerminalMutations from '../components/pos-terminals/store/mutations';

const types = core.createTypes('control_center');

export default {
	[types.CONTROL_CENTER_DATA_SUCCESS]: (state, data) => {
		state.props = {
			...state.props
		};
	},
	[types.SET_COLLAPSED_COMPONENTS]: (state, collapsedComponents) => {
		state.props = { ...state.props, collapsedComponents };
	},
	[types.UPDATE_LAST_RECEIPT]: (state, lastReceiptDetails) => {
		state.props = { ...state.props, lastReceiptDetails };
	},
	[types.UPDATE_POS_TRANSACTIONS]: (state, posTransactionsDetails) => {
		state.props = { ...state.props, posTransactionsDetails };
	},
	[types.VALIDATION_ERRORS]: (state, errors) => {
		state.props = {
			...state.props,
			validation_errors: errors
		};
	},
	[types.OPEN_ALERT_MODAL]: (state, alertMessage) => {
		state.props = {
			...state.props,
			alertMessage
		};
	},
	[types.CLOSE_ALERT_MODAL]: (state) => {
		state.props = {
			...state.props,
			alertMessage: null
		};
	},
	[types.TRANSFER_WORKPLACE_DATA]: (state, data) => {
		state.props = {
			...state.props,
			transfer_workplace: data
		};
	},
	[types.OPEN_TRANSFER_WORKPLACE_MODAL]: (state, data) => {
		state.props = {
			...state.props,
			transfer_workplace: null
		};
	},
	...cashbookControlsMutations,
	...openedCashdeskMutations,
	...cashdeskMutations,
	...posTerminalMutations
};
