const types = {
	main: 'main',
	receipt: 'receipt',
	outgoingCoverSheet: 'outgoing_cover_sheet',
	cashbookReport: 'cashbook_report',
	cashbookSupplement: 'cashbook_supplement'
};

const locale = {
	[types.main]: 'основна сторiнка',
	[types.receipt]: 'квитанцiя',
	[types.outgoingCoverSheet]: 'cупровідний касовий ордер',
	[types.cashbookReport]: 'звіт касира',
	[types.cashbookSupplement]: 'вкладний аркуш касової книги'
};

module.exports = {
	enum: types,
	locale: locale
};
