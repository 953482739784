<template>
	<div>
		<NP-Modal :toggle="toggle" :class="{ 'support-request': true, mobile: isMobileDevice }" variant="sidebar">
			<div class="support-request__header">
				<p>Створити заявку</p>
				<div slot="icon" class="icon-close-service-desk" @click.stop="toggle" />
			</div>
			<div class="scroll-container">
				<div>
					<NP-Select
						id="request-type"
						class="request-type-select"
						v-model="requestType"
						@input="clear"
						:options="
							[
								root.user.eds_request ? { id: 'eds_request', text: 'Заява на КЕП' } : null,
								needToApprovePhoneNumber ? { id: 'phone_number', text: 'Актуалізація телефону' } : null
							].filter(Boolean)
						"
					/>
				</div>
				<ApprovePhoneNumber v-if="requestType.id === 'phone_number'" :root="root"></ApprovePhoneNumber>
				<template v-else>
					<form action="" @submit.prevent="submit" @keydown.stop>
						<div v-if="requestType.id === 'eds_request' && root.user.eds_request" class="eds-request">
							<div
								v-if="[edsRequestStatuses.new].includes(root.user.eds_request.status)"
								class="eds-request-status new"
							>
								Твоя заява на КЕП відправлена та знаходиться на стадії перевірки документів
							</div>
							<div
								v-if="[edsRequestStatuses.waitingForCertificates].includes(root.user.eds_request.status)"
								class="eds-request-status checked"
							>
								Твоя заява опрацьована та очікується випуск КЕП
							</div>
							<div
								v-if="root.user.eds_request.status === edsRequestStatuses.processed"
								class="eds-request-status processed"
							>
								Твій КЕП випущено. Використовуй пароль до КЕП для підпису документів
							</div>
							<div
								v-if="root.user.eds_request.status === edsRequestStatuses.needsCorrection"
								class="eds-request-status rejected"
							>
								Твоя заява опрацьована, необхідно виправлення: {{ root.user.eds_request.reason }}
							</div>
							<div
								v-if="
									[edsRequestStatuses.precreated, edsRequestStatuses.needsCorrection].includes(
										root.user.eds_request.status
									)
								"
								class="precreated"
							>
								<a class="application-form" href="/v3/session/load-certificate-application-form" target="_blank">
									Заява про реєстрацію КЕП.pdf
									<span class="icon-download" />
								</a>
								<span v-if="root.user.eds_request.expiring_eds_date" class="info-text">
									Поточний КЕП дійсний до
									<span class="info-text red">{{ getExpirationDate(root.user.eds_request.expiring_eds_date) }}</span>
								</span>
								<div class="instructions">
									<h5>Інструкція</h5>
									<ol>
										<li>Роздрукуй заяву про реєстрацію КЕП.</li>
										<li>
											Ознайомся з Інструкцією щодо генерації КЕП на
											<a class="instruction-link" target="_blank" href="https://wiki.forpost.space/uk/VND_PNFP/KEP">
												https://wiki.forpost.space/uk/VND_PNFP/KEP
											</a>
										</li>
										<li>Звір заяву згідно з інструкцією та підпиши.</li>
										<li>Відскануй завірені заяву i документи та завантаж нижче.</li>
									</ol>
									<NP-Tabs class="upload-tabs" :tabs="uploadTabs" @select="(tab) => (activeTab = tab.title)" />
									<template v-if="activeTab === 'Паспорт'">
										<span class="info-text">
											Паспорт у формі книжечки: 1-2 сторінка (3-6 за наявності відміток) ID-картка: лицьова та зворотня
											сторона
										</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="(f) => handleFileUpload(f.target.files[0], edsFiles['passport'], 'passportFileInput')"
											ref="passportFileInput"
										/>
										<NP-ImagesList :images="passportImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
									<template v-if="activeTab === 'ІПН'">
										<span class="info-text">
											Окремий аркуш ІПН/зворотня сторона ID-картки або відмітка про відсутність ІПН<br />
											<span class="info-text red">Впевніться у читабельності скан-копії</span>
										</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="
												(f) => handleFileUpload(f.target.files[0], edsFiles['taxpayer_id'], 'taxpayerIdFileInput')
											"
											ref="taxpayerIdFileInput"
										/>
										<NP-ImagesList :images="taxpayerIdImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
									<template v-if="activeTab === 'Заява'">
										<span class="info-text">Заява про реєстрацію</span>
										<NP-Input
											class="eds-file-input"
											type="file"
											:accept="acceptEDSFiles"
											@input="(f) => handleFileUpload(f.target.files[0], edsFiles['request'], 'requestFileInput')"
											ref="requestFileInput"
										/>
										<NP-ImagesList :images="requestImagesListData" :no-images-text="'Скани відстутні'" />
									</template>
								</div>
							</div>
						</div>
						<div v-else-if="requestType.id === 'service_desk'" class="service-desk">
							<div class="service-desk__body">
								<div class="row">
									<NP-Input v-model="formData.salepoint_code" label="Номер ПНФП" type="text" disabled />
									<NP-Input v-model="formData.login" label="Логін касира" type="text" disabled />
								</div>
								<NP-Input
									v-model="formData.phone_number"
									type="phone"
									label="Телефон"
									:error="fieldErrors['phone_number']"
								/>
								<NP-Input
									v-model="formData.error_time"
									label="Час виникнення проблеми"
									type="date"
									date-format="DD.MM.YYYY HH:mm:ss"
									:error="localErrors.error_time || fieldErrors.error_time"
								/>
								<NP-Input
									type="text"
									label="Номер ЕН/ПЧ"
									v-model="formData.express_waybill"
									placeholder="5900000000000"
									:error="fieldErrors['express_waybill']"
								/>
								<NP-Input
									type="text"
									v-model="formData.ip_address"
									label="IP адреса комп'ютера"
									placeholder="123.123.123.123"
									:error="fieldErrors.ip_address"
								/>
								<div class="watcher-container" v-for="watcher in formData.watchers" :key="watcher.id">
									<NP-Select
										:id="'picked-watcher-' + watcher.id"
										label="Спостерігач"
										:options="[watcher]"
										:value="watcher"
										:do-empty-search-on-init="false"
										disabled
									/>
									<NP-Button
										class="gray"
										type="button"
										priority="low-borderless"
										@click="() => removeWatcher(watcher.id)"
									>
										<div slot="icon" class="icon-remove" />
									</NP-Button>
								</div>
								<div class="watcher-container">
									<NP-Select
										id="new-watcher"
										label="Спостерігач"
										v-model="watcherToAdd"
										searchable
										:options="usersList"
										:async-options="(query) => lookupUsers({ query, setUserOptions: (res) => (usersList = res) })"
										:do-empty-search-on-init="true"
										:error="localErrors.watchers || fieldErrors.watchers"
										allow-empty
									/>
									<NP-Button class="red" type="button" priority="low-borderless" @click="addWatcher">
										<div slot="icon" class="icon-plus" />
									</NP-Button>
								</div>
								<NP-Textarea
									type="text"
									variant="gray-50"
									class="text-area-input"
									v-model="formData.problem_description"
									label="Опис проблеми"
									maxlength="1500"
									:resizable="false"
									:error="fieldErrors['problem_description']"
								/>
								<NP-Input
									type="file"
									label="Додати скріншот або документ"
									:accept="acceptFiles"
									@input="(f) => handleFileUpload(f.target.files[0], files, 'fileInput')"
									ref="fileInput"
								/>
								<NP-ImagesList :images="imagesListData" :no-images-text="'Скани відстутні'" />
								<div class="work-available-container">
									<p>Проблема блокує роботу <span>всього відділення ?</span></p>
									<NP-Switch v-model="formData.blocks_salepoint" />
								</div>
							</div>
						</div>
						<div class="support-request__footer">
							<NP-Button type="button" priority="low-borderless" @click="toggle">
								<div slot="icon" class="icon-close dark" />
								Скасувати
							</NP-Button>
							<NP-Button
								type="submit"
								priority="medium"
								:disabled="requestType.id === 'eds_request' && missingEdsScans"
								:loading="loadingActions.includes('createSDReport') || loadingActions.includes('submitEdsRequestScans')"
							>
								<span> Створити </span>
							</NP-Button>
						</div>
					</form>
				</template>
			</div>
		</NP-Modal>
		<NP-LightBox :lightbox-scan="lightboxScan" :toggle-lightbox="toggleLightbox" />
	</div>
</template>

<script>
import inputMasks from '@services/input-masks';
import { validation } from 'novapay-ui';
import { DateTime } from 'luxon';

import { enum as edsRequestStatuses } from '@repo/enums/electronic-digital-signature-requests-statuses';

import ApprovePhoneNumber from './approve-phone-number.vue';
import isMobileDevice from '@services/is-mobile-device';

export default {
	name: 'SupportRequest',
	components: {
		ApprovePhoneNumber
	},
	props: {
		root: { type: Object, default: null },
		toggle: { type: Function, default: null },
		lookupUsers: { type: Function, default: null },
		createSDReport: { type: Function, default: null },
		submitEdsRequestScans: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] }
	},
	data() {
		return {
			usersList: [],
			watcherToAdd: null,
			errorTimeFormat: 'dd.MM.yyyy HH:mm:ss',
			formData: this.getInitialSDFormData(),
			files: [],
			edsFiles: { passport: [], taxpayer_id: [], request: [] },
			maxSizeMB: 10,
			acceptEDSFiles: 'image/*,.pdf',
			acceptFiles:
				'image/*,.pdf,.doc,.docx,application/msword,application/vnd' +
				'.openxmlformats-officedocument.wordprocessingml.document',
			localErrors: {},
			inputMasks,
			edsRequestStatuses,
			requestType: null,
			activeTab: 'Паспорт',
			lightboxScan: null,
			viewport: {
				width: null,
				height: null
			}
		};
	},
	created() {
		if (
			this.root.user.eds_request &&
			[edsRequestStatuses.precreated, edsRequestStatuses.needsCorrection].includes(this.root.user.eds_request.status)
		) {
			this.requestType = { id: 'eds_request', text: 'Заява на КЕП' };
		} else if (this.needToApprovePhoneNumber) {
			this.requestType = { id: 'phone_number', text: 'Актуалізація телефону' };
		} else {
			this.requestType = { id: 'eds_request', text: 'Заява на КЕП' };
		}
	},
	computed: {
		uploadTabs() {
			return [
				{ title: 'Паспорт', active: this.activeTab === 'Паспорт' },
				{ title: 'ІПН', active: this.activeTab === 'ІПН' },
				{ title: 'Заява', active: this.activeTab === 'Заява' }
			];
		},
		fieldErrors() {
			let errs = this.errors.filter(Boolean).map((err) => {
				if (err.dataPath.includes('ip_address')) {
					return { ...err, message: 'не відповідає формату' };
				}
				if (err.dataPath.includes('phone_number')) {
					return { ...err, message: 'не валідний' };
				}
				return err;
			});
			return validation.computeValidationMessages(errs, [
				'problem_description',
				'phone_number',
				'express_waybill',
				'blocks_salepoint',
				'error_time',
				'ip_address',
				'watchers'
			]);
		},
		imagesListData() {
			return this.files.map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [{ class: 'icon-remove', onClick: (img) => this.removeFile(img.title, this.files) }]
				};
			});
		},
		passportImagesListData() {
			return this.edsFiles['passport'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'passport') }
					]
				};
			});
		},
		taxpayerIdImagesListData() {
			return this.edsFiles['taxpayer_id'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'taxpayer_id') }
					]
				};
			});
		},
		requestImagesListData() {
			return this.edsFiles['request'].map((f) => {
				return {
					url: f.imageSrc,
					title: f.name,
					icons: [
						{ class: 'icon-view', onClick: (img) => this.toggleLightbox(img) },
						{ class: 'icon-remove', onClick: (img) => this.removeEdsFile(img.title, 'request') }
					]
				};
			});
		},
		missingEdsScans() {
			if (this.root.user.eds_request.status === edsRequestStatuses.needsCorrection) {
				return !this.edsFiles.passport.length && !this.edsFiles.taxpayer_id.length && !this.edsFiles.request.length;
			}
			return !this.edsFiles.passport.length || !this.edsFiles.taxpayer_id.length || !this.edsFiles.request.length;
		},
		needToApprovePhoneNumber() {
			if (!this.root.user.phone_approved_at) {
				return true;
			}

			let d1 = DateTime.local().minus({ months: 3 });
			let d2 = DateTime.fromISO(this.root.user.phone_approved_at);

			return d1 >= d2;
		},
		isMobileDevice() {
			return isMobileDevice;
		}
	},
	methods: {
		toggleLightbox(scan) {
			this.lightboxScan = scan || null;
		},
		setViewport() {
			this.viewport = {
				width: document.documentElement.clientWidth,
				height: document.documentElement.clientHeight
			};
		},
		getExpirationDate(date) {
			return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
		},
		getInitialSDFormData() {
			return {
				salepoint_code: this.root.salepoint.code,
				login: this.root.user.login,
				problem_description: '',
				phone_number: this.root.user.phone_number,
				error_time: DateTime.fromISO(new Date().toJSON()).toFormat('dd.MM.yyyy HH:mm:ss'),
				ip_address: null,
				watchers: [],
				blocks_salepoint: false
			};
		},
		clear() {
			this.files = [];
			this.formData = this.getInitialSDFormData();
		},
		trimPhone(phone) {
			return typeof phone === 'string' ? phone.replace(/[()-\s]/g, '') : phone;
		},
		removeWatcher(id) {
			this.formData.watchers = this.formData.watchers.filter((watcher) => watcher.id !== id);
		},
		addWatcher() {
			if (this.watcherToAdd && this.watcherToAdd.id) {
				if (this.formData.watchers.find((watcher) => watcher.id === this.watcherToAdd.id)) {
					this.localErrors.watchers = `${this.watcherToAdd.login || 'Цей користувач'} уже доданий`;
				} else {
					this.formData.watchers.push(this.watcherToAdd);
					this.watcherToAdd = null;
				}
			}
		},
		handleFileUpload(file, target, inputRefName) {
			this.$nextTick(() => {
				this.$refs[inputRefName].fileName = null;
			});
			if (!file) {
				return alert(`Виникла помилка під час завантаження`);
			}
			if (!file.size) {
				return alert(`Неможливо завантажити пустий файл.`);
			}
			if (this.maxSizeMB && file.size / 1024 / 1024 > this.maxSizeMB) {
				return alert(`Макс. розмір файлу ${this.maxSizeMB} МБ.`);
			}

			if (!file.imageSrc && !file.url && (file.type.includes('image') || file.type === 'application/pdf')) {
				let reader = new FileReader();
				reader.onload = (e) => {
					file.url = e.target.result;
					file.imageSrc = e.target.result;
					target.push(file);
				};
				reader.readAsDataURL(file);
			} else {
				target.push(file);
			}
		},
		removeFile(fileName) {
			if (fileName && fileName.length) {
				this.files = this.files.filter((f) => f.name !== fileName);
			}
		},
		removeEdsFile(fileName, target) {
			if (fileName && fileName.length) {
				this.edsFiles[target] = this.edsFiles[target].filter((f) => f.name !== fileName);
			}
		},
		submit() {
			if (this.requestType.id === 'eds_request') {
				return this.submitEdsRequestScans({
					files: this.edsFiles,
					loadingAction: true
				});
			} else {
				this.addWatcher();
				let reportData = {
					...this.formData,
					phone_number: this.trimPhone(this.formData.phone_number),
					watchers: this.formData.watchers.map((watcher) => watcher.login)
				};
				if (DateTime.fromFormat(this.formData.error_time, this.errorTimeFormat).isValid) {
					reportData.error_time = DateTime.fromFormat(this.formData.error_time, this.errorTimeFormat).toISO();
				} else {
					return (this.localErrors.error_time = 'необхідна відповідність формату date-time');
				}
				this.localErrors = [];
				return this.createSDReport({
					reportData,
					files: this.files,
					loadingAction: true
				});
			}
		}
	},
	beforeMount() {
		this.setViewport();
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-close: '~@assets/images/icon-close.svg';
$icon-plus: '~@assets/images/icon-plus.svg';
$icon-remove: '~@assets/images/icon-remove.svg';
$icon-close-filled: '~@assets/images/icon-close-filled.svg';
$icon-loading: '~@assets/images/icon-loading.svg';
$icon-download: '~@assets/images/icon-download.svg';

$tab-box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);

.support-request {
	&.sidebar {
		background: $white;
		box-shadow: $tab-box-shadow;
		display: flex;
		flex-direction: column;
		left: calc(100% - 328px);
		width: 330px;
		height: 100%;
		z-index: $menu-sidebar-z-index;
		::v-deep .modal-container {
			border: 0;
			height: 100%;
			width: 100%;
			.modal-header {
				display: none;
			}
			.modal-body {
				height: 100%;
				width: 100%;
				padding: 0;
			}
		}
	}
	::v-deep button {
		font-size: 12px;
		height: 32px;
	}
	.support-request__header {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 20px;
		position: relative;
		margin-bottom: 5px;
		.icon-close-service-desk {
			position: absolute;
			right: 16px;
			top: calc(50% - 13px);
			@include make-icon($icon-close-filled, $gray-30, 24px, 24px);
			&:hover {
				cursor: pointer;
				@include make-icon($icon-close-filled, $gray-50, 24px, 24px);
			}
		}
		p {
			color: $gray-50;
			font-family: Proxima Nova Semibold;
			font-size: 18px;
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
	.scroll-container {
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0 20px 20px 20px;
		height: calc(100% - 60px);
		@include pretty-scrollbar();
		.request-type-select {
			margin-bottom: 8px;
		}

		form {
			display: flex;
			flex-direction: column;
			height: inherit;
		}

		.eds-request {
			.eds-request-status {
				margin-top: 8px;
				margin-bottom: 20px;
				padding: 12px 16px;
				border-radius: 4px;
				font-size: 12px;
				line-height: 16px;
				&.new {
					background: $gray-10;
					color: #576680;
				}
				&.checked {
					background: #fff3d2;
					color: #976d02;
				}
				&.processed {
					background: #d8ffe8;
					color: #129248;
				}
				&.rejected {
					background: $light-red;
					color: $red;
				}
			}
			.precreated {
				margin-top: 8px;
				.application-form {
					display: flex;
					justify-content: space-between;
					vertical-align: center;
					cursor: pointer;
					background: $gray-10;
					border-radius: 4px;
					padding: 12px 16px;
					color: $gray-50;
					font-size: 12px;
					line-height: 20px;
					font-family: Proxima Nova Semibold;
					margin-bottom: 16px;
					text-decoration: none;
					.icon-download {
						@include make-icon($icon-download, $gray-50, 20px, 20px);
					}
				}
				.info-text {
					display: inline-block;
					margin-bottom: 9px;
					font-size: 12px;
					line-height: 113.1%;
					color: #8f94a8;
					&.red {
						color: $red;
					}
				}
				.instructions {
					h5 {
						font-size: 14px;
						line-height: 17px;
						font-family: Proxima Nova Semibold;
						color: $gray-50;
						margin-bottom: 5px;
					}
					ol {
						font-size: 14px;
						line-height: 24px;
						padding-left: 14px;
						margin-bottom: 24px;
						a {
							color: $red;
						}
					}
					::v-deep .tabs.upload-tabs {
						justify-content: space-between;
						margin-bottom: 9px;
						.tab-item {
							width: 100%;
							margin-right: 0;
							padding-bottom: 12px;
							border-bottom: 1px solid $gray-20;
							text-align: center;
							&.active {
								border-bottom: 2px solid $gray-40;
							}
							&::after {
								display: none;
							}
						}
					}
				}
				.eds-file-input {
					margin-bottom: 4px;
				}
			}
		}
		.service-desk {
			.service-desk__body {
				display: flex;
				flex-direction: column;
				padding: 12px 0 0 0;
				& > * {
					margin-bottom: 20px;
					width: 100%;
				}
				.row {
					display: flex;
					& > * {
						flex: 1;
					}
					& > :not(:last-child) {
						margin-right: 16px;
					}
				}
				::v-deep .input {
					&.disabled {
						& * {
							color: $gray-40;
						}
					}
				}
				::v-deep .textarea {
					&.text-area-input {
						height: 112px;
						textarea {
							height: auto;
							min-height: 112px;
						}
					}
				}
				.watcher-container {
					display: flex;
					::v-deep .np-select {
						flex: 1;
						&.disabled {
							& * {
								color: $gray-40;
							}
						}
						width: calc(100% - 50px);
						min-width: auto;
						.multiselect__tags {
							min-width: auto;
						}
					}
					::v-deep .button {
						width: 40px;
						height: 40px;
						flex-shrink: 0;
						&.red {
							background: $light-red;
							&:hover {
								background: rgba(237, 28, 36, 0.18);
							}
						}
						&.gray {
							background: $gray-10;
							&:hover {
								background: $gray-20;
							}
						}
					}
					.icon-plus {
						position: absolute;
						right: calc(50% - 13px);
						top: calc(50% - 11px);
						@include make-icon($icon-plus, $red, 24px, 24px);
					}
					.icon-remove {
						position: absolute;
						right: calc(50% - 12px);
						top: calc(50% - 12px);
						@include make-icon($icon-remove, $gray-50, 24px, 24px);
					}
					& > :not(:last-child) {
						margin-right: 8px;
					}
				}
				.work-available-container {
					background: $gray-5;
					border-radius: $border_radius;
					display: flex;
					align-items: center;
					padding: 14px;
					p {
						flex: 1;
					}
					.switch {
						padding: 4px;
					}
				}
			}
		}
		.support-request__footer {
			background: $white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: auto;
			width: 100%;
			& > * {
				flex: 1;
			}
			& > *:not(:last-child) {
				margin-right: 16px;
			}
			.icon-close {
				@include make-icon($icon-close, $gray-40, 20px, 20px);
			}
		}
	}
	&.mobile {
		width: 100vw;
		left: 0;
	}
}
</style>
