import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const types = core.createTypes('root');

export default core.createStore(actions, getters, mutations, {
	key: 'root',
	initial: 'idle',
	states: {
		idle: {
			on: {
				[types.PAGE_CHANGE]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		loading: {
			entry: 'getAppData',
			on: {
				[types.APP_DATA_SUCCESS]: 'render',
				[types.APP_DATA_ERROR]: 'login',
				[types.OPEN_GRYADA_KEY_FORM]: 'gryada_key',
				[types.ERROR]: 'error'
			}
		},
		login: {
			on: {
				[types.LOGIN_SUCCESS]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		gryada_key: {
			on: {
				[types.CREATE_GRYADA_KEY_SUCCESS]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		render: {
			activities: ['pollPOSDaemon'],
			on: {
				[types.LOGOUT]: 'login',
				[types.APP_DATA_ERROR]: 'login',
				[types.PAGE_CHANGE]: 'loading',
				[types.ERROR]: 'error',
				[types.TRANSFER_USER_TO_ANOTHER_SALEPOINT]: 'loading'
			}
		},
		error: {
			on: {
				[types.PAGE_CHANGE]: 'loading'
			}
		}
	}
});
