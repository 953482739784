import { core, lookup } from 'novapay-ui';

import handleError from '@services/handle-api-error';

const createActions = (types, rootTypes) => {
	const actions = {
		lookupContragentUsers: async (context, data) => {
			return lookup(`/v3/operations/revenue-encashment/lookup-contragent`, { query: data }).then((res) => {
				if (handleError()(res, context)) {
					data.cb(res.data);
				}
			});
		},
		setSelectedContragent: (context, data) => {
			context.commit(types.SET_SELECTED_CONTRAGENT, data);
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.SET_SELECTED_CONTRAGENT]: (state, { contragent, user } = {}) => {
		state.props = { ...state.props, selectedContragent: contragent, selectedContragentUser: user };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
