<template>
	<NP-Modal class="promo" :toggle="toggle" :disable-click-outside="true">
		<div class="content">
			<img :src="$options.img" class="img" />
			<p>Бери участь та вигравай крутезні подарунки.</p>
			<p>Шукай деталі на навчальному порталі</p>
			<div class="buttons">
				<NP-Button priority="high" @click="toggle">Слава Україні!</NP-Button>
			</div>
		</div>
	</NP-Modal>
</template>

<script>
import img from '@assets/images/cashdesk-promo.png';

export default {
	name: 'PromoModal',
	img,
	props: {
		toggle: Function
	}
};
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';

.promo.modal.modal-mask {
	::v-deep .modal-container {
		width: 462px;
		position: relative;
		.modal-header {
			padding: 0;

			.title .button.button-close {
				position: absolute;
				right: 16px;
				top: 16px;
				width: 30px;
				border-radius: 50%;
				background: rgb(0 0 0 / 60%);

				.text .icon-close {
					background-color: $white;
				}
			}
		}
		.modal-body {
			padding: 0 0 28px;
		}
		.content {
			.img {
				display: flex;
				margin: 8px auto 24px;
			}
			h3 {
				padding: 16px 0 8px;
				text-align: center;
				font-size: 18px;
				font-family: Proxima Nova Semibold, sans-serif;
				color: $gray-50;
			}
			p {
				text-align: center;
				font-size: 16px;
				color: $gray-50;
				line-height: 18px;
				span {
					font-family: Proxima Nova Semibold, sans-serif;
				}
			}
			.buttons {
				margin-top: 16px;
				display: flex;
				justify-content: center;

				.button {
					color: $gray-50;
					background: #ffe527;
					border: 1px solid #ffe527;
				}
			}
		}
	}
}
</style>
