const statuses = {
	expired: 'expired',
	created: 'created',
	initializedPay: 'initialized_pay',
	paid: 'paid',
	initializedRefund: 'initialized_refund',
	refunded: 'refunded',
	initializedPayout: 'initialized_payout',
	paidOff: 'paid_off',
	blocked: 'blocked'
};

const locale = {
	[statuses.expired]: 'видалена',
	[statuses.created]: 'створена',
	[statuses.initializedPay]: 'iницiйована сплата',
	[statuses.paid]: 'приймання',
	[statuses.initializedRefund]: 'iницiйовано повернення',
	[statuses.refunded]: 'повернення',
	[statuses.initializedPayout]: 'iницiйовано виплату',
	[statuses.paidOff]: 'видача',
	[statuses.blocked]: 'заблокована'
};

module.exports = {
	enum: statuses,
	locale: locale
};
