<template>
	<NP-SplashScreen class="splash-screen">
		<template slot="menu">
			<div class="wrap">
				<div class="menu">
					<div class="container">
						<div class="items-wrap">
							<div class="item info" />
							<div class="item chip" />
							<div class="item amount1" />
							<div class="item amount2" />
						</div>
						<div class="items-wrap">
							<div class="item requisites" />
						</div>
					</div>
					<div class="items-wrap">
						<div class="item tab" />
						<div class="item tab" />
					</div>
				</div>
			</div>
		</template>
	</NP-SplashScreen>
</template>

<script>
export default {
	name: 'CashbookDocumentsSplashScreen'
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

.splash-screen {
	::v-deep .default-screen.table {
		max-width: $max-content-width;
		margin: 0 auto;
		.controls-placeholder {
			margin: 160px 20px 24px 20px;
		}
		div.progress {
			position: absolute;
			left: 0;
			top: 140px;
		}
	}
	.wrap {
		position: absolute;
		left: 0;
		width: 100%;
		height: 138px;
		box-shadow: 0px 1px 1px rgba(226, 231, 240, 0.6);
		background: $white;
		.menu {
			margin: 0 auto;
			max-width: $max-content-width;
			height: 143px;
			padding: 0 20px;
			.container {
				display: flex;
				justify-content: space-between;
			}
			.items-wrap {
				display: flex;
				padding-top: 24px;
				.item {
					margin-right: 50px;
					height: 50px;
					width: 50px;
					background: $gray-5;
					border-radius: $border_radius;
					&.info {
						height: 60px;
						width: 170px;
					}
					&.chip {
						height: 30px;
						width: 90px;
						border-radius: 50px;
					}
					&.amount1 {
						height: 30px;
						width: 170px;
					}
					&.amount2 {
						height: 30px;
						width: 120px;
					}
					&.requisites {
						width: 150px;
						height: 35px;
						margin-right: 0px;
					}
					&.tab {
						height: 25px;
						width: 120px;
					}
				}
			}
		}
	}
}
</style>
