import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('operations');

export default core.createStore(actions, {}, mutations, {
	key: 'operations',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			entry: 'getOperations',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.OPERATIONS_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_PREVIEW_MODAL]: 'preview_modal',
						[types.LOADING_PREVIEW]: 'loading_preview',
						[types.OPEN_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: 'email_confirm_for_records',
						[types.OPEN_EMAIL_CONFIRM_FOR_OPERATIONS_MODAL]: 'email_confirm_for_operations',
						[types.OPEN_MASS_REGISTRY_PERIOD_SELECT_MODAL]: 'mass_registry_period_select',
						[types.OPEN_CASHDESKS_MODAL]: 'select_cashdesks_modal',
						[types.OPEN_DAILY_REPORT_PREVIEW_MODAL]: 'daily_report_preview_modal'
					}
				},
				daily_report_preview_modal: {
					on: {
						[types.CLOSE_DAILY_REPORT_PREVIEW_MODAL]: 'main'
					}
				},
				select_cashdesks_modal: {
					on: {
						[types.CLOSE_CASHDESKS_MODAL]: 'main',
						[types.OPEN_DAILY_REPORT_PREVIEW_MODAL]: 'daily_report_preview_modal'
					}
				},
				email_confirm_for_operations: {
					on: {
						[types.CLOSE_EMAIL_CONFIRM_FOR_OPERATIONS_MODAL]: 'main'
					}
				},
				mass_registry_period_select: {
					on: {
						[types.CLOSE_MASS_REGISTRY_PERIOD_SELECT_MODAL]: 'main',
						[types.OPEN_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: 'email_confirm_for_records',
						[types.CLOSE_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: 'main'
					}
				},
				email_confirm_for_records: {
					on: {
						[types.CLOSE_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: 'main'
					}
				},
				preview_modal: {
					on: {
						[types.CLOSE_PREVIEW_MODAL]: 'main',
						[types.OPEN_EDIT_RECIPIENT_MODAL]: 'edit_recipient_modal'
					}
				},
				edit_recipient_modal: {
					on: {
						[types.CLOSE_EDIT_RECIPIENT_MODAL]: 'main',
						[types.OPEN_OPERATION_NOT_PERMITTED_MODAL]: 'operation_not_permitted'
					}
				},
				loading_preview: {
					on: {
						[types.OPEN_PREVIEW_MODAL]: 'preview_modal'
					}
				},
				operation_not_permitted: {
					on: {
						[types.CLOSE_OPERATION_NOT_PERMITTED_MODAL]: 'main'
					}
				}
			}
		}
	}
});
