import find from 'lodash/find';
import { http } from 'novapay-ui';

let domain = 'localhost'; //'192.168.68.120';

class ThermalPrinter {
	constructor() {
		this.connected = false;
		this.uri = null;
		// means user confirmed that printer is working by printing a control str
		this.confirmedWorking = false;
		this.testNumber = null;

		this.printQueue = [];
		this.isPrinting = false;

		// delete old sw's
		if (window.navigator && navigator.serviceWorker) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					registration.unregister();
				}
			});
		}

		// only print in currently open tab
		this.windowActive = true;
		window.onfocus = () => (this.windowActive = true);
		window.onblur = () => (this.windowActive = false);
	}

	async checkConnection() {
		let res = await http(`http://${domain}:32909/printService?version=1.0.0&operation=getDeviceList`);
		let thermalPrinter = null;
		if (res.status === 200) {
			thermalPrinter = find(res.data?.printers || [], (p) => p.name.toLowerCase().includes('citizen'));
		}

		// for local debug
		// thermalPrinter = { name: 'citizen', uri: 'http://192.168.68.120:631/printers/citizen' };

		this.connected = Boolean(thermalPrinter);
		this.uri = thermalPrinter?.uri;
	}

	async print(rootTypes, context, base64data, fromQueue = false) {
		if (!this.connected || !this.uri || (!this.windowActive && !fromQueue)) {
			return;
		}

		if (this.isPrinting) {
			this.printQueue.push(base64data);
			return;
		}
		this.isPrinting = true;
		let res = await http(`http://${domain}:32909/printService?version=1.0.0&operation=addJob&uri=${this.uri}`, {
			method: 'POST',
			data: base64data
		});
		if (res.status !== 200) {
			context.commit(rootTypes.ADD_SNACKBAR, { variant: 'error', title: 'Помилка друку на термопринтерi' });
		}
		this.isPrinting = false;
		if (this.printQueue.length) {
			let data = this.printQueue.shift();
			this.print(rootTypes, context, data, true);
		}
	}

	async printTestNumber() {
		this.testNumber = Math.floor(Math.random() * 999);
		await http('/v3/salepoint/print-thermal-test-number', {
			method: 'POST',
			data: { text: btoa(`Vvedit kontrolnyi \nnomer v Novapay - ${this.testNumber}\n\n\n\n\n\n .`) }
		});
	}

	checkTestNumber(number) {
		return parseInt(number) === this.testNumber;
	}
}

export default new ThermalPrinter();
