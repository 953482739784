import { core, http } from 'novapay-ui';
import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';
import { enum as statuses } from '@repo/enums/operation-recipient-claim-statuses';

const rootTypes = core.getTypes('root');
const types = core.getTypes('operation_recipient_claims');
const { actions: commonActions } = commonStore('operation_recipient_claims');

const getClaims = async (context) => {
	let res = await http('/v3/claims', { query: context.state.props.query });
	if (!handleError()(res, context)) {
		return;
	}
	let total = res.data?.[0]?.total_count;
	commonActions.hideRootSplashScreen(context);
	context.commit(types.GET_CLAIMS_SUCCESS, { claims: res.data, total });
};

const toggleClaimModal = (context, edit_entity) => {
	let type = context.state.state_key === 'claim_modal' ? types.CLOSE_CLAIM_MODAL : types.OPEN_CLAIM_MODAL;
	context.commit(type, edit_entity);
};

const editClaim = async (context, data) => {
	let formData = new FormData();
	formData.append('id', data.id);
	formData.append('status', statuses.refinementDone);
	formData.append('description', data.description);
	data.files &&
		data.files.forEach((f) => {
			formData.append('files', f);
		});
	let res = await http('/v3/claims/edit-claim', { method: 'POST', data: formData });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.APPLY_QUERY, context.state.props.query);
	context.commit(rootTypes.namespaced.ADD_SNACKBAR, { title: 'Успішно', variant: 'success' }, { root: true });
};

export default {
	...commonActions,
	getClaims,
	toggleClaimModal,
	editClaim
};
