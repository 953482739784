const mediums = {
	cashdesk: 'cashdesk',
	courier: 'courier',
	mobileCashdesk: 'mobile_cashdesk',
	contragentApi: 'contragent_api',
	scheduler: 'scheduler',
	selfService: 'self_service',
	sst: 'self_service_terminal',
	noCashOperator: 'no_cash_operator'
};

const locale = {
	[mediums.cashdesk]: 'робоче мiсце',
	[mediums.courier]: 'кур\'єр',
	[mediums.mobileCashdesk]: 'мобiльний',
	[mediums.contragentApi]: 'кабiнет контрагента',
	[mediums.scheduler]: 'планувальник',
	[mediums.selfService]: 'селф сервіс',
	[mediums.sst]: 'пткс',
	[mediums.noCashOperator]: 'оператор НП',
};

module.exports = {
	enum: mediums,
	locale: locale
};
