import { core, http } from 'novapay-ui';
import router from '@/router';

import handleError from '@services/handle-api-error';

const createActions = (types) => ({
	toggleAwisBarcodeModal: (context) => {
		if (!context.state.props?.toggles?.awisBarcodeModal) {
			context.commit(types.TOGGLE_MODAL, { modalName: 'awisBarcodeModal', data: {} });
		} else {
			context.commit(types.TOGGLE_MODAL, { modalName: 'awisBarcodeModal', data: null });
		}
	},
	getOperationByAwisBarcode: async (context, { barcode }) => {
		context.commit(types.TOGGLE_MODAL, { modalName: 'awisBarcodeModal', data: { error: null } });

		let res = await http({
			url: '/v3/operations/import-by-awis-barcode',
			method: 'POST',
			data: { barcode }
		});

		const processingHandler = (hContext) => {
			hContext.commit(types.TOGGLE_MODAL, { modalName: 'awisBarcodeModal', data: { error: true } });
			return false;
		};

		if (!handleError({ processing: processingHandler })(res, context, 200, types.VALIDATION_ERRORS)) {
			return;
		}

		context.commit(types.TOGGLE_MODAL, { modalName: 'awisBarcodeModal', data: null });

		let paymentType = res.data.type ? res.data.type : 'awis';
		let query = router.options.stringifyQuery({
			ids: res.data.operations.map((o) => o.id)
		});
		let url = `/payment/${paymentType}${query}`;
		window.location.href = window.location.origin + url;
	}
});

const createMutations = (types) => ({});

const createComponentStore = () => {
	const mutations = createMutations(core.createTypes('root'));
	const actions = createActions(core.getTypes('root'));
	return { mutations, actions };
};

export default createComponentStore;
