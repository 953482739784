import Promise from 'bluebird';
import b64toBlob from './transform-to-blob';

/* experimental embed version just in case */

// const print = (pdf) => {
// 	let embed = document.createElement('embed');
// 	embed.src = `data:application/pdf;base64,${pdf}`;
// 	embed.type = 'application/pdf';
// 	embed.style = 'position: fixed; top: 0; left; 0; width: 100vw; height: 100vh';
//
// 	document.body.appendChild(embed);
// };

const WAIT_FOR_IFRAME_LOADED_INTERVAL = 300;
const WAIT_FOR_IFRAME_CONTENT_PRINT_READY = 500;
const FIREFOX_MAX_WAIT_ATTEMPTS_UNTIL_FORCE_PRINT = 30;

const doPrint = (pdf, attempt) => {
	return new Promise(function (resolve, reject) {
		let iframe = document.getElementById('printFrameNovapay');
		if (!iframe) {
			iframe = document.createElement('iframe');
			iframe.setAttribute('id', `printFrameNovapay`);
		}

		iframe.style = 'display: none';
		iframe.src = URL.createObjectURL(b64toBlob(pdf, 'application/pdf'));
		document.body.appendChild(iframe);

		var i = 0;
		var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
		return printOnReady();
		// eslint-disable-next-line no-inner-declarations
		function printOnReady() {
			return new Promise((resolvePrint) => {
				setTimeout(() => {
					if (
						iframeDoc.readyState === 'complete' ||
						iframeDoc.readyState === 'interactive' ||
						i > FIREFOX_MAX_WAIT_ATTEMPTS_UNTIL_FORCE_PRINT
					) {
						setTimeout(() => {
							iframe.contentWindow.print();
							resolve(resolvePrint());
						}, WAIT_FOR_IFRAME_CONTENT_PRINT_READY);
					} else {
						i++;
						printOnReady();
					}
				}, WAIT_FOR_IFRAME_LOADED_INTERVAL);
			});
		}
	}).catch((e) => {
		if (attempt > 2) {
			throw e;
		} else {
			return doPrint(pdf, attempt + 1);
		}
	});
};

const print = (pdf) => {
	let attempt = 1;
	return doPrint(pdf, attempt).catch((e) => {
		// eslint-disable-next-line
		console.error('failed to print: ' + e.message);
	});
};

export default print;
