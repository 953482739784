import { core, http, lookup } from 'novapay-ui';
import Promise from 'bluebird';
import print from '@services/print';
import { DateTime } from 'luxon';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import router from '@/router';

import { enum as centrifugoEventTypes } from '@repo/enums/centrifugo-event-types';

const { actions: commonStoreActions } = commonStore('acts');
let types = core.getTypes('acts');
let rootTypes = core.getTypes('root');

const getCashbookToRender = (context) => {
	context.commit(types.SET_CASHBOOK_ID, router.currentRoute.params?.cashbook_id ?? null);
};

const getPageData = async (context) => {
	let { cashbook_id, query, cashbook } = context.state.props;
	let quarterDoc = await http('/v3/salepoint/inventory-acts/settings/quarter-act-document');
	if (cashbook_id && isNaN(parseInt(cashbook_id))) {
		return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
	}
	if (!cashbook_id) {
		let acts = await http(`/v3/salepoint/inventory-acts-list`, { query });
		if (!handleError()(acts, context, 200) || !handleError()(quarterDoc, context, 200)) {
			return;
		}
		context.commit(types.PAGE_DATA_SUCCESS, {
			acts: acts.data,
			quarter_document: quarterDoc.data,
			total: acts.data?.length && acts.data[0].total_count
		});
	} else {
		if (!cashbook) {
			let cashbookRes = await http(`/v3/cashbooks`, { query: { filters: { id: [cashbook_id] } } });
			if (!handleError()(cashbookRes, context)) {
				return;
			}
			if (!cashbookRes.data?.length) {
				return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
			}
			cashbook = cashbookRes.data[0];
		}
		let [acts, totalsRes] = await Promise.all([
			http(`/v3/salepoint/inventory-acts-list`, {
				query: { ...query, filters: { ...query?.filters, cashbook_id: [cashbook_id] } }
			}),
			http(`/v3/salepoint/totals/${DateTime.fromISO(cashbook.created_at).toISODate()}`)
		]);
		if (!handleError()(acts, context) || !handleError()(totalsRes, context) || !handleError()(quarterDoc, context)) {
			return;
		}
		context.commit(types.PAGE_DATA_SUCCESS, {
			acts: acts.data,
			cashbook,
			totals: totalsRes.data,
			quarter_document: quarterDoc.data,
			total: acts.data?.length && acts.data[0].total_count
		});
	}

	return commonStoreActions.hideRootSplashScreen(context);
};

const lookupUsers = (context, { query, cb, id }) => {
	return lookup(`/v3/lookups/users/name?query=${query || ''}`, { id }).then((res) => {
		handleError()(res, context) && cb(res);
	});
};

const lookupSelfServiceTerminals = (context, { query, cb }) => {
	return lookup(`/v3/lookups/self-service-terminals?query=${query || ''}`).then((res) => {
		handleError()(res, context) && cb(res);
	});
};

const createInventoryAct = (context, data) => {
	return http('/v3/salepoint/inventory-act', { method: 'PUT', data }).then((res) => {
		if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
			return;
		}
		context.commit(types.CREATE_ACT_SUCCESS, res.data);
		return context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
	});
};

const toggleInventoryActModal = (context) => {
	if (context.state.state_key.render === 'inventory_act_modal') {
		return context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
	}
	return context.commit(types.OPEN_INVENTORY_ACT_MODAL);
};

const printAct = async (context, act) => {
	let res = await http(`/v3/salepoint/print-inventory-act/${act.id}`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printCashbook = async (context) => {
	let { id } = context.state.props.cashbook;
	let res = await http(`/v3/cashbooks/print/${id}/all`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const eventListener = (context, e) => {
	let { cashbook_id } = context.state.props;
	switch (e.type) {
		case centrifugoEventTypes.cashbook:
			return cashbook_id && context.commit(types.CASHBOOK_EVENT, e.payload);
		default:
			return;
	}
};

const toggleActScanModal = (context, { scanAct = null } = {}) => {
	if (context.state.state_key.render === 'act_scan_modal') {
		return context.commit(types.CLOSE_ACT_SCAN_MODAL);
	}
	return context.commit(types.OPEN_ACT_SCAN_MODAL, { scanAct });
};

const uploadActScan = async (context, { file }) => {
	let { scanAct } = context.state.props;

	let formData = new FormData();
	formData.append('file', file);

	let res = await http(`/v3/salepoint/inventory-acts/${scanAct.id}/scan`, {
		method: 'POST',
		data: formData,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.CLOSE_ACT_SCAN_MODAL);
	context.commit(types.APPLY_QUERY);
	return context.commit(
		rootTypes.namespaced.ADD_SNACKBAR,
		{ variant: 'success', title: 'Скан завантажено успішно' },
		{ root: true }
	);
};

export default {
	...commonStoreActions,
	getCashbookToRender,
	getPageData,
	lookupUsers,
	lookupSelfServiceTerminals,
	toggleInventoryActModal,
	createInventoryAct,
	print: printAct,
	printCashbook,
	eventListener,
	toggleActScanModal,
	uploadActScan
};
