import { core } from 'novapay-ui';
import commonStore from '@services/common-store';

const types = core.createTypes('workplaces');
const { mutations } = commonStore('workplaces');

export default {
	...mutations,
	[types.GET_WORKPLACES_SUCCESS]: (state, { workplaces, total }) => {
		state.props = { ...state.props, workplaces, total };
	},
	[types.OPEN_WORKPLACE_MODAL]: (state, edit_entity) => {
		state.props = { ...state.props, edit_entity };
	},
	[types.CLOSE_WORKPLACE_MODAL]: (state) => {
		state.props = { ...state.props, edit_entity: null, validationErrors: null };
	},
	[types.VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = { ...state.props, validationErrors };
	}
};
