import Errors from './errors';
import ErrorAlert from './error-alert';
import Confirm from './confirm-modal';
import Countdown from './countdown.vue';
import CashbookInfo from './cashbook-info';
import InventoryActModal from './inventory-act-modal';
import CashbookTotals from './cashbook-totals.vue';
import RowActions from './row-actions.vue';
import ArchiveInfo from './archive-info.vue';
import NaftogazApplicationPreviewModal from './naftogaz-application-preview-modal.vue';
import NavMenu from './nav-menu.vue';
import OperationNotPermitted from './operation-not-permitted.vue';
import VerificationBlocked from './verification-blocked.vue';
import AppInviteButton from './app-invite-button.vue';

const Components = {
	install: function (Vue) {
		Vue.component('Errors', Errors);
		Vue.component('ErrorAlert', ErrorAlert);
		Vue.component('Confirm', Confirm);
		Vue.component('Countdown', Countdown);
		Vue.component('CashbookInfo', CashbookInfo);
		Vue.component('InventoryActModal', InventoryActModal);
		Vue.component('CashbookTotals', CashbookTotals);
		Vue.component('RowActions', RowActions);
		Vue.component('ArchiveInfo', ArchiveInfo);
		Vue.component('NaftogazApplicationPreviewModal', NaftogazApplicationPreviewModal);
		Vue.component('NavMenu', NavMenu);
		Vue.component('OperationNotPermitted', OperationNotPermitted);
		Vue.component('VerificationBlocked', VerificationBlocked);
		Vue.component('AppInviteButton', AppInviteButton);
	}
};

export default Components;
