<template>
	<NP-Modal :toggle="toggle" :class="{ 'notification-menu': true, mobile: isMobileDevice }" variant="sidebar">
		<div class="notification__header">
			<NP-Tabs :tabs="notificationTabs" @select="(tabElement) => (notificationActiveTab = tabElement.tab)">
				<template #tab="slotProps">
					{{ slotProps.title }}
					<span v-if="slotProps.title === 'Повідомлення' && notifications.length" class="new-notifications-count">
						{{ notifications.length }}
					</span>
					<span v-if="slotProps.title === 'На підпис' && signatureRequests.length" class="new-notifications-count">
						{{ signatureRequests.length }}
					</span>
				</template>
			</NP-Tabs>
			<div slot="icon" class="icon-close-notification" @click.stop="toggle" />
		</div>
		<div class="notification__body">
			<div v-if="loadingActions.includes('getSignatureRequests')" class="loading-notifications">
				<div class="icon-loading" />
			</div>
			<div v-else-if="!notificationsToDisplay.length" class="no-notifications">Нових повідомлень немає!</div>
			<div
				v-else
				:class="{
					notification__messages: notificationActiveTab === 'notifications',
					'notification__signature-requests': notificationActiveTab === 'signatureRequests'
				}"
			>
				<div
					v-for="(msg, index) in notificationsToDisplay"
					:key="index"
					:class="{ notification__item: true, active: true }"
				>
					<div slot="icon" :class="{ notification__icon: true }">
						<i v-if="notificationActiveTab === 'signatureRequests'" class="icon-rules" />
					</div>
					<div class="notification__info">
						<p :class="{ notification__title: true, active: true }">{{ msg.title }}</p>
						<p class="notification__text">{{ msg.text }}</p>
						<div class="notification__date-and-actions">
							<span class="notification__date"> {{ msg.date }} </span>
							<div class="notification__actions">
								<template v-if="notificationActiveTab === 'signatureRequests'">
									<NP-Button
										v-if="!index"
										@click.stop="signDocument({ ...msg, loadingAction: { id: msg.id } })"
										type="button"
										priority="medium"
										:loading="loadingActions.includes(`signDocument-${msg.id}`)"
									>
										Підписати
									</NP-Button>
									<NP-Button
										class="btn-icon"
										type="button"
										variant="square"
										priority="low"
										:loading="loadingActions.includes(`printDocument-${msg.id}`)"
										@click.stop="printDocument({ ...msg, loadingAction: { id: msg.id } })"
									>
										<div slot="icon">
											<div class="icon-eye" />
										</div>
									</NP-Button>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</NP-Modal>
</template>

<script>
import { DateTime } from 'luxon';
import find from 'lodash/find';

import { enum as pdEntityTypes } from '@repo/enums/cashbook-printed-document-entity-types';
import { enum as updTypes } from '@repo/enums/user-printed-document-types';

import isMobileDevice from '@services/is-mobile-device';

export default {
	name: 'Notifications',
	props: {
		root: { type: Object, default: null },
		toggle: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] },
		printDocument: { type: Function, default: null },
		signDocument: { type: Function, default: null }
	},
	data() {
		return {
			notificationActiveTab: this.filterSignatureRequests(this.root.signatureRequests).length
				? 'signatureRequests'
				: 'notifications'
		};
	},
	computed: {
		isMobileDevice() {
			return isMobileDevice;
		},
		signatureRequests() {
			let signatureRequests = this.filterSignatureRequests(this.root.signatureRequests);
			return signatureRequests.map((s) => ({
				...s,
				...this.getNotificationText(s),
				date: this.getDateFromNow(s)
			}));
		},
		notifications() {
			let notifications = this.root.notifications || [];
			return notifications.map((n) => ({
				...n,
				...this.getNotificationText(n),
				date: this.getDateFromNow(n)
			}));
		},
		notificationsToDisplay() {
			return (
				(this.notificationActiveTab === 'notifications' && this.notifications) ||
				(this.notificationActiveTab === 'signatureRequests' && this.signatureRequests)
			);
		},
		notificationTabs() {
			return [
				{ tab: 'notifications', title: 'Повідомлення', active: this.notificationActiveTab === 'notifications' },
				{
					tab: 'signatureRequests',
					title: 'На підпис',
					active: this.notificationActiveTab === 'signatureRequests'
				}
			];
		}
	},
	methods: {
		filterSignatureRequests(requests) {
			return (requests || []).filter(
				(r) => r.user_id === this.root.user.id || (r.signerUserIds || []).includes(this.root.user.id)
			);
		},
		getNotificationText(notification) {
			if (!notification) {
				return '';
			}
			let { entity_type, printed_document_type, entity } = notification;
			let pdEntityType = (find(this.root.lists['cashbook-printed-document-entity-types'], { id: entity_type }) || {})
				.text;
			let pdType = (find(this.root.lists['cashbook-printed-document-types'], { id: printed_document_type }) || {}).text;
			let orderType = (find(this.root.lists['cashbook-order-types'], { id: entity && entity.type }) || {}).text;
			let orderSubType = (find(this.root.lists['cashbook-order-sub-types'], { id: entity && entity.sub_type }) || {})
				.text;
			let updTypeText = (find(this.root.lists['user-printed-document-types'], { id: entity_type }) || {}).text;

			switch (entity_type) {
				case pdEntityTypes.cashbook:
					return {
						title: `Підписати ${pdEntityType}`,
						text: `Ваш підпис необхідний в документі ${pdType}`
					};
				case pdEntityTypes.order:
					return {
						title: `Підписати ${pdEntityType}`,
						text:
							`Ваш підпис необхідний в документі ${orderType.toUpperCase()}: ${orderSubType}` +
							` ${entity.participant_name}`
					};
				case updTypes.salaryAccountTransitionApplication:
				case updTypes.vacationApplication:
				case updTypes.familiarisation:
					return {
						title: 'Підписати документ',
						text: `Ваш підпис необхідний в документі: ${updTypeText}`
					};
				default:
					throw new Error('trying to display unknown entity type');
			}
		},
		getDateFromNow({ created_at } = {}) {
			let date = DateTime.fromISO(created_at).setLocale('uk-UA');
			if (date.diffNow('days').toObject().days > -1) {
				return date.toRelative();
			}
			return date.toFormat('HH:mm, dd MMMM');
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-close-filled: '~@assets/images/icon-close-filled.svg';
$icon-eye: '~@assets/images/icon-eye.svg';
$icon-rules: '~@assets/images/icon-rules.svg';
$icon-loading: '~@assets/images/icon-loading.svg';

$tab-box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);

.notification-menu {
	&.sidebar {
		background: $white;
		box-shadow: $tab-box-shadow;
		display: flex;
		flex-direction: column;
		left: calc(100% - 360px);
		width: 360px;
		height: 100%;
		z-index: $menu-sidebar-z-index;
		::v-deep .modal-container {
			border: 0;
			overflow-y: hidden;
			height: 100%;
			width: 100%;
			.modal-header {
				display: none;
			}
			.modal-body {
				height: 100%;
				width: 100%;
				padding: 0 0 43px;
			}
		}
	}
	::v-deep button {
		font-size: 12px;
		height: 32px;
	}
	.notification__header {
		border-bottom: 1px solid $gray-10;
		padding: 10px 20px 2px 20px;
		position: relative;
		::v-deep .tab-item {
			color: $gray-40;
			font-size: 14px;
			line-height: 14px;
			&.active {
				color: $gray-50;
			}
			&::after {
				top: 24px;
			}
			.new-notifications-count {
				background: $red;
				border-radius: $border-radius;
				color: $white;
				font-size: 12px;
				padding: 2.5px 6px 1.5px 6px;
				margin-left: 5px;
			}
		}
		.icon-close-notification {
			position: absolute;
			right: 8px;
			top: 7px;
			margin: 3px 6px 0 0;
			@include make-icon($icon-close-filled, $gray-30, 24px, 24px);
			&:hover {
				cursor: pointer;
				@include make-icon($icon-close-filled, $gray-50, 24px, 24px);
			}
		}
	}
	.notification__body {
		@include pretty-scrollbar();
		display: flex;
		flex-direction: column;
		padding-top: 8px;
		overflow-y: auto;
		height: 100%;
		.loading-notifications {
			height: 100%;
			display: flex;
			justify-content: center;
			margin-top: 36px;
			.icon-loading {
				@include make-icon($icon-loading, $gray-50, 20px, 20px);
			}
		}
		.no-notifications {
			height: 100%;
			display: flex;
			justify-content: center;
			margin-top: 36px;
			color: $gray-50;
			font-size: 16px;
			font-family: Proxima Nova Semibold;
		}
		.notification__item {
			border-bottom: 1px solid $gray-10;
			display: flex;
			flex-direction: row;
			padding: 8px;
			&.active {
				.notification__title {
					color: $red;
					position: relative;
					width: fit-content;
					&::after {
						position: absolute;
						bottom: 8px;
						margin-left: 6px;
						width: 6px;
						height: 6px;
						content: '';
						background: #e40830;
						border-radius: 50%;
					}
				}
			}
			.notification__icon {
				background: $gray-20;
				border: none;
				border-radius: 50%;
				flex-shrink: 0;
				margin-left: 12px;
				width: 32px;
				height: 32px;
				.icon-rules {
					margin: 8px;
					@include make-icon($icon-rules, $gray-50, 16px, 16px);
				}
			}
			.notification__info {
				flex-shrink: 1;
				margin-left: 12px;
				width: 100%;
				.notification__title {
					font-size: 12px;
					line-height: 15px;
					padding-bottom: 4px;
				}
				.notification__text {
					color: $gray-50;
					font-size: 14px;
					line-height: 17px;
					padding-bottom: 4px;
				}
				.notification__date-and-actions {
					align-items: center;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: space-between;
					.notification__date {
						color: $gray-40;
						font-size: 11px;
						margin: 4px 0;
					}
					.notification__actions {
						& > :not(:last-child) {
							margin: 4px 4px 4px 0;
						}
						.icon-eye {
							@include make-icon($icon-eye, $gray-50, 20px, 20px);
						}
					}
				}
			}
		}
	}
	.notification__footer {
		background: $white;
		border-top: 1px solid $gray-10;
		bottom: 0;
		display: flex;
		flex-direction: row-reverse;
		position: absolute;
		padding: 8px 12px;
		min-height: 49px;
		width: 100%;
	}
	&.mobile {
		width: 100vw;
		left: 0;
	}
}
</style>
