import BigNumber from 'bignumber.js';
import { core, http, validation, lookup } from 'novapay-ui';
import { DateTime } from 'luxon';
import print from '@services/print';
import values from 'lodash/values';

import handleError from '@services/handle-api-error';
import scenarios from '@/components/inventory-act-modal/scenarios';
import openedCashdesksActions from '../../opened-cashdesks/store/actions';

import { enum as cashbookShiftStatuses } from '@repo/enums/cashbook-shift-statuses';
import { enum as banknoteDenominations } from '@repo/enums/banknote-denominations';
import { enum as coinDenominations } from '@repo/enums/coin-denominations';
import { enum as actTypes } from '@repo/enums/inventory-act-types';

const types = core.getTypes('control_center');
const rootTypes = core.getTypes('root');

const validateCreateInventoryAct = validation.compile({
	additionalProperties: true,
	properties: {
		user_from_name: { type: 'string', minLength: 1, maxLength: 255 },
		user_from_position: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		user_to_name: { type: ['string', 'null'] },
		user_to_position: { type: ['string', 'null'] },
		cash: {
			type: ['object', 'null'],
			uniqueItems: true,
			items: {
				properties: {
					banknotes: {
						type: 'array',
						uniqueItems: true,
						minItems: 1,
						items: {
							type: 'object',
							properties: {
								denomination: { type: 'string', enum: values(banknoteDenominations) },
								count: { type: 'number', minimum: 1, maximum: 99999999, multipleOf: 1 }
							},
							additionalProperties: false,
							required: ['count', 'denomination']
						}
					},
					coins: {
						type: 'array',
						uniqueItems: true,
						minItems: 1,
						items: {
							type: 'object',
							properties: {
								denomination: { type: 'string', enum: values(coinDenominations) },
								count: { type: 'number', minimum: 1, maximum: 99999999, multipleOf: 1 }
							},
							additionalProperties: false,
							required: ['count', 'denomination']
						}
					}
				},
				additionalProperties: false,
				anyOf: [{ required: ['banknotes'] }, { required: ['coins'] }]
			}
		},
		reason: { type: ['string', 'null'], maxLength: 255 },
		plombeter_number: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		safe_keys_amount: { type: 'integer', minimum: 1, maximum: 99999 },
		committee_head_name: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		committee_head_position: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		committee_member_name: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		committee_member_position: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		second_committee_member_name: { type: ['string', 'null'], minLength: 1, maxLength: 255 },
		second_committee_member_position: { type: ['string', 'null'], minLength: 1, maxLength: 255 }
	}
});

const openCashbookOpenModal = async (context) => {
	let { cashbook_shift: oldShift, user } = context.rootState.root.props;
	let isShiftOpened = oldShift?.status === cashbookShiftStatuses.opened;
	if (isShiftOpened && oldShift.user_id !== user.id) {
		if (oldShift?.is_transferring) {
			return toggleInventoryActModal(context, { scenario: scenarios.confirmShiftTransferOpenBook });
		}
		let res = await http('/v3/salepoint/cashbook-shift/init-transfer', {
			method: 'POST',
			data: { user_id: oldShift.user_id }
		});
		if (!handleError()(res, context, 200)) {
			return;
		}
		return toggleInventoryActModal(context, { scenario: scenarios.confirmShiftTransferOpenBook });
	} else {
		return toggleWorkplaceSelectModal(context);
	}
};

const toggleWorkplaceSelectModal = async (context) => {
	if (context.state.state_key.render === 'select_workplace_modal') {
		return context.commit(types.CLOSE_SELECT_WORKPLACE_MODAL);
	}
	return context.commit(types.OPEN_SELECT_WORKPLACE_MODAL);
};

const openCashbookWithShift = async (context, { workplace_id }) => {
	let shift = await http('/v3/salepoint/cashbook-shift', { method: 'PUT', data: { workplace_id } });
	if (!handleError()(shift, context, 200)) {
		return;
	}
	context.commit(types.CLOSE_SELECT_WORKPLACE_MODAL);
	openCashbook(context);
};

const openCashbook = async (context) => {
	let res = await http('/v3/salepoint/cashbook', { method: 'PUT' });
	if (!handleError()(res, context, 200)) {
		return;
	}
};

const initCashbookShiftTransfer = async (context) => {
	let shift = await http('/v3/salepoint/cashbook-shift/init-transfer', { method: 'POST' });
	if (!handleError()(shift, context)) {
		return;
	}
};

const getIssueAmount = async (context) => {
	let { cashbook } = context.rootState.root.props;
	let date = DateTime.fromISO(cashbook.created_at).toISODate();
	let res = await http(`/v3/salepoint/issue-amount/${cashbook.salepoint_id}/${date}`);

	if (!handleError()(res, context)) {
		return;
	}

	let {
		data: { issue_amount = 0 }
	} = res;

	return context.commit(types.SET_ISSUE_AMOUNT, issue_amount);
};

const cancelCashbookShiftTransfer = async (context, payload) => {
	let shift = await http('/v3/salepoint/cashbook-shift/cancel-transfer', { method: 'POST' });
	if (!handleError()(shift, context)) {
		return;
	}
};

const toggleCashbookClosalModal = async (context) => {
	let { cashbookClosalInitialized } = context.state.props;
	if (context.state.state_key?.render === 'cashbook_closal_modal') {
		if (cashbookClosalInitialized) {
			context.commit(types.CANCEL_CASHBOOK_CLOSAL);
			await cancelCashbookClosal(context);
		}
		return context.commit(types.CLOSE_CASHBOOK_CLOSAL_MODAL);
	}
	if (!cashbookClosalInitialized) {
		await initCashbookClosal(context);
	}
};

const initCashbookClosal = async (context) => {
	let res = await http('/v3/salepoint/cashbook', { method: 'DELETE' });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.INIT_CASHBOOK_CLOSAL);
	context.commit(types.OPEN_CASHBOOK_CLOSAL_MODAL);
	return context.commit(rootTypes.namespaced.CASHBOOK_EVENT, res.data, { root: true });
};

const cancelCashbookClosal = async (context, payload) => {
	let res = await http('/v3/salepoint/cashbook/closal/cancel', { method: 'POST' });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.CANCEL_CASHBOOK_CLOSAL);
	return context.commit(rootTypes.namespaced.CASHBOOK_EVENT, res.data, { root: true });
};

const setCashbookClosalData = async (context, payload) => {
	// eslint-disable-next-line no-unused-vars
	let { loadingAction, ...data } = payload;
	let res = await http('/v3/salepoint/cashbook/closal/data', { method: 'POST', data });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data) {
		await print(res.data);
	}
	context.commit(types.CLOSE_CASHBOOK_CLOSAL_MODAL);
	let { cashbook } = context.rootState.root.props;
	if (!cashbook.uses_electronic_digital_signatures) {
		confirmCashbookClosal(context);
	}
};

const confirmCashbookClosal = async (context) => {
	let res = await http('/v3/salepoint/cashbook/closal/confirm', { method: 'POST' });
	if (!handleError()(res, context, 200)) {
		return;
	}
	context.commit(types.CANCEL_CASHBOOK_CLOSAL);
};

const validateAct = (context, inventoryActData) => {
	let { errors } = validateCreateInventoryAct(inventoryActData);
	if (errors) {
		context.commit(types.VALIDATION_ERRORS, errors);
		return false;
	}
	return true;
};

const confirmCashbookShiftTransfer = async (context, data) => {
	let { workplace_id, ...inventoryActData } = data || {};
	if (!validateAct(context, inventoryActData)) {
		return;
	}
	let res = await http('/v3/salepoint/cashbook-shift/confirm-transfer', {
		method: 'POST',
		data: { workplace_id, inventoryActData }
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		await print(actRes.data);
	}
	context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
};

const confirmCashbookShiftTransferAndOpenBook = async (context, data) => {
	let { workplace_id, ...inventoryActData } = data || {};
	if (!validateAct(context, inventoryActData)) {
		return;
	}
	let res = await http('/v3/salepoint/cashbook-shift/confirm-transfer', {
		method: 'POST',
		data: { workplace_id, inventoryActData }
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	let cbRes = await http('/v3/salepoint/cashbook', { method: 'PUT' });
	if (!handleError()(cbRes, context, 200)) {
		return;
	}
	if (res.data.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		await print(actRes.data);
	}
	context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
};

const closeCashbookShift = async (context, inventoryActData) => {
	if (!validateAct(context, inventoryActData)) {
		return;
	}
	let res = await http('/v3/salepoint/cashbook-shift/closal', { method: 'POST', data: inventoryActData });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		await print(actRes.data);
	}
	return context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
};

const inventoryActModalSubmit = async (context, inventoryActData) => {
	let { actActionOnSubmit } = context.state.props;
	if (!actActionOnSubmit) {
		return;
	}
	await actActionOnSubmit(context, inventoryActData);
};

const confirmCashFromCashdeskWithAct = async (context, payload) => {
	let { act_data, order_id } = payload;

	let res = await http('/v3/orders/cash-from-cashdesk', { method: 'POST', data: { order_id, act_data } });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data.act?.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.act.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		await print(actRes.data);
	}
	return context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
};

const toggleInventoryActModal = async (context, { scenario, cash, cashdesk, order } = {}) => {
	if (context.state.state_key.render === 'inventory_act') {
		return context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
	}

	let {
		user: { id: user_id, position, first_name, last_name, patronymic }
	} = context.rootState.root.props;
	let user_name = `${last_name} ${first_name} ${patronymic || ''}`;
	let { cashbook_shift: oldShift, cashbook } = context.rootState.root.props;
	if ([scenarios.confirmShiftTransfer, scenarios.confirmShiftTransferOpenBook].includes(scenario)) {
		let actActionOnSubmit =
			scenario === scenarios.confirmShiftTransfer
				? confirmCashbookShiftTransfer
				: confirmCashbookShiftTransferAndOpenBook;
		return context.commit(types.OPEN_INVENTORY_ACT_MODAL, {
			actScenario: scenario,
			actUserFromId: oldShift.user_id,
			actUserFromName: `${oldShift.last_name} ${oldShift.first_name} ${oldShift.patronymic || ''}`,
			actUserFromPosition: oldShift.position,
			actUserToId: user_id,
			actUserToName: user_name,
			actUserToPosition: position,
			actRecordedAmount: cashbook.current_balance,
			actActionOnSubmit
		});
	}
	if (scenario === scenarios.closeShift) {
		return context.commit(types.OPEN_INVENTORY_ACT_MODAL, {
			actCash: cash,
			actScenario: scenario,
			actUserFromId: user_id,
			actUserFromName: user_name,
			actUserFromPosition: position,
			actRecordedAmount: cashbook.current_balance,
			actActionOnSubmit: closeCashbookShift
		});
	}
	if (scenario === scenarios.createCashdeskAct) {
		let { user_id, user_first_name, user_last_name, user_patronymic, user_position } = cashdesk;

		return context.commit(types.OPEN_INVENTORY_ACT_MODAL, {
			actCash: cash,
			actType: actTypes.cashdesk,
			actScenario: scenario,
			actUserFromId: user_id,
			actUserFromName: `${user_last_name} ${user_first_name} ${user_patronymic || ''}`,
			actUserFromPosition: user_position,
			actUserToId: oldShift.user_id,
			actUserToName: `${oldShift.last_name} ${oldShift.first_name} ${oldShift.patronymic || ''}`,
			actUserToPosition: oldShift.position,
			actRecordedAmount: order.recorded_amount || 0,
			actActionOnSubmit: (context, act_data) =>
				confirmCashFromCashdeskWithAct(context, { act_data, order_id: order.id })
		});
	}
	if (scenario === scenarios.forceShiftClose) {
		let { user_id, user_first_name, user_last_name, user_patronymic, user_position } = cashdesk;
		return context.commit(types.OPEN_INVENTORY_ACT_MODAL, {
			actCash: cash,
			actType: actTypes.cashdesk,
			actScenario: scenario,
			actUserFromId: user_id,
			actUserFromName: `${user_last_name} ${user_first_name} ${user_patronymic || ''}`,
			actUserFromPosition: user_position,
			actUserToId: oldShift.user_id,
			actUserToName: `${oldShift.last_name} ${oldShift.first_name} ${oldShift.patronymic || ''}`,
			actUserToPosition: oldShift.position,
			actRecordedAmount: new BigNumber(cashdesk.in_balance).minus(cashdesk.out_balance).toFixed(2),
			actActionOnSubmit: (context, act_data) =>
				openedCashdesksActions.forceCloseCashdeskRequest(context, { act_data, cashdesk })
		});
	}
};

const lookupUsers = (context, { query, cb }) => {
	return lookup(`/v3/lookups/users/name?query=${query || ''}`).then((res) => {
		handleError()(res, context) && cb(res);
	});
};

export default {
	openCashbookOpenModal,
	toggleWorkplaceSelectModal,
	openCashbookWithShift,
	openCashbook,
	initCashbookShiftTransfer,
	cancelCashbookShiftTransfer,
	cancelCashbookClosal,
	toggleCashbookClosalModal,
	setCashbookClosalData,
	confirmCashbookClosal,
	toggleInventoryActModal,
	inventoryActModalSubmit,
	lookupUsers,
	getIssueAmount
};
