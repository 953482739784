const types = {
	providerChange: 'provider_change',
	newConnection: 'new_connection',
	certificate: 'certificate',
	payment: 'payment',
	act: 'act'
};

const locale = {
	[types.providerChange]: 'зміна постачальника',
	[types.newConnection]: 'нове підключення',
	[types.certificate]: 'довідка про відсутність заборгованості',
	[types.act]: 'акт звірки',
	[types.payment]: 'рахунок на сплату заборгованості'
}

module.exports = {
	enum: types,
	locale: locale,
	requestDocumentTypes: { certificate: types.certificate, payment: types.payment, act: types.act }
};
