import { render, staticRenderFns } from "./service-desk-success-modal.vue?vue&type=template&id=2c51edcc&scoped=true&"
import script from "./service-desk-success-modal.vue?vue&type=script&lang=js&"
export * from "./service-desk-success-modal.vue?vue&type=script&lang=js&"
import style0 from "./service-desk-success-modal.vue?vue&type=style&index=0&id=2c51edcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c51edcc",
  null
  
)

export default component.exports