import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('control_center');

export default core.createStore(actions, {}, mutations, {
	key: 'control_center',
	initial: 'idle',
	states: {
		idle: {
			onEntry: 'getControlCenterData',
			on: {
				[types.CONTROL_CENTER_DATA_SUCCESS]: 'render',
				[types.RESET_STATE]: 'idle'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.RESET_STATE]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_CASHBOOK_CLOSAL_MODAL]: 'cashbook_closal_modal',
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act',
						[types.OPEN_SELECT_CASH_MACHINE_MODAL]: 'select_cash_machine_modal',
						[types.OPEN_CASHDESK_ACTION_MODAL]: 'cashdesk_action_modal',
						[types.OPEN_GIVE_ACCEPT_CASH_MODAL]: 'give_accept_cash_modal',
						[types.OPEN_POS_DETAILS_MODAL]: 'pos_details_modal',
						[types.OPEN_CLOSAL_SHIFT_MODAL]: 'closal_shift_modal',
						[types.OPEN_ALERT_MODAL]: 'alert_modal',
						[types.OPEN_TRANSFER_WORKPLACE_MODAL]: 'transfer_workplace_modal',
						[types.OPEN_SELECT_WORKPLACE_MODAL]: 'select_workplace_modal'
					}
				},
				pos_details_modal: {
					on: {
						[types.CLOSE_POS_DETAILS_MODAL]: 'main'
					}
				},
				closal_shift_modal: {
					on: {
						[types.CLOSE_CLOSAL_SHIFT_MODAL]: 'main',
						[types.OPEN_CASHDESK_ACTION_MODAL]: 'cashdesk_action_modal'
					}
				},
				cashbook_closal_modal: {
					on: {
						[types.CLOSE_CASHBOOK_CLOSAL_MODAL]: 'main',
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act'
					}
				},
				inventory_act: {
					on: {
						[types.CLOSE_INVENTORY_ACT_MODAL]: 'main'
					}
				},
				select_cash_machine_modal: {
					on: {
						[types.CLOSE_SELECT_CASH_MACHINE_MODAL]: 'main'
					}
				},
				cashdesk_action_modal: {
					on: {
						[types.CLOSE_CASHDESK_ACTION_MODAL]: 'main',
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act'
					}
				},
				give_accept_cash_modal: {
					on: {
						[types.CLOSE_GIVE_ACCEPT_CASH_MODAL]: 'main',
						[types.CLOSE_CASHDESK_ACTION_MODAL]: 'main'
					}
				},
				alert_modal: {
					on: {
						[types.CLOSE_ALERT_MODAL]: 'main'
					}
				},
				transfer_workplace_modal: {
					on: {
						[types.CLOSE_TRANSFER_WORKPLACE_MODAL]: 'main'
					}
				},
				select_workplace_modal: {
					on: {
						[types.CLOSE_SELECT_WORKPLACE_MODAL]: 'main'
					}
				}
			}
		}
	}
});
