const types = {
	cashdesk: 'cashdesk',
	acquiring: 'acquiring',
	partner: 'partner',
	sst: 'sst'
};

const locale = {
	[types.cashdesk]: 'каса',
	[types.acquiring]: 'технiчне',
	[types.partner]: 'партнер',
	[types.sst]: 'пткс'
};

module.exports = {
	enum: types,
	locale: locale
};
