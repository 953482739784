<template>
	<div class="calculator-content" ref="wrapper">
		<div class="calculator-header" ref="header">
			Покупюрний калькулятор
			<div slot="icon" class="icon-close-calculator" @click.stop="toggleCalculator" />
		</div>
		<div class="calculator-body">
			<a class="clear-calculator-cash" @click="cash = null">Очистити</a>
			<NP-Banknotes
				v-model="cash"
				:class="cashAndCashdeskBalanceDiscrepancy.class"
				:banknote-denominations="banknoteDenominations"
				:coin-denominations="coinDenominations"
				:lists="root.lists"
				:cashbook-total="cashdeskBalance"
				cashbook-total-text="Баланс каси"
				:total-amount-text="cashAndCashdeskBalanceDiscrepancy.text"
			/>
		</div>
	</div>
</template>

<script>
import { formatters } from 'novapay-ui';
import BigNumber from 'bignumber.js';
import calcCash from '@services/calculate-cash';

import { enum as banknoteDenominations } from '@repo/enums/banknote-denominations';
import { enum as coinDenominations } from '@repo/enums/coin-denominations';
import { enum as cashdeskStatuses } from '@repo/enums/cashdesk-statuses';

export default {
	name: 'Calculator',
	props: {
		root: { type: Object, default: null },
		toggleCalculator: { type: Function, default: null }
	},
	data() {
		return {
			banknoteDenominations,
			coinDenominations,
			cash: null
		};
	},
	computed: {
		cashAndCashdeskBalanceDiscrepancy() {
			let discrepancy = new BigNumber(calcCash(this.cash)).minus(this.cashdeskBalance);
			return {
				text: `( ${formatters.formatMoney(discrepancy.toString())} )`,
				class: discrepancy.gte(0) ? 'greater' : 'less'
			};
		},
		cashdeskBalance() {
			let { cashdesk = {} } = this.root;
			if (!cashdesk || cashdesk.status === cashdeskStatuses.closed) {
				return undefined;
			}
			return new BigNumber(cashdesk.in_balance).minus(cashdesk.out_balance).toString();
		}
	},
	methods: {
		handleDragStart() {
			this.$refs.header.style.cursor = 'move';
			this.$refs.header.addEventListener('mousemove', this.onDrag);
		},
		handleDragStop() {
			this.$refs.header.style.cursor = 'default';
			this.$refs.header.removeEventListener('mousemove', this.onDrag);
		},
		onDrag({ movementX, movementY }) {
			let { wrapper } = this.$refs;
			let style = window.getComputedStyle(wrapper);
			let height = parseInt(style.height);
			let left = parseInt(style.left);
			let top = parseInt(style.top);
			wrapper.style.right = 'inherit';
			if (left + movementX > 0) {
				wrapper.style.left = `${left + movementX}px`;
			}
			if (top + height + movementY < document.body.scrollHeight && top + movementY > 0) {
				wrapper.style.top = `${top + movementY}px`;
			}
		}
	},
	mounted() {
		this.$refs.header.addEventListener('mousedown', this.handleDragStart);
		document.addEventListener('mouseup', this.handleDragStop);
	},
	beforeDestroy() {
		this.$refs.header.removeEventListener('mousedown', this.handleDragStart);
		document.removeEventListener('mouseup', this.handleDragStop);
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-close: '~@assets/images/icon-close.svg';

.calculator-content {
	position: absolute;
	top: 50px;
	right: 20px;
	background: $white;
	border: $tab-border;
	box-shadow: $tab-box-shadow;
	border-radius: $border-radius;
	cursor: default;
	z-index: $menu-tab-z-index;
	.calculator-header {
		border-bottom: 1px solid $gray-10;
		padding: 14px 24px;
		font-size: 16px;
		font-family: Proxima Nova Semibold;
		letter-spacing: 0.8px;
		.icon-close-calculator {
			position: absolute;
			right: 10px;
			top: 10px;
			margin: 3px 6px 0 0;
			font-family: Proxima Nova;
			@include make-icon($icon-close, $gray-30, 24px, 24px);

			&:hover:not([disabled]) {
				cursor: pointer;
				@include make-icon($icon-close, $gray-50, 24px, 24px);
			}
		}
	}

	.calculator-body {
		padding: 14px 24px 24px 24px;
		.clear-calculator-cash {
			position: absolute;
			right: 25px;
			color: $red;
			font-family: Proxima Nova;
			font-size: 13px;
			text-decoration: underline;
			&:hover {
				cursor: pointer;
			}
		}
		::v-deep .banknotes {
			&.less {
				.totals div > .totals-label {
					color: $red;
				}
			}
			&.greater {
				.totals div > .totals-label {
					color: $green;
				}
			}
			width: 500px;
			.totals {
				.cashbook-amount {
					span.wrap {
						display: flex;
						flex-direction: row-reverse;
						.totals-label {
							margin: 0 8px 0 0;
						}
					}
				}
				.totals-label {
					font-size: 16px;
				}
			}
			.input.disabled.monetary {
				input {
					background-color: #dcf2e5;
				}
				&.no-value {
					input {
						background-color: $gray-0;
					}
				}
			}
		}
	}
}
</style>
