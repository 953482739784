import { core, http } from 'novapay-ui';
import { v4 as uuid } from 'uuid';
import find from 'lodash/find';
import filter from 'lodash/filter';

import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';
import print from '@services/print';
import { enum as statuses } from '@repo/enums/inventory-record-asset-statuses';

const types = core.getTypes('inventory_record');
let rootTypes = core.getTypes('root');

const { actions: commonActions } = commonStore('inventory-record');

const getPageData = async (context) => {
	const { id, asset_status } = context.rootState.root.props.inventory_record || {};
	const isRevision = asset_status === statuses.revision;

	let assetsRes = await http(isRevision ? `/v3/inventory-records/record-assets/${id}` : `/v3/inventory-records/assets`);

	if (!handleError()(assetsRes, context)) {
		return;
	}

	let assets = assetsRes.data.map((asset) => ({
		...asset,
		uuid: uuid(),
		file: isRevision ? { name: asset.image_name, url: asset.image } : undefined,
		factual_count: isRevision ? asset.factual_count : 0
	}));

	context.commit(types.PAGE_DATA_SUCCESS, {
		assets,
		query: context.state.props.query
	});
	commonActions.hideRootSplashScreen(context);
};

const toggleAssetModal = (context, { asset, modal_mode } = {}) => {
	let state_key = context.state.state_key.render;
	if (state_key && state_key === 'asset_modal') {
		return context.commit(types.CLOSE_ASSET_MODAL);
	}
	return context.commit(types.OPEN_ASSET_MODAL, { asset, modal_mode });
};

const updateAssets = (context, row) => {
	context.commit(types.CLOSE_ASSET_MODAL);
	let newAssets = null;
	if (!row.uuid) {
		newAssets = context.state.props.assets.concat({ ...row, uuid: uuid() });
	} else {
		newAssets = context.state.props.assets.map((asset) => {
			if (asset.uuid !== row.uuid) {
				return asset;
			}
			return { ...asset, ...row };
		});
	}
	if (filter(newAssets, (asset) => (asset.barcode || asset.name) === (row.barcode || row.name)).length > 1) {
		return context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{
				title: 'Помилка',
				text: 'вказане ТМЦ вже iснує в описi. ' + 'Змiнiть кiлькiсть iснуючого, або вкажiть нову назву.'
			},
			{ root: true }
		);
	}
	return context.commit(types.ASSETS_SUCCESS, newAssets);
};

const removeSurplusAsset = (context, { uuid }) => {
	return context.commit(
		types.ASSETS_SUCCESS,
		filter(context.state.props.assets, (a) => a.uuid !== uuid)
	);
};

const searchBarcode = (context, barcode) => {
	if (!barcode) {
		return;
	}
	barcode = barcode.trim();
	let asset = find(context.state.props.assets, { barcode });
	if (!asset) {
		return context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{
				title: 'Помилка',
				text:
					'ТМЦ за введеним ШК не знайдено. ' +
					'Перевiрте корректнiсть та створiть заявку на СД на внесення необхiдного ТМЦ'
			},
			{ root: true }
		);
	}
	toggleAssetModal(context, { asset });
};

const save = async (context) => {
	let { assets } = context.state.props;
	let hasAbsentAssets = assets.some((a) => !a.factual_count);
	if (hasAbsentAssets && !confirm('В описi є вiдсутнi ТМЦ. Продовжити формування опису?')) {
		return;
	}
	let data = {
		inventory_record_id: context.rootState.root.props.inventory_record.id,
		assets: assets.map((a) => ({
			...a,
			barcode: a.barcode || null,
			image: a.file ? a.file.name : null
		}))
	};

	let res = await http({ url: `/v3/inventory-records/record`, method: 'POST', data });
	if (!handleError()(res, context, 200)) {
		return;
	}

	context.commit(types.INVENTORIZATION_COMPLETED);

	await printLastRecord(context);
};

const printLastRecord = async (context) => {
	let res = await http({
		url: `/v3/inventory-records/print`,
		method: 'GET'
	});
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

export default {
	getPageData,
	toggleAssetModal,
	updateAssets,
	removeSurplusAsset,
	searchBarcode,
	save,
	printLastRecord,
	...commonActions
};
