import { core } from 'novapay-ui';
import commonStore from '@services/common-store';

const types = core.createTypes('operation_recipient_claims');
const { mutations } = commonStore('operation_recipient_claims');

export default {
	...mutations,
	[types.GET_CLAIMS_SUCCESS]: (state, { claims, total }) => {
		state.props = { ...state.props, claims, total };
	},
	[types.OPEN_CLAIM_MODAL]: (state, edit_entity) => {
		state.props = { ...state.props, edit_entity };
	},
	[types.CLOSE_CLAIM_MODAL]: (state) => {
		state.props = { ...state.props, edit_entity: null };
	}
};
