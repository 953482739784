import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('acts');
const { mutations } = commonStore('acts');

export default {
	...mutations,
	[types.PAGE_DATA_SUCCESS]: (
		state,
		{ acts = null, quarter_document = null, cashbook = null, totals, total = null } = {}
	) => {
		state.props = { ...state.props, acts, quarter_document, cashbook, totals, total };
	},
	[types.CASHBOOK_EVENT]: (state, cashbook) => {
		state.props = { ...state.props, cashbook };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { cashbook_id: state.props.cashbook_id, cashbook: state.props.cashbook, query };
	},
	[types.SET_CASHBOOK_ID]: (state, cashbook_id) => {
		state.props = { cashbook_id };
	},
	[types.CREATE_ACT_SUCCESS]: (state, act) => {
		state.props = {
			...state.props,
			acts: [act, ...state.props.acts],
			total: (state.props.total || 0) + 1
		};
	},
	[types.VALIDATION_ERRORS]: (state, validation_errors) => {
		state.props = { ...state.props, validation_errors };
	},
	[types.CLOSE_INVENTORY_ACT_MODAL]: (state) => {
		state.props = { ...state.props, validation_errors: null };
	},
	[types.OPEN_ACT_SCAN_MODAL]: (state, { scanAct }) => {
		state.props = { ...state.props, scanAct };
	},
	[types.CLOSE_ACT_SCAN_MODAL]: (state) => {
		state.props = { ...state.props, scanAct: null };
	}
};
