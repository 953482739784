import qs from 'qs';
import { core, http, lookup } from 'novapay-ui';
import { DateTime } from 'luxon';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import print from '@services/print';
import router from '@/router';
import { enum as updTypes } from '@repo/enums/user-printed-document-types';
import { enum as salepointTypes } from '@repo/enums/salepoint-types';

const { actions: commonStoreActions } = commonStore('profile');
const types = core.getTypes('profile');
const rootTypes = core.getTypes('root');

const initModule = async (context) => {
	let res = await http(`/v3/user-printed-documents/profile`);
	if (!handleError()(res, context)) {
		return;
	}
	if (res.data.salepoint_type === salepointTypes.partner) {
		return router.push('/404');
	}
	context.commit(types.GET_PROFILE_SUCCESS, res.data);
	return commonStoreActions.hideRootSplashScreen(context);
};

const getDocumentsList = async (context, query) => {
	let res = await http('/v3/user-printed-documents/list', { query });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.GET_DOCUMENTS_SUCCESS, { documents: res.data, query });
};

const createVacationApplication = async (context, data) => {
	if (
		data.diff_days >= 11 &&
		data.diff_days <= 13 &&
		!confirm(
			'Згідно правил законодавства України просимо вас переглянути ' +
				`кiлькiсть обраних днів до відпустки та збільшити їх до 14 днів.

Ви впевнені що бажаєте лишити обрану кількість днів?`
		)
	) {
		return;
	}
	const daysToStart = Math.ceil(DateTime.fromISO(data.start_date).diffNow('days').toObject().days);
	if (daysToStart <= 5) {
		alert(
			'Заявка на відпустку має бути погоджена всіма учасниками процесу до 14:00 дати початку відпустки. ' +
				'В іншому випадку заявку буде відхилено автоматично.'
		);
	}
	let res = await http(`/v3/user-printed-documents`, {
		method: 'PUT',
		data: { type: updTypes.vacationApplication, metadata: data }
	});
	const processing = () => {
		if (res.data.code === 'DeputyRequiredError') {
			context.commit(types.DEPUTY_ID_REQUIRED);
			return false;
		}
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{
				text: res.data.error,
				code: res.data.code
			},
			{ root: true }
		);
		return false;
	};
	if (!handleError({ processing })(res, context)) {
		return;
	}
	context.commit(types.DEPUTY_ID_REQUIRED, false);
	router.push('/profile/documents', () =>
		context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ variant: 'success', title: 'Заяву успішно створено', subtitle: 'Документ доступний до підпису' },
			{ root: true }
		)
	);
};

const lookupUsers = (context, { query, cb }) => {
	const user_id = context.rootState?.root?.props?.user?.id;
	return lookup(`/v3/lookups/users/name?${qs.stringify({ query, exclude: user_id ? [user_id] : undefined })}`).then(
		(res) => {
			handleError()(res, context, 200) && cb(res.data);
		}
	);
};

const printDocument = async (context, { id }) => {
	let res = await http(`/v3/user-printed-documents/print/${id}`);
	if (!handleError()(res, context)) {
		return;
	}
	print(res.data);
};

const cancelVacationApplication = async (context, { id }) => {
	if (!confirm('Ви впевнені, що хочете відмінити заявку на відпустку?')) {
		return;
	}
	let res = await http(`/v3/user-printed-documents/${id}`, {
		method: 'DELETE',
		data: {
			comment: 'Користувач відмінив заявку на відпустку'
		}
	});
	if (!handleError()(res, context)) {
		return;
	}
	context.dispatch('getDocumentsList', { ...context.state.props.documents_query, loadingAction: true });
};

export default {
	...commonStoreActions,
	initModule,
	getDocumentsList,
	createVacationApplication,
	lookupUsers,
	printDocument,
	cancelVacationApplication
};
