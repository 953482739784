<template>
	<NP-Modal class="inventory-act-modal" variant="sidebar" label="Створення акту інвентаризації" :toggle="toggle">
		<form @submit.prevent="apply" class="container">
			<div>
				<template v-if="isOnActsPage">
					<div class="act-type">
						<NP-Select id="act-type-select" label="Тип акту" :options="actTypeOptions" v-model="type" />
					</div>
				</template>

				<div class="sst-code" v-show="isSstAct">
					<NP-Select
						id="sst_id"
						v-model="sst_id"
						:options="sstOptions"
						class="select"
						:async-options="_lookupSelfServiceTerminals"
						label="номер ПТКС"
						:error="fieldErrors.sst_id"
						searchable
						do-empty-search-on-init
						with-icon-search
					/>
				</div>

				<template v-if="isQuarterAct">
					<p class="label bold">Причина створення акту</p>
					<div class="act-occasion-and-reason">
						<NP-Select
							id="act-creation-occasion-name"
							placeholder="Оберіть причину"
							v-model="act_creation_occasion_name"
							:options="actOccasionOptions"
							:error="fieldErrors.act_creation_occasion_name"
						/>
						<div class="reason-number-and-date">
							<NP-Input
								v-model="act_creation_occasion_number"
								class="act-creation-reason-number"
								name="act_creation_occasion_number"
								placeholder="№"
								type="text"
								:error="fieldErrors.act_creation_occasion_number"
							/>
							<NP-Input
								v-model="act_creation_occasion_date"
								class="act_creation_occasion_date"
								name="act_creation_occasion_date"
								type="date"
								:error="fieldErrors.act_creation_occasion_date"
							/>
						</div>
					</div>
				</template>
				<p class="label bold" v-if="showWorkplaceInput">Робоче місце</p>
				<div class="workplace" v-if="showWorkplaceInput">
					<NP-Input
						v-model="workplaceCode"
						label="ШК робочого місця"
						:error="workplaceError"
						:has-error="Boolean(workplaceError)"
					/>
				</div>
				<p class="label bold">Здав/МВО</p>
				<div class="user-from">
					<NP-Input
						v-model="user_from_position"
						name="user_from_position"
						label="Посада"
						type="text"
						:error="fieldErrors.user_from_position"
					/>
					<NP-Select
						id="user_from_name"
						label="ПІБ"
						v-model="user_from"
						:options="userFromOptions"
						:async-options="(q) => _lookupUsers(q, 'userFromOptions')"
						@select="(val) => onUserSelect(val, 'user_from')"
						:error="fieldErrors.user_from_name"
						:disabled="!isOnActsPage"
						do-empty-search-on-init
						searchable
						hold-search-string-on-open
					/>
				</div>

				<template v-if="isChiefCashierAct || isCashdeskAct || isSstAct">
					<p class="label bold">Прийняв</p>
					<div class="user-to">
						<NP-Input
							v-model="user_to_position"
							name="user_to_position"
							label="Посада"
							type="text"
							:error="fieldErrors.user_to_position"
							:disabled="closeShiftScenario || isCashdeskAct"
						/>
						<NP-Select
							id="user_to_name"
							label="ПІБ"
							v-model="user_to"
							:options="userToOptions"
							:async-options="(q) => _lookupUsers(q, 'userToOptions')"
							:error="fieldErrors.user_to_name"
							:disabled="!isOnActsPage"
							do-empty-search-on-init
							searchable
							allow-empty
							hold-search-string-on-open
						/>
					</div>
				</template>

				<div class="line" />

				<div class="factual-amount">
					<NP-Banknotes
						v-model="local_cash"
						:banknote-denominations="banknoteDenominations"
						:coin-denominations="coinDenominations"
						:init-bills-collapsed="!!local_cash"
						:init-coins-collapsed="!!local_cash"
						:banknotes-error="fieldErrors.cash || fieldErrors.factual_amount"
						:lists="root.lists"
						:disabled="shouldDisableCash"
						:hide-totals="!isCashdeskAct"
						@total-amount="(amount) => (factual_amount = amount)"
					/>
				</div>

				<p class="label bold">Баланс</p>
				<CashbookTotals
					:factual-amount="factual_amount"
					:recorded-amount="recordedAmount"
					:discrepancy="discrepancy"
					:recorded-amount-title="recordedAmountTitle"
				/>

				<div class="line margin-bottom" />

				<template v-if="isChiefCashierAct">
					<div class="keys-and-plombetter">
						<div class="safe-keys">
							<NP-Input
								v-model="safe_keys_amount"
								name="safe_keys_amount"
								placeholder=""
								type="number"
								:error="fieldErrors.safe_keys_amount"
								:min="1"
								:decimal="0"
								:label="'Ключі від сейфу, шт.'"
							/>
						</div>

						<div class="plombeter_number">
							<NP-Input
								v-model="plombeter_number"
								name="plombeter_number"
								placeholder=""
								type="text"
								:error="fieldErrors.plombeter_number"
								:label="'Пломбір №'"
							/>
						</div>
					</div>

					<div class="sst-keys" v-if="hasSST">
						<div class="sst-safe-keys-amount">
							<NP-Input
								v-model="safe_sst_keys_amount"
								name="safe_sst_keys_amount"
								placeholder=""
								type="number"
								:error="fieldErrors.safe_sst_keys_amount"
								:min="1"
								:decimal="0"
								label="Ключі від фальш-дверей та сейфу ПТКС, шт."
							/>
						</div>
						<div class="sst-case-keys-amount">
							<NP-Input
								v-model="case_sst_keys_amount"
								name="case_sst_keys_amount"
								placeholder=""
								type="number"
								:error="fieldErrors.case_sst_keys_amount"
								:min="1"
								:decimal="0"
								label="Ключі від касети ПТКС, шт."
							/>
						</div>
					</div>

					<div class="line margin-bottom" />
				</template>

				<template v-if="isSstAct">
					<div class="self-service-terminal">
						<NP-Select
							id="sst-case-integrity"
							:key="'sst-case-integrity'"
							label="Цілісність пломби ПТКС"
							class="select"
							v-model="sst_case_integrity"
							:options="root.lists['self-service-terminal-case-integrities']"
							:error="fieldErrors.sst_case_integrity"
						/>
						<div class="sst-numbers">
							<NP-Input
								class="sst-plombeter-number"
								label="№ пломби ПТКС"
								v-model="sst_plombeter_number"
								:error="fieldErrors.sst_plombeter_number"
							/>
							<NP-Input class="sst-case" label="№ касети" v-model="sst_case" :error="fieldErrors.sst_case" />
						</div>
					</div>

					<div class="line margin-bottom" />
				</template>

				<p class="label bold">Пояснення</p>
				<div class="reason">
					<NP-Textarea
						v-model="reason"
						name="reason"
						:error="fieldErrors.reason"
						:disabled="reasonDisabled"
						@keydown.enter="handleOnEnter"
					/>
				</div>

				<p class="label bold">Голова комісії</p>
				<div class="committee-head">
					<NP-Input
						v-model="committee_head_position"
						name="committee_head_position"
						label="Посада"
						type="text"
						:error="fieldErrors.committee_head_position"
					/>
					<NP-SearchableInput
						id="committee_head"
						v-model="committee_head_name"
						:error="fieldErrors.committee_head_name"
						:options="committeeHeadOptions"
						:search-fn="(q) => _lookupUsers(q, 'committeeHeadOptions')"
						@select="(val) => onUserSelect(val, 'committee_head')"
						placeholder="ПІБ"
						open-direction="top"
					/>
				</div>

				<p class="label bold">Член комісії</p>
				<div class="committee-member">
					<NP-Input
						v-model="committee_member_position"
						name="committee_member_position"
						type="text"
						label="Посада"
						:error="fieldErrors.committee_member_position"
					/>
					<NP-SearchableInput
						id="committee_member"
						v-model="committee_member_name"
						:error="fieldErrors.committee_member_name"
						:options="committeeMemberOptions"
						:search-fn="(q) => _lookupUsers(q, 'committeeMemberOptions')"
						@select="(val) => onUserSelect(val, 'committee_member')"
						placeholder="ПІБ"
						open-direction="top"
					/>
				</div>
				<p class="label bold">Член комісії</p>
				<div class="committee-member">
					<NP-Input
						v-model="second_committee_member_position"
						name="second_committee_member_position"
						type="text"
						label="Посада"
						:error="fieldErrors.second_committee_position"
					/>
					<NP-SearchableInput
						id="second_committee_member"
						v-model="second_committee_member_name"
						:error="fieldErrors.second_committee_name"
						:options="secondCommitteeMemberOptions"
						:search-fn="(q) => _lookupUsers(q, 'secondCommitteeMemberOptions')"
						@select="(val) => onUserSelect(val, 'second_committee_member')"
						placeholder="ПІБ"
						open-direction="top"
					/>
				</div>
			</div>
			<div class="buttons">
				<NP-Button type="submit" priority="high" class="confirm-btn" :loading="loading"> Зберегти </NP-Button>
				<NP-Button type="button" class="cancel-btn" @click.stop="toggle">
					<i slot="icon" class="icon-close" />
					Скасувати
				</NP-Button>
			</div>
		</form>
	</NP-Modal>
</template>

<script>
import { formatters, validation } from 'novapay-ui';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';

import scenarios from './scenarios';

import { enum as banknoteDenominations } from '@repo/enums/banknote-denominations';
import { enum as coinDenominations } from '@repo/enums/coin-denominations';
import { enum as actTypes } from '@repo/enums/inventory-act-types';
import { enum as actOccasions } from '@repo/enums/act-creation-occasions';

export default {
	name: 'InventoryActForm',
	props: {
		isOnActsPage: { type: Boolean, default: false },
		printPagesPerYear: { type: Boolean, default: false },
		printPagesPerMonth: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		actType: { type: String, default: null },
		actTypeData: { type: Object, default: () => {} },
		currentScenario: { type: String, default: null },
		userFromId: { type: Number, default: null },
		userFromName: { type: String, default: null },
		userFromPosition: { type: String, default: null },
		userToId: { type: Number, default: null },
		userToName: { type: String, default: null },
		userToPosition: { type: String, default: null },
		recordedAmount: { type: [String, Number], default: null },
		root: { type: Object, default: null },
		cash: { type: Object, default: null },
		quarterDocument: { type: Object, default: null },
		toggle: { type: Function, default: null },
		submit: { type: Function, default: null },
		lookupUsers: { type: Function, default: null },
		lookupSelfServiceTerminals: { type: Function, default: null },
		errors: { type: Array, default: () => [] }
	},
	data() {
		return {
			workplaceCode: '',
			workplaceError: null,
			banknoteDenominations,
			coinDenominations,
			localErrors: [],

			committeeHeadOptions: [],
			committeeMemberOptions: [],
			secondCommitteeMemberOptions: [],
			userFromOptions: [],
			userToOptions: [],
			actTypeOptions: [actTypes.chiefCashier, actTypes.quarter].map((type) => ({
				id: type,
				text: formatters.capitalize(formatters.getListTextValue(type, this.root.lists['inventory-act-types']))
			})),
			actOccasionOptions: Object.keys(actOccasions).map((occasion) => ({
				id: occasion,
				text: formatters.capitalize(formatters.getListTextValue(occasion, this.root.lists['act-creation-occasions']))
			})),
			sstOptions: [],

			type: {
				id: this.actType || actTypes.chiefCashier,
				text: formatters.capitalize(
					formatters.getListTextValue(this.actType || actTypes.chiefCashier, this.root.lists['inventory-act-types'])
				)
			},
			...this.prepareInitialQuarterActOccasionData(),

			factual_amount: null,
			user_from: this.userFromId ? { id: this.userFromId, text: this.userFromName } : null,
			user_from_name: this.userFromName || null,
			user_from_position: this.userFromPosition || null,
			user_to: this.userToId ? { id: this.userToId, text: this.userToName } : null,
			user_to_name: this.userToName || null,
			user_to_position: this.userToPosition || null,
			local_cash: this.cash,
			reason: null,
			safe_keys_amount: null,
			plombeter_number: this.root.salepoint.plombeter_number || null,
			safe_sst_keys_amount: null,
			case_sst_keys_amount: null,
			sst_id: this.actTypeData?.sst ? { id: this.actTypeData?.sst.id, text: this.actTypeData?.sst.code } : null,
			sst_case_integrity: null,
			sst_plombeter_number: null,
			sst_case: null,
			committee_head_name: null,
			committee_head_position: null,
			committee_member_name: null,
			committee_member_position: null,
			second_committee_member_name: null,
			second_committee_member_position: null
		};
	},
	computed: {
		allowReason() {
			return this.hasDiscrepancy;
		},
		discrepancy() {
			return new BigNumber(this.factual_amount).minus(this.recordedAmount).toNumber();
		},
		hasDiscrepancy() {
			return Number(this.discrepancy) !== 0;
		},
		recordedAmountTitle() {
			return this.actType === actTypes.sst ? 'Сума ПТКС' : 'Сума за КК';
		},
		fieldErrors() {
			let fieldKeys = [
				'cash',
				'user_from_position',
				'user_to_position',
				'factual_amount',
				'reason',
				'safe_keys_amount',
				'plombeter_number',
				'safe_sst_keys_amount',
				'case_sst_keys_amount',
				'sst_id',
				'sst_case_integrity',
				'sst_plombeter_number',
				'sst_case',
				'committee_head_name',
				'committee_head_position',
				'committee_member_name',
				'committee_member_position',
				'second_committee_name',
				'second_committee_position',
				'act_creation_occasion_name',
				'act_creation_occasion_number',
				'act_creation_occasion_date',
				'type'
			];
			let errs = this.localErrors ? this.localErrors.concat(this.errors || []) : this.errors;
			return validation.computeValidationMessages(errs, fieldKeys);
		},
		isChiefCashierAct() {
			return this.type?.id === actTypes.chiefCashier;
		},
		isQuarterAct() {
			return this.type?.id === actTypes.quarter;
		},
		isCashdeskAct() {
			return this.type?.id === actTypes.cashdesk;
		},
		isSstAct() {
			return this.type?.id === actTypes.sst;
		},
		hasSST() {
			return this.root.salepoint.virtual_salepoints_count > 0;
		},
		closeShiftScenario() {
			return [scenarios.closeShift].includes(this.currentScenario);
		},
		reasonDisabled() {
			return new BigNumber(this.factual_amount).eq(this.recordedAmount);
		},
		shouldDisableCash() {
			return (
				scenarios &&
				[scenarios.closeShift, scenarios.createCashdeskAct, scenarios.forceShiftClose].includes(this.currentScenario)
			);
		},
		showWorkplaceInput() {
			return (
				scenarios &&
				[scenarios.confirmShiftTransfer, scenarios.confirmShiftTransferOpenBook].includes(this.currentScenario)
			);
		}
	},
	watch: {
		user_to(val) {
			this.onUserSelect(val, 'user_to');
		},
		allowReason(newVal, oldVal) {
			if (!newVal && oldVal) {
				this.reason = '';
			}
		}
	},
	methods: {
		handleOnEnter(e) {
			if (!e.shiftKey) {
				e.preventDefault();
				this.apply();
			}
		},
		_lookupUsers(query, field) {
			return this.lookupUsers({
				query,
				id: field,
				cb: (res) => {
					this[field] = res.data;
				}
			});
		},
		_lookupSelfServiceTerminals(query) {
			if (!this.lookupSelfServiceTerminals) {
				return Promise.resolve();
			}
			return this.lookupSelfServiceTerminals({
				query,
				cb: (res) => (this.sstOptions = res.data)
			});
		},
		onUserSelect(result, field) {
			let text = result?.text && result.text.includes('(') ? result.text.split('(')[0].trim() : result?.text;
			this[`${field}_name`] = text;
			this[`${field}_position`] = result?.position || '';
		},
		prepareInitialQuarterActOccasionData() {
			let act_creation_occasion_name = null;
			let act_creation_occasion_number = null;
			let act_creation_occasion_date = null;
			if (this.quarterDocument) {
				let occasion_name_text = formatters.capitalize(
					formatters.getListTextValue(
						this.quarterDocument.act_creation_occasion_name,
						this.root.lists['act-creation-occasions']
					)
				);
				act_creation_occasion_name = occasion_name_text
					? {
							id: this.quarterDocument.act_creation_occasion_name,
							text: occasion_name_text
					  }
					: null;
				// eslint-disable-next-line prefer-destructuring
				act_creation_occasion_number = this.quarterDocument.act_creation_occasion_number;
				act_creation_occasion_date = this.quarterDocument.act_creation_occasion_date
					? DateTime.fromISO(this.quarterDocument.act_creation_occasion_date).toFormat('dd-MM-yyyy')
					: null;
			}
			return {
				act_creation_occasion_name,
				act_creation_occasion_number,
				act_creation_occasion_date
			};
		},
		prepareOccasionDate() {
			return this.act_creation_occasion_date.replace(/\//g, '-').split('-').reverse().join('-');
		},
		fillLocalErrors() {
			this.localErrors = [];
			if (this.user_to_name && !this.user_to_position) {
				this.localErrors = [...this.localErrors, { dataPath: 'user_to_position', message: `обов'язкове поле` }];
			}

			if (this.committee_head_name && this.committee_head_name === this.committee_member_name) {
				this.localErrors = [
					...this.localErrors,
					{ dataPath: 'committee_head_name', message: `не повинно дорівнювати Члену комісії` },
					{ dataPath: 'committee_member_name', message: `не повинно дорівнювати Голові комісії` }
				];
			}
			if (this.committee_head_name && this.committee_head_name === this.second_committee_member_name) {
				this.localErrors = [
					...this.localErrors,
					{ dataPath: 'committee_head_name', message: `не повинно дорівнювати Члену комісії` },
					{ dataPath: 'second_committee_name', message: `не повинно дорівнювати Голові комісії` }
				];
			}

			if (this.committee_member_name && this.committee_member_name === this.second_committee_member_name) {
				this.localErrors = [
					...this.localErrors,
					{ dataPath: 'committee_member_name', message: `не повинно дорівнювати iншому члену комісії` },
					{ dataPath: 'second_committee_name', message: `не повинно дорівнювати iншому члену комісії` }
				];
			}

			if (this.isChiefCashierAct) {
				if (!this.safe_keys_amount) {
					this.localErrors = [...this.localErrors, { dataPath: 'safe_keys_amount', message: `обов'язкове поле` }];
				}

				if (this.hasSST) {
					if (!this.safe_sst_keys_amount) {
						this.localErrors = [...this.localErrors, { dataPath: 'safe_sst_keys_amount', message: `обов'язкове поле` }];
					}

					if (!this.case_sst_keys_amount) {
						this.localErrors = [...this.localErrors, { dataPath: 'case_sst_keys_amount', message: `обов'язкове поле` }];
					}
				}
			}

			if (this.hasDiscrepancy && !(this.reason || '').trim().length) {
				this.localErrors = [...this.localErrors, { dataPath: 'reason', message: `обов'язкове поле` }];
			}

			if (this.committee_head_position && !this.committee_head_name) {
				this.localErrors = [...this.localErrors, { dataPath: 'committee_head_name', message: `обов'язкове поле` }];
			}

			if (this.committee_head_name && !this.committee_head_position) {
				this.localErrors = [...this.localErrors, { dataPath: 'committee_head_position', message: `обов'язкове поле` }];
			}

			if (this.committee_member_position && !this.committee_member_name) {
				this.localErrors = [...this.localErrors, { dataPath: 'committee_member_name', message: `обов'язкове поле` }];
			}

			if (this.committee_member_name && !this.committee_member_position) {
				this.localErrors = [
					...this.localErrors,
					{ dataPath: 'committee_member_position', message: `обов'язкове поле` }
				];
			}

			if (this.second_committee_member_position && !this.second_committee_member_name) {
				this.localErrors = [...this.localErrors, { dataPath: 'second_committee_name', message: `обов'язкове поле` }];
			}

			if (this.second_committee_member_name && !this.second_committee_member_position) {
				this.localErrors = [
					...this.localErrors,
					{ dataPath: 'second_committee_position', message: `обов'язкове поле` }
				];
			}

			if (this.isSstAct) {
				if (!this.sst_id) {
					this.localErrors = [...this.localErrors, { dataPath: 'sst_id', message: `обов'язкове поле` }];
				}

				if (!this.sst_case_integrity) {
					this.localErrors = [...this.localErrors, { dataPath: 'sst_case_integrity', message: `обов'язкове поле` }];
				}

				if (!this.sst_plombeter_number) {
					this.localErrors = [...this.localErrors, { dataPath: 'sst_plombeter_number', message: `обов'язкове поле` }];
				}

				if (!this.sst_case) {
					this.localErrors = [...this.localErrors, { dataPath: 'sst_case', message: `обов'язкове поле` }];
				}
			}
			return this.localErrors.length;
		},
		apply() {
			this.workplaceError = null;
			let { workplaceCode: workplace_id } = this;
			workplace_id = (workplace_id || '').trim();
			if (
				this.showWorkplaceInput &&
				(!workplace_id ||
					!workplace_id.startsWith('433') ||
					!/^[0-9-]+$/.test(workplace_id) ||
					workplace_id.length < 5 ||
					workplace_id.length > 11)
			) {
				this.workplaceError = 'ШК введено невiрно';
				return;
			}
			workplace_id = workplace_id.replace(/\D/g, '').substr(3);
			!this.fillLocalErrors() &&
				this.submit({
					type: this.type.id,
					act_creation_occasion_name: this.act_creation_occasion_name?.id || undefined,
					act_creation_occasion_number: this.act_creation_occasion_number || undefined,
					act_creation_occasion_date: this.act_creation_occasion_date ? this.prepareOccasionDate() : undefined,

					user_from_id: this.user_from ? this.user_from.id : undefined,
					user_from_name: this.user_from ? this.user_from_name : undefined,
					user_from_position: this.user_from_position || undefined,
					user_to_id: this.user_to ? this.user_to.id : undefined,
					user_to_name: this.user_to ? this.user_to_name : undefined,
					user_to_position: this.user_to ? this.user_to_position : undefined,

					cash: this.local_cash,
					factual_amount: this.factual_amount,
					reason: this.reason ? this.reason.trim() : '',
					safe_keys_amount: this.safe_keys_amount,
					plombeter_number: this.plombeter_number || null,

					safe_sst_keys_amount: this.safe_sst_keys_amount,
					case_sst_keys_amount: this.case_sst_keys_amount,

					sst_id: this.sst_id ? this.sst_id.id : undefined,
					sst_case_integrity: this.sst_case_integrity ? this.sst_case_integrity.id : undefined,
					sst_plombeter_number: this.sst_plombeter_number,
					sst_case: this.sst_case,

					committee_head_name: this.committee_head_name || null,
					committee_head_position: this.committee_head_position || null,
					committee_member_name: this.committee_member_name || null,
					committee_member_position: this.committee_member_position || null,
					second_committee_member_name: this.second_committee_member_name || null,
					second_committee_member_position: this.second_committee_member_position || null,

					print_pages_per_year: this.printPagesPerYear || false,
					print_pages_per_month: this.printPagesPerMonth || false,
					workplace_id,
					loadingAction: true
				});
		}
	},
	beforeMount() {
		return this.lookupUsers({
			query: '',
			cb: (res) => {
				this.committeeHeadOptions = res.data;
				this.committeeMemberOptions = res.data;
				this.secondCommitteeMemberOptions = res.data;
				this.userFromOptions = res.data;
				this.userToOptions = res.data;
			}
		});
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

$icon-close: '~@assets/images/icon-close.svg';

.inventory-act-modal {
	::v-deep .modal-container {
		width: 608px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		& .modal-body {
			height: 100%;
		}
	}
	.label {
		margin-bottom: 12px;
		color: $gray-50;
	}
	.line {
		width: 100%;
		margin-top: 24px;
		border-bottom: 1px solid $gray-10;
	}
	.margin-bottom {
		margin-bottom: 24px;
	}
	.container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.workplace {
			margin-bottom: 16px;
		}
		.user-from,
		.user-to,
		.factual-amount,
		.committee-head,
		.committee-member,
		.safe-keys,
		.sst-safe-keys-amount,
		.act-occasion-and-reason,
		.self-service-terminal {
			display: flex;
			align-items: flex-end;
			margin-bottom: 16px;
			font-size: 14px;
			justify-content: space-between;
			.input,
			.np-select {
				flex-grow: 1;
				&.has-error {
					::v-deep label {
						margin-top: 0;
					}
				}
				&:first-child {
					margin-right: 24px;
				}
			}
			.searchable-input {
				width: 257px;
			}
			.reason-number-and-date {
				display: flex;
				width: 257px;
				.input {
					flex-grow: 1;
				}
			}

			::v-deep .np-select {
				width: 200px;
			}
		}

		.self-service-terminal {
			.input,
			.np-select {
				&:first-child {
					margin-right: 12px;
				}
			}

			.select {
				max-width: 50%;
				min-width: 50%;
			}

			.sst-numbers {
				display: flex;
			}
		}
		.act-type,
		.sst-code {
			margin-bottom: 16px;
		}
		.committee-head .searchable-input,
		.committee-member .searchable-input {
			::v-deep .options-list {
				max-height: 100px;
			}
		}
		.recorded-amount {
			.input {
				width: 250px;
			}
		}
		.factual-amount {
			margin: 30px 0;
		}
		.keys-and-plombetter {
			display: flex;
			.input {
				margin-left: 0;
			}
			.safe-keys,
			.plombeter_number {
				flex-grow: 1;
				margin-bottom: 0;
			}
		}
		.sst-keys {
			margin-top: 24px;
			display: flex;
			.input {
				margin-left: 0;
			}
			.sst-safe-keys-amount,
			.sst-case-keys-amount {
				flex-grow: 1;
				margin-bottom: 0;
			}

			.sst-safe-keys-amount {
				::v-deep .label {
					font-size: 12px;
				}
			}
		}
		.reason {
			margin: 16px 0;
			& .textarea {
				::v-deep textarea {
					height: 80px;
				}
			}
		}
	}
	.buttons {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		margin-top: 24px;
		padding-bottom: 24px;
		.icon-close {
			@include make-icon($icon-close, $gray-40, 20px, 20px);
		}
		& .button:nth-child(2) {
			margin-right: 24px;
		}
	}
}
</style>
