import { core, http } from 'novapay-ui';

import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';

const types = core.getTypes('cashbooks');
let { actions: commonStoreActions } = commonStore('cashbooks');

const getCashbooks = async (context) => {
	let cashbooks = await http('/v3/cashbooks/', { query: context.state.props.query });
	if (!handleError()(cashbooks, context)) {
		return;
	}
	context.commit(types.GET_CASHBOOKS_SUCCESS, cashbooks.data);
	commonStoreActions.hideRootSplashScreen(context);
};

export default {
	...commonStoreActions,
	getCashbooks
};
