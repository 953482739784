import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('cash_management');
const { mutations } = commonStore('cash-management');

export default {
	...mutations,
	[types.DATA_SUCCESS]: (state, { cashbook, notReceivedOrders, schedules, issueAmount, messages, totals }) => {
		state.props = {
			...state.props,
			cashbook,
			notReceivedOrders,
			schedules,
			issueAmount,
			messages,
			totals
		};
	}
};
