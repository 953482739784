<template>
	<NP-Modal priority="error" disable-close disable-click-outside>
		<div class="transfer-user-body">
			<img :src="$options.modalError" />
			<h3 class="title">Користувач не закріплений за даним ПНФП</h3>
			<p class="text">
				{{ text | capitalize }}
			</p>
			<div class="actions-container">
				<p>Перезакріпити користувача за даним ПНФП?</p>
				<div class="actions">
					<NP-Button
						type="button"
						@click.stop="() => transferUserToAnotherSalepoint({ loadingAction: true })"
						:loading="loadingActions.includes('transferUserToAnotherSalepoint')"
					>
						Перезакріпити
					</NP-Button>
					<NP-Button
						type="button"
						@click.stop="() => logout({ loadingAction: true })"
						:loading="loadingActions.includes('logout')"
					>
						Вийти
					</NP-Button>
				</div>
			</div>
		</div>
	</NP-Modal>
</template>

<script>
import modalError from '@assets/images/modal-error.svg';

export default {
	name: 'TransferUserModal',
	props: {
		data: { type: Object, default: null },
		loadingActions: { type: Array, default: () => [] },
		logout: { type: Function, default: null },
		transferUserToAnotherSalepoint: { type: Function, default: null }
	},
	modalError,
	computed: {
		code() {
			return (this.data && this.data.code) || '';
		},
		text() {
			return (this.data && this.data.text) || '';
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';

::v-deep .modal-body {
	padding-top: 0 !important;
}
.transfer-user-body {
	width: 400px;
	img {
		display: block;
		margin: 10px auto 16px auto;
	}
	.title {
		text-align: center;
		margin-bottom: 12px;
	}
	.text {
		text-align: center;
		color: $gray-50;
	}
	.actions-container {
		text-align: center;
		& > p {
			margin: 10px 0 10px 0;
		}
		.actions {
			display: flex;
			justify-content: center;
			padding-top: 5px;
			& > :not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}
</style>
