import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('inventory_record');
const { mutations } = commonStore('inventory-record');

export default {
	...mutations,
	[types.PAGE_DATA_SUCCESS]: (state, { assets }) => {
		state.props = { assets };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { ...state.props, query };
	},
	[types.OPEN_ASSET_MODAL]: (state, { asset, modal_mode }) => {
		state.props = {
			...state.props,
			asset,
			modal_mode
		};
	},
	[types.ASSETS_SUCCESS]: (state, assets) => {
		state.props = {
			...state.props,
			assets
		};
	},
	[types.INVENTORIZATION_COMPLETED]: (state) => {
		state.props = {
			...state.props,
			inventorization_completed: true
		};
	}
};
