import { core, http, validation } from 'novapay-ui';
import BigNumber from 'bignumber.js';

import handleError from '@services/handle-api-error';
import calculateCash from '@services/calculate-cash';

import inventoryActScenarios from '@/components/inventory-act-modal/scenarios';
import cashbookActions from '../../cashbook/store/actions';
import actionTypes from './cashdesk-action-types';
import { enum as orderTypes } from '@repo/enums/cashbook-order-types';
import { enum as orderSubTypes } from '@repo/enums/cashbook-order-sub-types';

const types = core.createTypes('control_center');

const validateAmount = validation.compile({
	properties: {
		amount: { type: 'number', minimum: 0, multipleOf: 0.1, maximum: 99999999.99 }
	}
});

const toggleCashdeskActionModal = (context, cashdeskActionModalData) => {
	if (context.state.state_key?.render === 'cashdesk_action_modal') {
		return context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
	}
	return context.commit(types.OPEN_CASHDESK_ACTION_MODAL, cashdeskActionModalData);
};

const toggleClosalShiftModal = (context, cashdeskData) => {
	if (context.state.state_key?.render === 'closal_shift_modal') {
		return context.commit(types.CLOSE_CLOSAL_SHIFT_MODAL);
	}
	return context.commit(types.OPEN_CLOSAL_SHIFT_MODAL, cashdeskData);
};

const confirmShiftClosal = async (context) => {
	let { cashdeskData } = context.state.props;
	if (new BigNumber(cashdeskData?.balance).isEqualTo(0)) {
		return forceCloseCashdeskRequest(context, { cashdesk: cashdeskData });
	}
	return context.commit(types.OPEN_CASHDESK_ACTION_MODAL, {
		action: actionTypes.forceClose,
		order: {
			type: orderTypes.incoming,
			sub_type: orderSubTypes.cashFromCashdesk,
			recorded_amount: cashdeskData.balance
		},
		cashdesk: cashdeskData
	});
};

const createCashToCashdesk = async (context, payload) => {
	let { recipient_user_id: user_id, amount } = payload;
	let res = await http('/v3/orders/cash-to-cashdesk', { method: 'PUT', data: { user_id, amount } });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
};

const confirmCashToCashdesk = async (context, payload) => {
	let { order } = payload;
	let stopRes = await http('/v3/salepoint/cashdesk/stop', { method: 'POST' });
	if (!handleError()(stopRes, context, 200)) {
		return;
	}
	let res = await http('/v3/orders/cash-to-cashdesk', { method: 'POST', data: { order_id: order?.id } });
	if (!handleError()(res, context, 200)) {
		return;
	}
	return context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
};

const confirmCashFromCashdesk = async (context, payload) => {
	let { order, cashdesk, cash } = payload;
	let amount = calculateCash(cash);
	let { errors } = validateAmount({ amount });
	if (errors) {
		return;
	}
	if (!new BigNumber(amount).isEqualTo(order.recorded_amount)) {
		if (new BigNumber(amount).eq(0) && !confirm('Ви впевнені, що фактична сума 0?')) {
			return;
		}
		return cashbookActions.toggleInventoryActModal(context, {
			scenario: inventoryActScenarios.createCashdeskAct,
			cash,
			cashdesk,
			order
		});
	}
	let res = await http('/v3/orders/cash-from-cashdesk', {
		method: 'POST',
		data: { order_id: order.id, act_data: { cash } }
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
};

const cancelCreatedOrder = async (context, payload) => {
	let { order } = payload;
	let res = await http('/v3/orders/cancel', { method: 'POST', data: { order_id: order.id } });
	if (!handleError()(res, context, 200)) {
		return;
	}
	return context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
};

const forceCloseCashdeskShift = async (context, payload) => {
	let { cashdesk, cash } = payload;
	if (!new BigNumber(calculateCash(cash)).isEqualTo(payload.amount)) {
		return cashbookActions.toggleInventoryActModal(context, {
			scenario: inventoryActScenarios.forceShiftClose,
			cash,
			cashdesk
		});
	}
	return forceCloseCashdeskRequest(context, { cashdesk, cash });
};

const forceCloseCashdeskRequest = async (context, payload) => {
	let { cashdesk, act_data, cash } = payload;
	let res = await http('/v3/salepoint/cashdesk/force-close', {
		method: 'POST',
		data: { user_id: cashdesk.user_id, actData: { cash, ...(act_data || {}) } }
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	context.commit(types.CLOSE_CLOSAL_SHIFT_MODAL);
	context.commit(types.CLOSE_INVENTORY_ACT_MODAL);
	context.commit(types.CLOSE_CASHDESK_ACTION_MODAL);
};

export default {
	toggleCashdeskActionModal,
	createCashToCashdesk,
	confirmCashToCashdesk,
	confirmCashFromCashdesk,
	cancelCreatedOrder,
	toggleClosalShiftModal,
	confirmShiftClosal,
	forceCloseCashdeskShift,
	forceCloseCashdeskRequest
};
