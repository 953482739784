const types = {
	chiefCashier: 'chief_cashier',
	cashdesk: 'cashdesk',
	quarter: 'quarter',
	chiefCashierCentral: 'chief_cashier_central',
	cashdeskCentral: 'cashdesk_central',
	sst: 'sst',

};

const locale = {
	[types.chiefCashier]: 'акт СК',
	[types.cashdesk]: 'акт ДК',
	[types.quarter]: 'акт Квартал',
	[types.chiefCashierCentral]: 'Акт ЦО Сейф',
	[types.cashdeskCentral]: 'Акт ЦО ДК',
	[types.sst]: 'Акт ПТКС'
};

module.exports = {
	enum: types,
	locale: locale
};
