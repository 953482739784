const types = {
	privatDefault: 'privat_default',
	privatParted: 'privat_parted',
	oschadNovaPoshta: 'oschad_nova_poshta',
	oschadContragents: 'oschad_contragents',
	oschadP2P: 'oschad_p2p',
	oschadVisa: 'oschad_visa',
	oschadMasterCard: 'oschad_master_card',
	avalPayment: 'aval_payment',
	avalP2P: 'aval_p2p',
	novapayDefault: 'novapay_default',
	novapayP2P: 'novapay_p2p'
};

const locale = {
	[types.privatDefault]: 'ПриватБанк',
	[types.privatParted]: 'ПриватБанк розстрочка',
	[types.oschadNovaPoshta]: 'ОщадБанк послуги доставки',
	[types.oschadContragents]: 'ОщадБанк накладні платежі',
	[types.oschadP2P]: 'ОщадБанк P2P',
	[types.oschadVisa]: 'ОщадБанк Visa',
	[types.oschadMasterCard]: 'ОщадБанк MasterCard',
	[types.avalPayment]: 'Аваль платежi',
	[types.avalP2P]: 'Аваль P2P',
	[types.novapayDefault]: 'НоваПей',
	[types.novapayP2P]: 'НоваПей P2P'
};


module.exports = {
	enum: types,
	locale: locale
};
