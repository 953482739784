const instruments = {
	cash: 'cash',
	pos: 'pos',
	portablePos: 'portable_pos',
	mit: 'mit',
	mitByDefault: 'mit_by_default',
	tapxphone: 'tapxphone',
	api: 'api',
	mobilePos: 'mobile_pos',//deprecated instrument for pos payments via courier api
	acquiring: 'acquiring',
	sstPos: 'sst_pos'
};

const locale = {
	[instruments.cash]: 'готiвка',
	[instruments.pos]: 'пос термiнал',
	[instruments.portablePos]: 'мобiльний пос термiнал',
	[instruments.mit]: 'автосписання',
	[instruments.mitByDefault]: 'автосписання за замовчуванням',
	[instruments.api]: 'API',
	[instruments.mobilePos]: 'мобiльний пос термiнал (старе API)',
	[instruments.tapxphone]: 'TapXPhone',
	[instruments.sstPos]: 'пос термінал ПТКС'
};

module.exports = {
	enum: instruments,
	locale: locale
};
