import Promise from 'bluebird';
import find from 'lodash/find';
import values from 'lodash/values';
import some from 'lodash/some';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import printjs from 'print-js';
import print from '@services/print';
import router from '@/router';

import { core, http, lookup } from 'novapay-ui';

import handleError from '@services/handle-api-error';
import { convertToImages, loadPdfScripts } from '@services/pdf';

import isMobileDevice from '@services/is-mobile-device';

import { enum as biometricDocumentTypes } from '@repo/enums/payment-biometric-document-types';
import { enum as documentTypes } from '@repo/enums/payment-document-types';
import { enum as scanTypes } from '@repo/enums/document-scan-types';
import { enum as scanSourceTypes } from '@repo/enums/document-scan-source-types';
import { enum as paymentDocumentTypes, locale as paymentDocumentTypesLocale } from '@repo/enums/payment-document-types';
import { enum as countries } from '@repo/enums/countries';
import { enum as verificationProfileTypes } from '@repo/enums/verification-profile-types';
import { enum as clientStatuses } from '@repo/enums/client-statuses';

function formatDate(date) {
	return date ? DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd') : null;
}

function checkAddressNotChanged(savedDocument, client) {
	let shouldCompareAddresses =
		!client.is_resident || client.is_resident !== savedDocument?.is_resident || client.isCredit;
	return !shouldCompareAddresses || addressToString(savedDocument?.address) === addressToString(client.address);
}

function addressToString(v) {
	if (!v) {
		return null;
	}
	switch (typeof v) {
		case 'string':
			return v;
		case 'object':
			return [v.region, v.district, v.city, v.street, v.building, v.apartment].filter(Boolean).join(' ');
		default:
			return null;
	}
}

function checkClientNotChanged({ savedClient, savedDocument, client }) {
	return (
		isEqual(
			{
				last_name: savedClient?.name?.last_name || null,
				first_name: savedClient?.name?.first_name || null,
				patronymic: savedClient?.name?.patronymic || null,
				phone: (find(savedClient?.phones, { is_active: true, phone: client.phone }) || {}).phone || null,
				is_resident: savedDocument?.is_resident,
				birth_place: savedClient?.main?.birth_place || null,
				birth_date: savedClient?.main?.birth_date || null,
				taxpayer_id: savedClient?.main?.taxpayer_id || null,
				residence: savedClient?.residence?.address || null,
				is_verified: !!savedClient?.main?.verified_at
			},
			{
				last_name: client.last_name || null,
				first_name: client.first_name || null,
				patronymic: client.patronymic || null,
				phone: client.phone || null,
				is_resident: client.is_resident,
				birth_place: client.birth_place || null,
				birth_date: client.birth_date,
				taxpayer_id: client.taxpayer_id || null,
				is_verified: !!client.is_verified,
				residence: client.residence || null
			}
		) && checkAddressNotChanged(savedDocument, client)
	);
}

const createActions = (types, rootTypes) => {
	const processingHandler = (context, res) => {
		if (res.data.code === 'VerificationBlockedError') {
			context.commit(types.OPEN_VERIFICATION_BLOCKED);
			return false;
		}
		let errorAlert = { text: res.data.error, code: res.data.code };
		context.commit(rootTypes.ERROR_ALERT, errorAlert, { root: true });
		return false;
	};

	const actions = {
		lookupDocument: async (context, payload = {}) => {
			let { type, series, number, country, state, isRecipientAgent, preventShowNameMismatchModal } = payload;
			let { isRecipientAgent: isIrregularPayout } = context.state.props;
			let seriesNotFilled = !values(biometricDocumentTypes).includes(type?.id) && !series?.length;
			if (seriesNotFilled || !number?.length) {
				return;
			}
			let res = await lookup('/v3/novaclients/lookup-client-by-document', {
				query: {
					type: type?.id,
					series: !values(biometricDocumentTypes).includes(type?.id) ? series : undefined,
					number,
					country: country?.id,
					use_external_domain: isMobileDevice ? '1' : '0'
				}
			});
			if (!handleError({ processing: processingHandler })(res, context, 200, types.DOCUMENT_VALIDATION_ERRORS)) {
				return;
			}
			let client = res.data?.client || {};
			let vp = find(client?.verification_profiles || [], {
				type: verificationProfileTypes.operation,
				document_id: res.data?.document?.id
			});
			let document = {
				...(res.data?.document || {}),
				type: res.data?.document?.type ?? type?.id,
				series: res.data?.document?.series ?? series,
				number: res.data?.document?.number ?? number,
				country: res.data?.document?.country ?? country?.id,
				state,
				birth_date: res.data?.client?.main?.birth_date,
				birth_place: res.data?.client?.main?.birth_place,
				taxpayer_id: res.data?.client?.main?.taxpayer_id,
				address: (find(res.data?.client?.addresses, { is_active: true }) || {}).address || null,
				is_resident: res.data?.client?.residence
					? res.data?.client?.residence?.country === countries.UA
					: ![
							paymentDocumentTypes.foreignersPassport,
							paymentDocumentTypes.biometricTemporaryResidencePermit,
							paymentDocumentTypes.temporaryResidencePermit
					  ].includes(type && type.id),
				verification_profile_id: vp?.id ?? null,
				residence: res.data?.client?.residence?.address || null
			};
			let commit = {
				updateScans: true,
				savedClient: client,
				preventShowNameMismatchModal,
				savedDocument: document,
				root: context.rootState.root,
				isRecipientAgent
			};
			if (isRecipientAgent) {
				if (client?.main?.id && client.main.id !== context.state.props.selectedRecipientAgent?.main?.id) {
					commit.selectedRecipientAgent = null;
				}
				if (!client?.main?.id && context.state.props.selectedRecipientAgent?.main?.id) {
					commit.savedClient = context.state.props.selectedRecipientAgent;
				}
			}
			context.commit(types.SAVED_DOCUMENT, commit);
			if (!isIrregularPayout || isRecipientAgent) {
				context.commit(types.SET_CLIENT_IS_VERIFIED, !!client?.main?.verified_at);
			}
		},
		lookupPermitDocument: async (context, payload = {}) => {
			let { type, series, number, country } = payload;
			let seriesNotFilled = !values(biometricDocumentTypes).includes(type?.id) && !series?.length;
			if (seriesNotFilled || !number?.length) {
				return;
			}
			let res = await lookup('/v3/novaclients/lookup-client-by-document', {
				query: {
					type: type?.id,
					series: !values(biometricDocumentTypes).includes(type?.id) ? series : undefined,
					number,
					country: country?.id,
					use_external_domain: isMobileDevice ? '1' : '0'
				}
			});
			if (!handleError({ processing: processingHandler })(res, context, 200, types.PERMIT_DOCUMENT_VALIDATION_ERRORS)) {
				return;
			}
			let client = res.data?.client || {};
			let document = {
				...(res.data?.document || {}),
				type: res.data?.document?.type ?? type?.id,
				series: res.data?.document?.series ?? series,
				number: res.data?.document?.number ?? number,
				country: res.data?.document?.country ?? country?.id,
				birth_date: res.data?.client?.main?.birth_date,
				birth_place: res.data?.client?.main?.birth_place,
				taxpayer_id: res.data?.client?.main?.taxpayer_id,
				address: (find(res.data?.client?.addresses, { is_active: true }) || {}).address || null,
				is_resident: res.data?.client?.residence
					? res.data?.client?.residence?.country === countries.UA
					: ![
							paymentDocumentTypes.foreignersPassport,
							paymentDocumentTypes.biometricTemporaryResidencePermit,
							paymentDocumentTypes.temporaryResidencePermit
					  ].includes(type && type.id)
			};
			context.commit(types.LOOKUP_POA_USERS_SUCCESS, res.data?.poa_clients || null);
			context.commit(types.SAVED_PERMIT_DOCUMENT, {
				updateScans: true,
				savedClient: client,
				savedDocument: document,
				root: context.rootState.root
			});
		},
		lookupClient: async (context, payload = {}) => {
			let { savedClient, isRecipientAgent: isIrregularPayout } = context.state.props;
			let {
				taxpayer_id,
				is_resident,
				type,
				series,
				number,
				country,
				state,
				issued_at,
				issued_by,
				birth_date,
				birth_place,
				expired_at,
				without_expiration,
				address,
				residence,
				isRecipientAgent
			} = payload || {};
			if (!savedClient?.taxpayer_id && !taxpayer_id) {
				// handle empty lookup, except for the case when a value was deleted
				return;
			}
			let res = {};
			if (taxpayer_id?.length) {
				res = await http('/v3/novaclients/lookup-client-by-taxpayer-id', { query: { taxpayer_id } });
				if (!handleError()(res, context, 200, types.DOCUMENT_VALIDATION_ERRORS)) {
					return;
				}
			}
			context.commit(types.LOOKUP_CLIENT_SUCCESS, {
				savedDocument: {
					type,
					series,
					number,
					country,
					state,
					is_resident:
						// use what is in form if doc is one of these
						// because for other docs is_resident switch is disabled
						res.data?.residence &&
						[
							paymentDocumentTypes.passport,
							paymentDocumentTypes.internationalPassport,
							paymentDocumentTypes.biometricPassport
						].includes(type && type.id)
							? res.data?.residence?.country === 'UA'
							: is_resident,
					issued_at,
					issued_by,
					expired_at,
					without_expiration,
					taxpayer_id: res.data?.main?.taxpayer_id || taxpayer_id,
					birth_date: res.data?.main?.birth_date
						? DateTime.fromISO(res.data.main.birth_date).toFormat('dd/MM/yyyy')
						: birth_date || null,
					birth_place: res.data?.main?.birth_place || birth_place || null,
					address: (find(res.data?.addresses, { is_active: true }) || {}).address || address || null,
					residence: res.data?.residence?.address || residence || null
				},
				savedClient: res.data || {},
				isRecipientAgent
			});
			if (!isIrregularPayout || isRecipientAgent) {
				context.commit(types.SET_CLIENT_IS_VERIFIED, !!res.data?.main?.verified_at);
			}
		},
		setSavedRecipientAgent: async (context, data) => {
			context.commit(types.SAVED_RECIPIENT_AGENT, data);
		},
		_createPoaUser: async (context, data) => {
			let res = await http('/v3/novaclients/create-poa-user', { method: 'POST', data });
			if (!handleError()(res, context)) {
				throw new Error();
			}
			context.commit(types.LOOKUP_POA_USERS_SUCCESS, res.data);
		},
		_createDocumentForExistingUser: async (context, data, isPermitDoc = false) => {
			let doc = await http('/v3/novaclients/create-document', {
				method: 'post',
				data
			});
			if (
				!handleError()(
					doc,
					context,
					200,
					isPermitDoc ? types.PERMIT_DOCUMENT_VALIDATION_ERRORS : types.DOCUMENT_VALIDATION_ERRORS
				)
			) {
				throw new Error();
			}
			return doc.data;
		},
		upsertDocument: (context, payload) => {
			if (payload.client.nc3_client) {
				return actions.saveOfflineDetailsNc3(context, payload);
			}
			switch (payload.document.type.id) {
				case paymentDocumentTypes.powerOfAttorney:
					return actions.upsertPowerOfAttorney(context, payload);
				case paymentDocumentTypes.birthCertificate:
				case paymentDocumentTypes.certificateOfInheritance:
					return actions.upsertCertificates(context, payload);
				default:
					return actions.upsertRegularDocument(context, payload);
			}
		},
		upsertCertificates: async (context, payload = {}) => {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				let { document, client } = payload;
				let { savedPermitDocument, savedClient } = context.state.props;

				try {
					var upserted = await actions.upsertRegularDocument(
						context,
						{
							document: { residence: savedClient?.residence?.address ?? null, ...document },
							client,
							is_verified: false,
							preventCreateDocument: !!savedPermitDocument?.id
						},
						{ returnUpserted: true }
					);
				} catch (e) {
					reject(e);
					return;
				}
				let permitDocument = upserted?.document?.id
					? upserted.document
					: { ...savedPermitDocument, type: savedPermitDocument.type.id };
				context.commit(types.SAVED_PERMIT_DOCUMENT, {
					root: context.rootState.root,
					savedDocument: {
						...permitDocument,
						is_resident: upserted.client.residence
							? upserted.client.residence?.country === countries.UA
							: ![
									paymentDocumentTypes.foreignersPassport,
									paymentDocumentTypes.biometricTemporaryResidencePermit,
									paymentDocumentTypes.temporaryResidencePermit
							  ].includes(upserted.document.type)
					}
				});
				resolve({
					...permitDocument,
					type: find(context.rootState.root.props.lists['payment-document-types'], {
						id: permitDocument.type
					})
				});
			});
		},
		upsertPowerOfAttorney: async (context, payload = {}) => {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				let { document, regularDocument, client } = payload;
				let { savedPermitDocument, savedClientId, savedClient } = context.state.props;

				try {
					let client_id = savedClientId;
					if (!client_id) {
						let upserted = await actions.upsertRegularDocument(
							context,
							{
								document: { residence: savedClient?.residence?.address ?? null, ...regularDocument },
								client,
								is_verified: false,
								preventCreateDocument: !!savedPermitDocument?.id
							},
							{ returnUpserted: true }
						);
						client_id = upserted.client.main.id;
						// save client id until successful permit doc creation to avoid already existing document creation retry
						// in case some permit document validation errors
						context.commit(types.SAVED_CLIENT_ID, client_id);
					}
					if (!savedPermitDocument?.id) {
						var newCreatedPermitDoc = await actions._createDocumentForExistingUser(
							context,
							{
								client_id,
								document: {
									type: document.type.id,
									number: document.number || null,
									series: document.series || null,
									expire_at: formatDate(document.expired_at),
									country: countries.UA
								}
							},
							true
						);
					}
				} catch (e) {
					reject(e);
					return;
				}
				context.commit(types.SAVED_CLIENT_ID, null);
				let permitDocument = newCreatedPermitDoc || { ...savedPermitDocument, type: savedPermitDocument.type.id };
				context.commit(types.SAVED_PERMIT_DOCUMENT, {
					root: context.rootState.root,
					savedDocument: permitDocument
				});
				resolve({
					...permitDocument,
					type: find(context.rootState.root.props.lists['payment-document-types'], {
						id: permitDocument.type
					})
				});
			});
		},
		upsertRegularDocument: async (context, payload = {}, { returnUpserted = false } = {}) => {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				let { document, client, is_verified, isRecipientAgent = false, preventCreateDocument = false } = payload;
				let {
					savedDocument,
					savedClient,
					savedPermitDocument,
					savedRecipientAgentDocument,
					savedRecipientAgent,
					recipientAgentLookupResult
				} = context.state.props;

				if (isRecipientAgent) {
					savedClient = { ...savedRecipientAgent };
					savedDocument = { ...savedRecipientAgentDocument };
				}
				let shouldCreatePoaUser =
					isRecipientAgent &&
					savedPermitDocument?.id &&
					!(recipientAgentLookupResult || []).map((u) => u.main.id).includes(savedRecipientAgent?.main?.id);
				let mode = savedClient?.main?.id ? 'update' : 'create';
				let clientData = {
					client_id: savedClient?.main?.id || undefined,
					client: {
						phone: (client?.phone || '').replace(/[()\s_]/g, ''),
						last_name: client?.last_name,
						first_name: client?.first_name,
						patronymic: client?.patronymic,
						taxpayer_id: document.taxpayer_id || null,
						birth_place: document.birth_place || null,
						birth_date: formatDate(document.birth_date),
						is_verified
					},
					residence: {
						address: document.residence || null,
						country:
							document.type?.id === documentTypes.foreignersPassport || document.is_resident
								? document.country?.id // is set to UA for residents in doc component
								: null
					},
					address: document.address || null,
					document: {
						type: document.type?.id,
						number: document.number || null,
						series: document.series || null,
						issued_at: formatDate(document.issued_at),
						expire_at: formatDate(document.expired_at),
						issued_by: document.issued_by || null,
						country: document.country?.id || undefined
					}
				};

				let clientChanged =
					savedClient?.shouldUpdateClient ||
					!checkClientNotChanged({
						savedDocument,
						savedClient,
						client: {
							...clientData.client,
							is_resident: document.is_resident,
							residence: document.residence,
							address: clientData.address,
							isCredit: document.isCredit
						}
					});

				let commit = {
					document: {
						...savedDocument,
						type: savedDocument?.type?.id,
						country: savedDocument?.country?.id,
						birth_date: formatDate(savedDocument?.birth_date),
						issued_at: formatDate(savedDocument?.issued_at),
						expire_at: formatDate(savedDocument?.expired_at),
						address: document.address,
						verification_profile_id: checkAddressNotChanged(savedDocument, {
							is_resident: document.is_resident,
							address: clientData.address,
							isCredit: document.isCredit
						})
							? savedDocument?.verification_profile_id
							: null
					},
					client: savedClient
				};

				if (mode === 'update' && !savedDocument?.id && !preventCreateDocument) {
					try {
						let doc = await actions._createDocumentForExistingUser(context, clientData);
						commit.document = doc;
					} catch (e) {
						reject(e);
						return;
					}
				}
				if (mode === 'create' || (mode === 'update' && clientChanged)) {
					let res = await http(`/v3/novaclients/${mode}-client`, { method: 'post', data: clientData });
					if (!handleError()(res, context, 200, types.DOCUMENT_VALIDATION_ERRORS)) {
						return reject(new Error());
					}
					commit = mode === 'create' ? res.data : { ...commit, client: res.data };
				}
				if (shouldCreatePoaUser) {
					try {
						await actions._createPoaUser(context, {
							document_id: savedPermitDocument.id,
							client_id: commit.client.main.id
						});
					} catch (e) {
						reject(e);
						return;
					}
				}

				let vp = find(commit.client?.verification_profiles || [], {
					type: verificationProfileTypes.operation,
					document_id: commit.document?.id
				});
				context.commit(types.SAVED_DOCUMENT, {
					savedDocument: {
						...(commit.document || {}),
						type: commit.document.type,
						series: commit.document.series,
						number: commit.document.number,
						country: commit.document.country,
						state: savedDocument?.state, // only for moneygram dont save it in nc
						birth_date: commit.client.main?.birth_date,
						birth_place: commit.client.main?.birth_place,
						taxpayer_id: commit.client.main?.taxpayer_id,
						is_resident: commit.client.residence
							? commit.client.residence?.country === countries.UA
							: ![
									paymentDocumentTypes.foreignersPassport,
									paymentDocumentTypes.biometricTemporaryResidencePermit,
									paymentDocumentTypes.temporaryResidencePermit
							  ].includes(commit.document.type),
						address: (find(commit.client?.addresses, { is_active: true }) || {}).address || null,
						residence: commit.client?.residence?.address || null,
						verification_profile_id: vp?.id || commit.document.verification_profile_id || null
					},
					savedClient: commit.client || {},
					preventShowNameMismatchModal: true,
					root: context.rootState.root,
					isRecipientAgent
				});
				const documentForScans = isRecipientAgent
					? context.state.props.savedRecipientAgentDocument
					: context.state.props.savedDocument;
				resolve(returnUpserted ? commit : documentForScans);
			});
		},
		uploadScan: async (context, payload = {}) => {
			let { id, tmp_id, document_id, type, file, documentToUpdate, paymentType } = payload;
			const formData = new FormData();
			formData.append('type', type);
			formData.append('document_id', document_id);
			formData.append('source', isMobileDevice ? scanSourceTypes.mobileCashdesk : scanSourceTypes.physical);
			formData.append('use_external_domain', isMobileDevice ? '1' : '0');
			formData.append('file', file);
			formData.append('taken_reason', context.state.state_key.render?.credit ? 'credit' : 'novapay_operation');

			if (paymentType === 'onboarding') {
				formData.append('use_novaclients_3', '1');
			}
			if (!id.toString().includes('tmp')) {
				formData.append('previous_scan_id', id);
			}

			let res = await http({
				url: '/v3/novaclients/upload-scan',
				method: 'post',
				data: formData,
				headers: {
					'content-type': 'multipart/form-data'
				}
			});
			let processingHandler = (context, res) => {
				context.commit(types.SCAN_ERROR, { id, text: res.data.error || 'Помилка завантаження, спробуйте знову' });
			};
			if (!handleError({ processing: processingHandler })(res, context)) {
				return;
			}
			return context.commit(types.SCAN_UPLOAD, {
				...res.data,
				tmp_id: !id.toString().includes('tmp') ? tmp_id : id,
				previous_scan_id: !id.toString().includes('tmp') ? id : undefined,
				documentToUpdate
			});
		},
		requestDigitalDocument: async (context, { use_diia, barcode, pos_code, pin, phone }) => {
			// instead of disabling digital doc btns when phone is not filled
			// take this more user friendly approach
			phone = phone || context.state.props.diiaPhone || '';
			phone = phone.replace(/[()\s]/g, '');
			if (phone === '+380_________') {
				phone = null;
			}
			if (!phone) {
				return context.commit(
					rootTypes.ERROR_ALERT,
					{ text: `Заповніть поле "телефон" та спробуйте знову.` },
					{ root: true }
				);
			}
			// this uuid serves just as a loading flag for pos documents
			// but for diia it serves as a request identifier
			let requestUuid = uuid();
			let digitalDocTimeout = setTimeout(() => {
				context.commit(
					rootTypes.ERROR_ALERT,
					{ text: `Перевищено час очікування відповіді. Перевірте документ та спробуйте знову.` },
					{ root: true }
				);
				context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING);
			}, 60 * 1000 * 5);

			context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING, {
				uuid: requestUuid,
				timeout: digitalDocTimeout
			});

			let taken_reason = context.state.state_key.render?.credit ? 'credit' : 'novapay_operation';
			let is_for_nc3 = !!context.state.props.nc3Client;

			let res;
			if (use_diia) {
				res = await http({
					url: '/v3/novaclients/digital-document/diia',
					method: 'post',
					data: {
						barcode,
						requestUuid,
						phone,
						taken_reason,
						is_for_nc3,
						novaclients_id: context.state.props?.nc3Client?.client?.id
					}
				});
			} else {
				res = await http({
					url: `/v3/novaclients/digital-document/pos`,
					method: 'POST',
					data: {
						pin,
						code: pos_code,
						phone,
						taken_reason,
						is_for_nc3,
						novaclients_id: context.state.props?.nc3Client?.client?.id
					}
				});
			}

			if (!handleError({ processing: processingHandler })(res, context)) {
				clearTimeout(digitalDocTimeout);
				return context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING);
			}
		},
		receiveDigitalDocumentEvent: (context, data) => {
			let { user } = context.rootState.root.props;
			let { user_id, message, res, is_successful } = data.payload;
			let { digitalDocTimeout, digitalDocumentRequestUuid, nc3Client } = context.state.props;
			if (digitalDocTimeout) {
				clearTimeout(digitalDocTimeout);
			}
			if (user_id === user.id && digitalDocumentRequestUuid) {
				context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING);
				if (!is_successful) {
					return context.commit(rootTypes.ERROR_ALERT, { text: message }, { root: true });
				}

				let client = res.client || {};
				let document = res.document || {};

				if (nc3Client) {
					client = mapClientNc3ToNc2(res.client);
					document = {
						...res.document,
						birth_date: res.client.birth_date,
						birth_place: res.client.birth_place,
						taxpayer_id: res.client.taxpayer_id,
						address: {
							region: res.client.address_region || '-',
							district: res.client.address_district || '-',
							city: res.client.address_locality,
							street: res.client.address_street,
							building: res.client.address_house,
							apartment: res.client.address_apartment
						},
						residence: {
							region: res.client.residence_region || '-',
							district: res.client.residence_district || '-',
							city: res.client.residence_locality,
							street: res.client.residence_street,
							building: res.client.residence_house,
							apartment: res.client.residence_apartment
						}
					};
				} else {
					document = {
						...res.document,
						birth_date: res.client.main.birth_date,
						birth_place: res.client.main.birth_place,
						taxpayer_id: res.client.main.taxpayer_id,
						address: (find(res?.client?.addresses, { is_active: true }) || {}).address || null,
						is_resident: res?.client?.residence
							? res?.client?.residence?.country === countries.UA
							: ![
									paymentDocumentTypes.foreignersPassport,
									paymentDocumentTypes.biometricTemporaryResidencePermit,
									paymentDocumentTypes.temporaryResidencePermit
							  ].includes(res.document.type),
						residence: res?.client?.residence?.address || null
					};
				}

				context.commit(types.SAVED_DOCUMENT, {
					updateScans: true,
					savedClient: client,
					savedDocument: document,
					root: context.rootState.root,
					isRecipientAgent: context.state.props.isRecipientAgent,
					preventShowNameMismatchModal: !!nc3Client
				});
				context.commit(types.SET_CLIENT_IS_VERIFIED, Boolean(nc3Client || client?.main?.verified_at));
			}
		},
		printScans: async (context, { scans } = {}) => {
			if (!Array.isArray(scans)) {
				scans = [scans];
			}
			let rawHTML = getScanPrintHTML(scans.map((s) => s.url));
			printjs({ printable: rawHTML, type: 'raw-html' });
		},
		setLocalErrors: (context, errors) => {
			context.commit(types.VALIDATION_ERRORS, errors);
		},
		createVerificationProfile: async (context, data) => {
			let { documentToUpdate, ...payload } = data;
			await loadPdfScripts();
			let res = await http({
				url: '/v3/novaclients/verification-profile',
				method: 'post',
				data: payload
			});
			let validationHandler = (context, res) => {
				let validationText = '';
				if (some(res.data.errors, (e) => (e.dataPath || e.instancePath).includes('client'))) {
					validationText += `Перевірте чи заповнений ПІБ клієнта`;
				}
				return context.commit(
					rootTypes.ERROR_ALERT,
					{ title: 'Помилка створення анкети', text: validationText || JSON.stringify(res.data.errors) },
					{ root: true }
				);
			};
			if (handleError({ validation: validationHandler })(res, context)) {
				let images = await convertToImages(res.data.verification_profile_pdf);
				let rawHTML = getScanPrintHTML(images);
				printjs({ printable: rawHTML, type: 'raw-html' });
			}
			context.commit(types.VERIFICATION_PROFILE_CREATED, {
				verification_profile_id: res.data.id,
				documentToUpdate,
				reqPayload: payload
			});
		},
		getVerificationProfile: async (context, payload) => {
			await loadPdfScripts();
			let res = await http({
				url: `/v3/novaclients/verification-profile/${payload.id}`
			});

			if (handleError()(res, context)) {
				let images = await convertToImages(res.data.verification_profile_pdf);
				let rawHTML = getScanPrintHTML(images);
				printjs({ printable: rawHTML, type: 'raw-html' });
			}
		},
		setDocumentScansChecked: (context, payload) => context.commit(types.SET_DOCUMENT_SCANS_CHECKED, payload),
		clearSavedDocsClients: (context) => {
			context.commit(types.CLEAR_SAVED_DOCS_CLIENTS);
		},
		setIsRecipientAgent: (context, val) => {
			context.commit(types.SET_IS_RECIPIENT_AGENT, val);
		},
		setPermitDocument: (context, data) => {
			context.commit(types.SET_PERMIT_DOCUMENT, data);
		},
		setPreventShowNameMismatchModal: (context, val) => {
			context.commit(types.SET_PREVENT_SHOW_NAME_MISMATCH_MODAL, val);
		},
		setStopDocumentLookup: (context, val) => {
			context.commit(types.SET_STOP_DOCUMENT_LOOKUP, val);
		},
		getClientByBarcode: async (context, { barcode, paymentType }) => {
			let res = await http({
				url: '/v3/novaclients/v3/barcode/client',
				query: { barcode }
			});

			let localProcessingHandler = (context, res) => {
				if (res.data?.code === 'BarcodeNotFoundError') {
					return context.commit(types.UPDATE_NC3_CLIENT, { nc3Client: { not_found: true, barcode } });
				}
				return processingHandler(context, res);
			};

			if (!handleError({ processing: localProcessingHandler })(res, context)) {
				return;
			}
			const clientStatus = res.data?.client?.status;
			let allowedClientStatuses = [clientStatuses.lead, clientStatuses.verifying, clientStatuses.active];
			let allowedVerificationStatuses = ['created', 'returned'];
			let isOfflineVerification = res.data?.verification?.type === 'offline';
			let verificationInAllowedStatus = allowedVerificationStatuses.includes(res.data?.verification?.status);
			const isOnboarding = [clientStatuses.lead, clientStatuses.verifying].includes(res.data?.client?.status);
			context.commit(types.SAVED_DOCUMENT, {
				savedDocument: {
					...res.data?.document,
					taxpayer_id: res.data?.client?.taxpayer_id,
					birth_date: res.data?.client?.birth_date,
					birth_place: res.data?.client?.birth_place,
					address: {
						region: res.data?.client?.address_region || null,
						district: res.data?.client?.address_district || null,
						city: res.data?.client?.address_locality || null,
						street: res.data?.client?.address_street || null,
						building: res.data?.client?.address_house || null,
						apartment: res.data?.client?.address_apartment || null
					},
					residence: {
						region: res.data?.client?.residence_region || null,
						district: res.data?.client?.residence_district || null,
						city: res.data?.client?.residence_locality || null,
						street: res.data?.client?.residence_street || null,
						building: res.data?.client?.residence_house || null,
						apartment: res.data?.client?.residence_apartment || null
					}
				},
				savedClient: mapClientNc3ToNc2(res.data?.client),
				preventShowNameMismatchModal: true,
				root: context.rootState.root
			});
			// in these cases show client status info in client barcode modal without closing it
			if (
				!allowedClientStatuses.includes(clientStatus) ||
				(isOnboarding && (!isOfflineVerification || !verificationInAllowedStatus))
			) {
				return context.commit(types.UPDATE_NC3_CLIENT, { nc3Client: { ...res.data, barcode } });
			} else if (isOnboarding) {
				await router.push('/payment/onboarding');
			} else if (clientStatus === clientStatuses.active) {
				context.commit(types.OPEN_NOVAPAY_CARDS_ACTIONS_MODAL, true);
			}
			const [scan] = res.data?.document?.scans || [];
			if (isOfflineVerification && scan?.source === scanSourceTypes.diia) {
				context.commit(types.SET_CLIENT_IS_VERIFIED, true);
			}
			return context.commit(types.CLOSE_CLIENT_BARCODE, { nc3Client: { ...res.data, barcode } });
		},
		getNC3ClientById: async (context, { client_id }) => {
			let res = await http({
				url: '/v3/novaclients/v3/client',
				query: { client_id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.CLOSE_CLIENT_BARCODE, { nc3Client: { ...res.data } });
			return res.data;
		},
		showRootErrorAlert: (context, text) => {
			context.commit(rootTypes.ERROR_ALERT, { title: 'Помилка', text }, { root: true });
		},
		saveOfflineDetailsNc3: async (context, payload = {}) => {
			let { document, client } = payload;
			let processAddr = (doc, type) => {
				return Object.keys(doc[type] || {}).reduce((acc, key) => {
					let remappedKey = key;
					if (key === 'city') {
						remappedKey = 'locality';
					}
					if (key === 'building') {
						remappedKey = 'house';
					}
					return { ...acc, [`${type}_` + remappedKey]: doc[type][key] };
				}, {});
			};
			let data = {
				id: client.id,
				client: {
					first_name: client.first_name?.trim() || null,
					last_name: client.last_name?.trim() || null,
					patronymic: client.patronymic?.trim() || null,
					taxpayer_id: document.taxpayer_id || null,
					birth_date: formatDate(document.birth_date),
					birth_place: document.birth_place || null,
					...processAddr(document, 'address'),
					...processAddr(document, 'residence')
				},
				document: {
					type: document.type?.id,
					series: document.series || null,
					number: document.number || null,
					issued_at: formatDate(document.issued_at),
					issued_by: document.issued_by || null,
					expire_at: formatDate(document.expired_at),
					demographic_registry_id: document.demographic_registry_id || undefined
				}
			};
			let res = await http(`/v3/novaclients/v3/offline/details`, { method: 'post', data });
			if (!handleError()(res, context, 200, types.DOCUMENT_VALIDATION_ERRORS)) {
				throw new Error();
			}
			if (res.data?.client?.status === 'blocked') {
				// eslint-disable-next-line max-len
				let text = `На жаль, ми не можемо відкрити клієнту рахунок. Якщо залишились питання, порадь клієнту звернутись до онлайн-підтримки`;
				switch (res.data?.client?.block_reason) {
					case 'lost_document':
						text = 'За цим документом онбординг неможливий';
						break;
					case 'age':
						text = 'Клієнт не досяг повноліття, онбординг неможливий';
						break;
					default:
						break;
				}
				context.commit(rootTypes.ERROR_ALERT, { text }, { root: true });
				throw new Error('client_blocked');
			}
			if (res.data?.code === 'ClientAlreadyExistsError') {
				context.commit(
					rootTypes.ERROR_ALERT,
					{
						text: 'Клієнт з поточним ІПН та іншим номером телефону вже зареєстрований.\
						Щоб продовжити реєстрацію, порадь клієнту звернутись до онлайн підтримки.'
					},
					{ root: true }
				);
				throw new Error('client_already_exists');
			}
			context.commit(types.SAVED_DOCUMENT, {
				savedDocument: {
					...res.data.document,
					address: document.address,
					residence: document.residence,
					taxpayer_id: res.data.client.taxpayer_id,
					birth_date: res.data.client.birth_date,
					birth_place: res.data.client.birth_place
				},
				savedClient: mapClientNc3ToNc2(res.data.client),
				preventShowNameMismatchModal: true,
				updateScans: true,
				root: context.rootState.root
			});
			context.commit(types.UPDATE_CLIENT_VERIFICATION, res.data.verification);
			return context.state.props.savedDocument;
		},
		printUepAgreement: async (context, { client_id } = {}) => {
			let res = await http({
				url: '/v3/novaclients/v3/offline/get-agreement-pdf',
				query: { client_id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			print(res.data);
		},
		saveUepAgreement: async (context, { client_id, file, callback } = {}) => {
			const formData = new FormData();
			formData.append('client_id', client_id);
			formData.append('file', file);

			let res = await http({
				url: '/v3/novaclients/v3/offline/save-agreement',
				method: 'post',
				data: formData,
				headers: {
					'content-type': 'multipart/form-data'
				}
			});
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.UEP_AGREEMENT_SAVED, res.data);
			callback();
		},
		markOfflineVerificationReady: async (context, { client_id } = {}) => {
			let res = await http({
				url: '/v3/novaclients/v3/offline/verification-ready',
				method: 'post',
				data: { client_id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.PAYMENT_SUCCESS);
		},
		printScansVerification: async (context) => {
			let res = await http({
				url: '/v3/novaclients/v3/scans-verification-pdf',
				query: { client_id: context.state.props.nc3Client.client.id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			print(res.data);
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.SET_CLIENT_IS_VERIFIED]: (state, isLookupedClientVerified) => {
		state.props = { ...state.props, isLookupedClientVerified };
	},
	[types.SAVED_CLIENT_ID]: (state, savedClientId) => {
		state.props = { ...state.props, savedClientId };
	},
	[types.SET_IS_RECIPIENT_AGENT]: (state, isRecipientAgent) => {
		state.props = { ...state.props, isRecipientAgent };
	},
	[types.SET_PREVENT_SHOW_NAME_MISMATCH_MODAL]: (state, preventShowNameMismatchModal) => {
		state.props = { ...state.props, preventShowNameMismatchModal };
	},
	[types.SET_STOP_DOCUMENT_LOOKUP]: (state, stopDocumentLookup) => {
		state.props = { ...state.props, stopDocumentLookup };
	},
	[types.CLEAR_SAVED_DOCS_CLIENTS]: (state) => {
		state.props = {
			...state.props,
			savedDocument: {
				type: { id: paymentDocumentTypes.passport, text: paymentDocumentTypesLocale[paymentDocumentTypes.passport] },
				is_resident: true
			},
			errors: null,
			permit_doc_errors: null,
			savedPermitDocument: null,
			savedClient: null,
			savedRecipientAgent: null,
			savedRecipientAgentDocument: null,
			documentForScans: null
		};
	},
	[types.SAVED_DOCUMENT]: (
		state,
		{
			savedDocument,
			savedClient,
			root,
			updateScans,
			isRecipientAgent,
			preventShowNameMismatchModal = false,
			selectedRecipientAgent
		}
	) => {
		let fieldToUpdate = isRecipientAgent ? 'savedRecipientAgentDocument' : 'savedDocument';
		let canExpire = [
			documentTypes.biometricPassport,
			documentTypes.internationalPassport,
			documentTypes.biometricTemporaryResidencePermit,
			documentTypes.biometricPermanentResidencePermit,
			documentTypes.temporaryResidencePermit,
			documentTypes.temporaryCitizenCertificate,
			documentTypes.protectedPersonCertificate,
			documentTypes.refugeeCertificate,
			documentTypes.powerOfAttorney,
			documentTypes.foreignersPassport
		].includes(savedDocument.type);

		let birth_date = savedDocument.birth_date
			? DateTime.fromISO(savedDocument.birth_date).toFormat('dd/MM/yyyy')
			: null;
		let birth_place = savedDocument?.birth_place;
		let taxpayer_id = savedDocument?.taxpayer_id;
		state.props = {
			...state.props,
			documentValidationErrors: null,
			errors: null,
			scanErrors: null,
			isDocumentScansChecked: false,
			savedClientId: null,
			preventShowNameMismatchModal,
			selectedRecipientAgent: !isUndefined(selectedRecipientAgent)
				? selectedRecipientAgent
				: state.props.selectedRecipientAgent,
			[isRecipientAgent ? 'savedRecipientAgent' : 'savedClient']: savedClient,
			[fieldToUpdate]: {
				...savedDocument,
				birth_place,
				taxpayer_id,
				scans: !updateScans ? state.props[fieldToUpdate]?.scans || savedDocument.scans : savedDocument.scans,
				type: find(root.props.lists['payment-document-types'], { id: savedDocument.type }),
				issued_at: savedDocument.issued_at ? DateTime.fromISO(savedDocument.issued_at).toFormat('dd/MM/yyyy') : null,
				birth_date,
				expired_at: savedDocument.expire_at ? DateTime.fromISO(savedDocument.expire_at).toFormat('dd/MM/yyyy') : null,
				without_expiration: canExpire && !isUndefined(savedDocument.expire_at) ? !savedDocument.expire_at : undefined,
				country: find(root.props.lists['countries'], { id: savedDocument.country })
			}
		};
	},
	[types.LOOKUP_POA_USERS_SUCCESS]: (state, poa_clients) => {
		state.props = {
			...state.props,
			recipientAgentLookupResult: poa_clients,
			errors: null
		};
	},
	[types.SAVED_RECIPIENT_AGENT]: (state, { client, document } = {}) => {
		state.props = {
			...state.props,
			selectedRecipientAgent: !isUndefined(client) ? client : state.props.selectedRecipientAgent,
			savedRecipientAgent: !isUndefined(client) ? client : state.props.savedRecipientAgent,
			savedRecipientAgentDocument: !isUndefined(document) ? document : state.props.savedRecipientAgentDocument
		};
	},
	[types.SAVED_PERMIT_DOCUMENT]: (state, { savedDocument, savedClient, root, updateScans }) => {
		let canExpire = [documentTypes.powerOfAttorney].includes(savedDocument.type);
		state.props = {
			...state.props,
			documentValidationErrors: null,
			errors: null,
			permit_doc_errors: null,
			scanErrors: null,
			isPoaScansChecked: false,
			savedClientId: null,
			savedClient: savedClient || state.props.savedClient,
			savedDocument: {
				type: { id: paymentDocumentTypes.passport, text: paymentDocumentTypesLocale[paymentDocumentTypes.passport] },
				is_resident: true
			},
			savedPermitDocument: {
				...savedDocument,
				scans: !updateScans ? state.props.savedPermitDocument?.scans || savedDocument.scans : savedDocument.scans,
				type: find(root.props.lists['payment-document-types'], { id: savedDocument.type }),
				expired_at: savedDocument.expire_at ? DateTime.fromISO(savedDocument.expire_at).toFormat('dd/MM/yyyy') : null,
				without_expiration: canExpire && !isUndefined(savedDocument.expire_at) ? !savedDocument.expire_at : undefined
			}
		};
	},
	[types.SET_PERMIT_DOCUMENT]: (state, doc) => {
		state.props = { ...state.props, savedPermitDocument: doc };
	},
	[types.LOOKUP_CLIENT_SUCCESS]: (state, { savedDocument, savedClient, isRecipientAgent } = {}) => {
		const fieldToUpdate = isRecipientAgent ? 'savedRecipientAgent' : 'savedClient';
		const docFieldToUpdate = isRecipientAgent ? 'savedRecipientAgentDocument' : 'savedDocument';

		const clientUpdate = {
			...(state.props[fieldToUpdate] || {}),
			...savedClient,
			shouldUpdateClient: false
		};
		// this allows us to merge clients in nc2, when one does not have taxid, and another has
		if (state.props[fieldToUpdate]?.main?.id && state.props[fieldToUpdate].main.id !== savedClient?.main?.id) {
			clientUpdate.main.id = state.props[fieldToUpdate].main.id;
			clientUpdate.shouldUpdateClient = true;
		}
		state.props = {
			...state.props,
			documentValidationErrors: null,
			errors: null,
			scanErrors: null,
			savedClientId: null,
			[docFieldToUpdate]: {
				...(state.props[docFieldToUpdate] || {}),
				...savedDocument
			},
			[fieldToUpdate]: clientUpdate
		};
	},
	[types.SET_DOCUMENT_FOR_SCANS]: (state, documentForScans) => {
		state.props = { ...state.props, documentForScans };
	},
	[types.DOCUMENT_VALIDATION_ERRORS]: (state, errors) => {
		state.props = { ...state.props, errors };
	},
	[types.PERMIT_DOCUMENT_VALIDATION_ERRORS]: (state, errors) => {
		state.props = {
			...state.props,
			permit_doc_errors: errors,
			savedClient: state.props.savedDocument?.id ? state.props.savedClient : null,
			savedPermitDocument: null
		};
	},
	[types.CLOSE_DOCUMENT_SCANS]: (state) => {
		state.props = { ...state.props, scanErrors: null };
	},
	[types.SCAN_ERROR]: (state, scanError) => {
		state.props = {
			...state.props,
			scanErrors: (state.props.scanErrors || []).filter((e) => e.id !== scanError.id).concat([scanError])
		};
	},
	[types.SCAN_UPLOAD]: (state, scan) => {
		state.props = {
			...state.props,
			scanErrors: (state.props.scanErrors || []).filter((e) => e.id !== scan.tmp_id),
			[scan.documentToUpdate]: {
				...state.props[scan.documentToUpdate],
				verification_profile_id: null,
				scans: (state.props[scan.documentToUpdate].scans || [])
					.filter((e) => e.id !== scan.previous_scan_id)
					.concat([scan])
			},
			documentForScans: {
				...state.props.documentForScans,
				verification_profile_id: null,
				scans: (state.props.documentForScans.scans || []).filter((e) => e.id !== scan.previous_scan_id).concat([scan])
			}
		};
	},
	[types.SET_INSTANT_PAYOUT_RECIPIENT]: (state, instant_payout_recipient) => {
		state.props = { ...state.props, instant_payout_recipient: { ...instant_payout_recipient } };
	},
	[types.DIGITAL_DOCUMENT_REQUEST_LOADING]: (state, { uuid, timeout } = {}) => {
		state.props = {
			...state.props,
			digitalDocumentRequestUuid: uuid,
			digitalDocTimeout: uuid && timeout ? timeout : null
		};
	},
	[types.SET_DOCUMENT_SCANS_CHECKED]: (state, { isPoaScansChecked, isDocumentScansChecked }) => {
		state.props = {
			...state.props,
			isPoaScansChecked: !isUndefined(isPoaScansChecked) ? isPoaScansChecked : state.props.isPoaScansChecked,
			isDocumentScansChecked: !isUndefined(isDocumentScansChecked)
				? isDocumentScansChecked
				: state.props.isDocumentScansChecked
		};
	},
	[types.VERIFICATION_PROFILE_CREATED]: (state, { verification_profile_id, documentToUpdate, reqPayload }) => {
		state.props = {
			...state.props,
			[documentToUpdate]: { ...state.props[documentToUpdate], verification_profile_id },
			documentForScans: { ...state.props.documentForScans, verification_profile_id },
			savedClient: {
				...state.props.savedClient,
				verification_profiles: [{ ...reqPayload, id: verification_profile_id }]
			}
		};
	},
	[types.UEP_AGREEMENT_SAVED]: (state, verification) => {
		state.props = { ...state.props, nc3Client: { ...state.props.nc3Client, verification } };
	},
	[types.UPDATE_CLIENT_VERIFICATION]: (state, verification) => {
		state.props = { ...state.props, nc3Client: { ...state.props.nc3Client, verification } };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;

export const documentScanTypes = {
	[documentTypes.passport]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.pageFourFive,
		scanTypes.pageSixSeven,
		scanTypes.registration,
		scanTypes.otherPage
	],
	[documentTypes.biometricPassport]: [
		scanTypes.sideOne,
		scanTypes.sideTwo,
		scanTypes.registration,
		scanTypes.otherPage,
		scanTypes.digital
	],
	[documentTypes.refugeeCertificate]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.pageFourFive,
		scanTypes.otherPage
	],
	[documentTypes.internationalPassport]: [
		scanTypes.pageOne,
		scanTypes.taxpayerId,
		scanTypes.otherPage,
		scanTypes.digital
	],
	[documentTypes.temporaryCitizenCertificate]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.pageFourFive,
		scanTypes.otherPage
	],
	[documentTypes.residencePermanentPermit]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.pageFourFive,
		scanTypes.otherPage
	],
	[documentTypes.temporaryResidencePermit]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.pageFourFive,
		scanTypes.otherPage
	],
	[documentTypes.biometricTemporaryResidencePermit]: [
		scanTypes.sideOne,
		scanTypes.sideTwo,
		scanTypes.registration,
		scanTypes.otherPage
	],
	[documentTypes.biometricPermanentResidencePermit]: [
		scanTypes.sideOne,
		scanTypes.sideTwo,
		scanTypes.registration,
		scanTypes.otherPage
	],
	[documentTypes.protectedPersonCertificate]: [
		scanTypes.pageOne,
		scanTypes.pageTwoThree,
		scanTypes.registration,
		scanTypes.otherPage
	],
	[documentTypes.powerOfAttorney]: [scanTypes.pageOne, scanTypes.otherPage],
	[documentTypes.birthCertificate]: [scanTypes.pageOne, scanTypes.otherPage],
	[documentTypes.certificateOfInheritance]: [scanTypes.pageOne, scanTypes.otherPage],
	[documentTypes.foreignersPassport]: [scanTypes.pageOne, scanTypes.registration, scanTypes.otherPage]
};

/* eslint-disable */
const getScanPrintHTML = (urls) =>
	`<html>
		<head>
			<style>
				@page {
					size: A4;
					margin: 0;
				}
				html, body {
					height: 100%;
					margin: 0;
					padding: 0;
				}
				.page {
					margin: 0;
					overflow: hidden;
					position: relative;
					box-sizing: border-box;
					page-break-after: always;
					padding: 5mm;
					width: 210mm;
					height: 296mm;
					background: white;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					background: white;
				}
			</style>
		</head>
		<body>
			${urls
				.map(
					(url) => `
				<div class="page">
					<img src="${url}"></img>
				</div>
			`
				)
				.join('')}
		</body>
	</html>`;
/* eslint-enable */

// kinda nc3 nc2 compat hack
function mapClientNc3ToNc2(client) {
	return {
		main: client,
		name: {
			first_name: client.first_name,
			last_name: client.last_name,
			patronymic: client.patronymic
		},
		phones: [{ phone: client.phone, is_active: true }]
	};
}
