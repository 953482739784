import BigNumber from 'bignumber.js';
import find from 'lodash/find';

import { core, http, lookup } from 'novapay-ui';

import handleError from '@services/handle-api-error';
import { enum as providers } from '@repo/enums/operation-providers';
import { enum as productParticipantTypes } from '@repo/enums/product-participant-types';

const createActions = (types) => {
	const actions = {
		lookupFamilyBankService: async (context, { query = '', category = {}, region = {}, external_id }) => {
			let res = await lookup('/v3/lookups/family-bank-service', {
				query: { query, category: category?.id, region_id: region?.id, external_id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.FAMILY_BANK_SERVICE_LOOKUP, res.data);
		},
		lookupFamilyBankDictionary: async (context, { name, value_query = '', key_query, list_id }) => {
			let res = await http({
				url: '/v3/lookups/family-bank-dictionary',
				query: { value_query, key_query, list_id }
			});
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.FAMILY_BANK_DICTIONARY_LOOKUP, { res: res.data, name });
		},
		lookupBank: async (context, query = '') => {
			let res = await lookup('/v3/lookups/bank', {
				query: { query }
			});
			if (!handleError()(res, context)) {
				return;
			}
			return context.commit(types.BANK_LOOKUP, res.data);
		},
		setFamilyBankUtilityPayments: (context, payments) => {
			context.commit(types.FAMILY_BANK_SET_UTILITY_PAYMENTS, payments);
		},
		setFamilyBankUtilityCounters: (context, counters) => {
			context.commit(types.FAMILY_BANK_SET_UTILITY_COUNTERS, counters);
		},
		getFamilyBankExtraData: async (context, { family_bank_client_identifier, template }) => {
			let { processingHandler, validationHandler } = await import('../support'); // dynamic not breaking app
			const { familyBankSelectedExtraService } = context.state.props;
			const { family_bank_config } = context.rootState.root.props;

			let familyBankService = { ...familyBankSelectedExtraService };
			if (!familyBankSelectedExtraService && template?.family_bank_service_external_id) {
				await actions.lookupFamilyBankService(context, { external_id: template.family_bank_service_external_id });
				[familyBankService] = context.state.props.familyBankServiceLookupResult;
			}
			let res = await http({
				url: '/v3/operations/check-payments',
				method: 'POST',
				data: {
					provider: providers.familyBank,
					payments: [
						{
							id: null,
							payer: null,
							recipients: [
								{
									type: productParticipantTypes.legal,
									amount: new BigNumber(familyBankService.min_amount).gte(0.1) ? familyBankService.min_amount : 0.1,
									identifier: family_bank_config.contragent_identifier
								}
							],
							metadata: {
								family_bank_client_identifier,
								family_bank_service_external_id: familyBankService.external_id
							}
						}
					]
				}
			});
			if (
				!handleError({ processing: processingHandler, validation: validationHandler })(
					res,
					context,
					200,
					types.VALIDATION_ERRORS
				)
			) {
				return;
			}

			let extraData = res.data?.[0]?.externalResponse || {};
			return context.commit(types.CLOSE_FAMILY_BANK_EXTRA_IDENTIFIER, {
				familyBankSelectedExtraService: familyBankService,
				familyBankSelectedExtraServiceData: {
					...extraData,
					family_bank_client_identifier,
					template: template || { client_identifier: family_bank_client_identifier }
				}
			});
		},
		getFamilyBankUtilityData: async (
			context,
			{ family_bank_client_identifier, family_bank_sub_service_id, template }
		) => {
			let { processingHandler, validationHandler } = await import('../support'); // dynamic not breaking app
			const { familyBankSelectedUtilityService } = context.state.props;
			const { family_bank_config } = context.rootState.root.props;

			let familyBankService = { ...familyBankSelectedUtilityService };
			if (!familyBankSelectedUtilityService && template?.family_bank_service_external_id) {
				await actions.lookupFamilyBankService(context, { external_id: template.family_bank_service_external_id });
				[familyBankService] = context.state.props.familyBankServiceLookupResult;
			}
			let res = await http({
				url: '/v3/operations/check-payments',
				method: 'POST',
				data: {
					provider: providers.familyBank,
					payments: [
						{
							id: null,
							payer: null,
							recipients: [
								{
									type: productParticipantTypes.legal,
									amount: new BigNumber(familyBankService.min_amount).gte(0.1) ? familyBankService.min_amount : 0.1,
									identifier: family_bank_config.contragent_identifier
								}
							],
							metadata: {
								family_bank_client_identifier,
								family_bank_service_external_id: familyBankService.external_id,
								family_bank_sub_service_id
							}
						}
					]
				}
			});
			if (
				!handleError({ processing: processingHandler, validation: validationHandler })(
					res,
					context,
					200,
					types.VALIDATION_ERRORS
				)
			) {
				return;
			}

			let utilityData = res.data?.[0]?.externalResponse || {};
			return context.commit(types.CLOSE_FAMILY_BANK_UTILITY_IDENTIFIER, {
				familyBankSelectedUtilityService: familyBankService,
				familyBankSelectedUtilityServiceData: {
					...utilityData,
					family_bank_client_identifier,
					family_bank_sub_service_id,
					template
				}
			});
		}
	};
	return actions;
};

const createMutations = (types) => ({
	[types.FAMILY_BANK_SERVICE_LOOKUP]: (state, familyBankServiceLookupResult) => {
		state.props = { ...state.props, familyBankServiceLookupResult, productTemplatesLookupResult: null };
	},
	[types.FAMILY_BANK_DICTIONARY_LOOKUP]: (state, { res, name }) => {
		state.props = {
			...state.props,
			familyBankDictionaryLookupResult: {
				...(state.props.familyBankDictionaryLookupResult || {}),
				[name]: res
			}
		};
	},
	[types.BANK_LOOKUP]: (state, lookupBankResult) => {
		state.props = { ...state.props, lookupBankResult };
	},
	[types.OPEN_FAMILY_BANK_EXTRA_IDENTIFIER]: (state, familyBankSelectedExtraService) => {
		state.props = { ...state.props, familyBankSelectedExtraService };
	},
	[types.CLOSE_FAMILY_BANK_EXTRA_IDENTIFIER]: (state, payload) => {
		let { familyBankSelectedExtraService, familyBankSelectedExtraServiceData } = payload;
		state.props = {
			...state.props,
			familyBankSelectedExtraServiceData,
			familyBankSelectedService: familyBankSelectedExtraService
		};
	},
	[types.OPEN_FAMILY_BANK_UTILITY_IDENTIFIER]: (state, familyBankSelectedUtilityService) => {
		state.props = { ...state.props, familyBankSelectedUtilityService };
	},
	[types.CLOSE_FAMILY_BANK_UTILITY_IDENTIFIER]: (state, payload) => {
		let { familyBankSelectedUtilityService, familyBankSelectedUtilityServiceData } = payload;
		state.props = { ...state.props, familyBankSelectedUtilityService, familyBankSelectedUtilityServiceData };
	},
	[types.OPEN_FAMILY_BANK_UTILITY_COUNTERS]: (state, familyBankSelectedUtilityPayment) => {
		state.props = { ...state.props, familyBankSelectedUtilityPayment };
	},
	[types.CLOSE_FAMILY_BANK_UTILITY_COUNTERS]: (state, { counters, amountToPay }) => {
		if (!counters) {
			return;
		}
		state.props = {
			...state.props,
			familyBankUtilityPayments: state.props.familyBankUtilityPayments.map((p) => {
				if (p.id === state.props.familyBankSelectedUtilityPayment.id) {
					let amount = new BigNumber(amountToPay);
					return { ...p, amount_to_pay: amount.toFixed(2, 2) };
				}
				return p;
			}),
			familyBankUtilityCounters: state.props.familyBankUtilityCounters.map((c) => {
				let updated = find(counters, (counter) => counter.id === c.id);
				return updated ? { ...c, ...updated } : c;
			}),
			familyBankSelectedUtilityPayment: null
		};
	},
	[types.FAMILY_BANK_SET_UTILITY_PAYMENTS]: (state, familyBankUtilityPayments) => {
		state.props = { ...state.props, familyBankUtilityPayments };
	},
	[types.FAMILY_BANK_SET_UTILITY_COUNTERS]: (state, familyBankUtilityCounters) => {
		state.props = { ...state.props, familyBankUtilityCounters };
	},
	[types.FAMILY_AMOUNT_RESTRICTIONS]: (state, familyAmountRestrictions) => {
		state.props = { ...state.props, familyAmountRestrictions };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

export default createComponentStore;
