import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

let types = core.createTypes('cashbooks');

export default core.createStore(actions, {}, mutations, {
	key: 'cashbooks',
	initial: 'idle',
	states: {
		idle: {
			entry: 'getCashbooks',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.GET_CASHBOOKS_SUCCESS]: 'render'
			}
		},
		render: {
			on: {
				[types.APPLY_QUERY]: 'idle'
			}
		}
	}
});
