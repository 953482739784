import { core } from 'novapay-ui';
import cloneDeep from 'lodash/cloneDeep';

import commonStore from '@services/common-store';

const types = core.createTypes('operations');
const { mutations } = commonStore('operations');

export default {
	...mutations,
	[types.OPERATIONS_SUCCESS]: (state, { operations, total, totals } = {}) => {
		state.props = { ...state.props, operations, total, totals };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { ...state.props, query };
	},
	[types.OPEN_PREVIEW_MODAL]: (state, transaction) => {
		state.props = { ...state.props, transaction };
	},
	[types.UPDATE_WU_STATUS]: (state, status) => {
		let transaction = cloneDeep(state.props.transaction);
		Object.assign(transaction.metadata, { wu_payment_status: status });
		state.props = { ...state.props, transaction };
	},
	[types.CLOSE_PREVIEW_MODAL]: (state) => {
		state.props = { ...state.props, transaction: null };
	},
	[types.OPEN_MASS_REGISTRY_PERIOD_SELECT_MODAL]: (state, massRegistryGroups) => {
		state.props = { ...state.props, massRegistryGroups };
	},
	[types.OPEN_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: (state, timePeriod) => {
		state.props = { ...state.props, timePeriod };
	},
	[types.CLOSE_EMAIL_CONFIRM_FOR_RECORDS_MODAL]: (state) => {
		state.props = { ...state.props, timePeriod: null };
	},
	[types.OPEN_CASHDESKS_MODAL]: (state, cashdesks) => {
		state.props = { ...state.props, cashdesks };
	},
	[types.CLOSE_CASHDESKS_MODAL]: (state) => {
		state.props = { ...state.props, cashdesks: null };
	},
	[types.OPEN_DAILY_REPORT_PREVIEW_MODAL]: (state, dailyReportData) => {
		state.props = { ...state.props, dailyReportData };
	},
	[types.CLOSE_DAILY_REPORT_PREVIEW_MODAL]: (state) => {
		state.props = { ...state.props, dailyReportData: null };
	},
	[types.VALIDATION_ERRORS]: (state, validation_errors) => {
		state.props = { ...state.props, validation_errors };
	},
	[types.CLOSE_EDIT_RECIPIENT_MODAL]: (state) => {
		state.props = { ...state.props, validation_errors: null };
	}
};
