import actions from './actions';
import mutations from './mutations';
import { core } from 'novapay-ui';

const types = core.createTypes('archive_documents');

export default core.createStore(actions, {}, mutations, {
	key: 'archive_documents',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			onEntry: 'getDocumentsAndScheduleData',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.DOCUMENTS_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.CREATE_DOCUMENT_SUCCESS]: 'idle',
				[types.REMOVE_DOCUMENT_SUCCESS]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_CREATE_DOCUMENT_MODAL]: 'create_document_modal'
					}
				},
				create_document_modal: {
					on: {
						[types.CLOSE_CREATE_DOCUMENT_MODAL]: 'main'
					}
				}
			}
		}
	}
});
