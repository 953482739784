import Vue from 'vue';
import Promise from 'bluebird';
import { DateTime } from 'luxon';
import find from 'lodash/find';

import log from '@services/log';

import { enum as merchantBanks } from '@repo/enums/pos-merchant-banks';
import { enum as merchantTypes } from '@repo/enums/pos-merchant-types';
import { enum as instruments } from '@repo/enums/operation-instruments';

class NativeAppBridge extends Vue {
	constructor() {
		super();
		this.interface = null;
		if (window.parent && window.parent.nativeAppInterface) {
			this.interface = window.parent.nativeAppInterface;
			this.isNewMobileRMO = true;
		} else if (window.jsinterface) {
			this.interface = window.jsinterface;
			this.isNewMobileRMO = false;
		}
		window.actionPaymentCallback = (method, data) => this.actionPaymentCallback(method, data);
	}
	// needResponse: if true, then returns a promise which resolves to the value
	// if false, a callback on this class is called with the value
	callDevice(method, needResponse = false, data) {
		// eslint-disable-next-line
		console.log('callDevice', method, needResponse, data);
		if (this.isNewMobileRMO) {
			return this.interface.callPaymentDevice(method, needResponse, JSON.stringify(data));
		} else {
			return this.interface.callDevice(method, needResponse, JSON.stringify(data));
		}
	}
	actionPaymentCallback(method, data) {
		// eslint-disable-next-line
		console.log('actionPaymentCallback: ', method, ' - ', data);
		try {
			data = data && data.length ? data.replace(/[\r\n]+/g, ' ') : data;
			data = JSON.parse(data);
		} catch (e) {
			// eslint-disable-next-line
			console.warn(e);
		}
		log('mobile-pos-callback', { method, data });
		this.$emit(method, data);
	}
}

class MobilePosInterface {
	constructor() {
		try {
			document.domain = 'np.work';
			this.bridge = new NativeAppBridge();
		} catch (e) {
			// eslint-disable-next-line
			console.log('novapay mob pos: ', { err: e.message, stack: e.stack });
			null;
		}

		if (!this.bridge || !this.bridge.interface) {
			this.bridge = null;
			return;
		}

		this.lastTransactionReverseData = null; // used to cancel last transaction in case of an error in our backend
		this.connectedPosDevices = [];
	}
	cleanupListeners() {
		this.bridge.$off(); // clears all listeners
	}
	connectTapxphone() {
		let existingTxp = find(this.connectedPosDevices, { instrument: instruments.tapxphone });
		if (existingTxp) {
			return;
		}

		let handleRes = (res) => {
			log('tapxphone-available', res);
			if (res.available && res.device_id) {
				let pos = {
					np_mobile_device_id: res.device_id,
					code: null,
					merchant_bank: merchantBanks.oschadbank,
					instrument: instruments.tapxphone
				};
				this.connectedPosDevices.push(pos);
				this.bridge.$emit('connected', pos);
			}
		};

		if (this.bridge.isNewMobileRMO) {
			this.bridge
				.callDevice('isTapXPhoneAppAvailable', true)
				.then(handleRes)
				.catch(() => handleRes({ available: false }));
		} else {
			let res = this.bridge.interface.isTapXPhoneAppAvailable();
			try {
				res = JSON.parse(res);
			} catch (e) {
				res = { available: false };
			}
			handleRes(res);
		}
	}
	connectPoses() {
		if (!this.bridge) {
			return null;
		}
		// dont await these promises, it will suspend our payment form code execution while we wait for mob pos to connect
		// they return the pos code by emitting an event
		try {
			this.connectTapxphone();
			if (this.bridge.isNewMobileRMO) {
				return;
			}
			let mposCode = this.bridge.interface.getConnectedMpos();
			let existing = find(this.connectedPosDevices, {
				instrument: instruments.portablePos,
				code: mposCode
			});
			if (mposCode && !existing) {
				let pos = {
					code: mposCode,
					merchant_bank: merchantBanks.privatbank,
					instrument: instruments.portablePos
				};
				this.connectedPosDevices.push(pos);
				this.bridge.$emit('connected', pos);
			}
		} catch (e) {
			// eslint-disable-next-line
			console.warn(e);
		}
	}
	pay(payload, tapxphoneData, onAlert) {
		if (!this.bridge) {
			throw new Error('mobile pos not connected');
		}
		if (!payload?.mobile_pos_amount || !onAlert) {
			throw new Error('mobile pos pay failed, not all arguments provided');
		}
		if (payload.instrument === instruments.tapxphone) {
			return this.payWithOschadTapXPhone(payload.mobile_pos_amount, tapxphoneData, onAlert);
		} else if (payload.instrument === instruments.portablePos) {
			return this.payWithPrivatMobile(payload.mobile_pos_amount, onAlert);
		}
	}
	payWithOschadTapXPhone(amount, tapxphoneData, onAlert) {
		return new Promise((resolve, reject) => {
			this.bridge.callDevice('startTapXPhonePayment', false, tapxphoneData);
			this.bridge.$on('tapXPhonePaymentResult', (res) => {
				let err = checkTxpResponse(res);
				if (err) {
					return reject(new Error(err));
				}
				// dont map receipt data here, as we will map it again anyway
				// after checking transaction status on backend
				resolve({ is_successful: true });
			});
		});
	}
	refundOschadTapXPhone(tapxphoneData) {
		return new Promise((resolve, reject) => {
			this.bridge.callDevice('startTapXPhonePayment', false, tapxphoneData);
			this.bridge.$on('tapXPhonePaymentResult', (res) => {
				let err = checkTxpResponse(res);
				if (err) {
					return reject(new Error(err));
				}
				resolve();
			});
		});
	}
	// eslint-disable-next-line class-methods-use-this
	mapPrivatTransactionData(data) {
		return {
			is_successful: data?.transactionData?.success && data?.transactionData?.result === 'ok',
			reason: data?.transactionData?.user_message || 'transaction rejected',
			pos_code: data?.sn,
			pan: data?.transactionData?.masked_pan,
			rrn: data?.transactionData?.rrn,
			auth_code: data?.transactionData?.approval_code,
			invoice_num: data?.transactionData?.receipt_id,
			terminal_id: data?.transactionData?.merchant,
			merchant_id: data?.transactionData?.merchant,
			merchant_index: 1,
			merchant_type: merchantTypes.privatDefault,
			card_processed_at: data?.transactionData?.date
				? DateTime.fromFormat(data.transactionData.date, 'yyyyMMdd HH:mm:ss ZZZ', {
						zone: 'Europe/Kiev'
				  }).toISO()
				: undefined,
			amount: data.amount
		};
	}
	payWithPrivatMobile(amount, onAlert) {
		return new Promise((resolve, reject) => {
			this.lastTransactionReverseData = null;
			this.bridge.callDevice('startTransaction', false, {
				mAmount: amount,
				mPurpose: 'оплата' // this hardcode intentional
			});
			this.bridge.$on('onUpdateUserInterface', ({ message }) => onAlert(message));
			// typo in "onExeption" is intentional
			this.bridge.$on('onExeption', ({ message }) => {
				this.cleanupListeners();
				reject(new Error(message));
			});
			this.bridge.$on('onTransactionFinish', (data) => {
				this.cleanupListeners();
				if (data?.transactionData?.id && data?.sn) {
					this.lastTransactionReverseData = {
						id: data.transactionData.id,
						sn: data.sn
					};
				}
				resolve(this.mapPrivatTransactionData(data));
			});
		});
	}
	async cancelLastTransaction() {
		return new Promise((resolve, reject) => {
			if (!this.lastTransactionReverseData) {
				return resolve();
			}
			this.bridge.callDevice('reverse', false, this.lastTransactionReverseData);
			this.bridge.$on('onReverseSuccess', (data) => {
				this.lastTransactionReverseData = null;
				this.cleanupListeners();
				resolve(data);
			});
			this.bridge.$on('onReverseFailure', ({ message }) => {
				this.cleanupListeners();
				reject(new Error(message));
			});
		});
	}
	async getPrivatTransactions() {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			let privatMobilePos = find(this.connectedPosDevices, { instrument: instruments.portablePos });
			if (!privatMobilePos) {
				return reject(new Error('pos not connected'));
			}
			this.bridge.callDevice('getMposTransactionsArchive', false, {
				sn: privatMobilePos.code,
				startDate: DateTime.local().minus({ days: 1 }).toFormat('yyyyMMdd'),
				endDate: DateTime.local().toFormat('yyyyMMdd')
			});
			this.bridge.$on('mposTransactionsArchiveSuccess', (data) => {
				this.cleanupListeners();

				log('get-privat-transactions', data);

				resolve(
					data.map((t) => {
						return {
							is_successful: true,
							reason: t.userMessage,
							pos_code: undefined,
							pan: t.pan,
							rrn: t.rrn,
							auth_code: t.approvalCode,
							amount: t.amount,
							invoice_num: t.receiptId,
							merchant_index: 1,
							merchant_type: merchantTypes.privatDefault,
							merchant_id: t.merchant,
							terminal_id: t.merchant,
							card_processed_at: DateTime.fromISO(t.insertDate).toISO()
						};
					})
				);
			});
			this.bridge.$on('mposTransactionsArchiveFailure', ({ message }) => {
				this.cleanupListeners();
				reject(new Error(message));
			});
		});
	}
}

function checkTxpResponse(res) {
	let { Result, server_error } = res || {};
	switch (Result) {
		case '0':
			return null;
		case '905':
			return `Невірний тип операції в токені`;
		case '904':
			return `Помилка активації терміналу. Зверніться в чат підтримки або СД (Код помилки 904)`;
		case '903':
			return 'Некоректний UID';
		case '902':
			return 'Сплив дозволений час на очікування оплати';
		case '901':
			return 'Помилка ініціалізації';
		case '900':
			return server_error && server_error.includes('101')
				? 'Ваш пристрій заблокований в системі банку. Зверніться в чат підтримки або СД (Код помилки 900-101)'
				: 'Помилка виконання транзакції';
		case '911':
			return 'Вимкніть режим розробника на телефоні та спробуйте повторно (Код помилки 911)';
		case '920':
			return 'Оплата відхилена банком емітентом';
		default:
			return `Помилка виконання транзакції${Result ? `, код помилки Tapxphone: ${Result}` : ''}`;
	}
}

export default new MobilePosInterface();
