const types = {
	biometricPassport: 'biometric_passport',
	biometricTemporaryResidencePermit: 'biometric_temporary_residence_permit',
	biometricPermanentResidencePermit: 'biometric_permanent_residence_permit',
	foreignersPassport: 'foreigners_passport',
	certificateOfInheritance: 'certificate_of_inheritance'
};

const locale = {
	[types.biometricPassport]: 'паспорт громадянина України (біометричний)',
	[types.biometricTemporaryResidencePermit]: 'біометрична посвідка на тимчасове проживання',
	[types.biometricPermanentResidencePermit]: 'біометрична посвідка на постійне проживання',
	[types.foreignersPassport]: 'паспорт громадянина іноземної держави',
	[types.certificateOfInheritance]: 'свідоцтво про право на спадщину'
};

module.exports = {
	enum: types,
	locale: locale
};
