import { core } from 'novapay-ui';
import { enum as cashbookStatuses } from '@repo/enums/cashbook-statuses';
import commonStore from '@services/common-store';

const types = core.createTypes('cashbooks');
let { mutations } = commonStore('cashbooks');

export default {
	...mutations,
	[types.GET_CASHBOOKS_SUCCESS]: (state, cashbooks) => {
		state.props = {
			cashbooks: cashbooks.map((c) => (c.status === cashbookStatuses.opened ? { ...c, closed_at: null } : c))
		};
	}
};
