const statuses = {
	created: 'created',
	confirmed: 'confirmed',
	rejected: 'rejected'
};

const locale = {
	[statuses.created]: 'створено',
	[statuses.confirmed]: 'пiдтверджено',
	[statuses.rejected]: 'скасовано'
};

module.exports = {
	enum: statuses,
	locale: locale
};
