import Promise from 'bluebird';
import BigNumber from 'bignumber.js';
import some from 'lodash/some';
import every from 'lodash/every';
import omit from 'lodash/omit';
import find from 'lodash/find';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { core, http, lookup } from 'novapay-ui';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import router from '@/router';

import isMobileDevice from '@services/is-mobile-device';

import posStoreExtension from './extensions/pos';
import documentStoreExtension from './extensions/document';
import familyBankStoreExtension from './extensions/family-bank';
import productTemplatesStoreExtension from './extensions/product-templates';
import mitStoreExtension from './extensions/mit';
import westernUnionExtension from './extensions/western-union';
import claimsStoreExtension from './extensions/claims';
import naftogazExtension from './extensions/naftogaz';
import creditExtension from './extensions/credit';
import revenueExtension from './extensions/revenue-encashment';
import awisExtension from './extensions/awis';
import {
	toggles,
	validationHandler,
	processingHandler,
	selfServiceProcessingHandler,
	noCashOperatorProcessingHandler
} from './support';

import { enum as centrifugoEventTypes } from '@repo/enums/centrifugo-event-types';
import { enum as statuses } from '@repo/enums/operation-statuses';
import { enum as instruments } from '@repo/enums/operation-instruments';
import { enum as mediums } from '@repo/enums/operation-mediums';
import { enum as operationTypes } from '@repo/enums/operation-types';
import { enum as posMerchantBanks } from '@repo/enums/pos-merchant-banks';
import { enum as posMerchantTypes } from '@repo/enums/pos-merchant-types';

const { actions: commonStoreActions } = commonStore('payment');
const { actions: posActions } = posStoreExtension('payment');
const { actions: documentActions } = documentStoreExtension('payment');
const { actions: familyBankActions } = familyBankStoreExtension('payment');
const { actions: productTemplatesActions } = productTemplatesStoreExtension('payment');
const { actions: mitActions } = mitStoreExtension('payment');
const { actions: westernUnionActions } = westernUnionExtension('payment');
const { actions: claimsActions } = claimsStoreExtension('payment');
const { actions: naftogazActions } = naftogazExtension('payment');
const { actions: creditActions } = creditExtension('payment');
const { actions: revenueActions } = revenueExtension('payment');
const { actions: awisActions } = awisExtension('payment');

const types = core.getTypes('payment');
const rootTypes = core.getTypes('root');

const idle = (context) => context.commit(types.IDLE);

const getOperations = async (context, { skipClientsCheck } = {}) => {
	let { paymentType } = router.currentRoute.params;
	let { ids, family_bank_selected_service, ria_operation, moneygram_operation, wu_operation, existing, credit_id } =
		router.currentRoute.query || {};
	let desiredStatuses = getDesiredInitialStatuses(context);
	let requests = [];
	let data = {
		operations: [],
		uniqueClients: null,
		nc3Client: null
	};

	if (ids) {
		if (paymentType === 'payout' && !skipClientsCheck) {
			requests.push({
				req: http('/v3/operations/check-unique-clients', { method: 'POST', data: { ids } }),
				handler: (res) => {
					if (!handleError()(res, context)) {
						return;
					}
					data.uniqueClients = res.data;
				}
			});
		} else {
			requests.push({
				req: http('/v3/operations', { query: { ids, paymentType } }),
				handler: (res) => {
					if (!handleError()(res, context)) {
						return;
					}
					if (some(res.data, (o) => !desiredStatuses.includes(o.status))) {
						res.data = [];
					}
					data.operations = res.data;
				}
			});
		}
	}

	if (paymentType === 'family') {
		requests.push({
			req: http('/v3/lists/family-bank-categories'),
			handler: (res) => {
				if (!handleError()(res, context)) {
					return;
				}
				data.familyBankCategories = res.data;
			}
		});
		if (family_bank_selected_service) {
			requests.push({
				req: http({
					url: '/v3/lookups/family-bank-service',
					query: { external_id: family_bank_selected_service }
				}),
				handler: (res) => {
					if (!handleError()(res, context)) {
						return;
					}
					data.familyBankSelectedService = res.data ? res.data[0] : null;
				}
			});
		}
	}

	if (paymentType === 'ria') {
		data.operations = [ria_operation];
	}

	if (['western-union-payout'].includes(paymentType)) {
		data.operations = [wu_operation];
	}

	if (paymentType === 'moneygram') {
		requests.push({
			req: http({
				url: '/v3/lists/moneygram/states'
			}),
			handler: (res) => {
				if (!handleError()(res, context)) {
					return;
				}
				data.moneygramStates = res.data;
			}
		});

		data.operations = [moneygram_operation];
	}

	if (paymentType === 'loan-repayment') {
		requests.push({
			req: http({
				url: `/v3/credits/repayment?query=${credit_id}`
			}),
			handler: (res) => {
				if (!handleError()(res, context)) {
					return;
				}
				data.loanData = res.data.credit;
				data.loanRecipeintData = res.data.contragent;
			}
		});
	}

	if (['novapay-cards'].includes(paymentType)) {
		toggles.toggleClientBarcode(context);
	}

	if (['novapay-card-withdrawal', 'novapay-card-deposit', 'onboarding'].includes(paymentType)) {
		data.keepProps = true;
	}

	await Promise.map(requests, async (r) => {
		let res = await r.req;
		return r.handler(res);
	});

	if (data.uniqueClients) {
		if (data.uniqueClients.length > 1) {
			toggles.toggleUniqueClients(context, { uniqueClients: data.uniqueClients });
		} else {
			return getOperations(context, { skipClientsCheck: true });
		}
	} else {
		context.commit(types.GET_OPERATIONS_SUCCESS, data);
	}

	commonStoreActions.hideRootSplashScreen(context);

	if (existing === 'true') {
		toggles.toggleExistingConfirm(context);
	} else if (paymentType === 'refund') {
		let posRefund = some(data.operations, (op) => {
			let paidTransaction = find(op.transactions || [], (t) => t.status === statuses.paid);
			return paidTransaction?.instrument === instruments.pos;
		});
		posRefund && toggles.togglePosRefundConfirm(context);
	}
};

/*
	main action in this module
	everything that results in submitting payment happens inside this method, including all preprocessing
	"resume" mechanism is used to achieve flow control, i.e. this method execution can be stopped early by resolve()
	and the current state is stored in an "actionToResume", which is later called after the interrupt is handled.
	same pattern is used for queued payments, where payments are submitted in series
*/
const submitPayment = async (context, payment) => {
	let { paymentType } = router.currentRoute.params;
	// this promise allows us to use vuex.dispatch on this action and await it
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve) => {
		// edge case here. loadingAction gets removed from payload by novapay-ui before the action sees it
		// so to pass it further, we must receive it in the action in a different prop
		let { loadingActionToResume, is_verified, ...payload } = payment;

		let paymentsQueue = [];
		let actionToResume = {
			actionName: 'submitPayment',
			payload,
			loadingAction: loadingActionToResume
		};

		// this allows submitPayment to be called per each queue item without firing all these preprocess actions each time
		if (!context.state.props.paymentsQueue) {
			if (payload.instant_payout_recipient) {
				// save recipient to build data for payout in instant-payout
				context.commit(types.SET_INSTANT_PAYOUT_RECIPIENT, payload.instant_payout_recipient);
			}

			if (['ria', 'moneygram'].includes(payment.provider) && payload.submittedDocument?.birth_date) {
				const date = DateTime.local().minus({ years: 18 });
				const birthDate = DateTime.fromFormat(payload.submittedDocument.birth_date, 'dd/MM/yyyy');

				if (birthDate.isValid && birthDate > date) {
					toggles.toggleBirthDateAlert(context);
					return resolve();
				}
			}

			let noPatronymicConfirmData = shouldConfirmNoPatronymic(context, payload);
			if (noPatronymicConfirmData) {
				toggles.togglePatronymicConfirm(context, { actionToResume, noPatronymicConfirmData });
				return resolve();
			}

			if (isMobileDevice) {
				payload.client_sign_png = context.state.props.clientSign;
			}

			let { cashQueueItems } = popCashPaymentsFromPayload(context, payload) || {};
			if (cashQueueItems) {
				paymentsQueue.push(...cashQueueItems);
			}

			if (payload.instrument === 'split-payment') {
				toggles.toggleSplitPayment(context, { actionToResume });
				return resolve();
			}

			if (payload.instrument === 'credit') {
				toggles.toggleCredit(context, { actionToResume });
				return resolve();
			}

			let { selectPosManually } = posActions.processPosSelection(context, payload) || {};
			if (selectPosManually) {
				toggles.togglePosSelect(context, { actionToResume });
				return resolve();
			}

			let { rrnCompletionData } = context.state.props;
			if (payload.is_rrn_completion && !rrnCompletionData) {
				toggles.toggleRRNCompletion(context, { actionToResume, loadingAction: payload.instrument });
				return resolve();
			} else if (rrnCompletionData) {
				payload.pos_data = rrnCompletionData;
			}

			let { selectMerchantManually, merchantQueueItems, stopExecution } =
				posActions.processMerchantSelection(context, payload) || {};

			// allows rrn merchant logic to prevent submit when incorrect merchant is selected
			if (stopExecution) {
				return resolve();
			}
			if (selectMerchantManually) {
				toggles.togglePosMerchantSelect(context, { actionToResume });
				return resolve();
			}

			if (merchantQueueItems) {
				// make novapayDefaultMerchant (shipment) the last of all payments
				let novapayDefaultMerchant;

				const idxOfNovapayDefault = merchantQueueItems.findIndex(
					(e) => e.payload.merchant_type === posMerchantTypes.novapayDefault
				);

				if (idxOfNovapayDefault !== -1) {
					novapayDefaultMerchant = merchantQueueItems.splice(idxOfNovapayDefault, 1);
				}

				paymentsQueue.push(...merchantQueueItems);
				novapayDefaultMerchant && paymentsQueue.push(...novapayDefaultMerchant);
			} else if (paymentsQueue.length) {
				// split payment, queue exists, but card payments can be done with one call
				paymentsQueue.push(actionToResume);
			}

			if (paymentType === 'western-union-pay') {
				let { instrument, payments } = payload;
				let [recipient] = payments[0].recipients;
				if (
					instrument === instruments.cash &&
					new BigNumber(recipient.amount).gt(5000) &&
					!context.state.props.isDocumentScansChecked
				) {
					context.commit(types.VERIFICATION_REQUIRED);
					toggles.toggleVerificationStatusModal(context);
					return resolve();
				}
			}

			if (
				[
					'western-union-pay',
					'western-union-payout',
					'payout',
					'refund',
					'ria',
					'moneygram',
					'instant-payout',
					'revenue-encashment'
				].includes(paymentType) &&
				!context.state.props.isRecipientAgent &&
				!(paymentType === 'instant-payout' && payload.endpoint === '/v3/operations/payout')
			) {
				try {
					let res = await documentActions.upsertRegularDocument(
						context,
						{
							client: payload.submittedClient,
							document: payload.submittedDocument,
							is_verified
						},
						{ returnUpserted: true }
					);
					if (paymentType === 'payout') {
						payload.recipient.novaclients_id = res.client.main.id;
					}
					if (paymentType === 'refund') {
						payload.payer.novaclients_id = res.client.main.id;
					}
					if (['ria', 'western-union-payout', 'moneygram'].includes(paymentType)) {
						payload.payments[0].recipients[0].novaclients_id = res.client.main.id;
					}
					if (['instant-payout', 'western-union-pay', 'revenue-encashment'].includes(paymentType)) {
						payload.payments[0].payer.novaclients_id = res.client.main.id;
						if (paymentType === 'instant-payout') {
							context.commit(types.SET_INSTANT_PAYOUT_RECIPIENT, {
								...payload.instant_payout_recipient,
								novaclients_id: res.client.main.id
							});
						}
					}
				} catch (e) {
					return resolve();
				}
			}

			if (paymentsQueue.length) {
				context.commit(types.SET_ACTION_TO_RESUME, paymentsQueue.shift());
				context.commit(types.SET_PAYMENTS_QUEUE, paymentsQueue);
				return resolve();
			}
		}

		// allow unused because we need to remove some extra values from data
		// eslint-disable-next-line no-unused-vars
		let { endpoint, submittedClient, submittedDocument, instant_payout_recipient, ...data } = payload;
		context.commit(types.SET_PAYMENT_FAILED_FLAG, false);
		let res = await http(endpoint, { method: 'POST', data });

		// allow using actionToResume, instrument in handlers
		res.actionToResume = actionToResume;
		res.instrument = payload.instrument;

		let handler = (() => {
			switch (payload.medium) {
				case mediums.selfService:
					return { processing: selfServiceProcessingHandler, validation: validationHandler };
				case mediums.noCashOperator:
					return { processing: noCashOperatorProcessingHandler, validation: validationHandler };
				default:
					return { processing: processingHandler, validation: validationHandler };
			}
		})();

		if (!handleError(handler)(res, context, 200, types.VALIDATION_ERRORS)) {
			context.commit(types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR);
			context.commit(types.EXIT_PAYMENTS_QUEUE_ON_ERROR);
			context.commit(types.SET_PAYMENT_FAILED_FLAG, true);
			return resolve();
		}

		context.commit(types.OPERATIONS_EVENT, {
			operations: res.data.filter((o) => o.type === operationTypes.body),
			newlyCreatedOperations: [
				'instant',
				'instant-payout',
				'instant-topup',
				'family',
				'ria',
				'moneygram',
				'western-union-pay',
				'loan-repayment',
				'novapay-card-withdrawal',
				'novapay-card-deposit'
			].includes(paymentType)
		});

		// split payment in 'awis' can have a race condition between success event and successful http res
		if (
			payload.instrument === instruments.cash &&
			!['awis', 'novapay-card-withdrawal', 'novapay-card-deposit'].includes(paymentType)
		) {
			context.commit(types.PAYMENT_SUCCESS);
		}

		if ([instruments.pos, instruments.portablePos].includes(payload.instrument)) {
			let { merchant_bank } = find(context.rootState.root.props.posTerminals, { code: payload.pos_code }) || {};
			if (payload.instrument === instruments.portablePos) {
				merchant_bank = posMerchantBanks.privatbank;
			}
			let { text } = find(context.rootState.root.props.lists['pos-merchant-banks'], { id: merchant_bank }) || {};
			context.commit(
				rootTypes.namespaced.ADD_ALERT,
				{
					severity: 'info',
					title: `Ініційована оплата на терміналі ${text}`
				},
				{ root: true }
			);
		}

		if ([instruments.portablePos, instruments.tapxphone].includes(payload.instrument) && !payload.is_rrn_completion) {
			await posActions.payWithMobilePos(context, { ...payload, initializeRes: res.data });
		}

		if (['novapay-card-withdrawal', 'novapay-card-deposit'].includes(paymentType)) {
			context.commit(types.LOADING_INSTRUMENT_SUBMIT, paymentType);
		}
		if ([instruments.pos, instruments.mit, instruments.mitByDefault].includes(payload.instrument)) {
			context.commit(types.LOADING_INSTRUMENT_SUBMIT, payload.instrument);
		}

		return resolve();
	});
};

const popCashPaymentsFromPayload = (context, payload) => {
	let { splitPayment } = context.state.props;
	if (!splitPayment) {
		return;
	}
	let { cashOperationIds, cash } = splitPayment;

	if (every(payload.ids, (id) => cashOperationIds.includes(id))) {
		payload.instrument = instruments.cash;
		payload.total_cash = cash;
		return;
	}

	payload.ids = payload.ids.filter((id) => !cashOperationIds.includes(id));
	payload.instrument = instruments.pos;

	if (cashOperationIds.length) {
		return {
			cashQueueItems: [
				{
					payload: {
						...payload,
						ids: cashOperationIds,
						instrument: instruments.cash,
						total_cash: cash
					},
					actionName: 'submitPayment',
					loadingActionToResume: { id: instruments.cash }
				}
			]
		};
	}
};

const getDesiredInitialStatuses = (context) => {
	let { paymentType } = router.currentRoute.params;
	let desiredStatuses;
	switch (paymentType) {
		case 'awis':
		case 'instant':
		case 'family':
		case 'loan-repayment':
			desiredStatuses = [statuses.created, statuses.initializedPay];
			break;
		case 'refund':
		case 'payout':
		case 'ria':
		case 'moneygram':
			desiredStatuses = [statuses.paid];
			break;
		default:
			desiredStatuses = [];
			break;
	}
	return desiredStatuses;
};

const shouldConfirmNoPatronymic = (context, payload) => {
	let {
		params: { paymentType },
		query: { no_cash_operator, self_service }
	} = router.currentRoute;
	let noPatronymicPayer;
	let noPatronymicRecipient;

	switch (paymentType) {
		case 'instant':
		case 'instant-topup':
			noPatronymicPayer = !payload.payments[0].payer.patronymic;
			noPatronymicRecipient = !payload.payments[0].recipients[0].patronymic;
			break;
		case 'instant-payout':
			// dont check for payout request, only for pay request
			noPatronymicPayer = payload.payments && !payload.payments[0].payer.patronymic;
			break;
		case 'family':
		case 'loan-repayment':
		case 'western-union-pay':
			noPatronymicPayer = !payload.payments[0].payer.patronymic;
			break;
		case 'awis':
			noPatronymicPayer = !payload.payer.patronymic;
			break;
		case 'payout':
			noPatronymicRecipient = !payload.recipient.patronymic;
			break;
		case 'ria':
		case 'western-union-payout':
		case 'moneygram':
			noPatronymicRecipient = !payload.payments[0].recipients[0].patronymic;
			break;
		default:
			noPatronymicPayer = false;
			noPatronymicRecipient = false;
			break;
	}
	return (noPatronymicPayer || noPatronymicRecipient) &&
		!context.state.props.emptyPatronymicConfirmed &&
		self_service !== 'true' &&
		no_cash_operator !== 'true'
		? {
				noPatronymicPayer,
				noPatronymicRecipient
		  }
		: null;
};

// this action is intended to work with xstate onExit hook, use it for logical interrupts
const resume = async (context) => {
	let { actionToResume } = context.state.props;
	if (!actionToResume) {
		return;
	}
	context.commit(types.RESUME);
	let { actionName, payload, loadingAction } = actionToResume;
	// just passing loadingAction in payload as usual will not work, dispatching required types manually
	loadingAction && context.commit('SET_LOADING_ACTION', { action: actionName, id: loadingAction?.id });
	await context.dispatch(actionName, omit(payload, 'loadingAction'));
	loadingAction && context.commit('RESET_LOADING_ACTION', { action: actionName, id: loadingAction?.id });
};

const detectCommission = async (context, { payments, provider }) => {
	let res = await lookup('/v3/operations/detect-commissions', {
		method: 'POST',
		data: { payments, provider }
	});
	let { detectCommissionLoading } = context.state.props;
	if (
		!handleError({ processing: processingHandler, validation: validationHandler })(
			res,
			context,
			200,
			types.VALIDATION_ERRORS
		)
	) {
		if (res.status === 499) {
			context.commit(types.SET_DETECT_COMMISSION_LOADER, true);
		} else if (res.status !== 499 && detectCommissionLoading) {
			context.commit(types.SET_DETECT_COMMISSION_LOADER, false);
		}
		return;
	}
	if (detectCommissionLoading) {
		context.commit(types.SET_DETECT_COMMISSION_LOADER, false);
	}
	let [{ commission }] = res.data;
	context.commit(types.FETCH_COMMISSION, commission);
};

const checkPayments = async (context, payload) => {
	let { paymentType } = router.currentRoute.params;
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve) => {
		let actionToResume = {
			actionName: 'checkPayments',
			loadingAction: 'checkPayments',
			payload
		};

		let noPatronymicConfirmData = shouldConfirmNoPatronymic(context, payload);
		if (noPatronymicConfirmData) {
			toggles.togglePatronymicConfirm(context, { actionToResume, noPatronymicConfirmData });
			return resolve();
		}

		if (paymentType === 'family' && !validateFamilyPaymentAmount(context, payload)) {
			return resolve();
		}

		let res = await http({
			url: '/v3/operations/check-payments?print_template=true',
			method: 'POST',
			data: payload
		});
		if (
			!handleError({ processing: processingHandler, validation: validationHandler })(
				res,
				context,
				200,
				types.VALIDATION_ERRORS
			)
		) {
			return resolve(payload.cb(false));
		}
		context.commit(types.CHECKED_PAYMENTS, res.data);
		let commission = res.data?.[0]?.payment?.commission;
		if (paymentType === 'western-union-pay') {
			commission = res.data?.[0]?.externalResponse?.commission;
			if (
				!context.state.props.wuShouldReprintTemplate &&
				(commission.rate !== context.state.props.fetchedCommission?.rate ||
					commission.amount !== context.state.props.fetchedCommission?.amount ||
					commission.currency_amount !== context.state.props.fetchedCommission?.currency_amount)
			) {
				context.commit(
					rootTypes.namespaced.ERROR_ALERT,
					{ title: 'Комісія або курс або сума виплати змінені', text: 'Повідомте клієнта' },
					{ root: true }
				);
			}
			context.commit(types.WU_REPRINT_TEMPLATE_ON_COMMISSION_DISCREPANCY, false);
		}
		context.commit(types.FETCH_COMMISSION, commission);
		if (paymentType === 'family' && !context.state.props.familyBankSelectedUtilityServiceData) {
			let { AmtMerchant } = res.data?.[0]?.externalResponse ?? {};
			if (AmtMerchant.PayMode !== '0') {
				context.commit(types.FAMILY_AMOUNT_RESTRICTIONS, AmtMerchant);
			}
		}

		resolve(payload.cb(true));
	});
};

const checkClientInPaymentSystem = async (context, client) => {
	let res = await http({
		url: '/v3/operations/check-client-in-payment-system',
		method: 'POST',
		data: client
	});
	handleError(() => {
		toggles.toggleOperationNotPermitted(context, res.data.params);
		return context.commit(types.CLEAR);
	})(res, context, 200);
};

const validateFamilyPaymentAmount = (context, payload) => {
	let { familyBankSelectedService, familyBankSelectedUtilityServiceData } = context.state.props;
	let [{ amount }] = payload.payments[0].recipients;
	let res = {
		data: {
			errors: []
		}
	};
	if (familyBankSelectedUtilityServiceData) {
		let minAmount = [2, 4].includes(parseInt(familyBankSelectedUtilityServiceData.AmtMerchant?.PayMode))
			? new BigNumber(familyBankSelectedUtilityServiceData.AmtMerchant.Amount).dividedBy(100).toFixed(2, 2)
			: 0;
		if (new BigNumber(amount).lt(minAmount) || new BigNumber(amount).isZero()) {
			context.commit(
				rootTypes.namespaced.ERROR_ALERT,
				{ text: 'Введена сума менше за мінімальну дозволену, перевірте поле "до сплати".' },
				{ root: true }
			);
			return false;
		}
	}
	if (familyBankSelectedService) {
		if (new BigNumber(amount).lt(familyBankSelectedService.min_amount)) {
			res.data.errors = [{ dataPath: 'amount', message: `не менше ніж ${familyBankSelectedService.min_amount} грн.` }];
		} else if (new BigNumber(amount).gt(familyBankSelectedService.max_amount)) {
			res.data.errors = [{ dataPath: 'amount', message: `не більше ніж ${familyBankSelectedService.max_amount} грн.` }];
		}
	}
	if (res.data.errors.length) {
		validationHandler(context, res, types.VALIDATION_ERRORS);
		return false;
	}
	return true;
};

const doInstantPayout = async (context, op) => {
	let { instant_payout_recipient: recipient } = context.state.props;
	context.commit('SET_LOADING_ACTION', { action: 'submitPayment-pos' });
	await Promise.delay(1000);
	await submitPayment(context, {
		endpoint: '/v3/operations/payout',
		ids: [op.id],
		is_recipient_agent: false,
		instrument: instruments.cash,
		recipient,
		ds_scan_ids: op.transaction.ds_scan_ids
	});
	context.commit('RESET_LOADING_ACTION', { action: 'submitPayment-pos' });
};

const createDonation = async (context, payload) => {
	let { amount, callback } = payload;
	let [{ clients }] = context.state.props.operations;
	let payer = clients.find((c) => c.type === 'payer');
	let res = await http({
		url: '/v3/operations/import',
		method: 'post',
		data: {
			provider: 'awis',
			payments: [
				{
					id: uuid(),
					payer: {
						first_name: payer.first_name,
						last_name: payer.last_name,
						patronymic: payer.patronymic,
						phone: payer.phone
					},
					recipients: [
						{
							type: 'legal',
							amount: amount
						}
					],
					metadata: {
						is_donation: true,
						tax_payer: 'payer',
						type: 'PaymentForAfterPayment'
					}
				}
			]
		}
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	let newOperations = [...context.state.props.operations, ...res.data.operations];
	context.commit(types.OPERATIONS_EVENT, {
		operations: newOperations,
		newlyCreatedOperations: true
	});
	toggles.toggleDonationModal(context);
	let totalAmount = newOperations.reduce((acc, op) => new BigNumber(acc).plus(op.amount), 0);
	if (new BigNumber(totalAmount).gte(30000) && !context.state.props.isDocumentScansChecked) {
		setTimeout(() => {
			toggles.toggleVerificationStatusModal(context);
		}, 100);
	} else if (callback) {
		callback();
	}
};

const sendInstructionsViaSms = async (context, payload) => {
	context.commit('SET_LOADING_ACTION', { action: 'sendInstructionsViaSms' });
	const res = await http('/v3/sms', {
		method: 'POST',
		data: payload
	});
	context.commit('RESET_LOADING_ACTION', { action: 'sendInstructionsViaSms' });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	context.commit(types.VALIDATION_ERRORS, []);
	context.commit(
		rootTypes.namespaced.ADD_SNACKBAR,
		{ variant: 'success', title: 'Інструкції успішно відправлені' },
		{ root: true }
	);
	toggles.toggleOperationNotPermitted(context);
};
const eventListener = async (context, e) => {
	let { paymentType } = router.currentRoute.params;
	let { paymentsQueue, actionToResume } = context.state.props;
	switch (e.type) {
		case centrifugoEventTypes.operations: {
			let unknownOps = some(
				e.payload.filter((o) => o.type === operationTypes.body),
				(o) => !context.state.props.operations.map((i) => i.id).includes(o.id)
			);
			if (unknownOps) {
				return;
			}
			context.commit(types.OPERATIONS_EVENT, { operations: e.payload });
			if (every(e.payload, (o) => [statuses.paid, statuses.paidOff, statuses.refunded].includes(o.status))) {
				if (!paymentsQueue || !paymentsQueue.length) {
					// remove completed by rrn operations from regular ops
					// and store them in state to display in the table as successful
					if (paymentType === 'instant-payout' && e.payload[0].status === statuses.paid) {
						await doInstantPayout(context, e.payload[0]);
					}
					if (actionToResume?.payload?.is_rrn_completion) {
						let newlyCreatedOperations = [
							'instant',
							'instant-payout',
							'instant-topup',
							'family',
							'ria',
							'western-union-pay',
							'western-union-payout',
							'moneygram',
							'loan-repayment'
						].includes(paymentType);
						let updatedOperations = !newlyCreatedOperations
							? actionToResume.payload.ids
							: context.state.props.operations.map((i) => i.id);

						// no error, but still clear the rrn completion data and pos selection
						context.commit(types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR);
						context.commit(types.SET_SUCCESSFUL_COMPLETED_PAYMENTS, updatedOperations);

						// if all successful - operations arr is empty
						if (!context.state.props.operations.length) {
							context.commit(types.PAYMENT_SUCCESS);
						}
					} else {
						context.commit(types.PAYMENT_SUCCESS);
						// last payment in queue
						if (paymentsQueue && !paymentsQueue.length) {
							context.commit(types.SET_SUCCESSFUL_QUEUED_PAYMENT, actionToResume);
						}
					}
					context.commit(types.SET_ACTION_TO_RESUME, null);
					context.commit(types.LOADING_INSTRUMENT_SUBMIT, null);
				} else {
					paymentsQueue = [...paymentsQueue];
					let nextPayment = paymentsQueue.shift();
					context.commit(types.SET_SUCCESSFUL_QUEUED_PAYMENT, actionToResume);
					context.commit(types.SET_PAYMENTS_QUEUE, paymentsQueue);
					context.commit(types.SET_ACTION_TO_RESUME, nextPayment);
				}
			}
			break;
		}
		case centrifugoEventTypes.paymentFailed: {
			let ids = e.payload?.ids ?? [];
			if (some(ids, (id) => !context.state.props.operations.map((i) => i.id).includes(id))) {
				return;
			}
			// centrifugoEventTypes.paymentFailed always means status is created
			context.commit(types.OPERATIONS_EVENT, { operations: ids.map((id) => ({ id, status: statuses.created })) });
			context.commit(types.HIDE_MIT_BY_DEFAULT_ON_ERROR);
			context.commit(types.RESET_POS_AND_MERCHANT_SELECTION_ON_ERROR);
			context.commit(types.EXIT_PAYMENTS_QUEUE_ON_ERROR);
			if (e.payload?.provider === 'western_union') {
				context.commit(types.OPEN_WU_POS_ERROR_MODAL, { error: e.payload.reason });
			} else if (e.payload.reason === 'BlacklistedClientError') {
				toggles.toggleOperationNotPermitted(context, { blacklist_id: e.payload.error_params.id });
			} else {
				context.commit(
					rootTypes.namespaced.ERROR_ALERT,
					{ title: e.payload.title, text: e.payload.reason },
					{ root: true }
				);
			}
			context.commit(types.LOADING_INSTRUMENT_SUBMIT, null);
			break;
		}
		case centrifugoEventTypes.digitalDocument: {
			documentActions.receiveDigitalDocumentEvent(context, e);
			break;
		}
		case centrifugoEventTypes.otpInput: {
			posActions.receiveOtpInputEvent(context, e.payload);
			break;
		}
		default:
			return;
	}
};

const selectNovapayAction = async (context, action) => {
	context.commit(types.SELECT_NOVAPAY_CARDS_ACTION, action);
	router.push(`/payment/${action}`);
};

const closeNovapayAction = async (context) => {
	context.commit(types.OPEN_CLIENT_BARCODE, false);
};

export default {
	...commonStoreActions,
	...posActions,
	...documentActions,
	...familyBankActions,
	...productTemplatesActions,
	...mitActions,
	...westernUnionActions,
	...claimsActions,
	...naftogazActions,
	...creditActions,
	...revenueActions,
	...awisActions,
	...toggles,
	idle,
	getOperations,
	submitPayment,
	eventListener,
	resume,
	detectCommission,
	checkPayments,
	checkClientInPaymentSystem,
	createDonation,
	sendInstructionsViaSms,
	selectNovapayAction,
	closeNovapayAction
};
