<template>
	<div class="menu-container">
		<div class="menu" @mouseleave="() => toggleNavbar(false)">
			<div class="main-btn-container" @mouseenter="() => (isMobileDevice ? null : toggleNavbar(true))">
				<RouterLink to="/">
					<NP-Button ref="homeBtn" class="main-btn" type="button" priority="high" variant="square">
						<div slot="icon" class="logo-white" />
					</NP-Button>
				</RouterLink>
				<NavMenu v-if="showNavSidebar" :class="{ 'nav-sidebar': true, showNavbar: $route.path !== '/' }" />
			</div>
			<div v-if="!isMobileDevice" class="search-container" v-click-outside="clearOperationsSearch">
				<NP-Input
					ref="operations-search"
					v-model="search"
					class="search"
					type="text"
					variant="search"
					placeholder="КНП | ЕН | Телефон"
					hotkey="ctrl+f"
					:loading="loadingActions.includes('searchOperations')"
					@keydown.enter="doSearch"
					v-hotkey="clearKeyMap"
					@focus="onSearchFocus"
				/>
				<div v-if="searchResults" class="search-results">
					<template v-if="searchResultsByProvider.length && activeSearchType !== 'ria'">
						<div class="result" v-for="(operations, index) in searchResultsByProvider" :key="index">
							<div class="text">{{ getSearchResultsText(operations) }}</div>
							<div class="btns">
								<NP-Button
									v-if="getAllowOperationsRefund(operations)"
									type="button"
									variant="small"
									priority="low"
									@click="() => gotoPayment(operations, 'refund')"
								>
									Повернути
								</NP-Button>
								<NP-Button
									class="operations-search-payout-button"
									v-if="getAllowOperationsPayout(operations)"
									type="button"
									variant="small"
									priority="low"
									@click="() => gotoPayment(operations, 'payout')"
								>
									Виплатити
								</NP-Button>
							</div>
						</div>
					</template>
					<div v-else-if="!searchResultsByProvider.length" class="result">Операцій не знайдено</div>
				</div>
				<div class="separator">|</div>
				<div class="search-type-toggle" @click="onShowToggle">
					<span class="icon-worldwide" />
				</div>
				<div v-if="showSearchToggleDropdown" class="search-toggle-dropdown">
					<div class="toggle-item postfinance" @click="() => (searchType = 'postfinance')">
						<span>NovaPay</span>
						<NP-Switch :value="searchTypeToggles.postfinance" @input="(v) => changeSearchType(v, 'postfinance')" />
					</div>
					<div class="toggle-item ria" @click="() => (searchType = 'ria')">
						<span>Ria</span>
						<NP-Switch :value="searchTypeToggles.ria" @input="(v) => changeSearchType(v, 'ria')" />
					</div>
					<div class="toggle-item western-union" @click="() => (searchType = 'western_union')">
						<span>Western union</span>
						<NP-Switch :value="searchTypeToggles.western_union" @input="(v) => changeSearchType(v, 'western_union')" />
					</div>
					<div class="toggle-item moneygram" @click="() => (searchType = 'moneygram')">
						<span>Moneygram</span>
						<NP-Switch :value="searchTypeToggles.moneygram" @input="(v) => changeSearchType(v, 'moneygram')" />
					</div>
				</div>
			</div>
			<RouterLink v-if="!isCashbookClosed && isChiefCashier" :to="`/orders/${root.cashbook.id}`">
				<NP-Button class="cashbook-page" type="button" priority="general"> Касова Книга </NP-Button>
			</RouterLink>
			<RouterLink to="/operations">
				<NP-Button class="operations" type="button" priority="general"> Журнал Операцій </NP-Button>
			</RouterLink>
			<AppInviteButton v-if="!isMobileDevice"></AppInviteButton>
			<NP-Button
				v-if="root.salepoint.separate_cash_operator"
				class="barcode"
				type="button"
				priority="general"
				@click.stop="toggleAwisBarcodeModal"
				:hotkey="{ key: 'ctrl+b', action: toggleAwisBarcodeModal }"
			>
				ШК для оплати
			</NP-Button>
			<div class="calculator">
				<NP-Button
					:class="{ 'calculator-btn': true, open: showCalculator }"
					type="button"
					priority="low"
					variant="square"
					@click.stop="toggleCalculator"
				>
					<div slot="icon" class="icon-calculator" />
				</NP-Button>
			</div>
			<Calculator
				v-if="showCalculator"
				v-click-outside="toggleCalculator"
				:root="root"
				:toggle-calculator="toggleCalculator"
			/>
			<div class="notification">
				<NP-Button
					:class="{ 'notification-btn': true, open: showNotificationMenu, 'has-notifications': hasNotifications }"
					type="button"
					priority="low"
					variant="square"
					@click.stop="toggleNotifications"
				>
					<div slot="icon">
						<div class="icon-notification" />
					</div>
				</NP-Button>
			</div>
			<div class="support">
				<NP-Button type="button" priority="low" variant="square" @click.stop="toggleSupportSidebar">
					<div
						slot="icon"
						:class="{ 'icon-support': true, red: edsRequestPrecreated || shouldShowApprovePhoneNumberPopup }"
					/>
				</NP-Button>
				<div v-if="showPrecreatedEDSRequestPopup && edsRequestPrecreated" class="eds-precreated-info">
					<div class="header">
						<h4>Важливо! КЕП!</h4>
						<div class="icon-close" @click.stop="() => (showPrecreatedEDSRequestPopup = false)" />
					</div>
					<div class="description">Відправ у найближчий час заяву на створення КЕП та скан-копії документів</div>
				</div>
				<div v-else-if="showApprovePhoneNumberPopup && shouldShowApprovePhoneNumberPopup" class="eds-precreated-info">
					<div class="header">
						<h4>Увага!</h4>
						<div class="icon-close" @click.stop="() => (showApprovePhoneNumberPopup = false)" />
					</div>
					<div class="description">Необхідно підтвердити номер телефону</div>
				</div>
			</div>
			<div :class="{ 'user-login': true, open: showUserInfo }">
				<div class="user-login__action-container" @click.stop="toggleUserInfo">
					<p>{{ root.user.login }}</p>
					<span>№{{ root.salepoint.code || '' }} {{ currentTime.print }}</span>
				</div>
				<div v-if="showUserInfo" v-click-outside="toggleUserInfo" class="user-info">
					<div class="main-info">
						<div class="avatar">
							{{ root.user.first_name.slice(0, 1) + root.user.last_name.slice(0, 1) }}
						</div>
						<div class="creds">
							<div class="name">
								{{ root.user.last_name }} {{ root.user.first_name }} {{ root.user.patronymic || '' }}
							</div>
							<div class="login">
								{{ root.user.login }}
							</div>
						</div>
					</div>
					<router-link tag="div" class="link-button" to="/profile">Особистий кабінет</router-link>
					<div class="divider" />
					<NP-Button
						type="button"
						class="logout"
						@click="() => logout({ loadingAction: true })"
						:loading="loadingActions.includes('logout')"
					>
						<div class="logout-icon" slot="icon" />
						Вийти
					</NP-Button>
				</div>
			</div>
			<Notifications
				v-if="showNotificationMenu"
				:root="root"
				:toggle="toggleNotifications"
				:loading-actions="loadingActions"
				:print-document="printDocument"
				:sign-document="signDocument"
			/>
			<SupportRequest
				v-if="showSupportRequestModal"
				:root="root"
				:toggle="toggleSupportRequestModal"
				:loading-actions="loadingActions"
				:lookup-users="lookupUsers"
				:create-s-d-report="createSDReport"
				:errors="root.sdReportValidationErrors"
				:submit-eds-request-scans="submitEdsRequestScans"
			/>
			<ServiceDeskSuccess
				v-if="root.sdReportNumber"
				:toggle="toggleServiceDeskSuccessModal"
				:number="root.sdReportNumber"
			/>
		</div>
	</div>
</template>

<script>
import values from 'lodash/values';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';
import { formatters } from 'novapay-ui';
import isMobileDevice from '@services/is-mobile-device';

import { enum as banknoteDenominations } from '@repo/enums/banknote-denominations';
import { enum as coinDenominations } from '@repo/enums/coin-denominations';
import { enum as operationProviders } from '@repo/enums/operation-providers';
import { enum as operationMediums } from '@repo/enums/operation-mediums';
import { enum as operationInstruments } from '@repo/enums/operation-instruments';
import { enum as operationClientTypes } from '@repo/enums/operation-client-types';
import { enum as productParticipantTypes } from '@repo/enums/product-participant-types';
import { enum as cashdeskStatuses } from '@repo/enums/cashdesk-statuses';
import { enum as edsRequestStatuses } from '@repo/enums/electronic-digital-signature-requests-statuses';

import Notifications from './notifications';
import SupportRequest from './support-request';
import ServiceDeskSuccess from './service-desk-success-modal';
import Calculator from './calculator';

function isPhoneNumber(string) {
	return /^((\+38|38|80|0)+([0-9]){9})$/.test(string);
}

export default {
	name: 'Menu',
	components: { ServiceDeskSuccess, Notifications, SupportRequest, Calculator },
	props: {
		root: { type: Object, default: null },
		logout: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] },
		searchOperations: { type: Function, default: null },
		clearSearchResults: { type: Function, default: null },
		searchResults: { type: Array, default: null },
		printDocument: { type: Function, default: null },
		signDocument: { type: Function, default: null },
		getSignatureRequests: { type: Function, default: null },
		lookupUsers: { type: Function, default: null },
		createSDReport: { type: Function, default: null },
		toggleNotificationMenu: { type: Function, default: null },
		toggleSupportRequestModal: { type: Function, default: null },
		toggleServiceDeskSuccessModal: { type: Function, default: null },
		toggleAppInviteModal: { type: Function, default: null },
		toggleAwisBarcodeModal: { type: Function, default: null },
		showNotificationMenu: { type: Boolean, default: false },
		showSupportRequestModal: { type: Boolean, default: false },
		showCloseCashdesksWarning: { type: Function, default: null },
		submitEdsRequestScans: { type: Function, default: null }
	},
	data() {
		return {
			banknoteDenominations,
			coinDenominations,
			currentTime: { print: DateTime.local().toFormat('HH:mm'), timer: null },
			showCalculator: false,
			showUserInfo: false,
			search: null,
			showSearchToggleDropdown: false,
			// not doing this in "radio" style with a single value
			// because multiple switches behave weirdly if you click between them
			searchTypeToggles: {
				postfinance: true,
				ria: false,
				western_union: false,
				moneygram: false
			},
			lastSearchPhone: false,
			showNavSidebar: false,
			navbarContainer: null,
			showPrecreatedEDSRequestPopup: true,
			showApprovePhoneNumberPopup: true,
			appInvitePopupClosedManually: !!sessionStorage.getItem('appInvitePopupClosedManually'),
			cash: null,
			canToggleNavbar: false,
			lastWarnedAt: null
		};
	},
	computed: {
		isMobileDevice() {
			return isMobileDevice;
		},
		...mapGetters({
			isChiefCashier: 'root/isChiefCashier',
			isCashbookClosed: 'root/isCashbookClosed'
		}),
		hasNotifications() {
			let {
				signatureRequests,
				user: { id },
				notifications
			} = this.root;
			let requests = (signatureRequests || []).filter((r) => r.user_id === id || (r.signerUserIds || []).includes(id));
			return (notifications && notifications.length) || (requests && requests.length);
		},
		searchResultsByProvider() {
			if (!this.searchResults) {
				return;
			}

			let grouped = values(
				groupBy(this.searchResults, (op) => {
					return `${op.provider_name}${
						op.provider_name === operationProviders.comfort ? op.payment_system_public_id : 1
					}`;
				})
			);

			let filtered = grouped.map((ops) =>
				ops.filter((o) => this.getAllowOperationsPayout([o]) || this.getAllowOperationsRefund([o]))
			);

			return filtered.filter((ops) => ops.length);
		},
		activeSearchType() {
			let res;
			Object.keys(this.searchTypeToggles).forEach((key) => {
				if (this.searchTypeToggles[key]) {
					res = key;
				}
			});
			return res;
		},
		clearKeyMap() {
			return {
				esc: () => {
					return this.clearOperationsSearch();
				}
			};
		},
		edsRequestPrecreated() {
			return (
				this.root.user.eds_request &&
				[edsRequestStatuses.precreated, edsRequestStatuses.needsCorrection].includes(this.root.user.eds_request.status)
			);
		},
		shouldShowApprovePhoneNumberPopup() {
			if (!this.root.user.phone_approved_at) {
				return true;
			}

			let d1 = DateTime.local().minus({ months: 3 });
			let d2 = DateTime.fromISO(this.root.user.phone_approved_at);

			return d1 >= d2;
		}
	},
	methods: {
		hideAppInvitePopUp() {
			sessionStorage.setItem('appInvitePopupClosedManually', 'hidden');
			this.appInvitePopupClosedManually = true;
		},
		handleTouchEvent(e) {
			e.returnValue = false; // prevents from the surrounding text selection
			if (e.type === 'touchstart') {
				this.canToggleNavbar = false;
				setTimeout(() => (this.canToggleNavbar = true), 400);
			} else if (e.type === 'touchend') {
				this.canToggleNavbar && this.toggleNavbar(!this.showNavSidebar);
			}
		},
		toggleNavbar(shouldOpen) {
			this.showNavSidebar = shouldOpen;
		},
		toggleCalculator() {
			this.showCalculator = !this.showCalculator;
		},
		toggleNotifications() {
			if (!this.showNotificationMenu) {
				this.getSignatureRequests({
					loadingAction: true
				});
			}
			this.toggleNotificationMenu();
		},
		toggleUserInfo() {
			this.showUserInfo = !this.showUserInfo;
		},
		toggleSupportSidebar() {
			this.toggleSupportRequestModal();
			this.showPrecreatedEDSRequestPopup = false;
			this.showApprovePhoneNumberPopup = false;
		},
		gotoPayment(operations, paymentType) {
			let url = null;
			let isSearchTypeWU = this.activeSearchType === 'western_union';
			if (isSearchTypeWU && operations[0].allow_payout && paymentType === 'payout') {
				let query = this.$router.options.stringifyQuery({ wu_operation: operations[0], searchable: this.search });
				url = `/payment/western-union-payout/${query}`;
			} else {
				let query = this.$router.options.stringifyQuery({ ids: operations.map((o) => o.id), searchable: this.search });
				url = `/payment/${paymentType}${query}`;
			}
			window.location.href = window.location.origin + url;
		},
		doSearch() {
			this.showSearchToggleDropdown = false;
			this.searchOperations({
				search: (this.search || '').trim(),
				searchType: this.activeSearchType,
				loadingAction: true
			});
		},
		clearOperationsSearch() {
			if (this.$el.querySelector('.search-results')) {
				this.search = null;
				this.clearSearchResults();
			}
			if (this.showSearchToggleDropdown) {
				this.showSearchToggleDropdown = false;
			}
		},
		onShowToggle() {
			this.clearOperationsSearch();
			this.$nextTick(() => {
				this.showSearchToggleDropdown = true;
			});
		},
		changeSearchType(value, searchType) {
			this.searchTypeToggles[searchType] = value;
			if (value) {
				Object.keys(this.searchTypeToggles).forEach((key) => {
					if (searchType !== key) {
						this.searchTypeToggles[key] = false;
					}
				});
			} else {
				this.$nextTick(() => {
					this.searchTypeToggles['postfinance'] = true;
				});
			}
		},
		getAllowOperationsRefund(operations) {
			return (
				!this.lastSearchPhone &&
				!some(operations || [], (o) => {
					return (
						!o.allow_refund ||
						(o.provider_name === operationProviders.awis && o.medium !== operationMediums.sst) ||
						o.product_id === this.root.products.comfort ||
						o.instrument === operationInstruments.acquiring
					);
				})
			);
		},
		getAllowOperationsPayout(operations) {
			return !some(operations || [], (o) => {
				return (
					!o.allow_payout ||
					o.recipient_type === productParticipantTypes.legal ||
					(o.comfort_payout_salepoint_code && o.comfort_payout_salepoint_code !== this.root.salepoint.code)
				);
			});
		},
		getSearchResultsText(operations) {
			let provider = find(this.root.lists['operation-providers'], { id: operations[0].provider_name });
			let providerText = `${
				operations[0].provider_name === operationProviders.comfort ? operations[0].payment_system_public_id : ''
			} (${provider.text})`;
			let baseText = formatters.numberDeclination(operations.length, [
				`Знайдена % операція ${providerText}`,
				`Знайдено % операції ${providerText}`,
				`Знайдено % операцій ${providerText}`
			]);
			let wuText = ``;
			if (operations[0].provider_name === operationProviders.westernUnion) {
				let [op] = operations;
				let payer = find(op.clients, { type: operationClientTypes.payer });
				wuText = ` - ${payer.first_name} ${payer.patronymic || ''} ${payer.last_name}, ${op.amount}UAH`;
			}
			return `${baseText} ${wuText}`;
		},
		onSearchFocus() {
			this.showSearchToggleDropdown = false;
			this.$refs['operations-search'].$refs.input.select();
		}
	},
	watch: {
		searchResults(val) {
			if (val && val.length) {
				if (this.activeSearchType === 'ria') {
					let query = this.$router.options.stringifyQuery({ ria_operation: val[0], searchable: this.search });
					this.$router.push(`/payment/ria${query}`).catch((e) => {
						if (e.name === 'NavigationDuplicated') {
							return this.$router.go();
						}
					});
					return;
				}

				if (this.activeSearchType === 'moneygram') {
					let query = this.$router.options.stringifyQuery({ moneygram_operation: val[0], searchable: this.search });
					this.$router.push(`/payment/moneygram${query}`).catch((e) => {
						if (e.name === 'NavigationDuplicated') {
							return this.$router.go();
						}
					});
					return;
				}

				if (this.activeSearchType === 'western_union') {
					return;
				}
				this.lastSearchPhone = isPhoneNumber(this.search);
				this.$nextTick(() => {
					if (some(this.searchResultsByProvider, (ops) => this.getAllowOperationsPayout(ops))) {
						document.querySelector('.operations-search-payout-button').focus();
					}
				});
			}
		}
	},
	beforeMount() {
		let { searchable } = this.$route.query;
		if (searchable) {
			this.search = searchable;
		}
	},
	mounted() {
		this.currentTime.timer = setInterval(() => {
			let toPrint = DateTime.local().toFormat('HH:mm');
			let timeToWarn = ['23:45', '23:50', '23:55'].includes(toPrint);
			let isCashdesksOpened = (this.root.opened_cashdesks || []).some((c) => {
				let usesEcmsAndOpened = c.electronic_cash_machine_id && c.status === cashdeskStatuses.opened;
				return this.isChiefCashier ? usesEcmsAndOpened : c.user_id === this.root.user.id && usesEcmsAndOpened;
			});
			if (timeToWarn && isCashdesksOpened && this.lastWarnedAt !== toPrint) {
				this.lastWarnedAt = toPrint;
				let text = 'Необхідно закрити зміну до 23:59.';
				if (this.isChiefCashier) {
					text = 'На відділенні не закріті зміни ВК: ';
					this.root.opened_cashdesks.forEach((s, i, arr) => {
						let symbol = i === arr.length - 1 ? '. ' : ', ';
						let name = `${s.user_last_name} ${s.user_first_name}${s.user_patronymic ? ` ${s.user_patronymic}` : ''}`;
						text += `${name}${symbol}`;
					});
					text += 'Необхідно закрити зміни до 23:59.';
				}
				this.showCloseCashdesksWarning(text);
			}
			this.currentTime.print = toPrint;
		}, 1000 * 30);
		if (this.isMobileDevice) {
			let btn = this.$refs.homeBtn.$el;
			btn.addEventListener('touchstart', this.handleTouchEvent);
			btn.addEventListener('touchend', this.handleTouchEvent);
		}
	},
	beforeDestroy() {
		clearInterval(this.currentTime.timer);
		if (this.isMobileDevice) {
			let btn = this.$refs.homeBtn.$el;
			btn.removeEventListener('touchstart', this.handleTouchEvent);
			btn.removeEventListener('touchend', this.handleTouchEvent);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-logo-white: '~@assets/images/icon-logo-white.svg';
$icon-calculator-bold: '~@assets/images/icon-calculator-bold.svg';
$icon-notification: '~@assets/images/icon-notification.svg';
$icon-support: '~@assets/images/icon-support.svg';
$icon-logout: '~@assets/images/icon-logout.svg';
$icon-close: '~@assets/images/icon-close.svg';
$icon-worldwide: '~@assets/images/icon-worldwide.svg';
$icon-loudspeaker: '~@assets/images/icon-loudspeaker.svg';

.icon-close {
	@include make-icon($icon-close, $gray-50, 20px, 20px);
}
.menu-container {
	background: $white;
	box-shadow: 0 1px 1px rgba(226, 231, 240, 0.6);
	.menu {
		max-width: $max-content-width;
		margin: 0 auto;
		padding: 8px 20px;
		display: flex;
		align-items: center;
		position: relative;
		.main-btn-container {
			user-select: none;
			position: relative;
			.main-btn {
				user-select: none;
				min-width: 40px;
				margin-right: 8px;
				.logo-white {
					user-select: none;
					@include make-icon($icon-logo-white, $white, 18px, 22px);
				}
			}
		}
		.nav-sidebar {
			@media (max-width: $max-navbar-visibility-width) {
				display: block;
			}
			&.showNavbar {
				display: block;
			}
			display: none;
			position: absolute;
			left: 0;
			top: 100%;
			z-index: $modal-z-index;
			width: 300px;
			max-height: calc(100vh - 60px);
			background: $white;
			border: 1px solid rgba(205, 211, 221, 0.5);
			border-radius: 8px;
			box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);
			margin-top: 5px;
			overflow-y: auto;
			@include pretty-scrollbar();
		}
		.search-container {
			width: 100%;
			min-width: 240px;
			margin-right: 16px;
			position: relative;
			.search {
				width: 100%;
				::v-deep .hotkeys {
					right: 60px;
				}
				::v-deep .loading-icon {
					right: 60px;
				}
			}
			.search-results {
				position: absolute;
				top: 40px;
				left: 0;
				width: 100%;
				border: 1px solid $gray-20;
				border-radius: $border-radius;
				box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);
				z-index: $menu-tab-z-index;
				.result {
					color: $gray-50;
					font-size: 14px;
					padding: 8px 10px;
					background: $white;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					min-height: 50px;
					.btns {
						margin-left: 16px;
						display: flex;
						.button:last-child {
							margin-left: 8px;
						}
					}
				}
			}
			.separator {
				position: absolute;
				right: 48px;
				top: 13px;
				font-size: 16px;
				line-height: 16px;
				color: $gray-30;
			}
			.search-type-toggle {
				position: absolute;
				right: 4px;
				top: 4px;
				background: $light-red;
				border-radius: $border-radius;
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.icon-worldwide {
					@include make-icon($icon-worldwide, $red, 24px, 24px);
				}
			}
			.search-toggle-dropdown {
				position: absolute;
				width: 200px;
				bottom: -130px;
				right: 0;
				background: $white;
				box-shadow: 0 3px 20px rgba(47, 52, 61, 0.1);
				border: 1px solid $gray-30;
				padding: 8px 0;
				border-radius: $border-radius;
				z-index: $modal-z-index;
				.toggle-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 8px 16px;
					cursor: pointer;
					font-size: 14px;
					line-height: 17px;
					&:hover {
						background: $gray-5;
						span {
							font-family: Proxima Nova Semibold;
						}
					}
				}
			}
		}
		.button.operations {
			min-width: 148px;
		}
		.button.barcode,
		.button.operations,
		.app-invite-button {
			margin-right: 16px;
		}
		.button.cashbook-page {
			min-width: 146px;
			margin-right: 24px;
			border: 1px solid transparent;
			box-shadow: none;
		}
		.calculator {
			width: 32px;
			height: 32px;
			margin-right: 5px;
			.button.calculator-btn {
				border: none;
				width: 32px;
				height: 32px;
				::v-deep .icon {
					width: 32px;
					height: 32px;
					.icon-calculator {
						margin: 3px 6px 0 0;
						@include make-icon($icon-calculator-bold, $gray-30, 24px, 24px);
					}
				}
				&:hover:not([disabled]),
				&.open {
					border: none;
					background: $gray-10;
					border-radius: 50%;

					.icon-calculator {
						@include make-icon($icon-calculator-bold, $gray-50, 24px, 24px);
					}
				}
			}
		}
		.notification {
			width: 32px;
			height: 32px;
			margin-right: 5px;
			::v-deep .button.square {
				background: transparent;
				width: 32px;
				height: 32px;
			}
			.button.notification-btn {
				border: none;
				width: 32px;
				height: 32px;
				::v-deep .icon {
					width: 32px;
					height: 32px;
					.icon-notification {
						margin: 6px 4px 0 0;
						@include make-icon($icon-notification, $gray-30, 16px, 18px);
					}
				}
				&:hover:not([disabled]),
				&.open {
					border: none;
					background: $gray-10;
					border-radius: 50%;
					.icon-notification {
						@include make-icon($icon-notification, $gray-50, 16px, 18px);
					}
					&.has-notifications {
						background: $light-red;
						border-radius: 50%;
						.icon-notification {
							@include make-icon($icon-notification, $red, 16px, 18px);
						}
					}
				}
				&.has-notifications {
					.icon-notification {
						@include make-icon($icon-notification, $red, 16px, 18px);
					}
				}
			}
		}
		.support {
			position: relative;
			.button {
				border: none;
				width: 32px;
				height: 32px;
				margin-right: 18px;
				::v-deep .icon {
					width: 32px;
					height: 32px;
					.icon-support {
						margin: 7px 5px 0 0;
						@include make-icon($icon-support, $gray-30, 18px, 18px);
						&.red {
							@include make-icon($icon-support, $red, 18px, 18px);
						}
					}
				}
				&:hover:not([disabled]) {
					border: none;
					background: $gray-10;
					border-radius: 50%;
					.icon-support {
						@include make-icon($icon-support, $gray-50, 18px, 18px);
						&.red {
							@include make-icon($icon-support, $red, 18px, 18px);
						}
					}
				}
			}
			.eds-precreated-info {
				position: absolute;
				z-index: $modal-z-index - 2;
				top: 50px;
				left: -72px;
				background: $red;
				color: $white;
				padding: 16px;
				border-radius: 5px;
				width: 182px;
				text-align: center;
				box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-width: 10px;
					border-style: solid;
					border-color: transparent transparent $red transparent;
					top: -18px;
					left: 78px;
				}
				.header {
					position: relative;
					font-size: 14px;
					line-height: 14px;
					margin-bottom: 8px;
					h4 {
						font-family: Proxima Nova Semibold;
					}
					.icon-close {
						position: absolute;
						right: -4px;
						top: -4px;
						cursor: pointer;
						@include make-icon($icon-close, $white, 20px, 20px);
					}
				}
				.description {
					font-size: 12px;
					line-height: 15px;
				}
			}
		}
		.user-login {
			font-size: 14px;
			line-height: 17px;
			color: $black;
			position: relative;
			cursor: pointer;
			&.open .user-login__action-container * {
				color: $red;
			}
			.user-login__action-container {
				&:hover * {
					color: $red;
				}
				text-align: right;
				white-space: nowrap;
				p {
					margin: 0;
				}
				span {
					color: $gray-40;
					font-size: 12px;
				}
			}
			.user-info {
				position: absolute;
				top: 40px;
				right: 0;
				width: 320px;
				box-sizing: border-box;
				padding: 16px;
				background: $white;
				border: $tab-border;
				box-shadow: $tab-box-shadow;
				border-radius: $border-radius;
				cursor: default;
				z-index: $modal-z-index - 1;
				.main-info {
					display: flex;
					.avatar {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						width: 32px;
						height: 32px;
						font-family: Proxima Nova Semibold;
						font-size: 14px;
						line-height: 18px;
						background: #d4d9dd;
						border-radius: 50%;
						margin-right: 16px;
					}
					.creds {
						.name {
							font-family: Proxima Nova Semibold;
							font-size: 18px;
							line-height: 20px;
							color: $gray-50;
						}
						.login {
							color: $black;
						}
					}
				}
				.divider {
					height: 1px;
					width: 100%;
					background: rgba(205, 211, 221, 0.5);
					margin-bottom: 16px;
					margin-top: 16px;
				}
				.logout {
					float: right;
					.logout-icon {
						@include make-icon($icon-logout, $gray-50, 20px, 20px);
					}
				}
				.link-button {
					width: 100%;
					padding: 12px 10px;
					margin-top: 10px;
					margin-bottom: 10px;
					vertical-align: middle;
					&:hover {
						cursor: pointer;
						background: #eeeff2;
					}
				}
			}
		}
	}
}
.app-invite {
	margin-right: 24px;
}
</style>
