const types = {
	limListStopped: 'lim_list_stopped',
	limListPhone: 'lim_list_phone',
	mbki: 'mbki',
	ubkiScore: 'ubki_score',
	ubkiDebts: 'ubki_debts',
	ubkiCurrentDebts: 'ubki_current_debts',
	ubkiAge: 'ubki_age',
	ubkiLimit: 'ubki_limit',
	ubkiPhone: 'ubki_phone',
	ubkiMfo: 'ubki_mfo',
	ubkiError: 'ubki_error',
	ubkiLudomans: 'ubki_ludomans',
	mobOperator: 'mob_operator',
	socialStatus: 'social_status',
	age: 'age',
	amount: 'amount',
	phoneUniqueness: 'phone_uniqueness',
	notResident: 'not_resident',
	financialMonitoring: 'financial_monitoring',
	financialMonitoringPEP: 'financial_monitoring_pep',
	minimumLimitNotReached: 'minimum_limit_not_reached',
	taxpayerIdPhoneMissmatch: 'taxpayer_id_phone_missmatch',
	blackList: 'black_list',
	regionDecline: 'region_decline'
};

const locale = {
	[types.limListStopped]: 'відмова за лім. відомістю',
	[types.limListPhone]: 'відмова за лім. відомістю',
	[types.mbki]: 'відмова за даними МБКІ',
	[types.ubkiScore]: 'відмова за скорингом УБКІ',
	[types.ubkiDebts]: 'відмова за заборгованістю УБКІ',
	[types.ubkiCurrentDebts]: 'відмова за поточною заборгованістю УБКІ',
	[types.ubkiAge]: 'відмова за віком УБКІ',
	[types.ubkiLimit]: 'відмова за лімітом УБКІ',
	[types.ubkiPhone]: 'відмова за телефоном УБКІ',
	[types.ubkiMfo]: 'відмова за донором УБКІ',
	[types.ubkiError]: 'помилка УБКІ',
	[types.ubkiLudomans]: 'відмова за наявністю в реєстрі лудоманів УБКІ',
	[types.mobOperator]: 'відмова за даними моб. оператора',
	[types.socialStatus]: 'відмова за соц. статусом',
	[types.age]: 'відмова за віком',
	[types.amount]: 'відмова за сумою кредиту',
	[types.phoneUniqueness]: 'відмова через неунікальний номер телефону',
	[types.notResident]: 'клієнт не резидент',
	[types.financialMonitoring]: 'клієнт в чорному списку',
	[types.financialMonitoringPEP]: 'ліміт по ПЕП',
	[types.minimumLimitNotReached]: 'мінімальний ліміт не досягнуто',
	[types.blackList]: 'чорний список',
	[types.regionDecline]: 'область прописки',
};

module.exports = {
	enum: types,
	locale: locale
};
