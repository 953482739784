import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('acts');

export default core.createStore(actions, {}, mutations, {
	key: 'acts',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			entry: 'getPageData',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.PAGE_DATA_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act_modal',
						[types.OPEN_ACT_SCAN_MODAL]: 'act_scan_modal'
					}
				},
				inventory_act_modal: {
					on: {
						[types.CLOSE_INVENTORY_ACT_MODAL]: 'main'
					}
				},
				act_scan_modal: {
					on: {
						[types.CLOSE_ACT_SCAN_MODAL]: 'main'
					}
				}
			}
		}
	}
});
