import { core, http } from 'novapay-ui';
import { DateTime } from 'luxon';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import router from '@/router';

const { actions: commonStoreActions } = commonStore('cash-management');
let types = core.getTypes('cash_management');
let rootTypes = core.getTypes('root');

const resetState = (context) => context.commit(types.RESET_STATE);

const getData = async (context) => {
	let { cashbook } = context.state.props;

	let id = router.currentRoute.params?.cashbook_id;

	if (!id) {
		return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
	}
	if (!cashbook) {
		let cashbookRes = await http(`/v3/cashbooks`, { query: { filters: { id: [id] } } });
		if (!handleError()(cashbookRes, context)) {
			return;
		}
		if (!cashbookRes.data?.length) {
			return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
		}
		[cashbook] = cashbookRes.data;
	}

	let date = DateTime.fromISO(cashbook.created_at).toISODate();
	let { salepoint_id } = cashbook;
	let [totalsRes, notReceivedOrders, issueAmount, schedules, messages] = await Promise.all([
		http(`/v3/salepoint/totals/${date}`),
		http(`/v3/salepoint/not-receiver-orders/${salepoint_id}`),
		http(`/v3/salepoint/issue-amount/${salepoint_id}/${date}`),
		http(`/v3/salepoint/encashment-schedules/${salepoint_id}/${date}`),
		http(`/v3/salepoint/cash-management-messages/${salepoint_id}`)
	]);

	if (
		!handleError()(totalsRes, context) ||
		!handleError()(schedules, context) ||
		!handleError()(notReceivedOrders, context) ||
		!handleError()(issueAmount, context) ||
		!handleError()(messages, context)
	) {
		return;
	}

	context.commit(types.DATA_SUCCESS, {
		cashbook,
		notReceivedOrders: notReceivedOrders.data,
		schedules: schedules.data,
		issueAmount: issueAmount.data,
		messages: messages.data,
		totals: totalsRes.data
	});

	return commonStoreActions.hideRootSplashScreen(context);
};

export default {
	getData,
	resetState
};
