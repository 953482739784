<template>
	<NP-Modal :class="{ 'operation-not-permitted': true, mobile: isMobileDevice }" priority="error" :toggle="toggle">
		<img :src="$options.modalError" />
		<h4 class="header">
			Увага<br v-if="title" />
			{{ title }}
		</h4>
		<div class="description">
			<ol>
				<li v-if="steps.has(1)">Проінформуйте Клієнта про технічну помилку.</li>
				<li v-if="steps.has(2)">
					Створіть запит на <a href="https://sd.npu.np.work/" target="_blank"> https://sd.npu.np.work/ </a>
					<br />Сервіси компанії НоваПей → Фінансовий моніторинг → тип: Розблокування операцій Nova.Pay. <br />
					<span v-if="lastDocumentId || errorParams.clientForSdReport || errorParams.blacklist_id">
						Вкажіть у заявці
						<span v-if="incident">
							номер інциденту: <b>{{ incident.id }}</b>
						</span>
						<span v-if="clientName">
							{{ isClientToReportContragent ? 'Контрагент' : 'ПІБ' }}: <b>{{ clientName }}</b>
						</span>
						<span v-if="lastDocumentId">
							<span v-if="clientName"> та/або </span>ID скану документу: <b>{{ lastDocumentId }}</b>
						</span>
						<span v-if="errorParams.blacklist_id">
							ID запису для перевірки: <b>{{ errorParams.blacklist_id }}</b>
						</span>
					</span>
				</li>
				<li v-if="steps.has(3)">
					Очікуйте виконання запиту
					<span v-if="incident">протягом 15 хвилин та дотримуйтесь отриманих вказівок</span>
					<span v-else>у строк до 1 робочого дня</span>.
				</li>
				<li v-if="steps.has(4)">Запропонуй відправити повідомлення з посиланням на інструкцію по розблокуванню.</li>
				<li v-if="steps.has(5)">Уточни номер телефону клієнта.</li>
				<li v-if="steps.has(6)">
					Проінформуй клієнта звернутись до гарячої лінії підтримки для отримання додаткової інформації. Телефон гарячої
					лінії: 0 800 30 79 79
				</li>
				<li v-if="steps.has(7)">
					Роздрукуй <a href="https://wiki.forpost.space/uk/ВНД_з_питань_ФМ/finmon" target="_blank">опитувальник</a>
				</li>
				<li v-if="steps.has(8)">Надай для заповнення клієнту</li>
				<li v-if="steps.has(9)">
					Відскануй опитувальник та ІПН клієнта (або сторінку паспорту про відмову від коду), створи запит на
					<a href="https://sd.npu.np.work/" target="_blank"> https://sd.npu.np.work/</a>
					Сервіси компанії НоваПей → Фінансовий моніторинг → тип: Розблокування операцій Nova.Pay.
				</li>
				<li v-if="steps.has(10)">Вкажи в заявці ПІБ клієнта: {{ clientName }}</li>
				<li v-if="steps.has(11)">
					Додайте до запиту, за наявності, документи/інформацію: копію паспорта (якщо це фізична особа) або код ЄДРПОУ
					(8 цифр) (якщо це юридична особа) по наступним даним, зазначеним в призначені платежу:
					<b>{{ incidentRecipient }}</b>
				</li>
			</ol>
		</div>
		<form @submit.prevent="send" class="form" v-if="isFormShown">
			<NP-Input
				ref="phone"
				class="phone"
				label="Телефон"
				type="phone"
				v-model="formValues.phone"
				:error="formErrors.phone"
			/>
			<NP-Button type="submit" priority="high" :loading="loadingActions.includes('sendInstructionsViaSms')">
				Відправити інструкцію
			</NP-Button>
		</form>
		<div class="btns" v-else>
			<NP-Button type="submit" priority="high" @click.stop="toggle">Зрозумiло</NP-Button>
		</div>
	</NP-Modal>
</template>

<script>
import { validation } from 'novapay-ui';
import isMobileDevice from '@services/is-mobile-device';
import modalError from '@assets/images/modal-error.svg';

const PHONE_FORMAT_REGEXP = /\D/g;
const REASONS = new Set(['00006', '00007', '00004', '00005', '00010', '00009', '00008', '00012', '00013']);
const LISTS = new Set(['whitelist_application']);

export default {
	name: 'OperationNotPermitted',
	props: {
		toggle: { type: Function, default: null },
		sendInstructionsViaSms: { type: Function, default: null },
		lastDocument: { type: Object, default: null },
		errors: { type: Array, default: () => [] },
		loadingActions: { type: Array, default: () => [] },
		errorParams: { type: Object, default: () => ({}) },
		paymentType: { type: String, default: null },
		payer: { type: Object, default: null },
		recipient: { type: Object, default: null },
		savedRecipientAgent: { type: Object, default: null }
	},
	modalError,
	data() {
		return {
			formValues: {
				phone: ''
			}
		};
	},
	computed: {
		recipientAgent() {
			if (!this.savedRecipientAgent) {
				return null;
			}
			return {
				...this.savedRecipientAgent.name,
				phone: (this.savedRecipientAgent.phones || []).find((p) => p?.is_active)?.phone
			};
		},
		clients() {
			if (!this.paymentType) {
				return [];
			}
			switch (this.paymentType) {
				case 'payout':
					return [this.recipient, this.recipientAgent].filter(Boolean);
				default:
					return [this.payer].filter(Boolean);
			}
		},
		reason() {
			const { clientForSdReport: client, blacklistPhones, blacklistCategory, listType } = this.errorParams ?? {};
			if (listType && LISTS.has(listType)) {
				return listType;
			}

			if (
				!this.clients.length ||
				!client ||
				this.isClientToReportContragent ||
				!blacklistPhones?.length ||
				!blacklistCategory ||
				!REASONS.has(blacklistCategory)
			) {
				return null;
			}

			const clients = [...this.clients];
			const phones = new Set(blacklistPhones.filter(Boolean).map((p) => p.replace(PHONE_FORMAT_REGEXP, '')));
			const fullname = `${client.first_name}${client.last_name}${client.patronymic || ''}`.toLowerCase();

			const hasMatch = clients.some(
				({ first_name, last_name, patronymic, phone }) =>
					`${first_name}${last_name}${patronymic || ''}`.toLowerCase() === fullname &&
					phones.has(phone.replace(PHONE_FORMAT_REGEXP, ''))
			);

			return hasMatch ? blacklistCategory : null;
		},

		title() {
			return {
				'00006': 'Перевищено ліміт за післяплатою',
				'00007': 'Перевищено ліміт за післяплатою',
				'00004': 'Перевищено ліміт за грошовими переказами',
				'00005': 'Перевищено ліміт за грошовими переказами',
				'00010': 'Перевищено ліміт ФОП',
				'00009': 'Необхідна декларація ФОП',
				'00008': 'Необхідна декларація ФОП',
				'00012': 'Відмова в обслуговуванні',
				'00013': 'Перевищено ліміт на проведення фінансових операцій',
				whitelist_application:
					'Необхідно додаткова верифікація клієнта через досягнення максимальної суми проведених операцій'
			}[this.reason];
		},
		steps() {
			const defaultSteps = [1, 2, 3];
			if (this.incident) {
				defaultSteps.push(11);
			}
			const values =
				{
					'00006': [4, 5],
					'00007': [4, 5],
					'00004': [4, 5],
					'00005': [4, 5],
					'00010': [4, 5],
					'00009': [4, 5],
					'00008': [4, 5],
					'00012': [1, 2, 4],
					'00013': [6],
					whitelist_application: [7, 8, 9, 10]
				}[this.reason] ?? defaultSteps;
			return new Set(values);
		},
		instructions() {
			return (
				{
					'00006': 'Для розблокування операції надайте документи. Повний список на https://novapay.ua/b5',
					'00007': 'Для розблокування операції надайте документи. Повний список на https://novapay.ua/b5',
					'00004': 'Для розблокування операції надайте документи. Повний список на https://novapay.ua/b2',
					'00005': 'Для розблокування операції надайте документи. Повний список на https://novapay.ua/b2',
					'00010':
						'Для розблокування операцій підтвердіть ваші реєстраційні дані ФОП. Детальніше на https://novapay.ua/b3',
					'00009': 'Для розблокування операцій надайте декларацію ФОП. Детальніше на https://novapay.ua/b3',
					'00008': 'Для розблокування операцій надайте декларацію ФОП. Детальніше на https://novapay.ua/b3',
					'00012': 'Інформація про причини блокування на https://novapay.ua/vidmova-v-obslugovuvanni',
					'00013': 'Інформація про причини блокування на https://novapay.ua/vidmova-v-obslugovuvanni'
				}[this.reason] ?? null
			);
		},
		isFormShown() {
			return this.reason && !['00013', 'whitelist_application'].includes(this.reason);
		},
		formErrors() {
			let errs = (this.errors || []).filter(Boolean);
			return validation.computeValidationMessages(errs, ['phone']);
		},
		isClientToReportContragent() {
			return (
				this.errorParams &&
				this.errorParams.clientForSdReport &&
				this.errorParams.clientForSdReport.identifier &&
				this.errorParams.clientForSdReport.name
			);
		},
		clientName() {
			if (!this.errorParams || !this.errorParams.clientForSdReport || this.incident) {
				return;
			}
			let { identifier, first_name, last_name, patronymic, name } = this.errorParams.clientForSdReport;
			return this.isClientToReportContragent
				? `${identifier} (${name})`
				: `${last_name} ${first_name} ${patronymic || ''}`.trimEnd();
		},
		incident() {
			return this.errorParams.incident || null;
		},
		incidentRecipient() {
			if (!this.incident) {
				return;
			}
			const { last_name, first_name, patronymic, legal_name } = this.incident?.recipient || {};
			const fullName = [last_name, first_name, patronymic].filter(Boolean).join(' ');
			return legal_name || fullName;
		},
		lastDocumentId() {
			return this.lastDocument ? this.lastDocument.id : null;
		},
		isMobileDevice() {
			return isMobileDevice;
		}
	},
	methods: {
		send() {
			const { phone } = this.formValues;
			const phoneValue = phone && `+${phone.replace(/\D/g, '')}`;
			this.sendInstructionsViaSms({
				phone: phoneValue,
				message: this.instructions
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';
.operation-not-permitted {
	::v-deep .modal-container {
		width: 460px;
		text-align: center;
		img {
			display: block;
			margin: -40px auto 16px auto;
		}
		.header {
			font-size: 18px;
			font-family: Proxima Nova Semibold;
			margin-bottom: 8px;
		}
		.description {
			font-size: 14px;
			line-height: 18px;
			color: $gray-50;
			text-align: left;
			margin: 0 0 16px 18px;
			li {
				margin-bottom: 4px;
			}
			b {
				font-weight: normal;
				font-family: Proxima Nova Semibold;
			}
			a {
				color: $gray-40;
			}
		}
	}
	&.mobile {
		::v-deep .modal-container {
			width: 100vw !important;
		}
	}
}
.form {
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
}
</style>
