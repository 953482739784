import { core, http } from 'novapay-ui';
import handleError from '@services/handle-api-error';
import commonStore from '@services/common-store';
import print from '@services/print';

const rootTypes = core.getTypes('root');
const types = core.getTypes('workplaces');
const { actions: commonActions } = commonStore('workplaces');

const getWorkplaces = async (context) => {
	let res = await http('/v3/workplaces', { query: context.state.props.query });
	if (!handleError()(res, context)) {
		return;
	}
	let total = res.data?.[0]?.total_count;
	commonActions.hideRootSplashScreen(context);
	context.commit(types.GET_WORKPLACES_SUCCESS, { workplaces: res.data, total });
};

const toggleWorkplaceModal = (context, edit_entity) => {
	let type = context.state.state_key === 'workplace_modal' ? types.CLOSE_WORKPLACE_MODAL : types.OPEN_WORKPLACE_MODAL;
	context.commit(type, edit_entity);
};

const saveWorkplace = async (context, data) => {
	let res = await http(`/v3/workplaces${data.id ? `/${data.id}` : ''}`, {
		method: data.id ? 'PUT' : 'POST',
		data: data
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	context.commit(types.APPLY_QUERY, context.state.props.query);
	context.commit(rootTypes.namespaced.ADD_SNACKBAR, { title: 'Успішно', variant: 'success' }, { root: true });
};

const removeWorkplace = async (context, { id }) => {
	if (!confirm('Ви впевнені, що хочете видалити робоче місце?')) {
		return;
	}
	let res = await http(`/v3/workplaces/${id}`, {
		method: 'DELETE'
	});
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.APPLY_QUERY, context.state.props.query);
};

const printWorkplace = async (context, { id }) => {
	let res = await http(`/v3/workplaces/${id}/print`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

export default {
	...commonActions,
	getWorkplaces,
	toggleWorkplaceModal,
	saveWorkplace,
	removeWorkplace,
	printWorkplace
};
