const types = {
	confirmed: 'confirmed',
	unconfirmed: 'unconfirmed',
	noDocument: 'no_document',
	hasBeenChanged: 'has_been_changed',
	electronicDigitalSignature: 'electronic_digital_signature'
};

const locale = {
	[types.confirmed]: 'ОК',
	[types.unconfirmed]: 'не підтверждено',
	[types.noDocument]: 'документ відсутній',
	[types.hasBeenChanged]: 'документ було змінено, передрукуйте',
	[types.electronicDigitalSignature]: 'КЕП'
};

module.exports = {
	enum: types,
	locale: locale
};
