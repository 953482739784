import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('profile');

export default core.createStore(actions, {}, mutations, {
	key: 'profile',
	strict: true,
	initial: 'render',
	states: {
		idle: {
			entry: 'initModule',
			on: {
				[types.GET_PROFILE_SUCCESS]: 'render'
			}
		},
		render: {}
	}
});
