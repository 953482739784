const types = {
	MoneyTransfer: 'MoneyTransfer',
	PaymentForGood: 'PaymentForGood',
	PaymentForServices: 'PaymentForServices',
	PaymentForAfterPayment: 'PaymentForAfterPayment',
	PaymentForRedirecting: 'PaymentForRedirecting',
	PaymentForReturn: 'PaymentForReturn',
	PaymentForStorage: 'PaymentForStorage',
	PaymentForInternationalDelivery: 'PaymentForInternationalDelivery',
	PaymentForPackingAgent: 'PaymentForPackingAgent',
	PaymentForPacking: 'PaymentForPacking',
	PaymentForCustomsPayments: 'PaymentForCustomsPayments',
	PaymentForCommissionBroker: 'PaymentForCommissionBroker',
	PaymentForInternationalDeliveryNPU: 'PaymentForInternationalDeliveryNPU',
	PaymentForLinehaul: 'PaymentForLinehaul',
	MoneyTransferCommission: 'MoneyTransferCommission',
	AfterPaymentCommission: 'AfterPaymentCommission',
	PaymentForParcel: 'PaymentForParcel',
	PaymentForAdditionalServices: 'PaymentForAdditionalServices',
};

const locale = {
	[types.MoneyTransfer]: 'грошовий переказ',
	[types.PaymentForGood]: 'експрес',
	[types.PaymentForServices]: 'за послуги НП',
	[types.PaymentForAfterPayment]: 'за послуги "Контроль оплати"',
	[types.PaymentForRedirecting]: 'за переадресування',
	[types.PaymentForReturn]: 'за повернення',
	[types.PaymentForStorage]: 'за зберігання',
	[types.PaymentForInternationalDelivery]: 'за послуги міжнародної доставки',
	[types.PaymentForPackingAgent]: 'за пакування',
	[types.PaymentForPacking]: 'за пакування',
	[types.PaymentForCustomsPayments]: 'мито',
	[types.PaymentForCommissionBroker]: 'митне оформлення',
	[types.PaymentForInternationalDeliveryNPU]: 'міжнародна доставка',
	[types.PaymentForLinehaul]: 'міжнародне перевезення',
	[types.MoneyTransferCommission]: 'комісія за переказ',
	[types.AfterPaymentCommission]: 'комісія за оплату',
	[types.PaymentForParcel]: 'за інші поштові та кур’єрські послуги',
	[types.PaymentForAdditionalServices]: 'за додаткові послуги Нової Пошти',
};

module.exports = {
	enum: types,
	locale: locale
};
