import { core, http } from 'novapay-ui';
import Promise from 'bluebird';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import print from '@services/print';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';

const { actions: commonStoreActions } = commonStore('archive-documents');
let types = core.getTypes('archive_documents');

const getDocumentsAndScheduleData = async (context) => {
	let { query } = cloneDeep(context.state.props);
	let [month, year] = DateTime.local().toFormat('M-yyyy').split('-');
	if (!query.filters?.month?.length || !query.filters?.year?.length) {
		Object.assign(query.filters, {
			month: query.filters?.month || [month],
			year: query.filters?.year || [year]
		});
	}
	let [documentsRes, scheduleRes] = await Promise.all([
		http('/v3/archive/documents', { query }),
		http('/v3/archive/schedule')
	]);
	if (!handleError()(documentsRes, context, 200) || !handleError()(scheduleRes, context, 200)) {
		return;
	}
	context.commit(types.DOCUMENTS_SUCCESS, {
		documents: documentsRes.data,
		schedule: scheduleRes.data,
		total: (documentsRes.data || [])[0]?.total_count || 0
	});
	return commonStoreActions.hideRootSplashScreen(context);
};

const toggleCreateDocumentModal = (context) => {
	if (context.state.state_key?.render === 'create_document_modal') {
		return context.commit(types.CLOSE_CREATE_DOCUMENT_MODAL);
	}
	return context.commit(types.OPEN_CREATE_DOCUMENT_MODAL);
};

const createDocument = async (context, data) => {
	let res = await http('/v3/archive/document', { method: 'PUT', data });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data?.id) {
		printDocument(context, { id: res.data?.id });
	}
	return context.commit(types.CREATE_DOCUMENT_SUCCESS);
};

const removeDocument = async (context, { id }) => {
	let res = await http(`/v3/archive/document/${id}`, { method: 'DELETE' });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.REMOVE_DOCUMENT_SUCCESS);
};

const printDocument = async (context, { id }) => {
	let res = await http(`/v3/archive/document/${id}/print`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

export default {
	...commonStoreActions,
	getDocumentsAndScheduleData,
	toggleCreateDocumentModal,
	createDocument,
	removeDocument,
	printDocument
};
