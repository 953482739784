import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('printed_documents');
const { mutations } = commonStore('printed-documents');

export default {
	...mutations,
	[types.DOCUMENTS_SUCCESS]: (state, { documents, cashbook, totals }) => {
		state.props = {
			...state.props,
			documents,
			cashbook,
			totals
		};
	},
	[types.FINALIZE_SUCCESS]: (state, cashbook) => {
		state.props = {
			...state.props,
			cashbook: {
				...(state.props?.cashbook || {}),
				...(cashbook ? { salepoint_verified: cashbook.salepoint_verified } : {})
			}
		};
	},
	[types.VERIFY]: () => {},
	[types.VERIFY_ERROR]: () => {},
	[types.VERIFY_SUCCESS]: () => {},
	[types.FINALIZE]: () => {},
	[types.FINALIZE_ERROR]: () => {}
};
