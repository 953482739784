import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const types = core.createTypes('refreshments');

export default core.createStore(actions, getters, mutations, {
	key: 'refreshments',
	strict: true,
	initial: 'idle',
	states: {
		idle: {
			entry: 'getRefreshments',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.PAGE_DATA_SUCCESS]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_REFRESHMENT_FORM]: 'refreshment_form',
						[types.OPEN_REJECT_MODAL]: 'reject_modal',
						[types.OPEN_NOT_ACCEPT_MODAL]: 'not_accept_modal',
						[types.OPEN_CONFIRM_ACCEPT_MODAL]: 'confirm_accept_modal',
						[types.OPEN_ALERT_MODAL]: 'alert_modal'
					}
				},
				refreshment_form: {
					on: {
						[types.CLOSE_REFRESHMENT_FORM]: 'main',
						[types.OPEN_ALERT_MODAL]: 'alert_modal'
					}
				},
				reject_modal: {
					on: {
						[types.CLOSE_REJECT_MODAL]: 'main'
					}
				},
				not_accept_modal: {
					on: {
						[types.CLOSE_NOT_ACCEPT_MODAL]: 'main'
					}
				},
				confirm_accept_modal: {
					on: {
						[types.CLOSE_CONFIRM_ACCEPT_MODAL]: 'main',
						[types.OPEN_ACCEPT_MODAL]: 'accept_modal'
					}
				},
				accept_modal: {
					on: {
						[types.CLOSE_ACCEPT_MODAL]: 'main'
					}
				},
				alert_modal: {
					on: {
						[types.CLOSE_ALERT_MODAL]: 'main'
					}
				}
			}
		}
	}
});
